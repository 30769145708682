import React, { useEffect, useState } from 'react';

import s from './PersonalDataBlock.module.scss';
import questionId from '../../assets/img/questionId.svg';
import Button from '../ui/Button/Button';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import osisstant from '../../assets/img/onlineOsisstant.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PersonalDataBlock({ user, setUserInfo }, OnSumbit) {
  const lang = useSelector((state) => state.lang.language);

  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  const [name, setName] = useState(user.name);
  const [tel, setTel] = useState(user.tel);
  const [surname, setSurname] = useState(user['surname']);
  const [email, setEmail] = useState(user['email']);

  const [errors, setErrors] = useState({ name: '', tel: '', email: '' });

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  };

  const notifyOk = () =>
    toast(
      <div>
        <span style={imgOssistant}>Ваши данные успешно сохранены!</span>
        <img width={300} src={osisstant} />
      </div>,
    );

  console.log(name);
  const editUser = async () => {
    if (
      name.replace(/\s+/g, '').trim().length &&
      tel &&
      email.length &&
      (tel.length === 11 || tel.length > 11) &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      await axios.patch(`${BASE_URL}/client/user/me`, { name, tel, surname, email }, config);
      notifyOk();
      toast.clearWaitingQueue();
      console.log(editUser);
      setErrors('');
      setTimeout(() => {
        setUserInfo(user);
      }, 1000);
    } else {
      if (!name.length) {
        setErrors((prev) => ({
          ...prev,
          name: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!tel) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (tel.length < 11 && tel.length >= 1) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Номер введен некорректно' : 'Number entered incorrectly',
        }));
      }
      if (!email.length) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Неверный Email' : 'Invalid Email Address',
        }));
      }
    }
  };
  console.log(errors, tel);
  // const editAvatar = async () => {
  //   const formData = new FormData();
  //   formData.append('files', avatar);
  //   axios
  //     .patch(`https://back.roboweb.site/client/user/avatar`, formData, config)
  //     .then((res) => {
  //       console.log(`Success` + res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    setName(user.name);
    setTel(user.tel);
    setSurname(user.surname);
    setEmail(user.email);
  }, [user]);

  return (
    <div className={s.personalDataBlock}>
      <div className={s.idBox}>
        <span className={s.yourId}>{lang === 'ru' ? `Ваш ID` : `Your ID`}</span>
        <img className={s.questionId} src={questionId} alt="Question" />
      </div>
      <input className={s.inputId} type="text" readOnly={true} defaultValue={user.id} />
      <div className={s.nameBox}>
        <span className={s.yourName}>
          {lang === 'ru' ? `Ваше имя` : `Your name`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputName}
        type="text"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setErrors({ ...errors, name: '' });
        }}
      />
      {errors.name ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.name}</span>
        </p>
      ) : null}
      <div className={s.surnameBox}>
        <span className={s.yourSurname}>{lang === 'ru' ? `Ваша фамилия` : `Your last name`}</span>
      </div>
      <input
        className={s.inputSurname}
        type="text"
        value={surname}
        onChange={(e) => setSurname(e.target.value)}
      />
      <div className={s.telephoneBox}>
        <span className={s.telephone}>
          {lang === 'ru' ? `Телефон` : `Phone`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <PhoneInput
        specialLabel={''}
        country={'ru'}
        placeholder="+7"
        className={s.inputTelephone}
        type="number"
        value={tel}
        onChange={(tel) => {
          setTel(tel);
          setErrors({ ...errors, tel: '' });
        }}
      />
      {errors.tel ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.tel}</span>
        </p>
      ) : null}
      <div className={s.emailBox}>
        <span className={s.email}>
          {lang === 'ru' ? `E-mail` : `E-mail`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputEmail}
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setErrors({ ...errors, email: '' });
        }}
      />
      {errors.email ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.email}</span>
        </p>
      ) : null}
      <Button
        onClick={() => {
          editUser();
          // OnSumbit();
        }}
        className={s.btnSave}
      >
        {lang === 'ru' ? `Сохранить` : `Save`}
      </Button>
      <ToastContainer hideProgressBar={true} autoClose={6000} limit={1} position="bottom-left" />
      <span className={s.removeAccount}>
        {lang === 'ru' ? `Удалить аккаунт` : `Delete account`}
      </span>
    </div>
  );
}

export default PersonalDataBlock;
