import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { mediaBreakpoints } from "../constants/mediaBreakpoints";

const Screen = ({ children, size, down }) => {
  const [mounted, setMounted] = useState(false);
  const [media, setMedia] = useState({ minWidth: mediaBreakpoints[size] });
  useEffect(() => {
    setMounted(true);
  }, []);

  let screen = useMediaQuery(media);

  useEffect(() => {
    if (down) {
      screen = setMedia({
        maxWidth: mediaBreakpoints[size] - 0.02,
      });
    } else {
      screen = setMedia({
        minWidth: mediaBreakpoints[size],
      });
    }
  }, [size, down]);

  return mounted && screen ? children : null;
};

export default Screen;