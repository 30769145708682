import React, { useEffect, useState } from 'react';

import s from './PersonalDataAdministratorBlock.module.scss';
import questionId from '../../assets/img/questionId.svg';
import osisstant from '../../assets/img/onlineOsisstant.svg';
import Button from '../ui/Button/Button';
import { BASE_URL } from '../..';
import PhoneInput from 'react-phone-input-2';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

function PersonalDataAdministratorBlock({ setUserInfo, setEditFunction }) {
  const lang = useSelector((state) => state.lang.language);

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    width: '180px',
  };
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({ name: '', tel: '', email: '' });

  const notify = () =>
    toast(
      <div>
        <span style={imgOssistant}>
          {lang === 'ru'
            ? 'Ваши данные успешно сохранены!'
            : 'Your data has been successfully saved!'}
        </span>
        <img width={300} src={osisstant} />
      </div>,
    );

  useEffect(() => {
    fetch(`${BASE_URL}/client/user/me`, {
      method: 'GET',
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
      .then((result) => result.json())
      .then((data) => setUser(data.user))
      .catch((err) => console.log(err));
  }, []);

  const updateUser = () => {
    if (
      user.name.replace(/\s+/g, '').trim().length &&
      user.tel &&
      (user.tel.length === 11 || user.tel.length > 11) &&
      user.email.length &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)
    ) {
      fetch(`${BASE_URL}/client/user/me`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          name: user.name,
          email: user.email,
          surname: user.surname,
          tel: user.tel,
        }),
      })
        .then((result) => result.json())
        .then((data) => {
          console.log('save user');
          setUser({ ...user, ...data });
        })
        .catch((err) => console.log(err));
      notify();
      setTimeout(() => {
        setUserInfo(user);
      }, 1000);
      toast.clearWaitingQueue();
    } else {
      if (!user.name.length) {
        setErrors((prev) => ({
          ...prev,
          name: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!user.tel) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (user.tel.length < 11 && user.tel.length >= 1) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Номер введен некорректно' : 'Number entered incorrectly',
        }));
      }
      if (!user.email.length) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Неверный Email' : 'Invalid Email Address',
        }));
      }
    }
  };

  return (
    <div className={s.personalDataBlock}>
      <div className={s.idBox}>
        <span className={s.yourId}>{lang === 'ru' ? 'Ваш ID' : 'Your ID'}</span>
        <img className={s.questionId} src={questionId} alt="Question" />
      </div>
      <input readOnly={true} className={s.inputId} type="text" defaultValue={user.id} />
      <div className={s.nameBox}>
        <span className={s.yourName}>
          {lang === 'ru' ? 'Ваше имя' : 'Your name'}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputName}
        type="text"
        value={user.name ?? ''}
        onChange={(event) => {
          setUser({ ...user, name: event.target.value });
          setErrors({ ...errors, name: '' });
        }}
      />
      {errors.name ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.name}</span>
        </p>
      ) : // <p className={s.error} style={{ height: 16 }}></p>
      null}
      <div className={s.surnameBox}>
        <span className={s.yourSurname}>{lang === 'ru' ? 'Ваша фамилия' : 'Your last name'}</span>
      </div>
      <input
        className={s.inputSurname}
        type="text"
        onChange={(event) => setUser({ ...user, surname: event.target.value })}
        value={user.surname ?? ''}
      />
      <div className={s.telephoneBox}>
        <span className={s.telephone}>
          {lang === 'ru' ? 'Телефон' : 'Telephone'} <span className={s.requiredField}>*</span>
        </span>
      </div>
      <PhoneInput
        specialLabel={''}
        country={'ru'}
        placeholder="Телефон"
        className={s.inputTelephone}
        type="number"
        onChange={(event) => {
          setUser({ ...user, tel: event });
          setErrors({ ...errors, tel: '' });
        }}
        value={user.tel ?? ''}
      />
      {errors.tel ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.tel}</span>
        </p>
      ) : // <p className={s.error} style={{ height: 16 }}></p>
      null}
      <div className={s.emailBox}>
        <span className={s.email}>
          E-mail <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputEmail}
        type="email"
        value={user.email ?? ''}
        onChange={(event) => {
          setUser({ ...user, email: event.target.value });
          setErrors({ ...errors, email: '' });
        }}
      />
      {errors.email ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.email}</span>
        </p>
      ) : // <p className={s.error} style={{ height: 16 }}></p>
      null}
      <Button
        onClick={() => {
          updateUser();
        }}
        className={s.btnSave}
      >
        {lang === 'ru' ? 'Сохранить' : 'Save'}
      </Button>
      <ToastContainer
        hideProgressBar={true}
        autoClose={6000}
        limit={1}
        // style={{ position: 'relative' }}
        position="bottom-left"
      />
      <span className={s.removeAccount}>
        {lang === 'ru' ? 'Удалить аккаунт' : 'Delete account'}
      </span>
    </div>
  );
}

export default PersonalDataAdministratorBlock;
