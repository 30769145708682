import React from 'react'

import s from './LinkTemplates.module.scss'

function LinkTemplates({children, className}) {
  return (
    <div className={`${s.linkBlock} + ' ' + ${className}`}>
        <a className={s.link} href="/">{children}</a>
    </div>
  )
}


export default LinkTemplates