import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

import s from './AboutTheProjectBlock.module.scss';

// import background from '../../assets/AboutTheProjectIcon/background.svg';
import circleBlue from '../../assets/AboutTheProjectIcon/circleBlue.svg';
import circleLeft from '../../assets/AboutTheProjectIcon/circleLeft.png';
import circleRight from '../../assets/AboutTheProjectIcon/circleRight.png';
import screen from '../../assets/AboutTheProjectIcon/screen.svg';
import Button from '../ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { useSelector } from 'react-redux';

export default function AboutTheProjectBlock() {
  const lang = useSelector((state) => state.lang.language);

  const ref = useRef();
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const navigate = useNavigate();

  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }
  useEffect(() => {
    let circleLeft = document.querySelector(`.${s.circleLeft}`);
    let circleRight = document.querySelector(`.${s.circleRight}`);
    // let circleBlue = document.querySelector(`.${s.circleBlue}`)
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      circleLeft.style.transform = 'translate(-' + x * 80 + 'px, -' + y * 50 + 'px)';
      circleRight.style.transform = 'translate(-' + x * 100 + 'px, -' + y * 70 + 'px)';
      // circleBlue.style.transform = 'translate(-' + x * 38 + 'px, -' + y * 25 + 'px)';
    });
  }, []);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div ref={ref} className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `О проекте RoboWeb` : `About the RoboWeb project`}
          </h2>
          <div className={s.aboutTheProjectBlock}>
            <div className={s.aboutBox}>
              <p className={s.title}>
                {lang === 'ru'
                  ? `Веб-маркетплейс RoboWeb – современная платформа для создания красивых
                и функциональных сайтов для разных целей. Команда профессионалов уже создала готовые
                шаблоны сайтов, которые можно отредактировать под запросы каждого клиента.`
                  : `RoboWeb web marketplace is a modern platform for creating beautiful
                and functional sites for different purposes. A team of professionals has already created ready-made
                site templates that can be edited to suit the needs of each client.`}
              </p>
              <p className={s.title}>
                {lang === 'ru'
                  ? `Если вы готовы создавать классные проекты, то смело становитесь нашим партнёром. У
                нас выгодные условия для сотрудничества, которые вам не предложит ни одна другая
                компания.`
                  : `If you are ready to create cool projects, feel free to become our partner. At
                us favorable conditions for cooperation, which you will not be offered by any other
                company.`}
              </p>
              <p className={s.title}>
                {lang === 'ru'
                  ? `Если вы готовы к нам присоединиться, то нажимайте «Стать
                партнёром».`
                  : `If you are ready to join us, then click "Become
                partner."`}
              </p>
              <ul className={s.title}>
                {lang === 'ru' ? `Как заключить партнёрство?` : `How to conclude a partnership?`}

                <li className={s.title}>
                  {lang === 'ru'
                    ? `1. Создавайте крутой
                  проект`
                    : `1. Create cool
                  project`}
                </li>
                <li className={s.title}>
                  {lang === 'ru'
                    ? `2. Придумывайте современные уникальные дизайны с полноценным
                  функционалом`
                    : `2. Come up with modern unique designs with a full-fledged
                  functionality`}
                </li>
                <li className={s.title}>
                  {lang === 'ru'
                    ? `3. Присылайте работу на
                  модерацию`
                    : `3. Submit your work to
                  moderation`}
                </li>
              </ul>
              <p className={s.title}>
                {lang === 'ru'
                  ? `После оценки работы и прохождения на соответствие техническим
                требованиям мы заключим с вами соглашение о партнёрстве.`
                  : `After evaluating the work and passing for compliance with technical
                requirements, we will conclude a partnership agreement with you.`}
              </p>
              <Button
                onClick={() =>
                  localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
                }
                className={s.btnPartners}
              >
                {lang === 'ru' ? `Стать партнером` : `Become partner`}
              </Button>
            </div>
            <div className={s.imgBox}>
              <img className={s.screen} src={screen} alt="Screen" />
              <img className={s.circleLeft} src={circleLeft} alt="Circle" />
              <img className={s.circleRight} src={circleRight} alt="Circle" />
              <img className={s.circleBlue} src={circleBlue} alt="Circle" />
            </div>
          </div>
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}
