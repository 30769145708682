import React, { useEffect, useState } from 'react';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import s from './PartnerMyWorksModeration.module.scss';
import avatar from '../../assets/reviewsImg/avatar.jpeg';
import Button from '../ui/Button/Button';
import ButtonTemlates from '../ui/ButtonTemplates/ButtonTemplates';
import envelope from '../../assets/img/envelope.svg';
import axios from 'axios';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
import SkeletonsMySiteBlock from '../SkeletonsMySiteBlock/SkeletonsMySiteBlock';

function PartnerMyWorksModeration() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(lang === 'ru' ? `По названию` : 'By name');
  const [activeId, setActiveId] = useState(0);
  // const [activeOtherId, setActiveOtherId] = useState(0);
  // const [activeTreeDots, setActiveTreeDots] = useState(false);
  const [sortType, setSortType] = useState('NAME');
  const [isLoading, setIsLoading] = useState(true);

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  const skeletons = [...new Array(6)].map((_, i) => <SkeletonsMySiteBlock key={i} />);

  //Получение и фильтрация шаблонов партнера

  const [myTemplate, setMyTemplate] = useState([]);

  const getMyTemplate = async () => {
    let templateList = await axios.get(`${BASE_URL}/client/partner/templates/moderation`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setMyTemplate(templateList.data.templateList);
  };

  const sortTemplate = async (sortType) => {
    let sortTemplate = await axios.get(
      `${BASE_URL}/client/partner/templates/approved?templateSortType=${sortType}&orderSortType=ASC`,
      {
        headers: { 'x-access-token': localStorage.getItem('token') },
      },
    );
    setMyTemplate(sortTemplate.data.templateList);
  };

  useEffect(() => {
    getMyTemplate();
  }, []);

  return (
    <div className={s.mainContainer}>
      <div className={s.sortedBlock}>
        <span onClick={() => setActive(!active)} className={s.sortedMenu}>
          {sortedName}
        </span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  setSortType(obj.sortType);
                  sortTemplate(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        <div className={s.mySiteBlock}>
          {myTemplate.length ? (
            myTemplate.map((temp) => {
              return (
                <div className={s.cardBox}>
                  <img className={s.siteImage} src={avatar} alt="Site" />
                  <div className={s.aboutSite}>
                    <span className={s.siteName}>{temp.title}</span>
                    <span className={s.categorySite}>Зачем партнеру выбирать категорию ?</span>
                    <span className={s.id}>ID {temp.id}</span>
                    {/* <span className={s.sitePrice}>1 015 ₽</span> */}
                  </div>
                  <div className={s.buttonBox}>
                    <Button
                      className={
                        temp.status.toLowerCase() === 'sent'
                          ? s.btnSend
                          : temp.status.toLowerCase() === 'consideration'
                          ? s.btnUnderConsideration
                          : temp.status.toLowerCase() === 'approved'
                          ? s.btnApproved
                          : temp.status.toLowerCase() === 'not_approved'
                          ? s.btnRejected
                          : ''
                      }
                    >
                      {(temp.status.toLowerCase() === 'sent' && lang === 'ru' && 'Отправлено') ||
                        (temp.status.toLowerCase() === 'sent' && lang === 'en' && 'Sent')}
                      {(temp.status.toLowerCase() === 'consideration' &&
                        lang === 'ru' &&
                        'На рассмотрении') ||
                        (temp.status.toLowerCase() === 'consideration' &&
                          lang === 'en' &&
                          'Consideration')}
                      {(temp.status.toLowerCase() === 'approved' && lang === 'ru' && 'Одобрено') ||
                        (temp.status.toLowerCase() === 'approved' && lang === 'en' && 'Approved')}
                      {(temp.status.toLowerCase() === 'not_approved' &&
                        lang === 'ru' &&
                        'Отклонено') ||
                        (temp.status.toLowerCase() === 'not_approved' &&
                          lang === 'en' &&
                          'Rejected')}
                    </Button>
                    {/* <img src={envelope} alt="The envelope" /> */}
                  </div>
                </div>
              );
            })
          ) : isLoading ? (
            skeletons
          ) : (
            <div className={s.cardBoxoMain}>
              <p className={s.cardBoxo}>Список пуст</p>
            </div>
          )}
          {/* <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnSend}>Отправлено</Button>
              <img src={envelope} alt="The envelope" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnApproved}>Одобрено</Button>
              <img src={threeDots} alt="Three dots" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnRejected}>Отклонено</Button>
              <img className={s.envelope} src={envelope} alt="The envelope" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnUnderConsideration}>
                На рассмотрении
              </Button>
              <img src={threeDots} alt="" />
            </div>
          </div> */}
        </div>
        {/* <ButtonTemlates className={s.btnCreateSite}>Создать сайт</ButtonTemlates> */}
      </div>
    </div>
  );
}

export default PartnerMyWorksModeration;
