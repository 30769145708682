import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editDescription,
  editKeywords,
  editTitle,
} from '../../../app/features/editMeta';
import Button from '../../ui/Button/Button';

import titleImg from '../../../assets/TemplateUpdate/пример.svg';
import closeBtn from '../../../assets/TemplateUpdate/close.svg';
import favicon from '../../../assets/TemplateUpdate/favicon.svg';

import s from './changeMeta.module.scss';
import { useParams } from 'react-router-dom';
import $api from '../../../http';
import axios from 'axios';
import { historyPush, histoyPopImg } from '../../../app/features/editTemplate';
import { cropperEdit } from '../CropperEditor';

const ChangeMeta = ({ iframe, metaVisible, setMetaVisible, disabledRef }) => {
  const { templateId } = useParams();
  const title = useSelector((state) => state.title.title);
  const keywords = useSelector((state) => state.title.keywords);
  const description = useSelector((state) => state.title.description);
  const lang = useSelector((state) => state.lang.language);
  const dispatch = useDispatch();

  const [changeBgPopup, setChangeBgPopup] = useState(false);
  const [changePrPopup, setChangePrPopup] = useState(false);
  const [img, setImg] = useState([]);
  const [item, setItem] = useState('');
  const [page, setPage] = useState(1);
  const [src, setSrc] = useState(iframe.body?.querySelector('#rw_gmaps')?.src);
  const [srcLogo, setSrcLogo] = useState('');
  const [changeFaviconPopup, setChangeFaviconPopup] = useState(false);

  const handleMetaVisible = () => {
    setMetaVisible(false);
    disabledRef.current = false;
  };

  const handleGMaps = (e) => {
    e.preventDefault();
    iframe.body.querySelector('#rw_gmaps').src = src;
  };

  const handleFavicon = async (e) => {
    let newImg = e.target.files[0];
    const formData = new FormData();
    formData.append('files', newImg);

    try {
      await axios.patch(
        `https://back.roboweb.site/client/edit/images?templateId=${templateId}`,
        formData,
        {
          headers: { 'x-access-token': localStorage.getItem('token') },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    // setMetaVisible(false);
    handleMetaVisible();
    setChangeBgPopup(false);
  };

  const handleTitle = () => {
    iframe.head.getElementsByTagName('title')[0].textContent = title;
    iframe.head.getElementsByTagName('meta')[0].content = description;
    iframe.head.getElementsByTagName('meta')[1].content = keywords;
    closeModal();
  };

  //ПОЛУЧЕНИЕ ВСЕХ BG НА СТРАНИЦЕ

  const arrBg = iframe?.body?.querySelectorAll('[data-slide-bg]');
  let srcBg = [];
  arrBg?.forEach((el) => srcBg.push(el.dataset.slideBg));

  const editBgPopup = (i) => {
    setItem(i);
    setChangeBgPopup(!changeBgPopup);
  };

  const editBg = (src) => {
    let newSrc = src.includes('unsplash')
      ? `url('${src}')`
      : `url('images/${src}')`;
    let newDataset = src.includes('unsplash') ? src : `images/${src}`;
    iframe.body.querySelectorAll('[data-slide-bg]')[
      item
    ].style.backgroundImage = newSrc;
    iframe.body.querySelectorAll('[data-slide-bg]')[item].dataset.slideBg =
      newDataset;
    console.log(iframe.body.querySelectorAll('[data-slide-bg]')[item]);
    setChangeBgPopup(false);
  };

  //ЗАКРЫТИЕ МОДАЛКИ С КАРТИНКАМИ ДЛЯ ИЗМЕНЕНИЯ

  const closePopup = (callback) => {
    dispatch(histoyPopImg());
    callback(false);
  };

  //ПОЛУЧЕНИЕ ВСЕХ PARALAX НА СТРАНИЦЕ

  const arrPr = iframe?.body?.querySelectorAll('.parallax-container');
  let srcPr = [];
  arrPr?.forEach((el) => srcPr.push(el.dataset.parallaxImg));

  const getImage = async () => {
    let imageList = await $api.get(
      `/client/edit/images?templateId=${templateId}`
    );
    setImg(imageList.data.imageList);
  };

  const editPrPopup = (i) => {
    setItem(i);
    setChangePrPopup(!changePrPopup);
  };

  const editPr = (src) => {
    let newSrc = src.includes('unsplash')
      ? `url('${src}')`
      : `url('images/${src}')`;
    let newDataset = src.includes('unsplash') ? src : `images/${src}`;
    iframe.body.querySelectorAll('.parallax-container')[
      item
    ].style.backgroundImage = newSrc;
    iframe.body.querySelectorAll('.parallax-container')[
      item
    ].dataset.parallaxImg = newDataset;

    iframe.body.querySelectorAll('.material-parallax')[item].childNodes[0].src =
      newDataset;

    setChangePrPopup(false);
  };

  const editFavPopup = () => {
    setChangeFaviconPopup(!changeFaviconPopup);
  };

  const editFavicon = (src, i) => {
    let newSrc = src.includes('unsplash') ? src : `images/${src}`;
    let newDataset = src.includes('unsplash') ? src : `images/${src}`;
    let test = iframe.body.querySelectorAll('#logo');
    [...test].map((item) => {
      item.src = newSrc;
    });
    setChangeFaviconPopup(false);
  };

  //ПОЛУЧЕНИЕ ВСЕХ КАРТИНОК

  useEffect(() => {
    getImage();
  }, []);

  const nextImage = () => {
    setPage(page + 1);
    moreImg(page);
  };

  const moreImg = async () => {
    let imageList = await axios.get(
      `https://api.unsplash.com/search/collections?client_id=lwQAsZjOvPkGepXZtjseqeyHT9XzXaR_C6AsPLhiBv4&query=car&page=${page}&per_parge=10`
    );
    let newImg = [];
    imageList.data.results.map((data) => {
      newImg.push(data.cover_photo.urls.regular);
    });
    setImg([...img, ...newImg]);
  };

  return (
    metaVisible && (
      <section className={s.section}>
        <div className={s.block}>
          <img className={s.btn} src={closeBtn} alt="X" onClick={closeModal} />
          <div className={s.block_1}>
            <h3 className={s.title_1}>
              {lang === 'ru' ? 'Настройки сайта' : 'Settings site'}
            </h3>
            <p className={s.pr_1}>
              {lang === 'ru'
                ? 'Заголовок, описание и ключевые слова помогают определить поисковому роботу, какие товары или услуги вы предлагаете, и какому пользователю показать ваш сайт в поисковой выдаче в ответ на его вопрос. Постарайтесь максимально точно и ясно составить данные мета-теги, ведь они играют важную роль в развитии сайта и привлечении целевых пользователей.'
                : 'The title, description, and keywords help the crawler determine what products or services you offer and which user to show your site in the search results in response to his question. Try to make these meta tags as accurate and clear as possible, because they play an important role in the development of the site and attracting target users.'}{' '}
            </p>
          </div>
          <form className={s.block_2}>
            <div className={s.container_1}>
              <h3 className={s.title_2}>
                {lang === 'ru' ? 'Заголовок' : 'Title'}
              </h3>
              <div className={s.blockTitle}>
                <input
                  className={s.input}
                  value={title}
                  onChange={(e) => dispatch(editTitle(e.target.value))}
                />
                <img src={titleImg} alt="Пример" className={s.img} />
              </div>
              <p className={s.pr_2}>
                {lang === 'ru'
                  ? 'Заголовок — это название сайта в поисковой выдаче и на вкладке браузера. Составить его нужно, используя до 3-х самых важных ключевых фраз для Вашего сайта и название компании. Необходимо избежать использования символов (= / + _ ) и точек, двоеточий, восклицательных и вопросительных знаков. Например: «Интернет магазин Варвара — купить косметику в Москве недорого». Рекомендованная длина заголовка — до 60 символов.'
                  : 'The title is the name of the site in the search results and on the browser tab. You need to compose it using up to 3 of the most important key phrases for your site and the name of the company. The use of symbols (= / + _ ) and periods, colons, exclamation points, and question marks must be avoided. For example: "Online store Varvara - buy cosmetics in Moscow inexpensively." The recommended title length is up to 60 characters.'}
              </p>
            </div>
            <div className={s.container_1}>
              <h3 className={s.title_2}>
                {lang === 'ru' ? 'Ключевые слова' : 'Keywords'}
              </h3>
              <textarea
                className={s.textarea}
                placeholder={
                  lang === 'ru' ? 'Введите ключевые слова' : 'Enter keywords'
                }
                rows="4"
                onChange={(e) => dispatch(editKeywords(e.target.value))}
              />
              <p className={s.pr_2}>
                {lang === 'ru'
                  ? 'Ключевые слова — это фразы, соответствующие содержимому страницы. Именно их вводит пользователь в строку поиска, и именно на них поисковая система дает ответ. Подобрать Вам их помогут сервисы Яндекс и Googl. Рекомендуем на одну страницу таких фраз подбирать не более 10-ти, а написать их нужно без заглавных букв, тире и прочих символов, через запятую, например: «купить косметику в москве, купить косметику недорого, инернет магазин купить косметику».'
                  : 'Keywords are phrases that match the content of the page. It is them that the user enters into the search bar, and it is on them that the search engine gives an answer. Yandex and Google services will help you pick them up. We recommend that you select no more than 10 such phrases per page, and you need to write them without capital letters, dashes and other symbols, separated by commas, for example: “buy cosmetics in Moscow, buy cosmetics inexpensively, buy cosmetics online store”.'}
              </p>
            </div>
            <div className={s.container_1}>
              <h3 className={s.title_2}>
                {lang === 'ru' ? 'Описание' : 'Description'}
              </h3>
              <textarea
                className={s.textarea}
                placeholder={
                  lang === 'ru' ? 'Введите описание' : 'Enter description'
                }
                rows="6"
                onChange={(e) => dispatch(editDescription(e.target.value))}
              />
              <p className={s.pr_2}>
                {lang === 'ru'
                  ? 'Описание — это небольшой текст, который поисковая система показывает в сниппете. Именно этот текст должен рассказывать содержания страницы, ведь это поможет поисковику в ранжировании страницы в поиске. Размер описания рекомендуем делать не более 160 символов, максимум — 200. Например: «Интернет магазин Варвара: здесь можно купить косметику в Москве недорого с бесплатной доставкой. Большой ассортимент! Премиум бренды оригинального качества.»'
                  : 'The description is a small piece of text that the search engine shows in the snippet. It is this text that should tell the content of the page, because it will help the search engine in ranking the page in the search. We recommend that the description size be no more than 160 characters, maximum 200. For example: “Varvara online store: here you can buy cheap cosmetics in Moscow with free delivery. A large assortment! Premium brands of original quality.”'}
              </p>
            </div>

            <div className={s.container_1}>
              <h3 className={s.title_2}>Гугл карты</h3>
              <div className={s.blockTitle}>
                <input
                  className={s.inputGoogle}
                  value={src}
                  onChange={(e) => setSrc(e.target.value)}
                  placeholder="Вставьте ссылку"
                />
              </div>
              <button className={s.imgBtn} onClick={(e) => handleGMaps(e)}>
                Заменить
              </button>
            </div>
          </form>
        </div>
        {srcBg.length > 0 && (
          <div className={s.blockEdit}>
            <h3 className={s.title}>Замена баннеров</h3>
            <div className={s.editBg}>
              {srcBg.map((url, i) => (
                <div className={s.editBgContainer} key={i + Math.random()}>
                  <img
                    className={s.imgBg}
                    id="editImg"
                    src={
                      url.includes('unsplash') || url.includes('data:image')
                        ? url
                        : `/templates/${templateId}/${url}`
                    }
                    alt="slide"
                  />
                  {/* <button
                    className={s.btnEditBg}
                    onClick={() => editBgPopup(i)}
                  > */}
                  <button className={s.imgBtn} onClick={() => editBgPopup(i)}>
                    Заменить
                  </button>
                  {/* </button> */}
                  {/* <button
                    className={s.btnEditBg}
                    onClick={() => {
                      console.log(
                        iframe.body.querySelectorAll('[data-slide-bg]')[i]
                      );
                      cropperEdit(
                        iframe,
                        iframe.body.querySelectorAll('[data-slide-bg]')[i],
                        iframe.body.querySelectorAll('[data-slide-bg]')[i],
                        disabledRef,
                        dispatch,
                        historyPush,
                        cropperMeta
                      );
                    }}
                  > */}
                  <button
                    className={s.imgBtn}
                    onClick={() => {
                      cropperEdit(
                        iframe,
                        iframe.body.querySelectorAll('[data-slide-bg]')[i],
                        iframe.body.querySelectorAll('[data-slide-bg]')[i],
                        disabledRef,
                        dispatch,
                        historyPush,
                        'bg',
                        templateId
                      );
                      // setMetaVisible(false);
                      handleMetaVisible();
                    }}
                  >
                    Редактировать
                  </button>

                  {/* </button> */}
                </div>
              ))}
            </div>
          </div>
        )}
        {srcPr.length > 0 && (
          <div className={s.blockEdit}>
            <h3 className={s.title}>Паралакс</h3>
            <div className={s.editBg}>
              {srcPr.map((url, i) => (
                <div className={s.editBgContainer} key={i + Math.random()}>
                  <img
                    className={s.imgBg}
                    id="editImg"
                    src={
                      url.includes('unsplash') || url.includes('data:image')
                        ? url
                        : `/templates/${templateId}/${url}`
                    }
                    alt="slide"
                  />
                  <button className={s.imgBtn} onClick={() => editPrPopup(i)}>
                    Заменить
                  </button>
                  <button
                    className={s.imgBtn}
                    onClick={() => {
                      cropperEdit(
                        iframe,
                        iframe.body.querySelectorAll('.parallax-container')[i],
                        iframe.body.querySelectorAll('.parallax-container')[i],
                        disabledRef,
                        dispatch,
                        historyPush,
                        'pr',
                        templateId
                      );
                      // setMetaVisible(false);
                      handleMetaVisible();
                    }}
                  >
                    Редактировать
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={s.favicon}>
          <div className={s.block_1}>
            <h3 className={s.title}>Favicon</h3>
            <p className={s.text}>
              Favicon - это иконка, которая отображается около заголовка сайта
              на вкладке браузера перед названием или заголовком. Присутствие
              такого значка важно, поскольку он является одной из составляющих
              интерфейса.
            </p>
          </div>
          <div className={s.block_2}>
            <input
              // value={src}
              className={s.input}
              type="file"
              onChange={(e) => handleFavicon(e)}
              placeholder="Вставьте ссылку"
              id="input_file"
            />
            {/* <button>Загрузить</button> */}
            <button onClick={editFavPopup}>Заменить</button>
            <label for="input_file">Загрузить</label>
            <p className={s.placeholder}>
              Изображение должно быть размером 32х32 пикселя. Поддерживаемые
              форматы - png, jpeg. Макс. 1 МБ.
            </p>
            <img src={favicon} className={s.img}></img>
          </div>
          {/* <button onClick={editFavPopup}>Заменить</button> */}
        </div>
        <Button
          className={s.btn_submit}
          onClick={() => {
            handleTitle();
            // handleGMaps();
          }}
        >
          Сохранить
        </Button>
        {changeBgPopup && (
          <div className={s.changeImg}>
            <div className={s.closeBtn}>
              <img
                src={closeBtn}
                alt="X"
                onClick={() => closePopup(setChangeBgPopup)}
              />
            </div>
            <div className={s.imgList}>
              {img.map((src, i) => (
                <img
                  src={
                    src.includes('unsplash')
                      ? src
                      : `/templates/${templateId}/images/${src}`
                  }
                  alt="img"
                  className={s.imgItem}
                  onClick={() => editBg(src, i)}
                />
              ))}
            </div>
            <button className={s.moreBtn} onClick={nextImage}>
              Еще
            </button>
          </div>
        )}
        {changePrPopup && (
          <div className={s.changeImg}>
            <div className={s.closeBtn}>
              <img
                src={closeBtn}
                alt="X"
                onClick={() => closePopup(setChangePrPopup)}
              />
            </div>
            <div className={s.imgList}>
              {img.map((src, i) => (
                <img
                  src={
                    src.includes('unsplash')
                      ? src
                      : `/templates/${templateId}/images/${src}`
                  }
                  alt="img"
                  className={s.imgItem}
                  onClick={() => editPr(src, i)}
                />
              ))}
            </div>
            <div className={s.btnImg}>
              <button className={s.moreBtn} onClick={nextImage}>
                Еще
              </button>
              {/* <button className={s.addBtn}></button> */}
            </div>
          </div>
        )}
        {changeFaviconPopup && (
          <div className={s.changeImg}>
            <div className={s.imgList}>
              {img.map((src, i) => (
                <img
                  src={
                    src.includes('unsplash')
                      ? src
                      : `/templates/${templateId}/images/${src}`
                  }
                  alt="img"
                  className={s.imgItem}
                  onClick={() => editFavicon(src, i)}
                />
              ))}
            </div>
            <div className={s.btnImg}>
              <button className={s.moreBtn} onClick={nextImage}>
                Еще
              </button>
              {/* <button className={s.addBtn}></button> */}
            </div>
          </div>
        )}
      </section>
    )
  );
};

export default ChangeMeta;
