/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import s from './SignUp.module.scss';
import logo from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';

import exit from '../../assets/img/exit.svg';
import viewPassword from '../../assets/img/viewPassword.svg';
import hiddenPassword from '../../assets/img/hiddenPassword.svg';
import Button from '../ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../app/features/user/userSlice';
import { BASE_URL } from '../..';

function SignUp(props) {
  const [toggle, setToggle] = useState('CLIENT');
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');

  const lang = useSelector((state) => state.lang.language);

  function handleClose() {
    setShow(false);
    props.closeModal(false);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      check: [],
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = lang === 'ru' ? 'Введите имя' : `Enter name`;
      }

      if (!values.email) {
        errors.email = lang === 'ru' ? 'Введите Email' : `Enter Email`;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = lang === 'ru' ? `Неверный формат Email` : `Invalid email format`;
      }

      if (!values.password) {
        errors.password = lang === 'ru' ? `Введите пароль` : `Enter password`;
      } else if (values.password < 6) {
        errors.password =
          lang === 'ru'
            ? 'Пароль должен быть не менее 6 символов'
            : `Password must be at least 6 characters`;
      }

      if (!values.repeatPassword) {
        errors.repeatPassword = lang === 'ru' ? 'Введите пароль повторно' : `Enter password again`;
      } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = lang === 'ru' ? 'Пароли не совпадают' : `Passwords do not match`;
      }

      if (!values.check.includes('agree')) {
        errors.check = lang === 'ru' ? 'Примите соглашение' : `Accept the agreement`;
      }

      return errors;
    },
    onSubmit: ({ name, email, password, systemRole = toggle }) => {
      fetch(`${BASE_URL}/auth/sign-up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, name, systemRole }),
      })
        .then((result) => result.json())
        .then((data) => {
          if (data.message) {
            // setError(data.message);
            setError(
              lang === 'ru'
                ? `Пользователь с такой почтой уже зарегистрирован`
                : `User with this email is already registered`,
            );
          }
          if (data.token) {
            setShow(false);
            props.openSignIn();
          }
        })
        .catch((err) => console.log(err));
    },
  });

  //Отображение пароля
  const [type, setType] = useState('password');
  const [typeRepeat, setTypeRepeat] = useState('password');
  const typePassword = (e) => {
    e.preventDefault();
    setType(type === 'password' ? 'text' : 'password');
  };
  const typePasswordRepeat = (e) => {
    e.preventDefault();
    setTypeRepeat(typeRepeat === 'password' ? 'text' : 'password');
  };

  function enableScroll() {
    document.body.classList.remove('disabled-scroll');
  }
  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
        opacity: show ? '1' : '0',
      }}
      className={s.wrapper}
    >
      <form onSubmit={formik.handleSubmit} className={s.signUpBox}>
        <img
          onClick={() => {
            handleClose();
            enableScroll();
          }}
          className={s.exit}
          src={exit}
          alt="Exit"
        />
        <img className={s.logo} src={logo} alt="Logo" />
        <img className={s.logoMobile} src={logoMobile} alt="Logo" />
        <h2 className={s.mainTitle}>{lang === 'ru' ? 'Регистрация' : `Register`}</h2>
        <div className={s.toggleContainer}>
          <div className={s.toggleBox}>
            <div
              onClick={() => setToggle('CLIENT')}
              className={s[toggle === 'CLIENT' ? 'toggleACtive' : 'toggleItem']}
            >
              {lang === 'ru' ? 'Пользователь' : `User`}
            </div>
            <div
              onClick={() => setToggle('PARTNER')}
              className={s[toggle === 'PARTNER' ? 'toggleACtive' : 'toggleItem']}
            >
              {/* {lang === 'ru' ? 'Пользователь' : `User`} */}
              {lang === 'ru' ? 'Партнер' : `Partner`}
            </div>
          </div>
        </div>
        <div className={s.inputBox}>
          <input
            placeholder={lang === 'ru' ? 'Имя' : `Name`}
            className={s.inputName}
            type="text"
            id={'name'}
            name={'name'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className={s.error}>
              <span className={s.errorVisible}>{formik.errors.name}</span>
            </p>
          ) : // <p className={s.error} style={{ height: 16 }}></p>
          null}
          <input
            placeholder="E-mail"
            className={s.inputName}
            type="email"
            id={'email'}
            name={'email'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className={s.error}>
              <span className={s.errorVisible}>{formik.errors.email}</span>
            </p>
          ) : // <p className={s.error} style={{ height: 16 }}></p>
          null}
          <div className={s.passwordVisibleBlock}>
            <input
              placeholder={lang === 'ru' ? 'Пароль' : `Password`}
              className={s.inputName}
              type={type}
              id={'password'}
              name={'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />

            <img
              src={type === 'password' ? hiddenPassword : viewPassword}
              className={s.passwordVisible}
              onClick={typePassword}
            />
          </div>
          {formik.touched.password && formik.errors.password ? (
            <p className={s.error}>
              <span className={s.errorVisible}>{formik.errors.password}</span>
            </p>
          ) : // <p className={s.error} style={{ height: 16 }}></p>
          null}
          <div className={s.passwordVisibleBlock}>
            <input
              placeholder={lang === 'ru' ? 'Повторите пароль' : `Confirm password`}
              className={s.inputName}
              type={typeRepeat}
              id={'repeatPassword'}
              name={'repeatPassword'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.repeatPassword}
            />

            <img
              src={typeRepeat === 'password' ? hiddenPassword : viewPassword}
              className={s.passwordVisible}
              onClick={typePasswordRepeat}
            />
          </div>
          {formik.touched.repeatPassword && formik.errors.repeatPassword ? (
            <p className={s.error}>
              <span className={s.errorVisible}>{formik.errors.repeatPassword}</span>
            </p>
          ) : // <p className={s.error} style={{ height: 16 }}></p>
          null}
        </div>
        <div className={s.confidentiallyBlock}>
          <div className={lang === 'ru' ? s.confidentiallyBox : s.confidentiallyBoxEn}>
            <input
              className={s.checkBox}
              type="checkbox"
              id={'check'}
              name={'check'}
              onChange={formik.handleChange}
              value={'agree'}
            />
            <label className={s.inputLabel} htmlFor="check">
              {lang === 'ru' ? 'Принимаю условия' : `I accept the terms`}
            </label>
            <a className={s.linkConfidentially} href="3">
              {lang === 'ru' ? 'Политики конфиденциальности' : `Privacy Policy`}
            </a>
            <span className={s.i}>{lang === 'ru' ? 'и' : `and`}</span>
            <a className={s.linkConfidentially} href="3">
              {lang === 'ru' ? 'Пользовательского соглашения' : `User Agreement`}
            </a>
            {formik.touched.check && formik.errors.check ? (
              <p className={s.error}>
                <span className={s.errorVisible}>{formik.errors.check}</span>
              </p>
            ) : // <p className={s.error} style={{ height: 16 }}></p>
            null}
          </div>
        </div>
        <p className={s.error} style={{ textAlign: 'center', height: 16 }}>
          {error}
        </p>
        <Button type={'submit'} className={s.btnSignUp}>
          {lang === 'ru' ? 'Зарегистрироваться' : `Register`}
        </Button>
        <span className={s.linkSignUp}>
          {lang === 'ru' ? 'Уже зарегистрированы?' : `Already registered?`}
          <span onClick={props.openSignIn} className={s.linkSignIn}>
            {lang === 'ru' ? ' Войти' : ` Sign in`}
          </span>
        </span>
      </form>
    </div>
  );
}

export default SignUp;
