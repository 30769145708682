import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdministrationRequestsForModerationTable from '../AdministrationRequestForModerationTable/AdministrationRequestForModerationTable';

import s from './AdministrationRequestsForModeration.module.scss';

const linkNames = [
  { ru: 'Все', en: 'All', id: 0, sort: '' },
  { ru: 'Новые заявки', en: 'New Applications', id: 1, sort: 'SENT' },
  { ru: 'На проверке', en: 'Under review', id: 2, sort: 'CONSIDERATION' },
  { ru: 'Проверенные', en: 'Verified', id: 3 },
  { ru: 'Отклоненные', en: 'Rejected', id: 4, sort: 'NOT_APPROVED' },
];

function AdministrationRequestsForModeration() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(0);
  const [value, setValue] = useState(1);
  const [offset, setOffset] = useState(0);

  const [templateManagmentStatus, setTemplateManagmentStatus] = useState(false);

  useEffect(() => {
    const $elem = document.querySelector('#message');
    const text = $elem;
    // console.log([...text.children])
    console.log(text);
    $elem.addEventListener('click', () => {
      const newText = prompt('Введите новое значение');
      $elem.innerHTML = newText;
    });
  }, []);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.requestsForModerationBlock}>
            <h2 className={s.mainTitle} id="message">
              {lang === 'ru' ? `Заявки на модерацию` : `Request for moderation`}
            </h2>
            <div className={s.mainLinkBlock}>
              <ul className={s.linkBlock}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => {
                      setActive(obj.id);
                      setTemplateManagmentStatus(obj.sort);
                      setValue(1);
                      setOffset(0);
                    }}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>

            <AdministrationRequestsForModerationTable
              templateManagmentStatus={templateManagmentStatus}
              value={value}
              setValue={setValue}
              offset={offset}
              setOffset={setOffset}
            />
            {/* {active === 0 && <AdministrationTemplatesBlock />}
            {active === 1 && <AdministrationUserSitesBlock />}
            {active === 2 && <AdministrationTableUsers />}
            {active === 3 && <AdministrationTablePartners />}
            {active === 4 && <PartnersUploadWorkBlock />}
            {active === 5 && <AdministrationServiceCost />}
            {active === 6 && <AdministrationReviewsBlock />} */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdministrationRequestsForModeration;
