import React from 'react';
import PortfolioBlockPage from '../../components/PortfolioBlockPage/PortfolioBlockPage';
import PortfolioHeading from '../../components/PortfolioHeading/PortfolioHeading';

function Portfolio() {
  return (
    <>
      <PortfolioHeading />
      <PortfolioBlockPage/>
    </>
  );
}

export default Portfolio;
