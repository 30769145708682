import React from 'react'
import AboutTheProjectBlock from '../../components/AboutTheProjectBlock/AboutTheProjectBlock'
import HowMuchCanYouEarn from '../../components/HowMuchCanYouEarn/HowMuchCanYouEarn'
import PartnersHeading from '../../components/PartnersHeading/PartnersHeading'
import PartnersBenefitsBlock from '../../components/PartersBenefitesBlock/PartnersBenefitsBlock'
import TemplatesPurpose from '../../components/TemplatesPurpose/TemplatesPurpose'
import DigitBlock from '../../components/DigitBlock/DigitBlock'
import WhoSuits from '../../components/WhoSuits/WhoSuits'
import CooperationProcess from '../../components/CooperationProcess/CooperationProcess'
import TopTenPartnersBlock from '../../components/TopTenPartnersBlock/TopTenPartnersBlock'
import ReviewsBlockMain from '../../components/ReviewsBlockMain/ReviewsBlockMain'
import TechnicalRequirements from '../../components/TechnicalRequirements/TechnicalRequirements'
import OurPartnersReceive from '../../components/OurPartnersReceive/OurPartnersReceive'

function Partners() {
  return (
    <>
    <PartnersHeading/>
    <PartnersBenefitsBlock/>
    <AboutTheProjectBlock/>
    <TemplatesPurpose/>
    <WhoSuits/>
    <HowMuchCanYouEarn/>
    <DigitBlock/>
    <CooperationProcess/>
    <TopTenPartnersBlock/>
    <ReviewsBlockMain/>
    <TechnicalRequirements/>
    <OurPartnersReceive/>
    </>
  )
}

export default Partners