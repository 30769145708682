import React, { useEffect, useState } from 'react';

import s from './PartnersPersonalDataBlock.module.scss';
import questionId from '../../assets/img/questionId.svg';
import Button from '../ui/Button/Button';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import { useSelector } from 'react-redux';
import osisstant from '../../assets/img/onlineOsisstant.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';

import { BASE_URL } from '../..';

function PartnersPersonalDataBlock({ infoPartner, setUserInfo }, OnSumbit) {
  const lang = useSelector((state) => state.lang.language);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(lang === 'ru' ? 'Веб-дизайнер' : 'Web Designer');
  const [errors, setErrors] = useState({ name: '', tel: '', email: '' });

  // const [name, setName] = useState(infoPartner.name);
  // const [tel, setTel] = useState(infoPartner.tel);
  // const [surname, setSurname] = useState(infoPartner['surname']);
  // const [email, setEmail] = useState(infoPartner['email']); 

  const options = [
    { ru: 'Веб-дизайнер', en: 'Web Designer' },
    { ru: 'UX/UI дизайнер', en: 'UX/UI Designer' },
    { ru: 'Веб-разработчик', en: 'Web developer' },
  ];
  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  const [partner, setPartner] = useState({});

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  };

  const notify = () =>
    toast(
      <div>
        <span style={imgOssistant}>Ваши данные успешно сохранены!</span>
        <img width={300} src={osisstant} />
      </div>,
    );

  useEffect(() => {
    fetch(`${BASE_URL}/client/user/me`, {
      method: 'GET',
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
      .then((result) => result.json())
      .then((data) => setPartner(data.user))
      .then((err) => console.log(err));
  }, []);
  console.log(partner, 'parter');
  function updatePartner() {
    if (
      partner.name.replace(/\s+/g, '').trim().length &&
      partner.tel &&
      (partner.tel.length === 11 || partner.tel.length > 11) &&
      partner.email.length &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(partner.email)
    ) {
      fetch(`${BASE_URL}/client/user/me`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          name: partner.name,
          email: partner.email,
          surname: partner.surname,
          tel: partner.tel,
        }),
      })
        .then((result) => result.json())
        .then((data) => setPartner({ ...partner, ...data }))
        .then((err) => console.log(err));
      setTimeout(() => {
        setUserInfo(partner);
      }, 1000);
      notify();
      setErrors('');
      toast.clearWaitingQueue();
    } else {
      if (!partner.name.length) {
        setErrors((prev) => ({
          ...prev,
          name: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!partner.tel) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (partner.tel.length < 11 && partner.tel.length >= 1) {
        setErrors((prev) => ({
          ...prev,
          tel: lang === 'ru' ? 'Номер введен некорректно' : 'Number entered incorrectly',
        }));
      }
      if (!partner.email.length) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Обязательное поле' : 'Required field',
        }));
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(partner.email)) {
        setErrors((prev) => ({
          ...prev,
          email: lang === 'ru' ? 'Неверный Email' : 'Invalid Email Address',
        }));
      }
    }
  }

  console.log(partner);
  return (
    <div className={s.partnersPersonalDataBlock}>
      <div className={s.idBox}>
        <span className={s.yourId}>{lang === 'ru' ? `Ваш ID` : `Your ID`}</span>
        <img className={s.questionId} src={questionId} alt="Question" />
      </div>
      <input readOnly={true} className={s.inputId} defaultValue={partner.id} type="text" />
      <div className={s.nameBox}>
        <span className={s.yourName}>
          {lang === 'ru' ? `Ваше имя` : `Your name`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputName}
        type="text"
        value={partner.name ?? ''}
        onChange={(e) => {
          setPartner({ ...partner, name: e.target.value });
          setErrors({ ...errors, name: '' });
        }}
      />
      {errors.name ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.name}</span>
        </p>
      ) : null}
      <div className={s.surnameBox}>
        <span className={s.yourSurname}>{lang === 'ru' ? `Ваша фамилия` : `Your last name`}</span>
      </div>
      <input
        className={s.inputSurname}
        type="text"
        value={partner.surname ?? ''}
        onChange={(e) => {
          setPartner({ ...partner, surname: e.target.value });
        }}
      />
      <div className={s.telephoneBox}>
        <span className={s.telephone}>
          {lang === 'ru' ? `Телефон` : `Phone`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <PhoneInput
        placeholder="+7"
        specialLabel={''}
        country={'ru'}
        className={s.inputTelephone}
        // type="number"
        value={partner.tel ?? ''}
        onChange={(tel) => {
          setPartner({ ...partner, tel });
          setErrors({ ...errors, tel: '' });
        }}
      />
      {errors.tel ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.tel}</span>
        </p>
      ) : null}
      <div className={s.emailBox}>
        <span className={s.email}>
          {lang === 'ru' ? `E-mail` : `E-mail`}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <input
        className={s.inputEmail}
        type="email"
        value={partner.email ?? ''}
        onChange={(e) => {
          setPartner({ ...partner, email: e.target.value });
          setErrors({ ...errors, email: '' });
        }}
      />
      {errors.email ? (
        <p className={s.error}>
          <span className={s.errorVisible}>{errors.email}</span>
        </p>
      ) : null}

      <Button
        onClick={() => {
          updatePartner();
          OnSumbit();
        }}
        className={s.btnSave}
      >
        {lang === 'ru' ? `Сохранить` : `Save`}
      </Button>
      <ToastContainer
        hideProgressBar={true}
        autoClose={6000}
        limit={1}
        // style={{ position: 'relative' }}
        position="bottom-left"
      />
      <span className={s.removeAccount}>
        {lang === 'ru' ? `Удалить аккаунт` : `Delete account`}
      </span>
    </div>
  );
}

export default PartnersPersonalDataBlock;
