import React from 'react';

import logoWeb from '../../assets/img/Logo.svg';
import logoRobo from '../../assets/img/logoRobo.png';
import Container from '../ui/Container/Container';
import styles from './Footer.module.scss';
import neirositeLeft from '../../assets/footerImg/neirosetLeft.svg';
import neirositeRight from '../../assets/footerImg/neirosetRight.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Footer() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <footer className={styles.footer}>
      <img className={styles.neiroSiteLeft} src={neirositeLeft} alt="" />
      <div>
        <Container className={styles.Container}>
          <div className={styles.footerContainer}>
            <div className={styles.logo}>
              <img className={styles.logoImg} src={logoWeb} alt="roboweb" />
            </div>
            <div className={styles.listContainer}>
              <div className={styles.listBox}>
                <div className={styles.boxServicesInformation}>
                  <div className={styles.servicesBox}>
                    <h3 className="services">{lang === 'ru' ? `Услуги:` : `Services:`}</h3>
                    <ul className="servicesList">
                      <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        className="link"
                        to="templates"
                      >
                        <li className="servisItem">{lang === 'ru' ? `Шаблоны` : `Templates`}</li>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        className="link"
                        to="price"
                      >
                        <li className="servisItem">{lang === 'ru' ? `Стоимость` : `Cost`}</li>
                      </Link>

                      <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        className="link"
                        to="technicalSupport"
                      >
                        <li className="servisItem">
                          {lang === 'ru' ? `Тех.поддержка` : `Tech support`}
                        </li>
                      </Link>
                    </ul>
                  </div>
                  <div className={styles.informationBox}>
                    <h3 className="information">
                      {lang === 'ru' ? `Информация:` : `Information:`}
                    </h3>
                    <ul className="infoList">
                      <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        className="link"
                        to="about"
                      >
                        <li className="infoItem">{lang === 'ru' ? `О нас` : `About us`}</li>
                      </Link>
                      <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        className="link"
                        to="partners"
                      >
                        <li className="infoItem">{lang === 'ru' ? `Партнёрам` : `Partners`}</li>
                      </Link>

                      <li className="infoItem">
                        {lang === 'ru' ? `Связаться с нами` : `Contact us`}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.documentsBox}>
                  <h3 className="documents">{lang === 'ru' ? `Документы:` : `Documents:`}</h3>
                  <ul className={styles.documentList}>
                    <li className={styles.documentItem}>
                      {lang === 'ru' ? `Пользовательское соглашение` : `Terms of Use`}
                    </li>
                    <li className={styles.documentItem2}>
                      {lang === 'ru' ? `Политика конфиденциальности` : `Privacy Policy`}
                    </li>
                    <li className="documentItem">
                      {lang === 'ru' ? `Договор-оферта` : `Offer Agreement`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.security}>
            {lang === 'ru' ? (
              <span className={styles.securBox}>
                Разработано:
                <span className={styles.robowebTeam}>
                  <img className={styles.logoRobo} src={logoRobo} alt="" />
                  <span className={styles.roboweb}>
                    RoboWeb.<span className={styles.team}>{'Team '}</span>
                    <br className={styles.brr} />
                    <span className={styles.data}>
                      <span className={styles.c}>©</span> 2023.
                    </span>
                  </span>
                  {' Все права защищены'}.
                </span>
              </span>
            ) : (
              <span className={styles.securBox}>
                Developed by:
                <span className={styles.robowebTeam}>
                  <img className={styles.logoRobo} src={logoRobo} alt="" />
                  <span className={styles.roboweb}>
                    RoboWeb.<span className={styles.team}>{'Team '}</span>
                    <br className={styles.brr} />
                    <span className={styles.data}>
                      <span className={styles.c}>©</span> 2023.
                    </span>
                  </span>
                  {' All rights reserved.'}
                </span>
              </span>
            )}
          </div>
        </Container>
      </div>
      <img className={styles.neiroSiteRight} src={neirositeRight} alt="" />
    </footer>
  );
}

export default Footer;
