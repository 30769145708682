import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

import s from './PartnersHeading.module.scss';
import circleBlueLeft from '../../assets/partnersHeading/circleBlueLeft.svg';
import circleBlueRight from '../../assets/partnersHeading/circleBlueRight.svg';
import circleGreenLeft from '../../assets/partnersHeading/circleGreenLeft1.svg';
import circleGreenRight from '../../assets/partnersHeading/circleGreenRight.png';
import gearBlue from '../../assets/partnersHeading/gearBlue.svg';
import gearGreen from '../../assets/partnersHeading/gearGreen.svg';
import neiroLeft from '../../assets/partnersHeading/neiroLeft.svg';
import neiroRight from '../../assets/partnersHeading/neiroRight.svg';
import robot from '../../assets/partnersHeading/robot.svg';
import Button from '../ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { useSelector } from 'react-redux';

function PartnersHeading() {
  const ref = useRef();
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const navigate = useNavigate();

  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    let robot = document.querySelector(`.${s.robot}`);
    let circleBlueLeft = document.querySelector(`.${s.circleBlueLeft}`);
    let circleBlueRight = document.querySelector(`.${s.circleBlueRight}`);
    let circleGreenRight = document.querySelector(`.${s.circleGreenRight}`);
    //   let circleGreenLeft = document.querySelector(`.${s.circleGreenLeft}`)
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      robot.style.transform = 'translate(-' + x * 90 + 'px, -' + y * 50 + 'px)';
      circleBlueLeft.style.transform = 'translate(-' + x * 80 + 'px, -' + y * 10 + 'px)';
      circleBlueRight.style.transform = 'translate(-' + x * 48 + 'px, -' + y * 45 + 'px)';
      circleGreenRight.style.transform = 'translate(-' + x * 70 + 'px, -' + y * 26 + 'px)';
      //   circleGreenLeft.style.transform = 'translate(-' + x * 25 + 'px, -' + y * 60 + 'px)';
    });
  }, []);

  const lang = useSelector((state) => state.lang.language);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div ref={ref} className={s.wrapper}>
          <div className={s.parnersHeadingBlock}>
            <img className={s.neiroLeft} src={neiroLeft} alt="" />
            <img className={s.neiroRight} src={neiroRight} alt="" />
            <img className={s.circleBlueLeft} src={circleBlueLeft} alt="" />
            <img className={s.circleBlueRight} src={circleBlueRight} alt="" />
            <img className={s.circleGreenLeft} src={circleGreenLeft} alt="" />
            <img className={s.circleGreenRight} src={circleGreenRight} alt="" />
            <img className={s.gearBlue} src={gearBlue} alt="" />
            <img className={s.gearGreen} src={gearGreen} alt="" />
            {/* <img className={s.circleGreenLeft} src={circleGreenLeft} alt="" /> */}
            <div className={s.titleBox}>
              <div className={s.aboutBox}>
                <h1 className={s.title}>
                  {lang === 'ru' ? `Партнёрская программа` : `Affiliate program`}
                </h1>
                <h3 className={s.about}>
                  {lang === 'ru'
                    ? `Если вы профессиональный веб-дизайнер `
                    : `If you are a professional web designer `}
                  <br />
                  {lang === 'ru'
                    ? `или веб-разработчик и хотите показать всем, какие
                    классные сайты вы создаете, то RoboWeb предлагает стать нашим
                    партнёром. У нас выгодные условия сотрудничества для каждого
                    специалиста!`
                    : `or a web developer and want to show everyone what
                    cool sites you create, then RoboWeb offers to become our
                    partner. We have favorable terms of cooperation for everyone
                    specialist!`}
                </h3>
                <Button
                  onClick={() =>
                    localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
                  }
                  className={s.btnPartners}
                >
                  {lang === 'ru' ? `Стать партнером` : `Become a partner`}
                </Button>
              </div>
              <div className={s.imgBox}>
                <img className={s.robot} src={robot} alt="Robot" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default PartnersHeading;
