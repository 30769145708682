import React, { useRef } from 'react';

import logoWaveTop from '../../assets/img/wave_top.svg';
import logoWave from '../../assets/img/wave.svg';

import logoNeirosetLeft from '../../assets/portfolio/neirosetLeft.svg';
import logoNeirosetRight from '../../assets/portfolio/neirosetRight.svg';

import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import s from './PortfolioBlock.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

function PortfolioBlock() {
  const [template, setTemplate] = useState([]);

  const getTemplate = async (limit) => {
    const response = await fetch(`${BASE_URL}/client/template?limit=${limit}`);
    return await response.json();
  };

  // const loading = useRef(false)

  useEffect(() => {
    // if (loading.current === true) {
    getTemplate(12)
      .then((data) => {
        const key = Object.keys(data)[0];
        setTemplate((prevState) => prevState.concat(data[key]));
      })
      .catch((err) => console.log(err));
    // }
    return () => {
      setTemplate([]);
      // loading.current = true
    };
  }, []);

  const navigate = useNavigate();

  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <img className={s.waveTop} src={logoWaveTop} alt="Волна" />
          <h2 className={s.mainTitle}>
            {lang === 'ru'
              ? `Готовые сайты от партнёров RoboWeb`
              : `Ready sites from partners RoboWeb`}
          </h2>
          <div className={s.portfolioBox}>
            <img className={s.neirosetLeftTop} src={logoNeirosetLeft} alt="" />
            <img className={s.neirosetLeftMiddle} src={logoNeirosetLeft} alt="" />
            <img className={s.neirosetLeftBottom} src={logoNeirosetLeft} alt="" />
            <img className={s.neirosetRightTop} src={logoNeirosetRight} alt="" />
            <img className={s.neirosetRightMiddle} src={logoNeirosetRight} alt="" />
            <img className={s.neirosetRightBottom} src={logoNeirosetRight} alt="" />
            <div className={s.portfolioBlock}>
              {template.length
                ? template.map((item) => (
                    <div
                      key={item.id}
                      className={s.blockCardTitle}
                      onClick={() => {
                        navigate(`/viewTemplate/${item.id}`);
                      }}
                    >
                      <div className={s.blockCard}>
                        <img className={s.imageSite} src={`${BASE_URL}/${item.img}`} alt="Сайт" />
                      </div>
                      <h3 className={s.cardTitle}>{item.title}</h3>
                    </div>
                  ))
                : null}
            </div>
            <Link to="/templates">
              <ButtonTemplates className={s.buttonPorfolio}>
                {lang === 'ru' ? `Показать больше` : `Show more`}
              </ButtonTemplates>
            </Link>
          </div>
          <img className={s.waveBottom} src={logoWave} alt="Волна" />
        </div>
      </div>
    </section>
  );
}

export default PortfolioBlock;
