import React, { useEffect, useState } from 'react';

import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import s from './PartnersMyWorksBlock.module.scss';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatar from '../../assets/img/site.svg';
import iconLike from '../../assets/img/iconLike.svg';
import iconBasket from '../../assets/img/basket.svg';
import axios from 'axios';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
import SkeletonsMySiteBlock from '../SkeletonsMySiteBlock/SkeletonsMySiteBlock';

function PartnersMyWorksBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(lang === 'ru' ? `По названию` : 'By name');

  const [isLoading, setIsLoading] = useState(true);

  const [activeId, setActiveId] = useState(0);
  // const [activeOtherId, setActiveOtherId] = useState(0);
  // const [activeTreeDots, setActiveTreeDots] = useState(false);
  const [sortType, setSortType] = useState('NAME');

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  //Получение и фильтрация шаблонов партнера

  const [myTemplate, setMyTemplate] = useState([]);

  const skeletons = [...new Array(6)].map((_, i) => <SkeletonsMySiteBlock key={i} />);

  const getMyTemplate = async () => {
    let templateList = await axios.get(`${BASE_URL}/client/partner/templates/approved`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setMyTemplate(templateList.data.templateList);
    setIsLoading(false);
  };

  const sortTemplate = async (sortType) => {
    let sortTemplate = await axios.get(
      `${BASE_URL}/client/partner/templates/approved?templateSortType=${sortType}&orderSortType=ASC`,
      {
        headers: { 'x-access-token': localStorage.getItem('token') },
      },
    );
    setMyTemplate(sortTemplate.data.templateList);
  };

  useEffect(() => {
    getMyTemplate();
  }, []);
  console.log(myTemplate);
  return (
    <div className={s.mainContainer}>
      <div className={s.sortedBlock}>
        <span onClick={() => setActive(!active)} className={s.sortedMenu}>
          {sortedName}
        </span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  setSortType(obj.sortType);
                  sortTemplate(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        <div className={s.myWorksBlock}>
          {myTemplate.length ? (
            myTemplate.map((temp) => {
              return (
                <div className={s.cardBox} key={temp.id}>
                  <img className={s.siteImage} src={`${BASE_URL}/${temp.img}`} alt="Site" />
                  <div className={s.aboutSite}>
                    <span className={s.siteName}>{temp.title}</span>
                    <span className={s.categorySite}>{temp.title}</span>
                    <span className={s.id}>ID: {temp.id}</span>
                    <div className={s.iconBox}>
                      <img className={s.iconLike} src={iconLike} alt="Like" />
                      <span className={s.countLike}>{temp.favoriteCount}</span>
                      <img className={s.iconBasket} src={iconBasket} alt="Basket" />
                      <span className={s.countBasket}>{temp.orderCount}</span>
                    </div>
                  </div>
                  <div className={s.buttonBox}>
                    <Button className={s.btnAnalytic}>
                      {lang === 'ru' ? `Аналитика` : `Analytics`}
                    </Button>
                  </div>
                </div>
              );
            })
          ) : isLoading ? (
            skeletons
          ) : (
            <div className={s.cardBoxoMain}>
              <p className={s.cardBoxo}>{lang === 'ru' ? `Список пуст` : `The list is empty`}</p>
            </div>
          )}
          {/* <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <div className={s.iconBox}>
                <img className={s.iconLike} src={iconLike} alt="Like" />
                <span className={s.countLike}>123</span>
                <img className={s.iconBasket} src={iconBasket} alt="Basket" />
                <span className={s.countBasket}>321</span>
              </div>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnAnalytic}>Аналитика</Button>
            </div>
          </div> */}
          {/* <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <div className={s.iconBox}>
                <img className={s.iconLike} src={iconLike} alt="Like" />
                <span className={s.countLike}>123</span>
                <img className={s.iconBasket} src={iconBasket} alt="Basket" />
                <span className={s.countBasket}>321</span>
              </div>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnAnalytic}>Аналитика</Button>
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIRwwwwww ww www</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <div className={s.iconBox}>
                <img className={s.iconLike} src={iconLike} alt="Like" />
                <span className={s.countLike}>123</span>
                <img className={s.iconBasket} src={iconBasket} alt="Basket" />
                <span className={s.countBasket}>321</span>
              </div>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnAnalytic}>Аналитика</Button>
            </div>
          </div>
          <div className={s.cardBox}>
        <img className={s.siteImage} src={avatar} alt="Site" />
        <div className={s.aboutSite}>
          <span className={s.siteName}>Автосервис CAR REPAIR</span>
          <span className={s.categorySite}>Авто и мото</span>
          <span className={s.id}>ID 141622Z3Q</span>
               <div className={s.iconBox}>
              <img className={s.iconLike} src={iconLike} alt="Like" />
              <span className={s.countLike}>123</span>
              <img className={s.iconBasket} src={iconBasket} alt="Basket" />
              <span className={s.countBasket}>321</span>
            </div>
        </div>
        <div className={s.buttonBox}>
          <Button className={s.btnAnalytic}>Аналитика</Button>
        </div>
      </div> */}
        </div>
        <ButtonTemplates className={s.btnUploadNewTemplate}>
          {lang === 'ru' ? `Загрузить новый шаблон` : 'Download new template'}
        </ButtonTemplates>
      </div>
    </div>
  );
}

export default PartnersMyWorksBlock;
