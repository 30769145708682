import React, { useState } from 'react';

import s from './PersonalAccountPartnerMyWork.module.scss';
import PartnersMyWorksBlock from '../PartnersMyWorksBlock/PartnersMyWorksBlock';
import PartnersUploadWorkBlock from '../PartnersUploadWorkBlock/PartnersUploadWorkBlock';
import MyWorksLeaveFeedback from '../MyWorksLeaveFeedback/MyWorksLeaveFeedback';
import PartenrsMyWorksInDeveloping from '../PartnersMyWorksInDeveloping/PartnersMyWorksInDeveloping';
import PartnerMyWorksModeration from '../PartnerMyWorksModeration/PartnerMyWorksModeration';
import PartnerMyWorksRating from '../PartnerMyWorksRating/PartnerMyWorksRating';
import PersonalAccountPartnerAnalytics from '../PersonalAccountPartnerAnalytics/PersonalAccountPartnerAnalytics';
import { useSelector } from 'react-redux';

const linkNames = [
  { ru: 'Мои работы', en: 'My works', id: 0 },
  { ru: 'Загрузить работу', en: 'Download work', id: 1 },
  { ru: 'На модерации', en: 'Moderation', id: 2 },
  { ru: 'Аналитика', en: 'Analytics', id: 3 },
  { ru: 'Рейтинг', en: 'Rating', id: 4 },
  { ru: 'Оставить отзыв', en: 'Leave feedback', id: 5 },
];

export default function PersonalAccountPartnerMyWork() {
  const [active, setActive] = useState(0);

  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.personalAccountPartnerMyWorkBlock}>
            <h2 className={s.mainTitle}>
              {lang === 'ru' ? linkNames[active].ru : linkNames[active].en}
            </h2>
            <div className={s.linkContainer}>
              <ul className={s.linkBlock}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>
            {active === 0 && <PartnersMyWorksBlock />}
            {active === 1 && <PartnersUploadWorkBlock />}
            {active === 2 && <PartnerMyWorksModeration />}
            {active === 3 && <PersonalAccountPartnerAnalytics />}
            {active === 4 && <PartnerMyWorksRating />}
            {active === 5 && <MyWorksLeaveFeedback />}

            {/* {active === 2 && <FavoritesBlock />}
            {active === 3 && <HostingBlock />}
            {active === 4 && <LeaveFeedbackBlock />} */}
          </div>
        </div>
      </div>
    </section>
  );
}
