import React, { useState, useEffect, useReducer } from 'react';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import x from '../../assets/img/x.svg';

import s from './LeaveFeedbackBlock.module.scss';
import Button from '../ui/Button/Button';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

import osisstant from '../../assets/img/onlineOsisstant.svg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { useFormik } from "formik";

// Feedback block
// const initialState = { templateId: '', title: '', text: '', rating: 0 };

// function reducer(state, action) {
//   switch (action.type) {
//     case 'addFeedback':
//       return { ...state, ...action.payload };
//       case 'clearInput':

//       return { templateId: '', title: '', text: '', rating: 0 }

//     default:
//       throw new Error();
//   }
// }

function LeaveFeedbackBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [activeSiteName, setActiveSiteName] = useState(false);

  const [siteList, setSiteList] = useState([]);
  const [validate, setValidate] = useState({});

  const [selected, setSelected] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [rating, setRating] = useState(0);

  const zeroingValues = () => {
    setSelected('');
    setTitle('');
    setText('');
    setRating(0);
  };

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    maxWidth: '180px',
  };

  const notifyOk = () =>
    toast(
      <div>
        <span style={imgOssistant}>Ваш отзыв отправлен на модерацию!</span>
        <img width={300} src={osisstant} />
      </div>,
    );
  const notifyErr = () =>
    toast(
      <div>
        <span style={imgOssistant}>Вы уже оставляли отзыв на этот сайт!</span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const getTemplates = async () => {
    const response = await fetch(`${BASE_URL}/client/template`);
    return await response.json();
  };

  //Валидация

  const sendFeedback = async () => {
    if (!selected) {
      setValidate((prev) => ({
        ...prev,
        selected: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    }

    if (!title) {
      setValidate((prev) => ({
        ...prev,
        title: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (title.length < 5) {
      setValidate((prev) => ({
        ...prev,
        title:
          lang === 'ru'
            ? 'Кол-во символов должно быть больше 5-ти'
            : `Number of characters must be greater than 5`,
      }));
    }

    if (!text) {
      setValidate((prev) => ({
        ...prev,
        text: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (text.length < 10) {
      setValidate((prev) => ({
        ...prev,
        text:
          lang === 'ru'
            ? `Кол-во символов должно быть больше 10-ти`
            : `
        The number of characters must be more than 10`,
      }));
    }

    if (!rating) {
      setValidate((prev) => ({
        ...prev,
        rating: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (rating && text.length > 10 && title.length > 5 && selected) {
      await fetch(`${BASE_URL}/client/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          templateId: selected.id,
          title,
          text,
          rating: +rating,
        }),
      }).then((res) => {
        if (res.ok) {
          notifyOk();
        } else {
          notifyErr();
        }
      });
      zeroingValues();
    }

    // return await response.json();
  };

  const changeInputValue = (e) => {
    if (validate[e.target.name] !== undefined) {
      setValidate((prev) => ({ ...prev, [e.target.name]: null }));
    }

    if (e.target.name === 'selected') {
      setSelected(e.target.value);
    }

    if (e.target.name === 'title') {
      setTitle(e.target.value);
    }

    if (e.target.name === 'text') {
      setText(e.target.value);
    }

    if (e.target.name === 'rating') {
      setRating(e.target.value);
    }
  };

  useEffect(() => {
    getTemplates()
      .then((data) => setSiteList(data.templateList))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={s.container}>
      <div className={s.leaveFeedbackBlock}>
        <div className={s.ratingBox}>
          <div className={s.error}>
            <h3 className={s.titleRating}>{lang === 'ru' ? `Оценка:` : `Grade:`}</h3>
            {validate.rating && <span className={s.errorVisible}>{validate.rating}</span>}
          </div>
          <Rating
            name="size-large"
            value={rating}
            onChange={(e) => {
              changeInputValue({ target: { name: 'rating' } });
              setRating(e.target.value);
            }}
            size="large"
            emptyIcon={<StarIcon style={{ opacity: 0.55, color: '#9EC3FF' }} fontSize="inherit" />}
          />
        </div>
        <div onClick={() => setActiveSiteName(!activeSiteName)} className={s.selectSiteViewBox}>
          <div className={s.error}>
            {validate.selected && <span className={s.errorVisible}>{validate.selected}</span>}
          </div>
          <label className={s.titleSelectSiteView}>
            {lang === 'ru' ? `Выберите сайт:` : `Select site`}
          </label>
          <div className={s.selectSiteView}>
            <span className={s.siteTitle}>{selected.title}</span>
            <img
              style={{
                transform: activeSiteName ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              className={s.arrowMenu}
              src={arrowMenu}
              alt="Arrow menu"
            />
          </div>
          {/* <p className={s.error}>{validate.selected && <span className={s.errorVisible}>{validate.selected}</span>}</p> */}
          {activeSiteName && (
            <div className={s.contentBox}>
              {siteList.length
                ? siteList.map((site) => (
                    <h3
                      key={site.id}
                      onClick={() => {
                        changeInputValue({
                          target: { name: 'selected', value: site },
                        });
                        setActiveSiteName(false);
                      }}
                      className={s.title}
                    >
                      {site.title}
                    </h3>
                  ))
                : null}
            </div>
          )}
        </div>
        <div className={s.headerBox}>
          <div className={s.error}>
            <h3 className={s.titleHeader}>{lang === 'ru' ? `Заголовок:` : `Title:`}</h3>
          </div>
          {validate.title && <span className={s.errorVisible}>{validate.title}</span>}
          <input
            className={s.header}
            value={title}
            onChange={(e) => {
              changeInputValue({ target: { name: 'title' } });
              setTitle(e.target.value);
            }}
          />

          <img onClick={() => setTitle('')} className={s.x} src={x} alt="Arrow menu" />
          {/* {validate.title && <span>{validate.title}</span>} */}
        </div>
        <div className={s.commentBox}>
          <div className={s.error}>
            <h3 className={s.titleComment}>{lang === 'ru' ? `Комментарий:` : `Comment:`}</h3>
          </div>
          {validate.text && <span className={s.errorVisible}>{validate.text}</span>}
          <textarea
            className={s.comment}
            value={text}
            onChange={(e) => {
              changeInputValue({ target: { name: 'text' } });
              setText(e.target.value);
            }}
          />
          <img onClick={() => setText('')} className={s.x} src={x} alt="Arrow menu" />
        </div>
        <p className={s.agreement}>
          {lang === 'ru'
            ? `Оставляя
          отзыв, вы соглашаетесь с`
            : `Leaving
          review, you agree to`}{' '}
          <span className={s.terms}>
            {lang === 'ru'
              ? `условиями
            использования`
              : `conditions
              use`}
          </span>{' '}
          {lang === 'ru' ? `сайта` : `site`}
        </p>
        <Button
          onClick={() => {
            sendFeedback();
          }}
          className={s.btnReview}
        >
          {lang === 'ru'
            ? `Отправить
          отзыв`
            : `Send review`}
        </Button>
      </div>
      <ToastContainer
        hideProgressBar={true}
        autoClose={6000}
        limit={1}
        style={{ position: 'relative' }}
        position="bottom-left"
      />
    </div>
  );
}

export default LeaveFeedbackBlock;
