import React, { useRef } from 'react';
import { useEffect } from 'react';

import s from './PriceHeading.module.scss';
import gearBlueMiddle from '../../assets/TemplatesHeadingIcon/gearMiddle.svg';
import circleArrow from '../../assets/TemplatesHeadingIcon/circleArrow.svg';
import neirosetLeft from '../../assets/TemplatesHeadingIcon/neirosetLeft.svg';
import neirosetRight from '../../assets/TemplatesHeadingIcon/neirosetRight.svg';
import neirosetMiddle from '../../assets/TemplatesHeadingIcon/neirosetMiddle.svg';
import circleBlue from '../../assets/TemplatesHeadingIcon/circleBlue.png';
import circleGreenRight from '../../assets/TemplatesHeadingIcon/circleGreenRight.svg';
import circleGreenBig from '../../assets/TemplatesHeadingIcon/circleGreenBigRight.svg';
import gearBackgroundLeft from '../../assets/TemplatesHeadingIcon/gearBackgroundLeft.svg';
import gearBackgroundBlueLeft from '../../assets/TemplatesHeadingIcon/gearBackgroundBlueLeft.svg';
import { useSelector } from 'react-redux';

export default function PriceHeading() {
  const lang = useSelector((state) => state.lang.language);

  const ref = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    let circleGreenBig = document.querySelector(`.${s.circlegreenBig}`);
    let circleBlue = document.querySelector(`.${s.circleblue}`);
    let circleGreenRight = document.querySelector(`.${s.circlegreenRight}`);
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      circleBlue.style.transform = 'translate(-' + x * 220 + 'px, -' + y * 20 + 'px)';
      circleGreenRight.style.transform = 'translate(-' + x * 180 + 'px, -' + y * 50 + 'px)';
      circleGreenBig.style.transform = 'translate(-' + x * 138 + 'px, -' + y * 25 + 'px)';
    });
  }, []);
  return (
    <section>
      <div className={s.container}>
        <div ref={ref} className={s.wrapper}>
          <div className={s.priceHeadingBlock}>
            <div className={s.priceHeadingBox}>
              <h1 className={s.mainTitle}>
                {lang === 'ru' ? `Стоимость услуг от RoboWeb` : `Service cost from RoboWeb`}
              </h1>
              {/* <h3 className={s.about}>
                {lang === 'ru'
                  ? `Стоимость готовых решений от RoboWeb`
                  : `The cost of ready-made solutions from RoboWeb`}
              </h3> */}
              <div className={s.gearBox}>
                <img className={s.gearBlue} src={gearBlueMiddle} alt="Gear" />
                <img className={s.arrowCircle} src={circleArrow} alt="Arrow" />
              </div>
              <img className={s.neirosetLeft} src={neirosetLeft} alt="" />
              <img className={s.neirosetMiddle} src={neirosetMiddle} alt="" />
              <img className={s.neirosetRight} src={neirosetRight} alt="" />
              <img className={s.circleblue} src={circleBlue} alt="" />
              <img className={s.circlegreenRight} src={circleGreenRight} alt="" />
              <img className={s.circlegreenBig} src={circleGreenBig} alt="" />
              <img className={s.gearBackgroundLeft} src={gearBackgroundLeft} alt="" />
              <img className={s.gearBackgroundBlueLeft} src={gearBackgroundBlueLeft} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
