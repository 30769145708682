import React from 'react';

import s from './PartnersTheDocumentsBlock.module.scss';
import loadingFileImg from '../../assets/img/loadingFile.svg';
import { useSelector } from 'react-redux';

function PartnersTheDocumentsBlock() {
  const lang = useSelector((state) => state.lang.language);
  return (
    <div className={s.partnersTheDocumentsBlock}>
      <div className={s.contractForServicesBox}>
        <span className={s.contractForServices}>
          {lang === 'ru' ? 'Договор на оказание услуг' : 'Contract for services'}
        </span>
        <img className={s.contractForServicesImg} src={loadingFileImg} alt="Img" />
      </div>
      <div className={s.copyrightAgreementBox}>
        <span className={s.copyrightAgreement}>
          {lang === 'ru' ? 'Договор об авторском праве' : 'Copyright agreement'}
        </span>
        <img className={s.copyrightAgreementImg} src={loadingFileImg} alt="Img" />
      </div>
    </div>
  );
}

export default PartnersTheDocumentsBlock;
