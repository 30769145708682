import React from 'react';

import s from './PartnersMyWorksInDeveloping.module.scss';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import avatar from '../../assets/img/site.svg';
import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Like } from '../../assets/FullListTeamplates/like.svg';
import { useSelector } from 'react-redux';

function PartenrsMyWorksInDeveloping() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <>
      <div className={s.sortedBlock}>
        <span className={s.sortedMenu}>
          {lang === `ru` ? `По названию` : `By name`}
        </span>
        <img className={s.arrowMenu} src={arrowMenu} alt="Arrow" />
      </div>
      <div className={s.container}>
        <div className={s.inDevelopingBlock}>
          <div className={s.cardBox}>
            <div className={s.imgBox}>
              <img className={s.cardImage} src={avatar} alt="Site" />
              <Button className={s.btnUpdate}>
                {lang === `ru` ? `Редактировать` : `Edit`}
              </Button>
              <ButtonTemplates className={s.btnLook}>
                {lang === `ru` ? `Посмотреть` : `Look`}
              </ButtonTemplates>
              <Like className={s.like} src={Like} alt="Like" />
            </div>
            <span className={s.siteName}>
              {lang === `ru` ? `Аренда автомобилей` : `Car rent`}
            </span>
          </div>
          <div className={s.cardBox}>
            <div className={s.imgBox}>
              <img className={s.cardImage} src={avatar} alt="Site" />
              <Button className={s.btnUpdate}>
                {lang === `ru` ? `Редактировать` : `Edit`}
              </Button>
              <ButtonTemplates className={s.btnLook}>
                {lang === `ru` ? `Посмотреть` : `Look`}
              </ButtonTemplates>
              <Like className={s.like} src={Like} alt="Like" />
            </div>
            <span className={s.siteName}>
              {lang === `ru`
                ? `Аренда яхт Explore. Discover. Love`
                : `Yacht charter Explore. Discover. Love`}
            </span>
          </div>
          <div className={s.cardBox}>
            <div className={s.imgBox}>
              <img className={s.cardImage} src={avatar} alt="Site" />
              <Button className={s.btnUpdate}>
                {lang === `ru` ? `Редактировать` : `Edit`}
              </Button>
              <ButtonTemplates className={s.btnLook}>
                {lang === `ru` ? `Посмотреть` : `Look`}
              </ButtonTemplates>
              <Like className={s.like} src={Like} alt="Like" />
            </div>
            <span className={s.siteName}>
              {lang === `ru` ? `Аренда автомобилей` : `Car rent`}
            </span>
          </div>
          <div className={s.cardBox}>
            <div className={s.imgBox}>
              <img className={s.cardImage} src={avatar} alt="Site" />
              <Button className={s.btnUpdate}>
                {lang === `ru` ? `Редактировать` : `Edit`}
              </Button>
              <ButtonTemplates className={s.btnLook}>
                {lang === `ru` ? `Посмотреть` : `Look`}
              </ButtonTemplates>
              <Like className={s.like} src={Like} alt="Like" />
            </div>
            <span className={s.siteName}>
              {lang === `ru` ? `Аренда автомобилей` : `Car rent`}
            </span>
          </div>
          {/* <div className={s.cardBox}>
      <div className={s.imgBox}>
        <img className={s.cardImage} src={avatar} alt="Site" />
      <Button className={s.btnUpdate}>Редактировать</Button>
      <ButtonTemplates className={s.btnLook}>Посмотреть</ButtonTemplates>
      </div>
      <span className={s.siteName}>Аренда автомобилей</span>
    </div> */}
        </div>
        <ButtonTemplates className={s.btnCreateSite}>
          {lang === `ru` ? `Загрузить новый шаблон` : `Download new template`}
        </ButtonTemplates>
      </div>
    </>
  );
}

export default PartenrsMyWorksInDeveloping;
