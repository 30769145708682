import React, { useEffect, useState } from 'react';
import s from './ProfileUser.module.scss';
import avatarDefault from '../../assets/img/ava.jpeg';
import PersonalDataBlock from '../PersonalDataBlock/PersonalDataBlock';

import PasswordChangeBlock from '../PasswordChangeBlock/PasswordChangeBlock';
import toRubFormat from '../../utils/toRubFormat';
import { BASE_URL } from '../..';
import axios from 'axios';
import $api from '../../http';
import { REACT_APP_CP_PUBLIC_ID } from '../../utils/constants';
import { useSelector } from 'react-redux';
import OperationCardForm from '../OperationCardForm/OperationCardForm';
import Button from '../ui/Button/Button';
import ExtractBlock from '../ExtractBlock/ExtractBlock';

// console.log(REACT_APP_PUBLICID);
// console.log(process.env);

function ProfileUser() {
  const [active, setActive] = useState(0);

  const [avatar, setAvatar] = useState('');
  const [visibleButton, setVisibleButton] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const linkNames = [
    { ru: 'Личные данные', en: 'Personal data', id: 0 },
    { ru: 'Смена пароля', en: 'Password change', id: 1 },
    { ru: 'Операции', en: 'Operations', id: 2 },
    { ru: 'Выписка', en: 'Extract', id: 3 },
  ];

  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  //ПОЛУЧЕНИЕ ДАННЫХ ЮЗЕРА

  const [user, setUser] = useState({});

  const getUser = async () => {
    let userInfo = await $api.get(`/client/user/me`);
    setUser(userInfo.data.user);
  };

  useEffect(() => {
    getUser();
  }, [userInfo]);

  //ИЗМЕНЕНИЕ АВАТАРА

  const OnSumbit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append('files', avatar);
    console.log(formData);
    axios
      .patch(`${BASE_URL}/client/user/avatar`, formData, config)
      .then((res) => {
        console.log(`Success` + res);
        getUser();
        setVisibleButton(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [amount, setAmount] = useState('');

  // // Получение скрипта для CloudPayments
  // useEffect(() => {
  //   const scriptPay = document.createElement('script');
  //   scriptPay.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
  //   document.head.appendChild(scriptPay);
  //   return () => {
  //     document.head.removeChild(scriptPay);
  //   };
  // }, []);

  // useEffect(() => {
  //   const scriptCheckout = document.createElement('script');
  //   scriptCheckout.src = 'https://checkout.cloudpayments.ru/checkout.js';
  //   document.head.appendChild(scriptCheckout);
  //   return () => {
  //     document.head.removeChild(scriptCheckout);
  //   };
  // }, []);

  // //CloudPayments

  // const pay = (id) => {
  //   let payments = new window.cp.Widget({
  //     language: 'ru-RU',
  //     email: '',
  //     applePaySupport: false,
  //     googlePaySupport: false,
  //     yandexPaySupport: true,
  //     tinkoffInstallmentSupport: false,
  //   });
  //   payments
  //     .pay('charge', {
  //       publicId: REACT_APP_CP_PUBLIC_ID,
  //       description: 'Тестовая оплата',
  //       amount: +amount,
  //       currency: 'RUB',
  //       invoiceId: id,
  //       // accountId: '123',
  //       // email: '',
  //       skin: 'classic',
  //       requireEmail: false,
  //     })
  //     .then(function (widgetResult) {
  //       console.log('result', widgetResult);
  //     })
  //     .catch(function (error) {
  //       console.log('error', error);
  //     });
  // };

  // //Пополнение баланса
  // const sendAmount = async (e) => {
  //   try {
  //     const res = await $api.post(`/client/bill`, {
  //       amount: +amount,
  //       type: 'REPLENISHMENT',
  //     });
  //     pay(res.data.id);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const lang = useSelector((state) => state.lang.language);
  console.log(user, 'useeer');
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>{lang === 'ru' ? `Профиль` : `Profile`}</h2>
          <div className={s.profileUserBox}>
            <div className={s.infoBox}>
              <form onSubmit={(event) => OnSumbit(event)}>
                <div className={s.personal_image}>
                  <label className={s.label}>
                    <input
                      type="file"
                      // value={avatar}
                      onChange={(e) => setAvatar(e.target.files[0])}
                      onClick={() => setVisibleButton(true)}
                      accept=".png, .jpg, .jpeg"
                    />
                    <figure className={s.personal_figure}>
                      <img
                        src={user['avatar'] ? `${BASE_URL}${user['avatar']}` : avatarDefault}
                        className={s.personal_avatar}
                        alt="avatar"
                      />
                      <figcaption className={s.personal_figcaption}>
                        <img
                          src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                          alt="avatar"
                        />
                      </figcaption>
                    </figure>
                  </label>
                </div>
                {visibleButton && avatar ? (
                  <button className={s.btnImageSave}>
                    {lang === 'ru' ? `Обновить` : `Refresh`}
                  </button>
                ) : (
                  ''
                )}
              </form>
              <div className={s.aboutBox}>
                <h3 className={s.userName}>{user['name']}</h3>
                <p className={s.userEmail}>{user['email']}</p>
              </div>
            </div>
            <div className={s.userBalanceBox}>
              <h3 className={s.titleBalance}>
                {lang === 'ru' ? `Ваш баланс:` : `Your balance:`}
                <span className={s.balance}>{toRubFormat(user['balance'])}</span>
              </h3>
              {/* <div className={s.balanceBox}>
                <p className={s.userBalance}>
                  {toRubFormat(user['balance'])}
                </p>
                <p className={s.replenishTheBalance}>Пополнить баланс</p>
                <p className={s.extract}>Выписка</p>
              </div> */}
            </div>
            <div className={s.linkAboutContainer}>
              <ul className={s.linkAboutBox}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>
            {active === 0 && (
              <PersonalDataBlock
                // user={state}
                // dispatch={dispatch}
                // updateUser={updateUser}
                user={user}
                avatar={avatar}
                // OnSumbit={OnSumbit}
                setUserInfo={setUserInfo}
              />
            )}
            {active === 1 && <PasswordChangeBlock />}
            {active === 2 && <OperationCardForm />}
            {active === 3 && <ExtractBlock />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileUser;
