import React from 'react';
import s from './Container.module.scss';

const Container = ({ children, className }) => {
  return (
    <>
      <div className={`${s.container} + ' ' + ${className}`}>{children}</div>
    </>
  );
};
export default Container;
