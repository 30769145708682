import React, { useRef } from 'react';
import { useEffect } from 'react';

import Container from '../ui/Container/Container';
import s from './HowToStartBlockMain.module.scss';

import circleBackground1 from '../../assets/howToStartBlockMain/circleBackground1.svg';
import circleBackground2 from '../../assets/howToStartBlockMain/circleBackground2.svg';
import robot from '../../assets/howToStartBlockMain/robotBody1.svg';
import robotHand from '../../assets/howToStartBlockMain/Group.svg';
import circleGreenLeft from '../../assets/howToStartBlockMain/circleGreenLeft.png';
import circleBlue from '../../assets/howToStartBlockMain/circleBlue.svg';
import circleGreenRight from '../../assets/howToStartBlockMain/circleGreenLeft.png';
import circleGrey from '../../assets/howToStartBlockMain/circleRightS.svg';
import { useSelector } from 'react-redux';

export default function HowToStartBlockMain() {
  const lang = useSelector((state) => state.lang.language);

  const ref = useRef();
  useEffect(() => {
    try {
      let circleGreenRight = document.querySelector(`.${s.circleGreenRight}`);
      let circleGrey = document.querySelector(`.${s.circleGrey}`);
      let circleGreenLeft = document.querySelector(`.${s.circleGreenLeft}`);
      let circleBlue = document.querySelector(`.${s.circleBlue}`);
      return ref.current.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;
        circleGreenRight.style.transform =
          'translate(-' + x * 50 + 'px, -' + y * 10 + 'px)';

        circleGrey.style.transform =
          'translate(-' + x * 10 + 'px, -' + y * 50 + 'px)';

        circleGreenLeft.style.transform =
          'translate(-' + x * 38 + 'px, -' + y * 25 + 'px)';
        circleBlue.style.transform =
          'translate(-' + x * 50 + 'px, -' + y * 26 + 'px)';
      });
    } catch (error) {}
  }, []);
  return (
    <section>
      <Container>
        <div ref={ref} className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `Как начать?` : `How to get started?`}
          </h2>
          <div className={s.howToStartBlock}>
            <div className={s.cardsBox}>
              <div className={s.cardsBlock1}>
                <img
                  className={s.circleGreenLeft}
                  src={circleGreenLeft}
                  alt="Green circle"
                />
                <div className={s.cardBox}>
                  <div className={s.cardNumber}>
                    <h2 className={s.number}>1</h2>
                  </div>
                  <div className={s.cardTitle}>
                    <h3 className={s.title}>
                      {lang === 'ru'
                        ? `Выберите шаблон сайта`
                        : `Choose a website template`}
                    </h3>
                    <p className={s.about}>
                      {lang === 'ru'
                        ? `               Мы уверены, что вы сможете найти подходящий шаблон сайта
                      для своего бизнеса из множества представленных вариантов`
                        : `We are confident that you will find a suitable website template for your business from the many options presented`}
                    </p>
                  </div>
                </div>
                <div className={s.cardBox}>
                  <div className={s.cardNumber}>
                    <h2 className={s.number}>2</h2>
                  </div>
                  <div className={s.cardTitle}>
                    <h3 className={s.title}>
                      {lang === 'ru'
                        ? `Настройте контент`
                        : `Customize your content`}
                    </h3>
                    <p className={s.about}>
                      {lang === 'ru'
                        ? `С помощью RoboWeb сделайте необходимые изменения и внесите
                      данные о вашей компании`
                        : `Use RoboWeb to make changes and personalize your company.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className={s.cardsBlock2}>
                <img
                  className={s.circleBlue}
                  src={circleBlue}
                  alt="Circle blue"
                />
                <div className={s.cardBox}>
                  <div className={s.cardNumber}>
                    <h2 className={s.number}>3</h2>
                  </div>
                  <div className={s.cardTitle}>
                    <h3 className={s.title}>
                      {lang === 'ru' ? `Совершите оплату` : `Make payment`}
                    </h3>
                    <p className={s.about}>
                      {lang === 'ru'
                        ? `Вы можете оплатить заказ банковской картой онлайн
                      или через платёжные системы`
                        : `You can pay online or via credit card`}
                    </p>
                  </div>
                </div>
                <div className={s.cardBox}>
                  <div className={s.cardNumber}>
                    <h2 className={s.number}>4</h2>
                  </div>
                  <div className={s.cardTitle}>
                    <h3 className={s.title}>
                      {lang === 'ru'
                        ? `Получите рабочий сайт`
                        : `Get a working website`}
                    </h3>
                    <p className={s.about}>
                      {lang === 'ru'
                        ? `После покупки в личном кабинете будет доступна ссылка
                      для скачивания сайта`
                        : `After purchase a link to download the site will be available in your personal cabinet`}
                    </p>
                  </div>
                </div>
              </div>
              <img className={s.robotBody} src={robot} alt="Robot" />
              <img className={s.robotHand} src={robotHand} alt="Hand" />
            </div>
            <div className={s.illustrationBox}>
              <img
                className={s.background2}
                src={circleBackground2}
                alt="Background"
              />
              <img
                className={s.background1}
                src={circleBackground1}
                alt="Background"
              />
              <img
                className={s.circleGreenRight}
                src={circleGreenRight}
                alt="Green circle"
              />
              <img className={s.circleGrey} src={circleGrey} alt="Green grey" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
