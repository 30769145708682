import React from 'react';
import s from './AboutUsBenefitsBlock.module.scss';

import logoPrice from '../../assets/aboutUsBenefitsBlock/price.svg';
import logoKeyboard from '../../assets/aboutUsBenefitsBlock/keyboard.svg';
import logoRocket from '../../assets/aboutUsBenefitsBlock/rocket.svg';
import { useSelector } from 'react-redux';

const item = [
  
  {
    id: 1,
    img: logoPrice,
    title: 'Стоимость',
    item: 'Мы предлагаем привлекательные цены на сайты высокого качества. С нами вы получите уникальный сайт за минимальную стоимость.',
    titleEn: 'Price',
    itemEn:
      'We offer attractive prices for high quality websites. With us you will get a unique website at the lowest cost.',
  },
  {
    id: 2,
    img: logoKeyboard,
    title: 'Без кода',
    item: 'Качественный HTML, CSS и JQUERY код автоматически адаптирует информацию под структуру вашего сайта, не изменяя внешний вид изображений и текстов. Вам не нужно заботиться и познавать основы программирования.',
    titleEn: 'No code',
    itemEn:
      'High-quality HTML, CSS and JQUERY code automatically adapts information to the structure of your site without changing the appearance of images and texts. You do not need to care and learn the basics of programming.',
  },
  {
    id: 3,
    img: logoRocket,
    title: 'Скорость',
    item: 'Как и все наши клиенты, мы ценим время и качество. Именно поэтому мы уже сделали всё, чтобы вы могли оформить собственный сайт всего за 15 минут! Ни один фрилансер, веб-мастер и конструктор сайтов не сделает вам качественный сайт за это время.',
    titleEn: 'Speed',
    itemEn:
      'Like all our clients, we value time and quality. That is why we have already done everything so that you can design your own website in just 15 minutes! Not a single freelancer, webmaster and website builder will make you a quality website in this time.',
  },
];

export default function AboutUsBenefitsBlock() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <ul className={s.list}>
            {item.map((el) => (
              <li key={el.id} className={s.itemBox}>
                <div className={s.imageBox}>
                  <img className={s.icon} src={el.img} alt="Icon" />
                  {/* <img className={el.className} src={el.imgGroup} alt="Group" /> */}
                </div>
                <h3 className={s.title}>
                  {lang === 'ru' ? el.title : el.titleEn}
                </h3>
                <p className={s.item}>{lang === 'ru' ? el.item : el.itemEn}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
