import React from 'react';
import { useEffect, useRef, useCallback } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';

import Container from '../ui/Container/Container';
import s from './ReviewsBlockMain.module.scss';
import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import star from '../../assets/reviewsImg/star.svg';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';

import LinkTemplates from '../ui/LinkTemplates/LinkTemplates';
import { useState } from 'react';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

function ReviewsBlockMain() {
  const lang = useSelector((state) => state.lang.language);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const getReviews = async () => {
    const response = await fetch(`${BASE_URL}/client/review/partner`);
    return await response.json();
  };

  const [review, setReview] = useState([]);

  useEffect(() => {
    getReviews()
      .then((data) => {
        const key = Object.keys(data)[0];
        setReview((prevState) => [...prevState, ...data[key]]);
      })
      .catch((err) => console.log(err));

    return () => {
      setReview([]);
    };
  }, []);
  console.log(review, 'yyyyyyyyy');
  return (
    <section>
      <Container>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>{lang === 'ru' ? `Отзывы` : `Reviews`}</h2>
          <div className={s.reviewsBox}>
            <Swiper
              ref={sliderRef}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              breakpoints={{
                830: {
                  width: 640,
                  slidesPerView: 1,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Navigation]}
              className={s.mySwiper3}
            >
              {review.length
                ? review.map((item) => (
                    <>
                      <SwiperSlide>
                        <div key={item.id} className={s.reviewsCard}>
                          <div className={s.userBox}>
                            <img
                              className={s.avatar}
                              src={
                                item.user.avatar ? `${BASE_URL}${item.user.avatar}` : avatarDefault
                              }
                              alt="Аватар"
                            />
                            <div className={s.infoUser}>
                              <h3 className={s.userName}>{item.user.name}</h3>
                              <span className={s.rating}>{Number(item.rating).toFixed(1)}</span>
                              <img src={star} alt="Star" />
                            </div>
                          </div>
                          <div className={s.aboutBox}>
                            <p className={s.about}>{item.text}</p>
                            <LinkTemplates className={s.linkTemplates}>
                              www.salondeli.ru
                            </LinkTemplates>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ))
                : null}
            </Swiper>
            <section className={s.buttonBox}>
              <button className={s.arrowBtnPrev} onClick={handlePrev}>
                <ArrowLeft className={s.arrowPrev} src={Arrow} alt="Arrow" />
              </button>
              <button onClick={handleNext} className={s.arrowBtnNext}>
                <Arrow className={s.arrowNext} src={Arrow} alt="Arrow" />
              </button>
            </section>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ReviewsBlockMain;
