import Cropper from 'cropperjs';
import { ChangeImgAction } from './ChangeImgAction';

const div = document.createElement('div');
const divCropper = document.createElement('div');
const divEdit = document.createElement('div');
const img = document.createElement('img');
const imgBg = document.createElement('img');
const btn = document.createElement('button');
const btnLeft = document.createElement('button');
const btnRight = document.createElement('button');
const input = document.createElement('input');

div.style.cssText = `
position: fixed;
bottom: 25%;
left: 35%;
width: 500px;
height: 400px;
z-index: 10;
display: flex;
flex-direction: column;
background-color: white;
broder-radius: 10px;
`;

divCropper.style.cssText = `
height: 300px;
`;

divEdit.style.cssText = `
display: flex;
justify-content: space-between;
align-items: center
`;

btn.textContent = 'Сохранить';
btnLeft.textContent = 'Влево';
btnRight.textContent = 'Вправо';

input.setAttribute('type', 'range');
input.setAttribute('min', '0.1');
input.setAttribute('max', '3.6');
input.setAttribute('step', '0.1');

export function cropperEdit(
  res,
  image,
  current,
  disabledRef,
  dispatch,
  historyPush,
  cropperMeta = false,
  templateId
) {
  disabledRef.current = true;
  if (cropperMeta === 'bg') {
    img.src =
      image.dataset.slideBg.includes('data:image') ||
      image.dataset.slideBg.includes('unsplash')
        ? image.dataset.slideBg
        : // : `http://localhost:3000/templates/${templateId}/${image.dataset.slideBg}`;
          `https://roboweb.site/templates/${templateId}/${image.dataset.slideBg}`;
    // image.style.backgroundImage = `url('${img.src}')`;
  } else if (cropperMeta === 'pr') {
    img.src =
      image.dataset.parallaxImg.includes('data:image') ||
      image.dataset.parallaxImg.includes('unsplash')
        ? image.dataset.parallaxImg
        : // : `http://localhost:3000/templates/${templateId}/${image.dataset.parallaxImg}`;
          `https://roboweb.site/templates/${templateId}/${image.dataset.parallaxImg}`;
  } else {
    img.src = image.src;
  }
  div.appendChild(divCropper);
  div.appendChild(divEdit);
  divCropper.appendChild(img);
  divEdit.appendChild(btn);
  divEdit.appendChild(btnLeft);
  divEdit.appendChild(btnRight);
  divEdit.appendChild(input);
  console.log(img);
  const cropper = new Cropper(img, {
    // aspectRatio: 0,
    viewMode: 0,
    crop(event) {
      console.log(img);
      // res.innerHTML = '';
      // image.src = cropper.getCroppedCanvas().toDataURL('image/png');
    },
  });

  console.log('crop', cropper);

  if (cropperMeta === 'bg') {
    btn.onclick = function () {
      image.style.backgroundImage = `url('${cropper
        .getCroppedCanvas()
        .toDataURL()}')`;
      image.dataset.slideBg = cropper.getCroppedCanvas().toDataURL();
      cropper.destroy();
      div.remove();
      dispatch(
        historyPush({
          el: new ChangeImgAction(current.style.backgroundImage, current),
          cb: (state, action) => {},
        })
      );
      disabledRef.current = false;
    };
  } else if (cropperMeta === 'pr') {
    btn.onclick = function () {
      image.style.backgroundImage = `url('${cropper
        .getCroppedCanvas()
        .toDataURL()}')`;
      image.dataset.parallaxImg = cropper.getCroppedCanvas().toDataURL();
      cropper.destroy();
      div.remove();
      dispatch(
        historyPush({
          el: new ChangeImgAction(current.style.backgroundImage, current),
          cb: (state, action) => {},
        })
      );
      disabledRef.current = false;
    };
  } else {
    btn.onclick = function () {
      image.src = cropper.getCroppedCanvas().toDataURL();
      cropper.destroy();
      div.remove();
      dispatch(
        historyPush({
          el: new ChangeImgAction(
            current.childNodes[1].src,
            current.childNodes[1]
          ),
          cb: (state, action) => {},
        })
      );
      disabledRef.current = false;
    };
  }

  btnLeft.onclick = function () {
    cropper.rotate(-45);
  };

  btnRight.onclick = function () {
    cropper.rotate(45);
  };

  let previousValue = 0;

  input.addEventListener('input', (e) => {
    if (previousValue > input.value) {
      cropper.zoom(-0.1);
    } else {
      cropper.zoom(0.1);
    }
    previousValue = input.value;
  });

  const div1 = document.createElement('div');
  div1.style.cssText = `
position: fixed;
bottom: 25%;
left: 35%;
width: 500px;
height: 400px;
z-index: 10;
display: flex;
flex-direction: column;
background-color: white;
broder-radius: 10px;
`;

  if (cropperMeta === 'bg' || cropperMeta === 'pr') {
    res.body.appendChild(div);
  } else {
    current.appendChild(div);
  }
}
