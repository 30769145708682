import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import s from './PartnersHeader.module.scss';
import notificationIcon from '../../assets/img/notificationIcon.svg';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import moneyIcon from '../../assets/img/moneyIcon.svg';
import logoSvg from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';
import burherMenu from '../../assets/img/burgerMenu.svg';
import Button from '../ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../app/features/user/userSlice';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';
import { useState } from 'react';
import { switchLanguage } from '../../app/features/user/languageSwitch';
import $api from '../../http';
import NotificationsBlock from '../NotificationsBlock/NotificationsBlock';
import axios from 'axios';
import { BASE_URL } from '../..';

function PartnersHeader() {
  const [active, setActive] = useState(false);
  const [burgerActivePartner, setBurgerActivePartner] = useState(false);
  const dispatch = useDispatch();
  const { setHeaderType } = useContext(HeaderTypeContext);
  const [activeNatifications, setActiveNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notification, setNotification] = useState(null);

  const navigate = useNavigate();

  const lang = useSelector((state) => state.lang.language);

  async function getNotificationCount() {
    const res = await axios.get(`${BASE_URL}/client/notification/count`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setNotificationCount(res.data);
  }
  console.log(notificationCount);
  async function getAdminNotification() {
    const res = await axios.get(`${BASE_URL}/client/notification`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });

    setNotification(res.data);
  }

  async function deleteNotification(id) {
    await axios.delete(`${BASE_URL}/client/notification/${id}`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    getAdminNotification();
    getNotificationCount();
  }

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.id !== 'hello') {
        setActive(false);
      }
    });
  }, [active]);

  const [balance, setBalance] = useState('');

  const getMyBalance = async () => {
    let info = await $api(`/client/user/me`);
    setBalance(info.data.user.balance);
  };

  useEffect(() => {
    getMyBalance();
    getAdminNotification();
    getNotificationCount();
  }, []);

  console.log(notification);

  return (
    <div className={s.container}>
      <Link className={s.linkLogo} to="/">
        <img className={s.logoRoboweb} src={logoSvg} alt="roboweb" />
      </Link>
      <Link className={s.linkLogoMobile} to="/">
        <img className={s.logoMobile} src={logoMobile} alt="roboweb" />
      </Link>
      <ul className={s.navigateList}>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/profilePartnerMyWork">
            {lang === 'ru' ? 'Мои работы' : `My works`}
          </Link>
        </li>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/templates">
            {lang === 'ru' ? 'Создать сайт' : `Create a website`}
          </Link>
        </li>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/partners">
            {lang === 'ru' ? 'Партнерам' : `Partners`}
          </Link>
        </li>
      </ul>
      {burgerActivePartner && (
        <ul className={s.navigateListMobile}>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profilePartners">
              {lang === 'ru' ? 'Профиль' : `Profile`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
              {lang === 'ru' ? 'Мои работы' : `My works`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
              {lang === 'ru' ? 'Аналитика' : `Analytics`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
              {lang === 'ru' ? 'Загрузить работу' : `Download work`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
              {lang === 'ru' ? 'На модерации' : `Moderation`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/technicalSupport">
              {lang === 'ru' ? 'Техподдержка' : `Support`}
            </Link>
          </li>
          <Button
            onClick={() => {
              setHeaderType('');
              dispatch(logout());
              navigate('/');
            }}
            className={s.btnSignIn}
          >
            {lang === 'ru' ? 'Выйти' : `Exit`}
          </Button>
          <div className={s.switchBox}>
            <label className={s.switch}>
              <input
                type="checkbox"
                checked={lang === 'ru' ? '' : 'checked'}
                onClick={() =>
                  lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
                }
                onChange={(e) => {}}
              />
              <span className={`${s.slider} ${s.round}`}></span>
              <span className={s.ru}>RU</span>
              <span className={s.en}>EN</span>
            </label>
          </div>
        </ul>
      )}
      <div className={s.groupBox}>
        <div className={s.iconBox}>
          <div className={s.moneyBox}>
            <span className={s.money}>{new Intl.NumberFormat('ru-RU').format(balance)}</span>
            <img className={s.moneyIcon} src={moneyIcon} alt="Moderation" />
          </div>
          <div className={s.notificationContain}>
            <div
              className={s.notificationBox}
              onClick={() => setActiveNotifications(!activeNatifications)}
            >
              <img className={s.notificationIcon} src={notificationIcon} alt="Notification" />
              {notificationCount.notificationCount && (
                <span className={s.notificationCount}>{notificationCount.notificationCount}</span>
              )}
            </div>

            {activeNatifications && (
              <NotificationsBlock
                deleteNotification={deleteNotification}
                notification={notification}
                setActiveNotifications={setActiveNotifications}
              />
            )}
          </div>
        </div>
        <div onClick={() => setActive(!active)} className={s.sortedBlock} id="hello">
          <span className={s.sortedMenu} id="hello">
            {lang === 'ru' ? 'Меню' : `Menu`}
          </span>
          <img
            style={{
              transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={s.arrowMenu}
            src={arrowMenu}
            alt="Arrow"
            id="hello"
          />
          {active && (
            <div className={s.dropDownBox}>
              <span onClick={() => setActive(!active)} className={s.profile}>
                <Link className={s.itemLinkMobile} to="/profilePartners">
                  {lang === 'ru' ? 'Профиль' : `Profile`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.myWorks}>
                <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
                  {lang === 'ru' ? 'Мои работы' : `My works`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.analitic}>
                <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
                  {lang === 'ru' ? 'Аналитика' : `Analytics`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.downloadWork}>
                <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
                  {lang === 'ru' ? 'Загрузить работу' : `Download work`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.moderation}>
                <Link className={s.itemLinkMobile} to="/profilePartnerMyWork">
                  {lang === 'ru' ? 'На модерации' : `Moderation`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.tecnicalSupport}>
                <Link className={s.itemLinkMobile} to="/technicalSupport">
                  {lang === 'ru' ? 'Техподдержка' : `Support`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.exit}>
                <Link
                  onClick={() => {
                    setHeaderType('');
                    dispatch(logout());
                  }}
                  className={s.itemLinkMobile}
                  to="/"
                >
                  {lang === 'ru' ? 'Выйти' : `Exit`}
                </Link>
              </span>
            </div>
          )}
        </div>
        <div className={s.switchBox}>
          <label className={s.switch}>
            <input
              type="checkbox"
              checked={lang === 'ru' ? '' : 'checked'}
              onClick={() =>
                lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
              }
              onChange={(e) => {}}
            />
            <span className={`${s.slider} ${s.round}`}></span>
            <span className={s.ru}>RU</span>
            <span className={s.en}>EN</span>
          </label>
        </div>
        <div onClick={() => setBurgerActivePartner(!burgerActivePartner)} className={s.burgerBox}>
          <img
            style={{
              transform: burgerActivePartner ? 'rotate(45deg)' : 'rotate(0deg)',
              transformOrigin: burgerActivePartner ? 'center' : '',
              marginBottom: burgerActivePartner ? '-8px' : '5px',
            }}
            className={s.burgerTop}
            src={burherMenu}
            alt="Burger menu"
          />
          <img
            style={{
              transform: burgerActivePartner ? 'rotate(-45deg)' : 'rotate(0deg)',
              transformOrigin: burgerActivePartner ? 'center' : '',
            }}
            className={s.burgerBottom}
            src={burherMenu}
            alt="Burger menu"
          />
        </div>
      </div>
    </div>
  );
}

export default PartnersHeader;
