import React from 'react';
import AdditionalOffers from '../../components/AdditionalOffers/AdditionalOffers';
import PriceBlock from '../../components/PriceBlock/PriceBlock';
import PriceHeading from '../../components/PriceHeading/PriceHeading';
import ReviewsBlockMain from '../../components/ReviewsBlockMain/ReviewsBlockMain';
import UseForFree from '../../components/UseForFree/UseForFree';

function Price() {
  return (
    <>
      <PriceHeading />
      <PriceBlock />
      <AdditionalOffers />
      <ReviewsBlockMain />
      <UseForFree />
    </>
  );
}

export default Price;
