export class ChangeImgAction {
  constructor(src, el) {
    this.el = el;
    this.src = src;
  }

  undo() {
    this.el.src = this.src;
  }
  redo() {
    this.el.src = this.src;
  }
}
