import changeSrc from '../../../assets/TemplateUpdate/social.svg';
import closeBtn from '../../../assets/TemplateUpdate/close.svg';
import './style.css';

export const changeSocial = (iframe, socialLink, disabledRef) => {
  const div = document.createElement('div');
  const h3 = document.createElement('h3');
  const input = document.createElement('input');
  const btn = document.createElement('button');
  const btnClose = document.createElement('img');

  iframe.body.appendChild(div);
  div.appendChild(h3);
  div.appendChild(input);
  div.appendChild(btn);
  div.appendChild(btnClose);

  div.style.cssText = `
  height: 20%;
  width: 40%;
  position: fixed;
  top: 30%;
  left: 30%;
  border: 1px solid black;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  gap: 10px;
  align-items: center;
  `;

  h3.style.cssText = `
  font-size: 24px;
  `;
  h3.textContent = `
  Новая ссылка для соц. сети
  `;

  input.placeholder = 'Введите ссылку';
  input.style.cssText = `
  width: 80%;
  `;
  btn.textContent = 'Сохранить';
  btn.style.cssText = `
  width: 50%;
  `;
  btn.addEventListener('click', () => {
    socialLink.href = input.value;
    disabledRef.current = false;
    div.remove();
  });
  btnClose.style.cssText = `
  position: fixed;
  top: 25%;
  left: 71%;
  `;
  btnClose.src = closeBtn;
  btnClose.addEventListener('click', () => {
    div.remove();
    disabledRef.current = false;
  });
};

const createImg = (src, iframe, socialLink, disabledRef, div) => {
  const img = document.createElement('img');
  img.src = src;
  img.style.cssText = `
  background-color: white;
  margin-right: 5px;
  border-radius: 10px;
  `;
  img.addEventListener('click', () => {
    changeSocial(iframe, socialLink, disabledRef);
    disabledRef.current = true;
    div.remove();
  });
  return img;
};

const deleteSocial = (changeSrc) => {};

export const changeSocialBlock = (socialLink, iframe, disabledRef) => {
  const div = document.createElement('div');
  div.setAttribute('class', 'textEdit');
  div.style.cssText = `
  position: absolute;
  z-index: 10;
  top: -43px;
  left: 0;
  padding-bottom: 10px;
  zoom: 1;
  display: flex;

  `;
  div.appendChild(createImg(changeSrc, iframe, socialLink, disabledRef, div));
  // div.appendChild(deleteSocial(changeSrc));
  return div;
};
