import React, { useState } from 'react';

import s from './PersonalAccountPartnerAnalytics.module.scss';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import Calendar from 'color-calendar';
import 'color-calendar/dist/css/theme-glass.css';
import { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { BASE_URL } from '../../http';

import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import toRubFormat from '../../utils/toRubFormat';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const mockData = [
  {
    label: 'label 1',
    value: 40,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 2',
    value: 80,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 3',
    value: 10,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 5',
    value: 20,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 6',
    value: 70,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 7',
    value: 50,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 8',
    value: 90,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 9',
    value: 100,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 10',
    value: 34,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 11',
    value: 56,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 12',
    value: 79,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 13',
    value: 2,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 14',
    value: 14,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 15',
    value: 98,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 16',
    value: 51,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 17',
    value: 123,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 18',
    value: 113,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 19',
    value: 154,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 20',
    value: 11,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 21',
    value: 12,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 22',
    value: 14,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 23',
    value: 78,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 24',
    value: 15,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 25',
    value: 79,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 26',
    value: 69,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 27',
    value: 68,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 28',
    value: 56,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 29',
    value: 58,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 30',
    value: 36,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
  {
    label: 'label 31',
    value: 70,
    color: '#32ECCA',
    hoverColor: '#00B191',
    nested: { sales: 7, income: 5400, favorites: 58, watch: 94 },
  },
];

// Custom tooltip
const externalTooltipHandler = (context) => {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);
    console.log(bodyLines);

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml +=
        '<tr><td style="color: #75777B; font-size: 16px; font-weight: 400;">' +
        '04.06.2022' +
        '</td></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      const span = '<span style="' + style + '"></span>';
      innerHtml +=
        '<tr><td style="font-weight: 400; font-size: 16px; line-height: 24px; color: black;">' +
        'Продаж: 7' +
        '</td></tr>';
      innerHtml +=
        '<tr><td style="font-weight: 400; font-size: 16px; line-height: 24px; color: black;">' +
        'Доход:: 5 400 ₽' +
        '</td></tr>';
      innerHtml +=
        '<tr><td style="font-weight: 400; font-size: 16px; line-height: 24px; color: black;">' +
        'В избранное: 58' +
        '</td></tr>';
      innerHtml +=
        '<tr><td style="font-weight: 400; font-size: 16px; line-height: 24px; color: black;">' +
        'Просмотров: 94' +
        '</td></tr>';
    });
    innerHtml += '</tbody>';

    let tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.backgroundColor = 'white';
  tooltipEl.style.boxShadow = '0px 0px 12px rgba(185, 192, 196, 0.41)';
  tooltipEl.style.borderRadius = '6px';
  tooltipEl.style.padding = '8px';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 20 + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
  tooltipEl.style.pointerEvents = 'none';
};

function PersonalAccountPartnerAnalytics() {
  const [calendarActive, setCalendarActive] = useState(false);
  let calendar;
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   calendar = new Calendar({
  //     id: '#color-calendar',
  //     theme: "glass",
  //
  //     // calendarSize: 'small',
  //     // start: '2020-08-17T06:00:00',
  //     // end: '2020-08-18T20:30:00',
  //     // name: 'Blockchain 101',
  //     primaryColor: "#145CD0",
  //     // borderRadius: '40%',
  //     headerColor: "black",
  //     weekdayType: "long-upper",
  //     monthDisplayType: "long",
  //     calendarSize: "small",
  //     layoutModifiers: ["month-left-align"],
  //     // fontFamilyHeader: "Circe",
  //     weekdayDisplayType: "long-lower",
  //
  //     backgroundColor: "blue",
  //     eventsData: [
  //       {
  //         id: 1,
  //         name: "French class",
  //         start: "2020-08-17T06:00:00",
  //         end: "2020-08-18T20:30:00"
  //       },
  //       {
  //         id: 2,
  //         name: "Blockchain 101",
  //         start: "2020-08-20T10:00:00",
  //         end: "2020-08-20T11:30:00"
  //       }
  //     ],
  //     dateChanged: (currentDate, events) => {
  //       console.log("date change", currentDate, events);
  //     },
  //     monthChanged: (currentDate, events) => {
  //       console.log("month change", currentDate, events);
  //     }
  //   });
  // }, []);

  const lang = useSelector((state) => state.lang.language);

  const [analyticsData, setAnatylicsData] = useState([]);

  const obj = [
    {
      ru: 'Заработано',
      en: `Earned`,
      // count: '15 800 ₽',
      count: `${analyticsData['earning']}`,
    },
    {
      ru: 'Продажи',
      en: `Sales`,
      count: `${analyticsData['countOrder']}`,
    },
    {
      ru: 'Добавлено в избранное',
      en: `Added to favorites`,
      count: `${analyticsData['countFavorite']}`,
    },
    {
      ru: 'Просмотров',
      en: `Views`,
      count: `${analyticsData['countView']}`,
    },
    {
      // ru: 'Созданных шаблона',
      ru: 'Всего шаблонов',
      en: `Total Templates`,
      count: `${analyticsData['countCreated']}`,
    },
    {
      ru: 'Активных шаблона',
      en: `Active template`,
      count: `${analyticsData['countActive']}`,
    },
  ];

  let myData = [
    {
      label: 'Сайт-визитка',
      labelEn: 'Business card site',
      count: 0,
      price: 215,
      color: '#0597F2',
    },
    {
      label: 'Одностраничный',
      labelEn: 'Single page',
      count: 0,
      price: 1015,
      color: '#145CD0',
    },
    {
      label: 'Корпоративный',
      labelEn: 'Corporate website',
      count: 0,
      price: 2205,
      color: '#32ECCA',
    },
    // {
    //   label: 'Индивидуальная разработка',
    //   count: 0,
    //   price: 17991,
    //   color: '#00B191',
    // },
    // { label: 'Хостинг и редактор', count: 0, price: 449, color: '#9EC3FF' },
  ];

  const [startDate, setStartDate] = useState(new Date('2022-12-01'));
  const [endDate, setEndDate] = useState(new Date('2023-12-31'));
  const [analytics, setAnalytics] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFinance = async () => {
    await fetch(
      `${BASE_URL}/client/partner/analytics?dateStart=${
        startDate?.toISOString().split('T')[0]
      }&dateEnd=${
        endDate ? endDate.toISOString().split('T')[0] : startDate?.toISOString().split('T')[0]
      }`,
      {
        method: 'GET',
        headers: {
          'x-access-token': `${localStorage.getItem('token')}`,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setAnatylicsData(data);
        getAnalytic();
      });
  };

  const getAnalytic = () => {
    // console.log(item['templateTypeId']);

    for (let i = 0; analyticsData.templateList?.length > i; i++) {
      if (
        analyticsData.templateList[i].templateTypeId ===
        // '5db045ac3a86ec7504a2f9b5'
        '5e01febc6aaee931304e0bde'
      ) {
        console.log('1111');
        myData[2].count = myData[2].count + 1;
        // setData(data[2].count + 1);
      } else if (analyticsData.templateList[i].templateTypeId === '5db045ac3a86ec7504a2f9b4') {
        console.log('2222');
        myData[1].count = myData[1].count + 1;
      } else if (analyticsData.templateList[i].templateTypeId === '5f8801bff2a48205849f909c') {
        console.log('3333');
        myData[0].count = myData[0].count + 1;
      } else {
        console.log('что то не так');
      }
    }
  };

  getAnalytic();

  useEffect(() => {
    getFinance();
  }, [startDate, endDate]);

  return (
    <div className={s.container}>
      <div className={s.personalAccountPartnerAnalyticsBlock}>
        <div className={s.mainTitleBox}>
          {/* <span className={s.mainTitle}>Аналитика продаж</span> */}
          {/* <div
            onClick={() => setCalendarActive(!calendarActive)}
            className={s.calendarTitleBox}
          >
            <span className={s.monthTitle}>май</span>
            <img className={s.arrowMenu} src={arrowMenu} alt="Arrow" />
            {calendarActive ? (
              <div className={s.sortedWrapper}>
                <DatePicker
                  locale={ru}
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </div>
            ) : (
              ''
            )}
          </div> */}
          <div>
            {/*Аналитика продаж за месяц*/}
            <div className={s.sortedBlock}>
              <span
                onClick={() => setAnalytics((prevState) => !prevState)}
                className={s.sortedMenu}
              >
                {/* Аналитика продаж за май */}
                {lang === 'ru' ? `Аналитика продаж за период:` : `Sales analytics for the period:`}
              </span>
              <img
                style={{
                  transform: analytics ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                className={s.arrowMenu}
                src={arrowMenu}
                alt={'Arrow'}
              />
            </div>
            {analytics ? (
              <div className={s.sortedWrapper}>
                <DatePicker
                  locale={lang === 'ru' ? ru : ''}
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={s.statisticsPartnerBox}>
          {obj.map((el, i) => (
            <div key={i} className={s.cardBox}>
              <h2 className={s.count}>{el.count}</h2>
              <span className={s.title}>{lang === 'ru' ? el.ru : el.en}</span>
            </div>
          ))}
        </div>
      </div>
      {/* <Bar
        data={{
          labels: mockData.map((el) => el.label),
          datasets: [
            {
              label: 'Test',
              data: mockData.map((el) => el.value),
              backgroundColor: mockData.map((el) => el.color),
              hoverBackgroundColor: mockData.map((el) => el.hoverColor),
              borderWidth: 1,
            },
          ],
        }}
        options={{
          layout: {
            padding: {
              bottom: 50,
            },
          },
          borderRadius: 5,
          scales: {
            x: {
              display: true,
              offset: true,
              stackWeight: 0,
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                color: '#000000',
              },
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler,
            },
          },
        }}
      /> */}
      <div className={s.graphContainer}>
        <div className={s.graphContainerItem}>
          <Doughnut
            type="doughnut"
            data={{
              labels: myData?.map((d) => (lang === 'ru' ? d.label : d.labelEn)),
              datasets: [
                {
                  label: 'Sites',
                  data: myData?.map((d) => d.count),
                  borderRadius: 15,
                  backgroundColor: myData.map((d) => d.color),
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              cutout: '50%',
              responsive: true,
              elements: {
                arc: {},
              },
              layout: {
                padding: 10,
              },
              onClick: false,
              plugins: {
                tooltip: {
                  enabled: true,
                },
                legend: {
                  display: false,
                  position: 'right',
                  labels: {
                    usePointStyle: true,
                    pointStyleWidth: 5,
                    pointStyle: 'rect',
                  },
                },
                datalabels: {
                  color: 'white',
                  font: {
                    size: '22px',
                    weight: '400',
                    family: 'Circe',
                  },
                  formatter: (value) => {
                    if (value < 45000) return '';
                    return toRubFormat(value);
                  },
                },
              },
            }}
            plugins={[ChartDataLabels]}
          />
        </div>
        <div className={s.graphContainerItem}>
          <div className={s.legendBlock}>
            <p className={s.legendTitle}>
              {lang === 'ru'
                ? `Покупки пользователей по видам шаблонов`
                : `User purchases by template types`}
            </p>
            <table className={s.table}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }} className={s.tableHeaderItem}>
                    {lang === 'ru' ? `Вид сайта:` : `Site type:`}
                  </th>
                  <th className={s.tableHeaderItem}>
                    {lang === 'ru' ? `Количество:` : `Quantity:`}
                  </th>
                  <th className={s.tableHeaderItem}>{lang === 'ru' ? `Стоимость:` : `Price:`}</th>
                  <th className={s.tableHeaderItem}>{lang === 'ru' ? `Итого:` : `Total:`}</th>
                </tr>
              </thead>
              <tbody>
                {myData?.map((item, i) => {
                  return (
                    <tr key={item.label} className={s.hoverEffect}>
                      <td
                        className={s.tableBodyItem}
                        width={210}
                        style={{ textAlign: 'left', display: 'flex' }}
                      >
                        <span
                          style={{
                            backgroundColor: myData.map((d) => d.color)[i],
                            marginTop: 5,
                          }}
                          className={s.colorID}
                        ></span>
                        {lang === 'ru' ? item.label : item.labelEn}
                      </td>
                      <td className={s.tableBodyItem}>{item.count}</td>
                      <td className={s.tableBodyItem}>{toRubFormat(item.count)}</td>
                      <td className={s.tableBodyItem}>{toRubFormat(item.count)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ textAlign: 'left' }} className={s.tableFooterItem}>
                    {lang === 'ru' ? `Итого:` : `Total:`}
                  </td>
                  <td className={s.tableFooterItem}>
                    {analyticsData.templateTypeList?.reduce(
                      (current, item) => console.log(current),
                      // 0
                      '',
                    )}
                  </td>
                  <td></td>
                  <td className={s.tableFooterItem}>
                    {toRubFormat(myData?.reduce((current, item) => current + item.count, 0))}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalAccountPartnerAnalytics;
