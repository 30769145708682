import React, { useEffect, useState } from 'react';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import s from './FavoritesBlock.module.scss';
import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Like } from '../../assets/FullListTeamplates/like.svg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton';

function FavoritesBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(lang === 'ru' ? `По названию` : 'By name');
  const [activeId, setActiveId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // sorting
  const [sortType, setSortType] = useState('NAME');

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  const [favoriteList, setFavoriteList] = useState([]);

  const navigate = useNavigate();

  const getFavorites = async (templateSortType) => {
    // setIsLoading(true);
    const response = await axios.get(
      `${BASE_URL}/client/user/templates/favorite?templateSortType=${templateSortType}&orderSortType=ASC`,
      {
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      },
    );
    console.log(response.data.favoriteList);
    setFavoriteList(response.data.favoriteList);
    setIsLoading(false);
  };

  const skeletons = [...new Array(4)].map((_, i) => <TemplateSkeleton key={i} />);

  const removeFavorite = async (id, templateSortType) => {
    await axios.post(
      `${BASE_URL}/client/template/${id}/remove-favorite`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
      },
    );
    getFavorites(templateSortType);
  };

  useEffect(() => {
    getFavorites(sortType);
  }, [sortType]);

  return (
    <div className={s.mainContainer}>
      <div onClick={() => setActive(!active)} className={s.sortedBlock}>
        <span className={s.sortedMenu}>{sortedName}</span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  setSortType(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        {/* <div className={s.favoritesBlock}>
      {favoriteList.length ?
          favoriteList.map((item) => (
              <div key={item.id} className={s.cardBox}>
                <div className={s.imgBox}>
                  <img className={s.cardImage} src={`${BASE_URL}/${item.img}`} alt="Site" />
                  <Button className={s.btnUpdate}>Редактировать</Button>
                  <ButtonTemplates
                      onClick={() => {
                        navigate(`/viewTemplate/${item.id}`)
                      }}
                      className={s.btnLook}>Посмотреть</ButtonTemplates>
                  <Like className={s.like} src={Like} alt="Like" />
                </div>
                <span className={s.siteName}>{item.title}</span>
              </div>
          ))
          : null}
    </div> */}
        <div className={s.cardBlock}>
          {favoriteList.length ? (
            favoriteList.map((item) => (
              <div key={item.id} className={s.cardBoxMain}>
                <div className={s.cardBox}>
                  <div className={s.imgBox}>
                    <img className={s.imgCard} src={`${BASE_URL}/${item.img}`} alt="Шаблон сайта" />
                  </div>
                  <Button
                    onClick={() => {
                      navigate(`/templateUpdate/${item.id}`);
                    }}
                    className={s.btnUpdate}
                  >
                    {lang === 'ru' ? `Редактировать` : `Edit`}
                  </Button>
                  <ButtonTemplates
                    onClick={() => {
                      navigate(`/viewTemplate/${item.id}`);
                    }}
                    className={s[lang === 'ru' ? 'btnViewing' : 'btnViewingEn']}
                  >
                    {lang === 'ru' ? `Посмотреть` : `Look`}
                  </ButtonTemplates>
                  <Like
                    className={s.like}
                    src={Like}
                    alt="Like"
                    onClick={() => removeFavorite(item.id, sortType)}
                  />
                </div>
                <h3 className={s.siteName}>{item.title}</h3>
                <Button
                  onClick={() => {
                    navigate(`/templateUpdate/${item.id}`);
                  }}
                  className={s.btnUpdateMobile}
                >
                  {lang === 'ru' ? `Редактировать` : `Edit`}
                </Button>
                <Button
                  onClick={() => {
                    navigate(`/viewTemplate/${item.id}`);
                  }}
                  className={s.btnViewingMobile}
                >
                  {lang === 'ru' ? `Посмотреть` : `Look`}
                </Button>
              </div>
            ))
          ) : isLoading ? (
            skeletons
          ) : (
            <div className={s.cardBoxoMain}>
              <p className={s.cardBoxo}>Список пуст</p>
            </div>
          )}
        </div>
        <ButtonTemplates onClick={() => navigate('/templates')} className={s.btnTemplates}>
          {lang === 'ru' ? `Смотреть все шаблоны` : `See all templates`}
        </ButtonTemplates>
      </div>
    </div>
  );
}

export default FavoritesBlock;
