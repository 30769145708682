import React, { useEffect, useState } from 'react';

import s from './AdministrationRequestForModerationTable.module.scss';
import loadingFile from '../../assets/img/loadingFile.svg';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';
import { BASE_URL } from '../..';
import axios from 'axios';
import $api from '../../http';
import { useSelector } from 'react-redux';

const headerTable = [
  {
    ru: '№',
    en: '№',
  },
  {
    ru: 'Наименование',
    en: 'Name',
  },
  {
    ru: 'Раздел сайта',
    en: 'Section of the website',
  },
  {
    ru: 'Автор',
    en: 'Author',
  },
  {
    ru: 'Дата',
    en: 'Date',
  },
  {
    ru: 'Скачать',
    en: 'Download',
  },
];

function AdministrationRequestsForModerationTable({ templateManagmentStatus, value, setValue }) {
  const lang = useSelector((state) => state.lang.language);

  const [current, setCurrent] = useState(1);
  // const [value, setValue] = useState(1);
  const [data, setData] = useState([]);

  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  const [totalCount, setTotalCount] = useState(1);

  let total = current === 1 ? 0 : current * 15 - 15;
  //Получение шаблонов на модерацию
  const getModerTemplate = async () => {
    let templateList = await $api.get(
      `/sa/template/moderation?orderSortType=DESC&templateModerationSortType=CREATION_DATE&${
        templateManagmentStatus ? `templateManagmentStatus=${templateManagmentStatus}&` : ''
      }limit=${15}&offset=${total}`,
    );
    setData(templateList.data.templateList);
    setTotalCount(templateList.data.templateTotalCount);
    console.log(templateList.data.templateList);
  };

  //Отправка шаблонов на провеку
  const setConsideration = (id) => {
    fetch(`${BASE_URL}/sa/template/${id}/consideration`, {
      method: 'POST',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      console.log(res);
      getModerTemplate();
    });
  };

  //Отправка шаблонов в архив
  const setApprove = (id) => {
    fetch(`${BASE_URL}/sa/template/${id}/approve`, {
      method: 'POST',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      console.log(res);
      getModerTemplate();
    });
  };

  //Отправка шаблонов в архив
  const setArchive = (id) => {
    fetch(`${BASE_URL}/sa/template/${id}/archive`, {
      method: 'POST',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    }).then((res) => {
      console.log(res);
      getModerTemplate();
    });
  };

  //Отклонение шаблона
  const setNotApproved = async (id) => {
    await $api.post(`/sa/template/${id}/unapprove`).then((res) => {
      console.log(res);
      getModerTemplate();
    });
  };

  //Получение порядкового номера
  let num = [];

  const setNum = () => {
    for (let i = total; totalCount > i; i++) {
      num.push(i + 1);
      num.slice(0, 15);
    }
  };

  setNum();

  //Получение категории шаблона

  const [category, getCategory] = useState([]);
  const getPrice = async () => {
    let getNameCategory = await axios.get(`${BASE_URL}/sa/template-type`, config);
    console.log(getNameCategory.data.templateTypeList);
    getCategory(getNameCategory.data.templateTypeList);
  };

  const getCategoryName = (id) => {
    let c = category?.filter((cat) => cat.id === id);
    return lang === `ru` ? c[0]?.titleRu : c[0]?.titleEn;
  };

  useEffect(() => {
    getModerTemplate();
    getPrice();
    getCategoryName();
  }, [templateManagmentStatus, current]);

  // console.log(num.slice(0,3))
  // console.log(template)

  function changeCurrentPage(n) {
    if (n > data.count / 15) {
      console.log(n);
      setCurrent(Math.ceil(data?.count / 15));
      setValue(Math.ceil(data?.count / 15));
      return;
    }
    if (n < 1) {
      setCurrent(1);
      setValue(1);
      return;
    }

    setCurrent(n);
    setValue(n);
  }

  console.log(num);
// console.log(data)
  return (
    <div className={s.mainContainer}>
      <div className={s.container}>
        <div className={s.tableBlock}>
          <div className={s.tableHeader}>
            {headerTable.map((obj, i) => (
              <div className={s.tableHeader_title} key={i}>
                <span className={s.title}>{lang === `ru` ? obj.ru : obj.en}</span>
              </div>
            ))}
          </div>
          <div className={s.tableTitle}>
            {data?.map((obj, i) => {
                  const date = new Date(obj.createdAt);
                  function formatDate(date) {
                    var dd = date.getDate();
                    if (dd < 10) dd = '0' + dd;
    
                    var mm = date.getMonth() + 1;
                    if (mm < 10) mm = '0' + mm;
    
                    var yy = date.getFullYear() % 100;
                    if (yy < 10) yy = '0' + yy;
    
                    return dd + '.' + mm + '.' + yy;
                  }
              return (
                <div className={s.tableTitle_item} key={i}>
                  <span className={s.number}>{num[i]}</span>
                  <div className={s.title}>
                    <h3 className={s.nameSite}>{obj.title}</h3>
                    <span className={s.siteView}>{getCategoryName(obj.templateTypeId)}</span>
                    <span className={s.siteId}>{obj.id}</span>
                  </div>
                  <span className={s.categorySite}>{'obj.category'}</span>
                  <span className={s.author}>
                    {obj.user ? obj.user.name + ' ' + obj.user.surname : 'Имя и Фамилия'}
                  </span>
                  <div className={s.dataBox}>
                    <span className={s.data}>{formatDate(date)}</span>
                    {/* <span className={s.time}>в {obj.createdAt}</span> */}
                  </div>
                  <span className={s.iconLoadingFile}>
                    <img className={s.icon} src={loadingFile} alt="" />
                  </span>
                  <span className={s.btnBox}>
                    {}
                    <button
                      onClick={() => {
                        obj.status.toLowerCase() === 'consideration'
                          ? setApprove(obj.id)
                          : setConsideration(obj.id);
                      }}
                      className={
                        obj.status.toLowerCase() === 'consideration'
                          ? s.btnUnderReview
                          : s.btnAcceptForReview
                      }
                    >
                      {obj.status.toLowerCase() === 'consideration' && lang === `ru`
                        ? 'На проверке'
                        : obj.status.toLowerCase() !== 'consideration' && lang === `ru`
                        ? 'Принять на проверку'
                        : obj.status.toLowerCase() === 'consideration' && lang === `en`
                        ? `Under review`
                        : `Accept for review`}
                    </button>
                    <button
                      onClick={() => setNotApproved(obj.id)}
                      className={
                        obj.status.toLowerCase() === 'not_approved' ? s.btnRejected : s.btnReject
                      }
                    >
                      {obj.status.toLowerCase() === 'not_approved' && lang === `ru`
                        ? 'Отклонено'
                        : obj.status.toLowerCase() !== 'not_approved' && lang === `ru`
                        ? `Отклонить`
                        : obj.status.toLowerCase() === 'not_approved' && lang === `en`
                        ? `Rejected`
                        : `Reject`}
                    </button>
                    {/* <button className={s.btnUnderReview}>На проверке</button>
                <button className={s.btnVerified}>Проверено</button>
                <button className={s.btnRejected}>Отклонено</button> */}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.paginationBox}>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => changeCurrentPage(value)}
            className={s.inputPagination}
            type="number"
            min="1"
            max={totalCount / 15}
          />
          <span className={s.current}>
            {lang === `ru` ? `из` : `of`}{' '}
            {Math.ceil(totalCount / 15) === 0 ? '1' : Math.ceil(totalCount / 15)}
          </span>
          <div className={s.btnBox}>
            <button
              className={s.btnPrev}
              // onClick={() => changeCurrentPage(+current - 1)}
              onClick={() =>
                value > 1 ? changeCurrentPage(+current - 1) : changeCurrentPage(+current)
              }
            >
              <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
            </button>
            <button
              className={s.btnNext}
              // onClick={() => changeCurrentPage(+current + 1)}
              onClick={() =>
                Math.ceil(totalCount / 15) <= value
                  ? changeCurrentPage(+current)
                  : changeCurrentPage(+current + 1)
              }
            >
              <Arrow className={s.arrow} src={Arrow} alt="Like" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationRequestsForModerationTable;
