import {useEffect, useRef, useState } from "react";

const Counter = ({ value, className }) => {
    const [count, setCount] = useState(0);
    const countRef = useRef(0);
    countRef.current = count;

    useEffect(() => {
        const timer = setInterval(() => {
            if (countRef.current < value) {
                setCount((prevCount) => prevCount + 1);
            } else {
                clearInterval(timer)
            }
        }, 1);

        return () => {
            clearInterval(timer)
        }
    }, []);

    return <h3 className={className}>{count}</h3>
};

export default Counter;