import React, { useRef } from 'react';
import { useEffect } from 'react';

import s from './PortfolioHeading.module.scss';
import gearGreenMiddle from '../../assets/PortfolioHeading/gearGreenMiddle.svg';
import circleArrow from '../../assets/TemplatesHeadingIcon/circleArrow.svg';
import neirosetLeft from '../../assets/TemplatesHeadingIcon/neirosetLeft.svg';
import neirosetRight from '../../assets/TemplatesHeadingIcon/neirosetRight.svg';
import neirosetMiddle from '../../assets/TemplatesHeadingIcon/neirosetMiddle.svg';
import circleBlue from '../../assets/TemplatesHeadingIcon/circleBlue.png';
import circleGreenRight from '../../assets/TemplatesHeadingIcon/circleGreenRight.svg';
import circleGreenBig from '../../assets/PortfolioHeading/circleGreenLeft.svg';
import gearBlueRight from '../../assets/PortfolioHeading/gearBlueRight.svg';
import gearBackgroundBlueLeft from '../../assets/TemplatesHeadingIcon/gearBackgroundBlueLeft.svg';
import { useSelector } from 'react-redux';

function PortfolioHeading() {
  const lang = useSelector((state) => state.lang.language);

  const ref = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    let circleGreenBig = document.querySelector(`.${s.circleGreenBig}`);
    let circleBlue = document.querySelector(`.${s.circleBlue}`);
    let circleGreenRight = document.querySelector(`.${s.circleGreenRight}`);
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      circleBlue.style.transform =
        'translate(-' + x * 220 + 'px, -' + y * 20 + 'px)';
      circleGreenRight.style.transform =
        'translate(-' + x * 180 + 'px, -' + y * 50 + 'px)';
      circleGreenBig.style.transform =
        'translate(-' + x * 138 + 'px, -' + y * 25 + 'px)';
    });
  }, []);
  return (
    <section>
      <div className={s.container}>
        <div ref={ref} className={s.wrapper}>
          <div className={s.templatesHeadingBlock}>
            <div className={s.templatesHeadingBox}>
              <h1 className={s.mainTitle}>
                {lang === 'ru' ? `Портфолио` : `Portfolio`}
              </h1>
              <h3 className={s.about}>
                {lang === 'ru'
                  ? `Здесь собран полный список сайтов сделанных с помощью RoboWeb`
                  : `Here is a complete list of sites made using RoboWeb`}
              </h3>
              <div className={s.gearBox}>
                <img
                  className={s.gearGreenMiddle}
                  src={gearGreenMiddle}
                  alt="Gear"
                />
                <img className={s.arrowCircle} src={circleArrow} alt="Arrow" />
              </div>
              <img className={s.neirosetLeft} src={neirosetLeft} alt="" />
              <img className={s.neirosetMiddle} src={neirosetMiddle} alt="" />
              <img className={s.neirosetRight} src={neirosetRight} alt="" />
              <img className={s.circleBlue} src={circleBlue} alt="" />
              <img
                className={s.circleGreenRight}
                src={circleGreenRight}
                alt=""
              />
              <img className={s.circleGreenBig} src={circleGreenBig} alt="" />
              <img className={s.gearBlueRight} src={gearBlueRight} alt="" />
              <img
                className={s.gearBackgroundBlueLeft}
                src={gearBackgroundBlueLeft}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PortfolioHeading;
