import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PortfolioFilter from '../PortfolioFilter/PortfolioFilter';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import s from './PortfolioBlockPage.module.scss';
import Button from '../ui/Button/Button';
import { BASE_URL } from '../..';

function PortfolioBlockPage() {
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [value, setValue] = useState(1);

  const getTemplates = async (limit, offset, categoryId, templateTypeId, searchValue) => {
    const response = await axios.get(
      `${BASE_URL}/client/template?limit=${15}&offset=${0}&isUserTemplate=${true}`,
    );
    // setIsLoading(false);
    console.log(response, 'rrrrrrrrrrrrrrrrrrrr');
    setData(response.data.templateList);
  };

  console.log(data)

  useEffect(() => {
    getTemplates()
      // .then((data) => data)
      // .then((res) => console.log(res.json()));
    // axios(`https://back.roboweb.site/templates/get/${current}/20`).then((res) => setData(res.data));
  }, []);
  console.log(data);
  function changeCurrentPage(n) {
    if (n > data.count / 15) {
      console.log(n);
      setCurrent(Math.ceil(data?.count / 15));
      setValue(Math.ceil(data?.count / 15));
      return;
    }
    if (n < 1) {
      setCurrent(1);
      setValue(1);
      return;
    }

    setCurrent(n);
    setValue(n);
  }
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.portfolioBlock}>
            <PortfolioFilter />
            <div className={s.cardBlockWrapper}>
              <div className={s.cardBlock}>
                {data?.map((obj) => (
                  <div className={s.cardBoxMain} key={`${BASE_URL}/${obj.id}`}>
                    <div className={s.cardBox}>
                      <div className={s.imgBox}>
                        <img
                          className={s.imgCard}
                          src={`${BASE_URL}/${obj.imgCatalog}`}
                          alt="Шаблон сайта"
                        />
                      </div>
                      <ButtonTemplates className={s.btnViewing}>Посмотреть</ButtonTemplates>
                    </div>
                    <h3 className={s.siteName}>{obj.title}</h3>
                    <Button className={s.btnViewingMobile}>Посмотреть</Button>
                  </div>
                ))}
              </div>
              <div className={s.paginationBox}>
                <input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={(e) => changeCurrentPage(value)}
                  className={s.inputPagination}
                  type="number"
                />
                <span className={s.current}>из {Math.ceil(data?.count / 15)}</span>
                <div className={s.btnBox}>
                  <button className={s.btnPrev} onClick={() => changeCurrentPage(+current - 1)}>
                    <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
                  </button>
                  <button className={s.btnNext} onClick={() => changeCurrentPage(+current + 1)}>
                    <Arrow className={s.arrow} src={Arrow} alt="Like" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PortfolioBlockPage;
