import React, { useState } from 'react';
import s from './AdministrationFinanceBlock.module.scss';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import toRubFormat from '../../utils/toRubFormat';

import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// Doughnut chart
// const myData = [
//   { label: 'Сайт-визитка', count: 420, price: 215, color: '#0597F2' },
//   { label: 'Одностраничный', count: 314, price: 1015, color: '#145CD0' },
//   { label: 'Корпоративный', count: 82, price: 2205, color: '#32ECCA' },
//   {
//     label: 'Индивидуальная разработка',
//     count: 1,
//     price: 17991,
//     color: '#00B191',
//   },
//   { label: 'Хостинг и редактор', count: 12, price: 449, color: '#9EC3FF' },
// ];
let myData = [
  {
    label: 'Сайт-визитка',
    labelEn: 'Business card site',
    count: 0,
    price: 215,
    color: '#0597F2',
  },
  {
    label: 'Одностраничный',
    labelEn: 'Single page',
    count: 0,
    price: 1015,
    color: '#145CD0',
  },
  {
    label: 'Корпоративный',
    labelEn: 'Corporate website',
    count: 0,
    price: 2205,
    color: '#32ECCA',
  },
  // {
  //   label: 'Индивидуальная разработка',
  //   count: 0,
  //   price: 17991,
  //   color: '#00B191',
  // },
  // { label: 'Хостинг и редактор', count: 0, price: 449, color: '#9EC3FF' },
];

function AdministrationFinanceBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [user, setUser] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [analyticsData, setAnatylicsData] = useState([]);

  const [startDate, setStartDate] = useState(new Date('2022-12-01'));
  const [endDate, setEndDate] = useState(new Date('2022-12-31'));
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getFinance = async () => {
    await fetch(
      `${BASE_URL}/sa/finance/info?dateStart=${
        startDate?.toISOString().split('T')[0]
      }&dateEnd=${
        endDate
          ? endDate.toISOString().split('T')[0]
          : startDate?.toISOString().split('T')[0]
      }`,
      {
        method: 'GET',
        headers: {
          'x-access-token': `${localStorage.getItem('token')}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAnatylicsData(data);
      });
  };

  useEffect(() => {
    getFinance();
  }, [startDate, endDate]);

  console.log(analyticsData);

  return (
    <>
      <div className={s.container}>
        <p className={s.title}>{lang === `ru` ? `Финансы` : `Finance`}</p>
        <div className={s.menusContainer}>
          <div>
            {/*Пользователи*/}
            <div className={s.sortedBlock}>
              <span
                onClick={() => setUser((prevState) => !prevState)}
                className={s.sortedMenu}
              >
                {lang === `ru` ? `Пользователи` : `Users`}
              </span>
              <img
                style={{
                  transform: user ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                className={s.arrowMenu}
                src={arrowMenu}
                alt={'Arrow'}
              />
            </div>
          </div>
          {/* /.menus-item */}
          <div>
            {/*Аналитика продаж за месяц*/}
            <div className={s.sortedBlock}>
              <span
                onClick={() => setAnalytics((prevState) => !prevState)}
                className={s.sortedMenu}
              >
                {/* Аналитика продаж за май */}
                {lang === `ru`
                  ? `Аналитика продаж за период:`
                  : `Sales analytics for the period:`}
              </span>
              <img
                style={{
                  transform: analytics ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                className={s.arrowMenu}
                src={arrowMenu}
                alt={'Arrow'}
              />
            </div>
            {analytics ? (
              <div className={s.sortedWrapper}>
                <DatePicker
                  locale={lang === `ru` ? ru : ''}
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {/* /.menus-item */}
        </div>
        {/* /.menus-container */}
        <div className={s.graphContainer}>
          <div className={s.graphContainerItem}>
            <Doughnut
              data={{
                labels: analyticsData.templateTypeList?.map((d) =>
                  lang === `ru` ? d.titleRu : d.titleEn
                ),
                datasets: [
                  {
                    label: 'Sites',
                    data: analyticsData.templateTypeList?.map(
                      (d) => d.price * d.orderCount
                    ),
                    borderRadius: 15,
                    backgroundColor: myData.map((d) => d.color),
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{
                cutout: '50%',
                responsive: true,
                elements: {
                  arc: {},
                },
                layout: {
                  padding: 10,
                },
                onClick: false,
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    display: false,
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                      pointStyleWidth: 5,
                      pointStyle: 'rect',
                    },
                  },
                  datalabels: {
                    color: 'white',
                    font: {
                      size: '22px',
                      weight: '400',
                      family: 'Circe',
                    },
                    formatter: (value) => {
                      if (value < 45000) return '';
                      return toRubFormat(value);
                    },
                  },
                },
              }}
              plugins={[ChartDataLabels]}
            />
          </div>
          <div className={s.graphContainerItem}>
            <div className={s.legendBlock}>
              <p className={s.legendTitle}>
                {lang === `ru` ? `Покупки пользователей` : `User purchases`}
              </p>
              <table className={s.table}>
                <thead>
                  <tr>
                    <th
                      style={{ textAlign: 'left' }}
                      className={s.tableHeaderItem}
                    >
                      {lang === `ru` ? `Вид сайта:` : `Site type:`}
                    </th>
                    <th className={s.tableHeaderItem}>
                      {lang === `ru` ? `Количество:` : `Quantity:`}
                    </th>
                    <th className={s.tableHeaderItem}>
                      {lang === `ru` ? `Стоимость:` : `Price:`}
                    </th>
                    <th className={s.tableHeaderItem}>
                      {lang === `ru` ? `Итого:` : `Total:`}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {analyticsData.templateTypeList?.map((item, i) => {
                    return (
                      <tr key={item.titleRu} className={s.hoverEffect}>
                        <td
                          className={s.tableBodyItem}
                          width={210}
                          style={{ textAlign: 'left', display: 'flex' }}
                        >
                          <span
                            style={{
                              backgroundColor: myData.map((d) => d.color)[i],
                              marginTop: 5,
                            }}
                            className={s.colorID}
                          ></span>
                          {lang === `ru` ? item.titleRu : item.titleEn}
                        </td>
                        <td className={s.tableBodyItem}>{item.orderCount}</td>
                        <td className={s.tableBodyItem}>
                          {toRubFormat(item.price)}
                        </td>
                        <td className={s.tableBodyItem}>
                          {toRubFormat(item.price * item.orderCount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      style={{ textAlign: 'left' }}
                      className={s.tableFooterItem}
                    >
                      {lang === `ru` ? `Итого:` : `Total:`}
                    </td>
                    <td className={s.tableFooterItem}>
                      {analyticsData.templateTypeList?.reduce(
                        (current, item) => current + +item.orderCount,
                        // 0
                        0
                      )}
                    </td>
                    <td></td>
                    <td className={s.tableFooterItem}>
                      {toRubFormat(
                        analyticsData.templateTypeList?.reduce(
                          (current, item) =>
                            current + item.price * item.orderCount,
                          0
                        )
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        {/* /.graph-container */}
      </div>
    </>
  );
}

export default AdministrationFinanceBlock;
