import axios from 'axios';

// const [arrAddFonts, setArrAddFonts] = useState(['Roboto']);

let arrAddFonts = [];

export const createDiv = (prev) => {
  const div = document.createElement('div');
  div.setAttribute('class', 'textEdit');

  const ul = document.createElement('ul');
  const li1 = document.createElement('li');
  const li2 = document.createElement('li');
  const li3 = document.createElement('li');
  const li5 = document.createElement('li');
  const li6 = document.createElement('li');

  ul.setAttribute('id', 'textEdit');
  li2.setAttribute('id', 'textEdit');

  div.appendChild(ul);
  ul.appendChild(li1);
  ul.appendChild(li2);
  ul.appendChild(li3);
  ul.appendChild(li5);
  ul.appendChild(li6);

  ul.style.cssText = `
  display: flex;
  box-shadow: 0px 0px 12px rgba(185, 192, 196, 0.41);
  border-radius: 15px;
  background-color: #ffffff;
  height: 57px;
  width: 70%;
  `;

  if (window.innerWidth >= 1330) {
    div.style.cssText = `
    position: absolute;
    z-index: 10;
    top: -58px;
    left: 0;
    padding-bottom: 10px;
    zoom: 1;
    `;
  }

  if (window.innerWidth < 1330) {
    div.style.cssText = `
    position: absolute;
    z-index: 10;
    top: -58px;
    left: 0;
    padding-bottom: 10px;
    zoom: 0.7;
    `;
  }

  if (window.innerWidth <= 1030) {
    div.style.cssText = `
    position: absolute;
    z-index: 10;
    top: -58px;
    left: 0;
    padding-bottom: 10px;
    zoom: 0.5;
    `;
  }

  li1.style.cssText = `
  margin: 0;
  `;

  li2.style.cssText = `
  margin: 0;
  width: 25%;
  `;

  li3.style.cssText = `
  margin: 0;
  `;

  li5.style.cssText = `
  margin: 0;
  `;

  li6.style.cssText = `
  margin: 0;
  `;

  //ALL FOR SELECT1

  let N = 71;
  let font_size = Array.apply(null, { length: N }).map(Number.call, Number);

  const select1 = document.createElement('select');
  // useEffect(() => {
  li1.appendChild(select1);
  font_size.map((size) => {
    const option = document.createElement('option');
    select1.appendChild(option);
    option.setAttribute('value', size);
    option.setAttribute('id', 'textEdit');
    option.textContent = size;
  });
  select1.addEventListener('change', () => {
    change1();
  });
  // }, []);
  li1.setAttribute('id', 'textEdit');
  select1.setAttribute('id', 'textEdit');

  select1.style.cssText = `
    height: 41px;
    font-size: 22px;
    font-weight: 400;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(185, 192, 196, 0.41);
    border-radius: 6px; 
    border: none;
    margin: 8px 0;
    margin-right: 10px;
    margin-left: 10px;
    `;

  const change1 = () => {
    prev.style.fontSize = `${select1.value}px`;
  };

  //ALL FOR SELECT2

  const select2 = document.createElement('select');
  select2.setAttribute('id', 'textEdit');

  select2.style.cssText = `
    height: 41px;
    font-size: 22px;
    font-weight: 400;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(185, 192, 196, 0.41);
    border-radius: 6px; 
    border: none;
    margin: 8px 0;
    margin-right: 10px;
  `;

  // useEffect(() => {
  const getFonts = async () => {
    let fonts = await axios
      .get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBWk3PDaMDc5-7OgqB2U3aQAcUxdaAjhg0`
      )
      .then((data) => data.data.items);
    li2.appendChild(select2);
    fonts.map((font) => {
      const option = document.createElement('option');
      select2.appendChild(option);
      option.setAttribute('value', font.family);
      option.setAttribute('id', 'textEdit');
      option.textContent = font.family;
    });
    select2.addEventListener('change', () => {
      change2();
    });
  };
  getFonts();
  // }, []);

  const change2 = () => {
    let iframe = document.getElementById('iframe');
    if (!arrAddFonts.includes(select2.value)) {
      //   setArrAddFonts((prev) => [...prev, select2.value]);
      arrAddFonts.push(select2.value);
      const link = document.createElement('LINK');
      link.rel = 'stylesheet';
      link.href = `https://fonts.googleapis.com/css?family=${select2.value}`;
      iframe.contentDocument.head.appendChild(link);
      prev.style.fontFamily = select2.value;
    } else {
      prev.fontFamily = select2.value;
    }
  };

  //ALL FOR SELECT3

  const font_style = [
    '---',
    'Bold',
    'Normal',
    'Lighter',
    'Underline',
    'Italic',
  ];

  const select3 = document.createElement('select');
  // useEffect(() => {
  li3.appendChild(select3);
  font_style.map((style) => {
    const option = document.createElement('option');
    select3.appendChild(option);
    option.setAttribute('value', style);
    option.setAttribute('id', 'textEdit');
    option.textContent = style;
  });
  select3.addEventListener('change', () => {
    change3();
  });
  // }, []);
  li3.setAttribute('id', 'textEdit');
  select3.setAttribute('id', 'textEdit');

  select3.style.cssText = `
  height: 41px;
  font-size: 22px;
  font-weight: 400;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(185, 192, 196, 0.41);
  border-radius: 6px; 
  border: none;
  margin: 8px 0;
  margin-right: 10px;
  `;

  const change3 = () => {
    prev.style.fontWeight = select3.value.toLowerCase();
    if (select3.value.toLowerCase() === 'bold') {
      console.log('wwaaayt');
    }
    if (select3.value.toLowerCase() === 'underline') {
      prev.style.textDecoration = 'underline';
    } else if (select3.value.toLowerCase() === 'italic') {
      prev.style.fontStyle = 'italic';
    }
  };

  //ALL FOR SELECT5

  const text_align = ['Start', 'Center', 'End'];

  const select5 = document.createElement('select');
  // useEffect(() => {
  li5.appendChild(select5);
  text_align.map((style) => {
    const option = document.createElement('option');
    select5.appendChild(option);
    option.setAttribute('value', style);
    option.setAttribute('id', 'textEdit');
    option.textContent = style;
  });
  select5.addEventListener('change', () => {
    change5();
  });
  // }, []);
  li5.setAttribute('id', 'textEdit');
  select5.setAttribute('id', 'textEdit');

  select5.style.cssText = `
    // width: 90px;
    // width: 15%;
    height: 41px;
    font-size: 22px;
    font-weight: 400;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(185, 192, 196, 0.41);
    border-radius: 6px; 
    border: none;
    margin: 8px 0;
    margin-right: 10px;
    `;

  const change5 = () => {
    prev.style.textAlign = select5.value.toLowerCase();
    if (select5.value.toLowerCase() === 'start') {
      prev.style.textAlign = 'start';
    } else if (select5.value.toLowerCase() === 'center') {
      prev.style.textAlign = 'center';
    } else if (select5.value.toLowerCase() === 'end') {
      prev.style.textAlign = 'end';
    }
  };

  //ALL FOR SELECT5

  const input1 = document.createElement('input');
  // useEffect(() => {
  li6.appendChild(input1);
  li6.setAttribute('id', 'textEdit');
  input1.setAttribute('id', 'textEdit');
  input1.setAttribute('type', 'color');
  input1.addEventListener('input', () => {
    prev.style.setProperty('color', input1.value, 'important');
  });
  // }, []);

  input1.style.cssText = `
      // width: 50px;
      // width: 10%;
      height: 41px;
      font-size: 22px;
      font-weight: 400;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px rgba(185, 192, 196, 0.41);
      border-radius: 6px; 
      border: none;
      margin: 8px 0;
      margin-right: 10px;
      `;

  return div;
};
