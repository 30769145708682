// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/loading.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_container__QClwY {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  position: absolute;\n  z-index: 10000000;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n}\n.Loader_container__QClwY .Loader_imgBox__Gabyr {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.Loader_container__QClwY .Loader_imgBox__Gabyr .Loader_logo__DHuPR {\n  position: absolute;\n  width: 10vh;\n  top: 50%;\n  transform: translateY(-50%);\n}\n.Loader_container__QClwY .Loader_title__AMAQF {\n  margin-top: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACF;AAAE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AADI;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAGN;AACE;EACE,gBAAA;AACJ","sourcesContent":[".container {\n  background-image: url('../../assets/img/loading.svg');\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  position: absolute;\n  z-index: 10000000;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  .imgBox {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    .logo {\n      position: absolute;\n      width: 10vh;\n      top: 50%;\n      transform: translateY(-50%);\n      //   top: 41%;\n    }\n  }\n  .title {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Loader_container__QClwY",
	"imgBox": "Loader_imgBox__Gabyr",
	"logo": "Loader_logo__DHuPR",
	"title": "Loader_title__AMAQF"
};
export default ___CSS_LOADER_EXPORT___;
