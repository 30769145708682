import React, { useEffect, useState } from 'react';
// import ReactSlider from 'react-slider';
import s from './HowMuchCanYouEarn.module.scss';
import arrow from '../../assets/howMuchCanEarnIcon/arrow.svg';
import questionMark from '../../assets/howMuchCanEarnIcon/questionMark.svg';
import Button from '../ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

function HowMuchCanYouEarn() {
  // const [currentValue, setCurrentValue] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [countSite, setCountSite] = useState(0);
  const [selected, setSelected] = useState('');
  const [namePrice, getNamePrice] = useState([]);
  const [price, getPrice] = useState('');
  const [countSiteMounth, setCountSiteMounth] = useState(0);
  const [allPrice, getAllPrice] = useState(0);
  const [lumpSumPayment, setLumpSumPayment] = useState(price * countSite);
  const [monthlyPayment, setMonthlyPayment] = useState(price * countSiteMounth * 0.25);
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const navigate = useNavigate();

  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }

  const options = ['Сайт визитка', 'Одностраничный сайт', 'Корпоративный сайт'];

  async function setPrice() {
    await fetch(`${BASE_URL}/client/template-type`, {
      method: 'GET',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    })
      .then((data) => data.json())
      .then((res) => {
        getNamePrice(res.templateTypeList);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setPrice();
    getAllPrice(monthlyPayment + lumpSumPayment);
    setLumpSumPayment(Math.floor(price * countSiteMounth * 0.25));
    setMonthlyPayment(Math.floor(price * countSite));
  }, [selected, countSiteMounth, price, countSite, isActive, monthlyPayment, lumpSumPayment]);

  useEffect(() => {
    getAllPrice(0);
    setLumpSumPayment(0);
    setMonthlyPayment(0);
    setCountSiteMounth('0');
    setCountSite(0);
  }, [selected]);

  const getSum = (option) => {
    console.log(namePrice);
    console.log(option);
    console.log(option.toLowerCase() === namePrice[1].titleRu.toLowerCase());
    if (option.toLowerCase() === namePrice[0].titleRu.toLowerCase()) {
      getPrice(Math.floor(namePrice[0].price * (1 - 0.01 * namePrice[1].sale)));
    } else if (option.toLowerCase() === namePrice[1].titleRu.toLowerCase()) {
      getPrice(Math.floor(namePrice[1].price * (1 - 0.01 * namePrice[2].sale)));
    } else if (option.toLowerCase() === namePrice[2].titleRu.toLowerCase()) {
      getPrice(Math.floor(namePrice[2].price * (1 - 0.01 * namePrice[3].sale)));
    } else {
      getPrice(0);
    }
  };

  const lang = useSelector((state) => state.lang.language);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `Сколько можно заработать?` : `How much can you earn?`}
          </h2>
          <div className={s.howMuchCanYouEarnBlock}>
            <div className={s.aboutFormBox}>
              <div className={s.typeSiteBox}>
                <div onClick={() => setIsActive(!isActive)} className={s.selectBox}>
                  <div className={s.titleBox}>
                    <span className={s.typeSite}>{lang === 'ru' ? `Тип сайта` : `Site type`}</span>
                    <img
                      style={{
                        transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                      className={s.arrow}
                      src={arrow}
                      alt="Arrow"
                    />
                  </div>
                  <h3 className={s.selectTitle}>{selected}</h3>
                </div>
                {isActive && (
                  <div
                    // style={{
                    //   transform: isActive ? 'rotateY(200px)' : 'rotate(0deg)',
                    // }}
                    className={s.contentBox}
                  >
                    {options.map((option, i) => (
                      <h3
                        key={i}
                        onClick={() => {
                          getSum(option);
                          setSelected(option);
                          setIsActive(false);
                        }}
                        className={s.title}
                      >
                        {option}
                      </h3>
                    ))}
                  </div>
                )}
              </div>
              <div className={s.numberOfSitesMadeBox}>
                <span className={s.numberOfSitesMade}>
                  {lang === 'ru' ? `Количество сделанных сайтов` : `Number of sites made`}
                </span>
                <div className={s.numberOfSitesMadeCount}>{countSite}</div>
                <input
                  className={s.inputRange}
                  value={countSite}
                  onChange={(e) => setCountSite(e.target.value)}
                  min={0}
                  max={100}
                  type="range"
                />
              </div>
              <div className={s.averageNumberMonthBox}>
                <span className={s.averageNumberMonth}>
                  {lang === 'ru'
                    ? `Среднее количество продаж в месяц`
                    : `Average number of sales per month`}
                </span>
                <input
                  type="number"
                  className={s.averageNumberMonthCount}
                  placeholder="0"
                  value={countSiteMounth}
                  onChange={(e) => setCountSiteMounth(e.target.value)}
                />
              </div>
              {/* <button className={s.btnAdd}>Добавить еще +</button> */}
            </div>
            <div className={s.amountOfEarningsBox}>
              <h2 className={s.amountOfEarnings}>
                {lang === 'ru' ? `Сумма заработка` : `Amount of earnings`}
              </h2>
              <div className={s.lumpSumPaymentBox}>
                <p className={s.content}>
                  {lang === 'ru' ? `Единоразовая выплата` : `One-time payment`}
                </p>
                <img
                  className={s[lang === 'ru' ? 'questionMarkIcon' : 'questionMarkIconEn']}
                  src={questionMark}
                  alt="Icon"
                />
                <h3 className={s.sum}>{monthlyPayment} ₽</h3>
                <div className={s.infoActive}>
                  <p className={s.info}>
                    {lang === 'ru'
                      ? `Единоразовая выплата за готовый шаблон сайта, который прошел
                    модерацию`
                      : `One-time payment for a ready-made website template that has passed
                    moderation`}
                  </p>
                </div>
              </div>

              <div className={s.mountyPassiveBox}>
                <p className={s.content}>
                  {lang === 'ru' ? `Ежемесячный пассивный доход` : `Monthly passive income`}
                </p>
                <img
                  className={s[lang === 'ru' ? 'questionMarkIcon' : 'questionMarkIconEn']}
                  src={questionMark}
                  alt="Icon"
                />
                <h3 className={s.sum}>{lumpSumPayment} ₽</h3>
                <div className={s.infoActive}>
                  <p className={s.info}>
                    {lang === 'ru'
                      ? `Ежемесячная выплата за продажи на сайте вашего шаблона`
                      : `Monthly payment for sales on your template site`}
                  </p>
                </div>
              </div>
              <div className={s.totalEarningsBox}>
                <h3 className={s.content}>
                  {lang === 'ru' ? `Ежемесячный пассивный доход` : `Monthly passive income`}
                </h3>
                <h3 className={s.sum}>{allPrice} ₽</h3>
              </div>
              <Button
                onClick={() =>
                  localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
                }
                className={s.btnPartners}
              >
                {lang === 'ru' ? `Стать партнером` : `Become a partner`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default HowMuchCanYouEarn;
