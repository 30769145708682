import axios from 'axios';

export const BASE_URL = `https://back.roboweb.site`;

const $api = axios.create({
  withCredential: true,
  baseURL: BASE_URL,
});

$api.interceptors.request.use((config) => {
  config.headers = {
    ['x-access-token']: localStorage.getItem('token'),
  };
  return config;
});

export default $api;
