import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'ru',
};

const switchLangSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    switchLanguage(state, action) {
      state.language = action.payload;
      console.log(action.payload);
    },
  },
});

export const { switchLanguage } = switchLangSlice.actions;
export default switchLangSlice.reducer;
