import React, {useEffect, useRef, useState} from 'react';


import s from './CharacteristicsSiteBlock.module.scss';

function CharacteristicsSiteBlock({features}) {
  const [data, setData] = useState([])
  const createFeatures = (features) => {
    let arr = features.split(". ")
    let newArr = []

    for (let i in arr) {
      let it = arr[i];
      let blocks = it.split(': ');
      let obj = {
        title: blocks[0],
        items: blocks[1].split(', '),
      };
      newArr.push(obj);
    }

    return newArr
  }

  // const loading = useRef(false)

  useEffect(() => {
    // if (loading.current === true) {
      setData(createFeatures(features))
    // }

    return () => {
      setData([])
      // loading.current = true
    }
  }, [features])

  return (
    <div className={s.container}>
      <div className={s.characteristicsSiteBlock}>
        {data.map((item, index) => (
          <div key={index} className={s.aboutBox}>
            <h3 className={s.title} >{item.title}:</h3>
            <div className={s.itemBox}>
              {item.items.map((el, index) => (
              <span className={s.item} key={index}>
                {el}
              </span>
            ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CharacteristicsSiteBlock;
