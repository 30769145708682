import React from 'react';
// import ReactDOM from 'react-dom';
import './global.scss';
import App from './App';
// import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import Price from './pages/Price/Price.jsx';
import { createRoot } from 'react-dom/client';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { HeaderContextWrapper } from './utils/HeaderContext';
import { HeaderTypeWrapper } from './utils/HeaderTypeContext';

export const BASE_URL = `https://back.roboweb.site`;
export const config = {
  headers: { 'x-access-token': `${localStorage.getItem('token')}` },
};

createRoot(document.getElementById('root')).render(
  <HeaderContextWrapper>
    <HeaderTypeWrapper>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </HeaderTypeWrapper>
  </HeaderContextWrapper>
);
