import React from 'react'
import AboutUsBenefitsBlock from '../../components/AboutUsBenefitsBlock/AboutUsBenefitsBlock'
import AboutUsHeading from '../../components/AboutUsHeading/AboutUsHeading'
import Benefits from '../../components/Benefits/Benefits'
import DigitBlock from '../../components/DigitBlock/DigitBlock'
import PortfolioBlock from '../../components/PortfolioBlock/PortfolioBlock'
import ReviewsBlockMain from '../../components/ReviewsBlockMain/ReviewsBlockMain'
import TemplatesPurpose from '../../components/TemplatesPurpose/TemplatesPurpose'
import UseForFree from '../../components/UseForFree/UseForFree'

export default function AboutUs() {
  return (
    <>
    <AboutUsHeading/>
    <AboutUsBenefitsBlock/>
    <DigitBlock/>
    <Benefits/>
    <TemplatesPurpose/>
    <PortfolioBlock/>
    <ReviewsBlockMain/>
    <UseForFree/>
    </>
  )
}
