import React, { useEffect, useState } from 'react';
import s from './ProfileAdministrator.module.scss';
// import avatar from '../../assets/topTenPartnersIcon/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';
import { useSelector } from 'react-redux';

import PasswordChangeBlock from '../PasswordChangeBlock/PasswordChangeBlock';
import PersonalDataAdministratorBlock from '../PersonalDataAdministratorBlock/PersonalDataAdministratorBlock';
import PartnersTheDocumentsBlock from '../PartnersTheDocumentsBlock/PartnersTheDocumentsBlock';
import toRubFormat from '../../utils/toRubFormat';
import { BASE_URL } from '../..';
import axios from 'axios';
import $api from '../../http';

function ProfileAdministrator() {
  const [active, setActive] = useState(0);
  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  const linkNames = [
    { ru: 'Личные данные', en: 'Personal data', id: 0 },
    { ru: 'Смена пароля', en: 'Password change', id: 1 },
    // { ru: 'Банковские карты', en: 'Bank cards', id: 2 },
    // { ru: 'Документы', en: 'The Documents', id: 3 },
  ];

  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [editFunction, setEditFunction] = useState({});
  const [visibleButton, setVisibleButton] = useState(false);
  const [avatar, setAvatar] = useState('');

  console.log(editFunction, userInfo);

  function getPartner() {
    console.log(userInfo, 'iwhjediuhwihwiuhiuwehiewh');
    fetch(`${BASE_URL}/client/user/me`, {
      method: 'GET',
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    })
      .then((result) => result.json())
      .then((data) => {
        console.log('data.user', data.user);
        setUser(data.user);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getPartner();
  }, [userInfo]);
  // editFunction.updateUser();

  //ИЗМЕНЕНИЕ АВАТАРА

  const OnSumbit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append('files', avatar);
    console.log(formData);
    axios
      .patch(`${BASE_URL}/client/user/avatar`, formData, config)
      .then((res) => {
        console.log(`Success` + res);
        getPartner();
        setVisibleButton(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>{lang === 'ru' ? 'Профиль' : 'Profile'}</h2>
          <div className={s.profileUserBox}>
            <div className={s.infoBox}>
              <form onSubmit={(event) => OnSumbit(event)}>
                <div className={s.personal_image}>
                  <label className={s.label}>
                    <input
                      type="file"
                      // value={avatar}
                      onChange={(e) => setAvatar(e.target.files[0])}
                      onClick={() => setVisibleButton(true)}
                      accept=".png, .jpg, .jpeg"
                    />
                    <figure className={s.personal_figure}>
                      <img
                        src={user['avatar'] ? `${BASE_URL}${user['avatar']}` : avatarDefault}
                        className={s.personal_avatar}
                        alt="avatar"
                      />
                      <figcaption className={s.personal_figcaption}>
                        <img
                          src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                          alt="avatar"
                        />
                      </figcaption>
                    </figure>
                  </label>
                </div>
                {visibleButton && avatar ? (
                  <button className={s.btnImageSave}>
                    {lang === 'ru' ? `Обновить` : `Refresh`}
                  </button>
                ) : (
                  ''
                )}
              </form>
              {/* <img className={s.profileImage} src={user ? avatar : null} alt="Avatar" /> */}
              <div className={s.aboutBox}>
                <h3 className={s.userName}>{user ? user?.name : null}</h3>
                <p className={s.userEmail}>{user ? user.systemRole : null}</p>
              </div>
            </div>
            {/* <div className={s.userBalanceBox}>
              <h3 className={s.titleBalance}>Ваш баланс:</h3>
              <div className={s.balanceBox}>
                <p className={s.userBalance}>
                  {user ? toRubFormat(user.ballance) : null}
                </p>
                <p className={s.replenishTheBalance}>Пополнить</p>
                <p className={s.extract}>Вывести средства</p>
              </div>
            </div> */}
            <div className={s.linkAboutContainer}>
              <ul className={s.linkAboutBox}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>
            {active === 0 && <PersonalDataAdministratorBlock setUserInfo={setUserInfo} />}
            {active === 1 && <PasswordChangeBlock />}
            {/* {active === 3 && <PartnersTheDocumentsBlock />} */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileAdministrator;
