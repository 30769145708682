import React from 'react';

import s from './TechnicalRequirements.module.scss';
import arrowTop from '../../assets/technicalRequirementsIcon/arrowTop.svg';
import arrowBottom from '../../assets/technicalRequirementsIcon/arrowBottom.svg';
import iconLoad from '../../assets/technicalRequirementsIcon/iconLoad.svg';
import Button from '../ui/Button/Button';
import { useSelector } from 'react-redux';

function TechnicalRequirements() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === `ru`
              ? `Технические требования`
              : `Technical requirements`}
          </h2>
          <h2 className={s.mainTitleMobile}>
            {lang === `ru`
              ? `Изучите подробнее технические требования`
              : `Learn more about the technical requirements`}
          </h2>
          <div className={s.technicalRequirementsBlock}>
            <div className={s.cardBlock}>
              <div className={s.cardNum1}>
                <div className={s.circleParent1}>
                  <div className={s.circleChild1}>
                    <h1 className={s.one}>1</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.orderInLayout}>
                    {lang === `ru` ? `Порядок в макете` : `Order in layout`}
                  </h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Все слои одного логического элемента должны быть в одной папке (например, модуль
                    авторизации). Выравнивание слоев строго по Rulers. Их необходимо оставлять в
                    макете. Слои не должны быть склеены.`
                      : `All layers of one logic element must be in the same folder (for example, module
                        authorization). Alignment of layers strictly according to Rulers. They must be left in
                        layout. The layers must not be glued together.`}
                  </p>
                </div>
              </div>
              <div className={s.cardNum2}>
                <div className={s.circleParent2}>
                  <div className={s.circleChild2}>
                    <h1 className={s.two}>2</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.onlyPixels}>
                    {lang === `ru`
                      ? `Только пиксели`
                      : `
Only pixels`}
                  </h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Единицами измерения должны быть пиксели, а не сантиметры, миллиметры и другие
                    меры длины.`
                      : `
                    Units of measurement should be pixels, not centimeters, millimeters, etc.
                                        length measures.`}
                  </p>
                </div>
              </div>
              <div className={s.cardNum3}>
                <div className={s.circleParent3}>
                  <div className={s.circleChild3}>
                    <h1 className={s.three}>3</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.vectorElements}>
                    {lang === `ru` ? `Векторные элементы` : `Vector elements`}
                  </h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Векторные элементы должны быть векторными элементами: иконки, стрелки и т.д.
                    Допускаются элементы shape и smart-объекты с ai-элементами`
                      : `Vector elements must be vector elements: icons, arrows, etc.
                    Shape elements and smart objects with ai elements are allowed`}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.cardBlock2}>
              <div className={s.cardNum4}>
                <div className={s.circleParent4}>
                  <div className={s.circleChild4}>
                    <h1 className={s.four}>4</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.uiKit}>UI-kit</h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Необходимо описать поведение ссылок в дизайне (неактивная, при наведении,
                    посещенная)`
                      : `It is necessary to describe the behavior of links in the design (inactive, on hover,
                      visited)`}
                  </p>
                </div>
              </div>
              <div className={s.cardNum5}>
                <div className={s.circleParent5}>
                  <div className={s.circleChild5}>
                    <h1 className={s.five}>5</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.pixelGrid}>
                    {lang === `ru` ? `Пиксельная сетка` : `Pixel grid`}
                  </h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Значения не должны быть дробными, соблюдайте привязку к сетке.`
                      : `Values ​​should not be fractional, keep grid reference.`}
                  </p>
                </div>
              </div>
              <div className={s.cardNum6}>
                <div className={s.circleParent6}>
                  <div className={s.circleChild6}>
                    <h1 className={s.six}>6</h1>
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.fonts}>
                    {lang === `ru` ? `Шрифты` : `Fonts`}
                  </h3>
                  <p className={s.about}>
                    {lang === `ru`
                      ? `Допустимо и даже приветствуется использование свободных кириллических шрифтов от
                    Google Fonts.`
                      : `
                    It is acceptable and even encouraged to use free Cyrillic fonts from
                                        Google fonts.`}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.arrowBox}>
              <img className={s.arrowTop} src={arrowTop} alt="Arrow top" />
              <img
                className={s.arrowBottom}
                src={arrowBottom}
                alt="Arrow bottom"
              />
            </div>
            <div className={s.lernMoreBox}>
              <h2 className={s.lernMore}>
                {lang === `ru` ? `Изучи подробнее` : `Learn more`}
              </h2>
              <div className={s.lernMoreCards}>
                <div className={s.lernMoreCard}>
                  <img className={s.iconLoad} src={iconLoad} alt="" />
                  <h3 className={s.technicalUiUx}>
                    {lang === `ru`
                      ? `Технические требования для веб, UX/UI дизайнеров`
                      : `
Technical requirements for web, UX/UI designers`}
                  </h3>
                  <span className={s.technicalUiUxAbout}>
                    {lang === `ru`
                      ? `Скачай файл и ознакомься подробнее с правилами и порядком работы`
                      : `Download the file and learn more about the rules and procedures`}
                  </span>
                  <Button className={s.btnUiUx}>
                    {lang === `ru` ? `Скачать файл` : `Download file`}
                  </Button>
                </div>
                <div className={s.lernMoreCard}>
                  <img className={s.iconLoad} src={iconLoad} alt="" />
                  <h3 className={s.technicalWebDesigner}>
                    {lang === `ru`
                      ? `Технические требования для веб-разработчиков`
                      : `
Technical requirements for web developers`}
                  </h3>
                  <span className={s.technicalWebDesignerAbout}>
                    {lang === `ru`
                      ? `Скачай файл и ознакомься подробнее с правилами и порядком работы`
                      : `Download the file and learn more about the rules and procedures`}
                  </span>
                  <Button className={s.btnWebDesigner}>
                    {lang === `ru` ? `Скачать файл` : `Download file`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechnicalRequirements;
