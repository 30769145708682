import React from 'react';

import Container from '../../components/ui/Container/Container';
import axios from 'axios';

import s from './Templates.module.scss';
import iconBusines from '../../assets/templatesPurposeIcon/businesIcon.svg';
import iconAvent from '../../assets/templatesPurposeIcon/calendarIcon.svg';
import iconEducation from '../../assets/templatesPurposeIcon/iconIducation.svg';
import iconUser from '../../assets/templatesPurposeIcon/iconUser.svg';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

const aboutMenu = [
  {
    id: 1,
    accordionTitle: s.accordionTitle1,
    icon: iconBusines,
    alt: 'Для бизнеса',
    title: 'Для бизнеса',
    about: `Сайт для бизнеса позволит презентовать компанию в сети. Вы хотите сделать своё любимое
    дело ещё более прибыльным? Собственная страница для клиники или торгового центра
    поможет найти новых клиентов и поставщиков. Профессиональный сайт повысит престижность
    и узнаваемость любой организации.`,
    titleEn: `For business`,
    aboutEn: `A business website will allow you to present your company online. Do you want to make your favorite
    business even more profitable? Own page for a clinic or shopping center
    help you find new customers and suppliers. A professional website will increase prestige
    and recognition of any organization.`,
  },
  {
    id: 2,
    accordionTitle: s.accordionTitle2,
    icon: iconUser,
    alt: 'картинка',
    title: 'Для личных',
    about: `Личный сайт понадобится фотографу, учителю, писателю и менеджеру. С помощью сайта каждый человек сможет продемонстрировать свои навыки большей аудитории и стать известным по всей стране. Сделайте свой личный бренд ярким и качественным вместе с нами!`,
    titleEn: `For personal`,
    aboutEn: `A personal website will be needed by a photographer, teacher, writer and manager. With the help of the site, each person will be able to demonstrate their skills to a larger audience and become famous throughout the country. Make your personal brand bright and high quality with us!`,
  },
  {
    id: 3,
    accordionTitle: s.accordionTitle3,
    icon: iconAvent,
    alt: 'Календарь',
    title: 'Для события',
    about: `Такой сайт позволит рассказать о крупном мероприятии и зарегистрировать участников из разных городов. Подойдёт для форумов, выставок и приглашений на свадьбу, с таким сайтом каждое событие станет запоминающимся. Подготавливайте мероприятие легко и быстро — создавайте сайт для важных событий!`,
    titleEn: `For events`,
    aboutEn: `Such a site will allow you to talk about a major event and register participants from different cities. Suitable for forums, exhibitions and wedding invitations, with such a site every event will become memorable. Prepare an event easily and quickly — create a website for important events!`,
  },
  {
    id: 4,
    accordionTitle: s.accordionTitle4,
    icon: iconEducation,
    alt: 'Для бизнеса',
    title: 'Для образования',
    about: `Образовательные сайты имеют высокий спрос у студентов и школьников, которые много времени проводят в интернете. Такой шаблон подойдёт для сайта детского развивающего центра, школе английского языка и другим учреждениям, которые заботятся об образовании.`,
    titleEn: `For education`,
    aboutEn: `Educational sites are in high demand among students and schoolchildren who spend a lot of time on the Internet. Such a template is suitable for the website of a children's development center, an English language school and other institutions that care about education.`,
  },
];

function TemplatesPurpose() {
  const lang = useSelector((state) => state.lang.language);

  const [cardId, setCardId] = useState(1);
  const [items, setItems] = useState(false);
  const [mobileLinkActive, setMobileLinkActive] = useState(1);

  async function getTemplates() {
    await axios(`${BASE_URL}/client/template?limit=9`).then((res) =>
      setItems(res)
    );
  }
console.log(items, 'itwms')
  useEffect(() => {
    getTemplates();
  }, []);
  const changeCardId = (id) => {
    setCardId((prevId) => {
      if (prevId === id) {
        setCardId(null);
      } else {
        setCardId(id);
      }
    });
  };
  return (
    <section>
      <Container>
        <div className={s.wrapper}>
          <h1 className={s.MainTitle}>
            {lang === 'ru'
              ? 'Шаблоны готовых сайтов для любых целей'
              : `Site templates for any purpose`}
          </h1>
          <div className={s.boxContainer}>
            <div className={s.boxTemplates}>
              <img
                className={s.box1}
                src={
                  items ? `${BASE_URL}/${items?.data.templateList[3].img}` : '#'
                }
                alt=""
              />
              <img
                className={s.box2}
                src={
                  items
                    ? `${BASE_URL}/${items?.data.templateList[6].imgCatalog}`
                    : '#'
                }
                alt=""
              />
              <img
                className={s.box3}
                src={
                  items
                    ? `${BASE_URL}/${items?.data.templateList[8].imgCatalog}`
                    : '#'
                }
                alt=""
              />
            </div>
            <div className={s.boxMainTitle}>
              {aboutMenu.map((obj) => (
                <div
                  onClick={(e) => changeCardId(obj.id)}
                  key={obj.id}
                  className={s.boxTemplatesTitle}
                >
                  <div className={s.templatesTitle}>
                    <img className={s.icon} src={obj.icon} alt={obj.alt} />
                    <h3 className={obj.accordionTitle}>
                      {lang === 'ru' ? obj.title : obj.titleEn}
                    </h3>
                  </div>
                  {obj.id === cardId && (
                    <p className={s.businesAbout}>
                      {lang === 'ru' ? obj.about : obj.aboutEn}
                    </p>
                  )}
                  {obj.id === cardId && (
                    <Link to="/templates">
                      <ButtonTemplates className={s.buttonTemplates}>
                        {lang === 'ru'
                          ? 'Смотреть все шаблоны'
                          : `See all templates
              `}
                      </ButtonTemplates>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={s.mainMobileWrapper}>
            <div className={s.mobileContainer}>
              <div className={s.mobileWrapper}>
                <ul className={s.mobileBox}>
                  {aboutMenu.map((obj) => (
                    <li
                      onClick={() => setMobileLinkActive(obj.id)}
                      className={
                        s[mobileLinkActive === obj.id ? 'active' : 'disabled']
                      }
                    >
                      {obj.title}
                    </li>
                  ))}
                </ul>
              </div>
              {mobileLinkActive === 1 && (
                <p className={s.mobileAbout}>
                  {lang === 'ru'
                    ? `Сайт для бизнеса позволит презентовать компанию в сети.
              Вы хотите сделать своё любимое дело ещё более прибыльным?
              Собственная страница для клиники или торгового центра поможет
              найти новых клиентов и поставщиков. Профессиональный сайт
              повысит престижность и узнаваемость любой организации.`
                    : `A business website will allow you to present your company online.
              Do you want to make your favorite business even more profitable?
              Your own page for a clinic or shopping center will help
              find new customers and suppliers. Professional site
              will increase the prestige and recognition of any organization.`}
                </p>
              )}
              {mobileLinkActive === 2 && (
                <p className={s.mobileAbout}>
                  Личный сайт понадобится фотографу, учителю, писателю
                  и менеджеру. С помощью сайта каждый человек сможет
                  продемонстрировать свои навыки большей аудитории и стать
                  известным по всей стране. Сделайте свой личный бренд ярким
                  и качественным вместе с нами!
                </p>
              )}
              {mobileLinkActive === 3 && (
                <p className={s.mobileAbout}>
                  Такой сайт позволит рассказать о крупном мероприятии
                  и зарегистрировать участников из разных городов. Подойдёт
                  для форумов, выставок и приглашений на свадьбу, с таким сайтом
                  каждое событие станет запоминающимся. Подготавливайте
                  мероприятие легко и быстро — создавайте сайт для важных
                  событий!
                </p>
              )}
              {mobileLinkActive === 4 && (
                <p className={s.mobileAbout}>
                  Образовательные сайты имеют высокий спрос у студентов
                  и школьников, которые много времени проводят в интернете.
                  Такой шаблон подойдёт для сайта детского развивающего центра,
                  школе английского языка и другим учреждениям, которые
                  заботятся об образовании.
                </p>
              )}
              <div className={s.mobileWrapper}>
                <div className={s.mobileImgBox}>
                  {items ? (
                    items?.data.templateList.map((obj) => (
                      <img
                        className={s.templatesImg}
                        src={`${BASE_URL}/${obj.imgCatalog}`}
                        alt="Шаблон"
                      />
                    ))
                  ) : (
                    <h1>Нет</h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default TemplatesPurpose;
