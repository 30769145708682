import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import s from './UserHeader.module.scss';
import notificationIcon from '../../assets/img/notificationIcon.svg';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import favoritesIcon from '../../assets/img/favoriteIcon.svg';
import logoSvg from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';
import burherMenu from '../../assets/img/burgerMenu.svg';
import Button from '../ui/Button/Button';
import PopApWebsiteDevelopmentRequest from '../PopApWebsiteDevelopmentRequest/PopApWebsiteDevelopmentRequest';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../app/features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';
import { switchLanguage } from '../../app/features/user/languageSwitch';
import NotificationsBlock from '../NotificationsBlock/NotificationsBlock';
import axios from 'axios';
import { BASE_URL } from '../..';

function UserHeader() {
  const [active, setActive] = useState(false);
  const [burgerActiveUser, setBurgerActiveUser] = useState(false);
  const [launguage, setLaunguage] = useState('Ru');
  const [activeLunguage, setActiveLunguage] = useState(false);
  const [visiblity, setVisiblity] = useState(false);
  const [activeNatifications, setActiveNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notification, setNotification] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setHeaderType } = useContext(HeaderTypeContext);

  function launguageSwitcher() {
    if (launguage === 'Ru') {
      setLaunguage('En');
      setActiveLunguage(false);
    } else {
      setLaunguage('Ru');
      setActiveLunguage(false);
    }
  }

  function openModal() {
    setVisiblity(!visiblity);
  }

  function closeModal(e) {
    setVisiblity(e);
  }

  async function getNotificationCount() {
    const res = await axios.get(`${BASE_URL}/client/notification/count`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setNotificationCount(res.data);
  }

  async function getNotification() {
    const res = await axios.get(`${BASE_URL}/client/notification`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });

    setNotification(res.data);
  }

  async function deleteNotification(id) {
    await axios.delete(`${BASE_URL}/client/notification/${id}`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    getNotification();
    getNotificationCount();
  }

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.id !== 'hello') {
        setActive(false);
      }
    });
  }, [active]);

  useEffect(() => {
    getNotificationCount();
    getNotification();
  }, []);

  const lang = useSelector((state) => state.lang.language);
  console.log(notificationCount);
  console.log(notification);

  return (
    <>
      <div className={s.container}>
        {/* <Header burgerActiveUser={burgerActiveUser}/> */}
        <Link className={s.linkLogo} to="/">
          <img className={s.logoRoboweb} src={logoSvg} alt="roboweb" />
        </Link>
        <Link className={s.linkLogoMobile} to="/">
          <img className={s.logoMobile} src={logoMobile} alt="roboweb" />
        </Link>
        <ul className={s.navigateList}>
          <li className={s.listItem}>
            <Link className={s.itemLink} to="/personalArea">
              {lang === 'ru' ? 'Мои сайты' : `My sites`}
            </Link>
          </li>
          <li className={s.listItem}>
            <Link className={s.itemLink} to="/templates">
              {lang === 'ru' ? 'Создать сайт' : `Create site`}
            </Link>
          </li>
          <li className={s.listItem}>
            <Link className={s.itemLink} to="/price">
              {lang === 'ru' ? 'Стоимость' : `Price`}
            </Link>
          </li>
          <li onClick={openModal} className={s.listItem}>
            <Link className={s.itemLink}>
              {lang === 'ru' ? 'Нанять специалиста' : `Hire a specialist`}
            </Link>
          </li>
        </ul>
        {burgerActiveUser && (
          <ul
            className={s.navigateListMobile}
            style={{ position: burgerActiveUser ? 'fixed' : 'static' }}
          >
            <li onClick={() => setBurgerActiveUser(!burgerActiveUser)} className={s.listItemMobile}>
              <Link className={s.itemLinkMobile} to="/profileUser">
                {lang === 'ru' ? 'Профиль' : `Profile`}
              </Link>
            </li>
            <li onClick={() => setBurgerActiveUser(!burgerActiveUser)} className={s.listItemMobile}>
              <Link className={s.itemLinkMobile} to="/personalArea">
                {lang === 'ru' ? 'Мои сайты' : `My sites`}
              </Link>
            </li>
            <li onClick={() => setBurgerActiveUser(!burgerActiveUser)} className={s.listItemMobile}>
              <Link className={s.itemLinkMobile} to="/templates">
                {lang === 'ru' ? 'Создать сайт' : `Create site`}
              </Link>
            </li>
            <li onClick={() => setBurgerActiveUser(!burgerActiveUser)} className={s.listItemMobile}>
              <Link className={s.itemLinkMobile} to="/partners">
                {lang === 'ru' ? 'Тех.поддержка' : `Support`}
              </Link>
            </li>
            <Button
              onClick={() => {
                setHeaderType('');
                dispatch(logout());
                navigate('/');
              }}
              className={s.btnSignIn}
            >
              {lang === 'ru' ? 'Выйти' : `Exit`}
            </Button>
            <div className={s.switchBoxMbile}>
              <label className={s.switch}>
                <input
                  type="checkbox"
                  checked={lang === 'ru' ? '' : 'checked'}
                  onClick={() =>
                    lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
                  }
                  onChange={(e) => {}}
                />
                <span className={`${s.slider} ${s.round}`}></span>
                <span className={s.ru}>RU</span>
                <span className={s.en}>EN</span>
              </label>
            </div>
          </ul>
        )}
        <div className={s.groupBox}>
          <div className={s.iconBox}>
            <div className={s.notificationContain}>
              <div
                onClick={() => setActiveNotifications(!activeNatifications)}
                className={s.notificationBox}
              >
                <img className={s.notificationIcon} src={notificationIcon} alt="Notification" />
                {notificationCount.notificationCount !== 0 && (
                  <span className={s.notificationCount}>{notificationCount.notificationCount}</span>
                )}
              </div>
              {activeNatifications && (
                <NotificationsBlock
                  notification={notification}
                  deleteNotification={deleteNotification}
                  setActiveNotifications={setActiveNotifications}
                />
              )}
            </div>

            <Link className={s.favoritesIconBox} to={'/personalArea'} state={{ active: 2 }}>
              {/* <span className={s.money}>1 000</span> */}
              <img className={s.favoriteIcon} src={favoritesIcon} alt="Favorite" />
            </Link>
          </div>
          <div onClick={() => setActive(!active)} className={s.sortedBlock} id="hello">
            <span className={s.sortedMenu} id="hello">
              {lang === 'ru' ? 'Меню' : `Menu`}
            </span>
            <img
              style={{
                transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              className={s.arrowMenu}
              src={arrowMenu}
              alt="Arrow"
              id="hello"
            />
            {active && (
              <div className={s.dropDownBox}>
                <span onClick={() => navigate('/profileUser')} className={s.profile}>
                  {lang === 'ru' ? 'Профиль' : `Profile`}
                </span>
                <span onClick={() => navigate('/personalArea')} className={s.mySites}>
                  {lang === 'ru' ? 'Мои сайты' : `My sites`}
                </span>
                <span onClick={() => navigate('/templates')} className={s.createSite}>
                  {lang === 'ru' ? 'Создать сайт' : `Create site`}
                </span>
                <span onClick={() => navigate('/technicalSupport')} className={s.tecnicalSupport}>
                  {lang === 'ru' ? 'Тех.поддержка' : `Support`}
                </span>
                <span
                  className={s.exit}
                  onClick={() => {
                    setHeaderType('');
                    dispatch(logout());
                    navigate('/');
                  }}
                >
                  {lang === 'ru' ? 'Выйти' : `Exit`}
                </span>
              </div>
            )}
          </div>
          <div className={s.switchBox}>
            <label className={s.switch}>
              <input
                type="checkbox"
                checked={lang === 'ru' ? '' : 'checked'}
                onClick={() =>
                  lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
                }
                onChange={(e) => {}}
              />
              <span className={`${s.slider} ${s.round}`}></span>
              <span className={s.ru}>RU</span>
              <span className={s.en}>EN</span>
            </label>
          </div>
          <div onClick={() => setBurgerActiveUser(!burgerActiveUser)} className={s.burgerBox}>
            <img
              style={{
                transform: burgerActiveUser ? 'rotate(45deg)' : 'rotate(0deg)',
                transformOrigin: burgerActiveUser ? 'center' : '',
                marginBottom: burgerActiveUser ? '-8px' : '5px',
              }}
              className={s.burgerTop}
              src={burherMenu}
              alt="Burger menu"
            />
            <img
              style={{
                transform: burgerActiveUser ? 'rotate(-45deg)' : 'rotate(0deg)',
                transformOrigin: burgerActiveUser ? 'center' : '',
              }}
              className={s.burgerBottom}
              src={burherMenu}
              alt="Burger menu"
            />
          </div>
        </div>
      </div>
      <PopApWebsiteDevelopmentRequest show={visiblity} closeModal={closeModal} />
    </>
  );
}

export default UserHeader;
