import React, { useEffect, useRef, useState } from 'react';
// import {useNavigate} from 'react-router-dom'

import Container from '../ui/Container/Container';
import Button from '../ui/Button/Button';
import question from '../../assets/img/question.svg';

import s from './PriceBlock.module.scss';
import PopApWebsiteDevelopmentRequest from '../PopApWebsiteDevelopmentRequest/PopApWebsiteDevelopmentRequest';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from 'swiper';

import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

import { BASE_URL } from '../..';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function PriceBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [visiblityRequest, setVisiblityRequest] = useState(false);
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const navigate = useNavigate();
  // let swiper;
  const location = useLocation();
  console.log(location);
  // useEffect(() => {
  //   swiper = document.querySelector('.mySwiper').swiper;
  // }, []);

  function openModalRequest() {
    setVisiblityRequest(!visiblityRequest);
  }

  function closeModalRequest(e) {
    setVisiblityRequest(e);
  }

  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }

  const [prices, setPrices] = useState([]);

  const getTemplatePrice = () => {
    return fetch(`${BASE_URL}/client/template-type`)
      .then((result) => result.json())
      .then((data) => data)
      .catch((err) => console.log(err));
  };

  const getServicePrice = () => {
    return fetch(`${BASE_URL}/client/service`)
      .then((result) => result.json())
      .then((data) => data)
      .catch((err) => console.log(err));
  };

  // const loading = useRef(false)

  useEffect(() => {
    // if (loading.current === true) {
    Promise.all([getTemplatePrice(), getServicePrice()])
      .then((values) => {
        let result = [];
        for (let i = 0; i < values.length; i++) {
          let key = Object.keys(values[i])[0];
          result = result.concat(values[i][key]);
          setPrices((prevState) => prevState.concat(values[i][key]));
        }
      })
      .catch((err) => console.log(err));
    // }

    return () => {
      setPrices([]);
      // loading.current = true
    };
  }, []);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <Container>
        <div className={s.wrapper}>
          {location.pathname !== '/price' ? (
            <h2 className={s.mainTitle}>
              {lang === 'ru' ? `Стоимость услуг от RoboWeb` : `Price`}
            </h2>
          ) : null}
          <div className={s.cardBlock}>
            {prices
              ? prices.map((obj) => (
                  <div key={obj.id} className={s.cardBox}>
                    <img className={s.imgQuestion} src={question} alt="" />
                    <div className={s.priceBlock}>
                      <h3 className={s.priceTitle}>{lang === 'ru' ? obj.titleRu : obj.titleEn}</h3>
                      <div className={s.price}>
                        <h2 className={s.newPrice}>
                          {Math.ceil(obj.price - (obj.price / 100) * obj.sale)}
                        </h2>
                        <h4 className={s.oldPrice}>{obj.price}</h4>
                        <h4 className={s.sale}>{obj.sale} %</h4>
                      </div>
                    </div>
                    <div className={s.aboutBlock}>
                      <ul className={s.aboutList}>
                        <li className={s.aboutItem}>
                          {lang === 'ru' ? obj.descriptionFullRu : obj.descriptionFullEn}
                        </li>
                        <li className={s.aboutItem}>
                          {lang === 'ru' ? obj.descriptionRu : obj.descriptionEn}
                        </li>
                      </ul>
                    </div>
                    <div className={s.buttonBox}>
                      <Button
                        onClick={() =>
                          obj.forFeedback
                            ? [openModalRequest(), disabledScroll()]
                            : localStorage.token
                            ? navigate(`/templates`)
                            : [openModal(), disabledScroll()]
                        }
                        className={s.buttonTitle}
                      >
                        {obj.forFeedback && lang === 'ru' ? 'Оставить заявку' : ''}
                        {!obj.forFeedback && lang === 'ru' ? 'Попробовать бесплатно' : ''}
                        {obj.forFeedback && lang === 'en' ? 'Submit your application' : ''}
                        {!obj.forFeedback && lang === 'en' ? 'Try for free' : ''}
                      </Button>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div className={s.cardBlockMobile}>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              slidesPerGroup={1}
              loopFillGroupWithBlank={true}
              breakpoints={{
                320: {
                  width: 340,
                  slidesPerView: 1.01,
                },
                370: {
                  width: 340,
                  slidesPerView: 1.01,
                },
                420: {
                  width: 380,
                  slidesPerView: 1.04,
                },
                500: {
                  width: 400,
                  slidesPerView: 1.01,
                },
              }}
              pagination={{
                clickable: true,
                // dynamicBullets: true,
              }}
              // navigation={true}
              modules={[Pagination, Navigation]}
              className={s.mySwiper}
            >
              {prices.map((obj) => (
                <SwiperSlide className={s.swiperSlide}>
                  <div key={obj.id} className={s.cardBox}>
                  <img className={s.imgQuestion} src={question} alt="" />

                    <div className={s.priceBlock}>
                      <h3 className={s.priceTitle}>{obj.titleRu}</h3>
                      <div className={s.price}>
                        <h2 className={s.newPrice}>
                          {Math.ceil(obj.price - (obj.price / 100) * obj.sale)}
                        </h2>
                        <h4 className={s.oldPrice}>{obj.price}</h4>
                        <h4 className={s.sale}>{obj.sale} %</h4>
                      </div>
                    </div>
                    <div className={s.aboutBlock}>
                      <ul className={s.aboutList}>
                        <li className={s.aboutItem}>{obj.descriptionFullRu}</li>
                        <li className={s.aboutItem}>{obj.descriptionRu} </li>
                      </ul>
                    </div>
                    <div className={s.buttonBox}>
                      <Button
                        onClick={() =>
                          obj.forFeedback
                            ? [openModalRequest(), disabledScroll()]
                            : localStorage.token
                            ? navigate(`/templates`)
                            : [openModal(), disabledScroll()]
                        }
                        className={s.buttonTitle}
                      >
                        {obj.forFeedback ? 'Оставить заявку' : 'Попробовать бесплатно'}
                      </Button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <PopApWebsiteDevelopmentRequest show={visiblityRequest} closeModal={closeModalRequest} />
      </Container>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default PriceBlock;
