import React, { useState } from 'react';

import s from './AdministrationMySiteBlock.module.scss';
import MySiteBlock from '../MySiteBlock/MySiteBlock';
import InDevelopingBlock from '../InDevelopingBlock/InDevelopingBlock';
import FavoritesBlock from '../FavoritesBlock/FavoritesBlock';
import HostingBlock from '../HostingBlock/HostingBlock';

const linkNames = [
  { ru: 'Мои сайты', en: 'Me sites', id: 0 },
  { ru: 'В разработке', en: 'In developing', id: 1 },
  { ru: 'Избранное', en: 'Favorites', id: 2 },
  { ru: 'Хостинг', en: 'Hosting', id: 3 },
];

function AdministrationMySiteBlock() {
  const [active, setActive] = useState(0);
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.personalAreaLinkAdministrationBlock}>
            <h2 className={s.mainTitle}>{linkNames[active].ru}</h2>
            <div className={s.linkContainer}>
              <ul className={s.linkBlock}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {obj.ru}
                  </li>
                ))}
              </ul>
            </div>
            {active === 3 && (
              <p className={s.aboutHosting}>
                При выборе данной услуги вам будет предоставлен домен вида “yoursite.robohost.site”
                и хостинг сроком на 1 месяц. Вы сможете выбрать наиболее подходящий вариант
                доменного имени для вашего сайта из предоставленных, после чего сайт будет
                опубликован в течение 24 часов. Также эта услуга RoboWeb позволит Вам в течение
                месяца вносить неограниченное количество изменений на сайт.
              </p>
            )}

            {active === 0 && <MySiteBlock />}
            {active === 1 && <InDevelopingBlock />}
            {active === 2 && <FavoritesBlock />}
            {active === 3 && <HostingBlock />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdministrationMySiteBlock;
