import React, { useEffect, useState } from 'react';

import s from './PopApTariffPayment.module.scss';
import exit from '../../assets/img/exit.svg';
import Button from '../ui/Button/Button';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import $api from '../../http';
import { useSelector } from 'react-redux';

function PopApTariffPayment(props) {
  const { templateId } = useParams();
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const lang = useSelector((state) => state.lang.language);

  function handleClose() {
    setShow(false);
    props.closeModal(false);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const [templateData, setTemplateData] = useState(null);
  async function getTemplateById(id) {
    const data = await $api.get(`/client/user/templates/${id}`);
    console.log('tmp', templateId);
    await setTemplateData(data.data.template.templateType);
    console.log('templateId', data.data.template.templateType);
  }
  useEffect(() => {
    getTemplateById(templateId);
    console.log('templateId', templateId);
  }, []);

  const orderTemplate = async () => {
    $api.post(`/client/order/template`, {
      templateId,
    });
    console.log('ok');
  };

  return (
    <section>
      <div className={s.container}>
        <div
          style={{
            visibility: show ? 'visible' : 'hidden',
            opacity: show ? '1' : '0',
          }}
          className={s.wrapper}
        >
          <div className={s.popApBox}>
            <img onClick={handleClose} className={s.exit} src={exit} alt="" />
            <h2 className={s.mainTitle}>
              {lang === 'ru'
                ? 'Для публикации сайта и доступа ко всем функциям оплатите тариф'
                : 'To publish the site and access to all functions, pay the tariff'}
            </h2>
            <div className={s.cardBlock}>
              <div className={s.cardBox}>
                <div className={s.priceBlock}>
                  <h3 className={s.priceTitle}>
                    {lang === 'ru'
                      ? templateData?.titleRu
                      : templateData?.titleEn}
                  </h3>
                  <div className={s.price}>
                    <h2 className={s.newPrice}>
                      {templateData?.price * (1 - templateData?.sale * 0.01)} ₽
                    </h2>
                    <h4 className={s.oldPrice}>{templateData?.price}</h4>
                    <h4 className={s.sale}> - {templateData?.sale} %</h4>
                  </div>
                </div>
                <div className={s.aboutBlock}>
                  <ul className={s.aboutList}>
                    <li className={s.aboutItem}>
                      {lang === 'ru'
                        ? templateData?.descriptionFullRu
                        : templateData?.descriptionFullEn}
                    </li>
                    <li className={s.aboutItem}>
                      {lang === 'ru'
                        ? templateData?.descriptionRu
                        : templateData?.descriptionEn}
                    </li>
                  </ul>
                </div>
                <div className={s.buttonBox}>
                  <Button
                    onClick={() => {
                      orderTemplate();
                      navigate('/personalArea');
                    }}
                    //   onClick={() =>
                    //     obj.forFeedback
                    //       ? openModalRequest()
                    //       : localStorage.token
                    //       ? navigate(`/templates`)
                    //       : openModal()
                    //   }
                    className={s.buttonPayment}
                  >
                    {lang === 'ru' ? 'Оплатить' : 'Pay'}
                    {/* {lang === 'ru'
                          ? obj.descriptionFullRu
                          : obj.descriptionFullEn} */}
                    {/* {obj.forFeedback
                          ? 'Оставить заявку'
                          : 'Попробовать бесплатно'} */}
                    {/* {obj.forFeedback && lang === 'ru' ? 'Оставить заявку' : ''}
                          {!obj.forFeedback && lang === 'ru' ? 'Попробовать бесплатно' : ''}
                          {obj.forFeedback && lang === 'en' ? 'Submit your application' : ''}
                          {!obj.forFeedback && lang === 'en' ? 'Try for free' : ''} */}
                  </Button>
                  <Button disabled="disabled" className={s.btnDemoMode}>
                    {lang === 'ru'
                      ? 'Опубликовать в режиме демонстрации'
                      : 'Publish in demo mode'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopApTariffPayment;
