import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../..';
import AdministrationReviewsBlock from '../AdministrationReviewsBlock/AdministrationReviewsBlock';
import AdministrationServiceCost from '../AdministrationServiceCost/AdministrationServiceCost';
import AdministrationTablePartners from '../AdministrationTablePartners/AdministrationTablePartners';
import AdministrationTableUsers from '../AdministrationTableUsers/AdministrationTableUsers';
import AdministrationTemplatesBlock from '../AdministrationTemplatesBlock/AdministrationTemplatesBlock';
import AdministrationUserSitesBlock from '../AdministrationUserSiteBlock/AdministrationUserSiteBlock';
import PartnersUploadWorkBlock from '../PartnersUploadWorkBlock/PartnersUploadWorkBlock';

import s from './PersonalAccountAdministrator.module.scss';

const linkNames = [
  { ru: 'Шаблоны', en: 'Templates', id: 0 },
  { ru: 'Сайты пользователей', en: 'User sites', id: 1 },
  { ru: 'Пользователи', en: 'Users', id: 2 },
  { ru: 'Партнеры', en: 'Partners', id: 3 },
  { ru: 'Загрузить шаблон', en: 'Download template', id: 4 },
  { ru: 'Стоимость услуг', en: 'Service cost', id: 5 },
  { ru: 'Отзывы', en: 'Reviews', id: 6 },
];

function PersonalAccountAdministrator() {
  const [active, setActive] = useState(0);

  const lang = useSelector((state) => state.lang.language);

  //Получение всех категорий
  const [items, setItems] = useState([]);

  const getCategory = async () => {
    let allCategory = await axios.get(`${BASE_URL}/client/category`);
    setItems(allCategory.data.categoryList);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.personalAccountAdministratorBlock}>
            <h2 className={s.mainTitle}>
              {lang === 'ru' ? `Администрация` : `Administration`}
            </h2>
            <div className={s.linkContainer}>
              <ul className={s[lang === 'ru' ? 'linkBlock' : 'linkBlockEn']}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>
            {active === 0 && (
              <AdministrationTemplatesBlock items={items} setItems={setItems} />
            )}
            {active === 1 && (
              <AdministrationUserSitesBlock items={items} setItems={setItems} />
            )}
            {active === 2 && <AdministrationTableUsers />}
            {active === 3 && <AdministrationTablePartners />}
            {active === 4 && <PartnersUploadWorkBlock />}
            {active === 5 && <AdministrationServiceCost />}
            {active === 6 && <AdministrationReviewsBlock />}
            {/* {active === 2 && <FavoritesBlock />}
          {active === 3 && <HostingBlock />}
          {active === 4 && <LeaveFeedbackBlock />} */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalAccountAdministrator;
