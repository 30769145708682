import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './PortfolioFilter.module.scss';

const linkNames = [
  { ru: 'Все', en: 'All', id: 0 },
  { ru: 'Популярные', en: 'Popular', id: 1 },
  { ru: 'Бизнес', en: 'Business', id: 2 },
  { ru: 'События', en: 'Developments', id: 3 },
  { ru: 'Личные', en: 'Personal', id: 4 },
  { ru: 'Образование', en: 'Education', id: 5 },
  { ru: 'Другое', en: 'Other', id: 6 },
];

function PortfolioFilter() {
  const [active, setActive] = useState(0);

  const lang = useSelector((state) => state.lang.language);

  return (
    <div className={s.container}>
      <ul className={s.linkBlock}>
        {linkNames.map((obj) => (
          <li
            className={s[active === obj.id ? 'active' : 'disabled']}
            onClick={(e) => setActive(obj.id)}
            key={obj.id}
          >
            {lang === 'ru' ? obj.ru : obj.en}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PortfolioFilter;
