import React, { useEffect } from 'react';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import s from './MySiteBlock.module.scss';
import Button from '../ui/Button/Button';
import ButtonTemlates from '../ui/ButtonTemplates/ButtonTemplates';
import threeDots from '../../assets/img/threeDots.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';
import $api from '../../http';
import ForgotPasswordPopAp from '../ForgotPasswordPopAp/ForgotPasswordPopAp';
import { WindowSharp } from '@mui/icons-material';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton';
import SkeletonsMySiteBlock from '../SkeletonsMySiteBlock/SkeletonsMySiteBlock';

function MySiteBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(lang === 'ru' ? `По названию` : 'By name');
  const [activeId, setActiveId] = useState(0);
  const [activeOtherId, setActiveOtherId] = useState(0);
  const [activeTreeDots, setActiveTreeDots] = useState(null);
  const [myTemplate, setMyTemplate] = useState([]);

  //sorting
  const [sortType, setSortType] = useState('NAME');
  const [isLoading, setIsLoading] = useState(true);

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  const otherArr = [
    { ru: 'Скачать сайт', en: 'Download site', id: 0 },
    { ru: 'Посмотреть', en: 'Look', id: 1 },
    { ru: 'Заявки', en: 'Applications', id: 2 },
    { ru: 'Переименовать', en: 'Rename', id: 3 },
    { ru: 'Остановить', en: 'Stop', id: 4 },
    { ru: 'Удалить', en: 'Delete', id: 5 },
  ];

  const [payed, setPayed] = useState([]);

  //Сортировка купленных шаблонов

  const getPayed = async (templateSortType) => {
    const response = await fetch(
      `${BASE_URL}/client/user/templates/my?templateSortType=${templateSortType}&orderSortType=DESC`,
      {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      },
    );
    return response.status === 403
      ? alert(lang === 'ru' ? 'Вы были заблокированы' : `You were blocked`)
      : response.json();
  };

  const sortTemplate = async (sortType) => {
    let sortTemplate = await axios.get(
      `${BASE_URL}/client/user/templates/my?templateSortType=${sortType}&orderSortType=ASC`,
      {
        headers: { 'x-access-token': localStorage.getItem('token') },
      },
    );
    setMyTemplate(sortTemplate.data.templateList);
  };

  const navigate = useNavigate();

  //Получение купленных шаблонов

  const getMyTemplate = async () => {
    // setIsLoading(true);
    let templateList = await axios.get(`${BASE_URL}/client/user/templates/my`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setMyTemplate(templateList.data.templateList);
    setIsLoading(false);
  };

  //Изменение наименования шаблона
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    document.addEventListener('click', function (e) {
      e.stopPropagation();
      console.log(e.target);
      if (e.target.id !== 'treeDots' && e.target.id !== 'title') {
        setActiveTreeDots(null);
        setPopup(false);
        // console.log(e.target.id);
      }
    });
  }, []);

  const skeletons = [...new Array(6)].map((_, i) => <SkeletonsMySiteBlock key={i} />);

  useEffect(() => {
    getPayed(sortType)
      .then((data) => {
        const key = Object.keys(data)[0];
        setPayed(data[key]);
        getMyTemplate();
      })
      .catch((err) => console.log(err));
  }, [sortType, popup]);
  async function downloadFile(id) {
    axios
      .get(`${BASE_URL}/client/user/templates/${id}/zip`, {
        headers: { 'x-access-token': localStorage.getItem('token') },
      })
      .then((res) => {
        window.location.href = `${BASE_URL}${res.data.path}`;
      });
  }

  return (
    <div className={s.mainContainer}>
      <div onClick={() => setActive(!active)} className={s.sortedBlock}>
        <span className={s.sortedMenu}>{sortedName}</span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  sortTemplate(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.mySiteBlock}>
            {myTemplate.length ? (
              myTemplate.map((site) => (
                <div key={site.id} className={s.cardBox}>
                  <img className={s.siteImage} src={`${BASE_URL}${site.img}`} alt="Site" />
                  <div className={s.aboutSite}>
                    <span className={s.siteName}>{site.title}</span>
                    <span className={s.categorySite}>Нужна ли категория ?</span>
                    <span className={s.id}>ID:{site.id}</span>
                    <span className={s.sitePrice}>
                      {Math.ceil(
                        +site.templateType.price * ((100 - +site.templateType.sale) * 0.01),
                      )}{' '}
                      Р
                    </span>
                  </div>
                  <div className={s.buttonBox}>
                    <Button
                      className={s.btnUpdate}
                      onClick={() => navigate(`/templateUpdate/${site.id}`)}
                    >
                      {lang === 'ru' ? `Редактировать` : `Edit`}
                    </Button>
                    <img
                      onClick={() =>
                        setActiveTreeDots((prev) => (prev === site.id ? null : site.id))
                      }
                      className={s.threeDots}
                      src={threeDots}
                      alt="Three dots"
                      id="treeDots"
                    />
                    {activeTreeDots === site.id && (
                      <div className={s.dropDownBoxOther} id="treeDots">
                        <div className={s.boxFirst} id="treeDots">
                          {otherArr.slice(0, 3).map((obj) => (
                            <span
                              onClick={() => {
                                if (obj.ru === 'Скачать сайт' || obj.en === 'Download site') {
                                  downloadFile(site.id);
                                }
                                // setActiveTreeDots(null);
                                setActiveOtherId(obj.id);

                                obj.ru.toLowerCase() === ('посмотреть' || 'look') &&
                                  navigate(`/robolook/${site.id}`);
                              }}
                              id="treeDots"
                              className={s.otherFirst}
                            >
                              {lang === `ru` ? obj.ru : obj.en}
                            </span>
                          ))}
                        </div>
                        {otherArr.slice(3).map((obj) => (
                          <span
                            onClick={() => {
                              setActiveOtherId(obj.id);
                              obj.ru.toLowerCase() === ('переименовать' || 'rename') &&
                                setPopup(true);
                              // setActiveTreeDots(null);
                            }}
                            className={s.otherlast}
                            id="treeDots"
                          >
                            {lang === `ru` ? obj.ru : obj.en}
                          </span>
                        ))}
                        <ForgotPasswordPopAp
                          showForgot={popup}
                          setShowForgot={setPopup}
                          component="rename"
                          templateId={site.id}
                          setActiveTreeDots={setActiveTreeDots}
                          oldTitle={site.title}
                          role="user"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : isLoading ? (
              skeletons
            ) : (
              <div className={s.cardBoxoMain}>
                <p className={s.cardBoxo}>{lang === 'ru' ? `Список пуст` : `the list is empty`}</p>
              </div>
            )}
          </div>
        </div>
        <ButtonTemlates className={s.btnCreateSite} onClick={() => navigate('/templates')}>
          {lang === 'ru' ? `Создать сайт` : `Create site`}
        </ButtonTemlates>
      </div>
    </div>
  );
}

export default MySiteBlock;
