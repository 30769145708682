import React from 'react';
import s from './CooperationProcess.module.scss';

import iconStep1 from '../../assets/cooperatinProcces/iconStep1.svg';
import iconStep2 from '../../assets/cooperatinProcces/iconStep2.svg';
import iconStep3 from '../../assets/cooperatinProcces/iconStep3.svg';
import iconStep4 from '../../assets/cooperatinProcces/iconStep4.svg';
import iconStep5 from '../../assets/cooperatinProcces/iconStep5.svg';
import iconStep6 from '../../assets/cooperatinProcces/iconStep6.svg';
import line from '../../assets/cooperatinProcces/line.svg';
import { useSelector } from 'react-redux';

function CooperationProcess() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `` : ``}Процесс сотрудничества
          </h2>
          <div className={s.cooperationProccesBlock}>
            {/* <div className={s.cardBlock1}> */}
            <div className={s.cardStep1}>
              <div className={s.circleParent1}>
                <div className={s.circleChild1}>
                  <img className={s.iconStep1} src={iconStep1} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step1}>
                  {lang === 'ru' ? `Шаг 1` : `Step 1`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Регистрация на сайте в качестве партнера`
                    : `Registration on the site as a partner`}
                </p>
              </div>
            </div>
            <div className={s.cardStep2}>
              <div className={s.circleParent2}>
                <div className={s.circleChild2}>
                  <img className={s.iconStep2} src={iconStep2} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step2}>
                  {lang === 'ru' ? `Шаг 2` : `Step 2`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Ознакомьтесь с требованиями и создавайте крутой шаблон сайта, после чего
                    присылайте на модерацию`
                    : `Read the requirements and create a cool website template, after which
                    send for moderation`}
                </p>
              </div>
            </div>
            <div className={s.cardStep3}>
              <div className={s.circleParent3}>
                <div className={s.circleChild3}>
                  <img className={s.iconStep3} src={iconStep3} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step3}>
                  {lang === 'ru' ? `Шаг 3` : `Step 3`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Мы проверим работу в течение 48 часов на соответствие техническим требованиям`
                    : `We will check the work within 48 hours for compliance with the technical requirements`}
                </p>
              </div>
            </div>
            {/* </div> */}
            <img className={s.line} src={line} alt="Line" />
            {/* <div className={s.cardBlock2}> */}
            <div className={s.cardStep4}>
              <div className={s.circleParent4}>
                <div className={s.circleChild4}>
                  <img className={s.iconStep4} src={iconStep4} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step4}>
                  {lang === 'ru' ? `Шаг 4` : `Step 4`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Заключение договора (при соответствии всем требованиям)`
                    : `
Conclusion of the contract (if all requirements are met)`}
                </p>
              </div>
            </div>
            <div className={s.cardStep5}>
              <div className={s.circleParent5}>
                <div className={s.circleChild5}>
                  <img className={s.iconStep5} src={iconStep5} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step5}>
                  {lang === 'ru' ? `Шаг 5` : `Step 5`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Публикация готовой работы на сайте для продаж`
                    : `
Publication of the finished work on the site for sales`}
                </p>
              </div>
            </div>
            <div className={s.cardStep6}>
              <div className={s.circleParent6}>
                <div className={s.circleChild6}>
                  <img className={s.iconStep6} src={iconStep6} alt="Icon" />
                </div>
              </div>
              <div className={s.aboutBox}>
                <h3 className={s.step6}>
                  {lang === 'ru' ? `Шаг 6` : `Step 6`}
                </h3>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Получение выплаты за создание шаблона сайта и 25% процентов с каждой его продажи`
                    : `Getting paid for creating a website template and 25% percent from each sale`}
                </p>
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* <div className={s.cooperationProccesBlockMobile}>
            <div className={s.cardBlock1}>
              <div className={s.cardStep1}>
                <div className={s.circleParent1}>
                  <div className={s.circleChild1}>
                    <img className={s.iconStep1} src={iconStep1} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step1}>Шаг 1</h3>
                  <p className={s.about}>Регистрация на сайте в качестве партнера</p>
                </div>
              </div>
              <div className={s.cardStep2}>
                <div className={s.circleParent2}>
                  <div className={s.circleChild2}>
                    <img className={s.iconStep2} src={iconStep2} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step2}>Шаг 2</h3>
                  <p className={s.about}>
                    Ознакомьтесь с требованиями и создавайте крутой шаблон сайта, после чего
                    присылайте на модерацию
                  </p>
                </div>
              </div>
              <div className={s.cardStep3}>
                <div className={s.circleParent3}>
                  <div className={s.circleChild3}>
                    <img className={s.iconStep3} src={iconStep3} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step3}>Шаг 3</h3>
                  <p className={s.about}>
                    Мы проверим работу в течение 48 часов на соответствие техническим требованиям
                  </p>
                </div>
              </div>
            </div>
            <div className={s.cardBlock2}>
              <div className={s.cardStep4}>
                <div className={s.circleParent4}>
                  <div className={s.circleChild4}>
                    <img className={s.iconStep4} src={iconStep4} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step4}>Шаг 4</h3>
                  <p className={s.about}>Заключение договора (при соответствии всем требованиям)</p>
                </div>
              </div>
              <div className={s.cardStep5}>
                <div className={s.circleParent5}>
                  <div className={s.circleChild5}>
                    <img className={s.iconStep5} src={iconStep5} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step5}>Шаг 5</h3>
                  <p className={s.about}>Публикация готовой работы на сайте для продаж</p>
                </div>
              </div>
              <div className={s.cardStep6}>
                <div className={s.circleParent6}>
                  <div className={s.circleChild6}>
                    <img className={s.iconStep6} src={iconStep6} alt="Icon" />
                  </div>
                </div>
                <div className={s.aboutBox}>
                  <h3 className={s.step6}>Шаг 5</h3>
                  <p className={s.about}>Получение выплаты за создание шаблона сайта и 25% процентов с каждой его продажи</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default CooperationProcess;
