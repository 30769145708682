import React from 'react';

import s from './Benefits.module.scss';
import animationScreen from '../../assets/benefitsIcon/animation_screen.svg';
import logoCard from '../../assets/benefitsIcon/cart.svg';
import animationLoupe from '../../assets/benefitsIcon/loupe.svg';
import logoFile from '../../assets/benefitsIcon/file.svg';
import animationMobile from '../../assets/benefitsIcon/illustrationMobile.svg';
import animationSearch from '../../assets/benefitsIcon/illustrationSearch.svg';
import animationRobot from '../../assets/benefitsIcon/animation_robot.svg';
import animationEye from '../../assets/benefitsIcon/eye.svg';
import animationSmail from '../../assets/benefitsIcon/smail.svg';
import logoWaveTop from '../../assets/img/wave_top.svg';
import logoWave from '../../assets/img/wave.svg';
import { useSelector } from 'react-redux';

function Benefits() {
  const lang = useSelector((state) => state.lang.language);
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <img className={s.waveTop} src={logoWaveTop} alt="Волна" />
          <div className={s.boxScreen}>
            <img className={s.animationScreen} src={animationScreen} alt="Экран" />
            <div className={s.boxScreenTitle}>
              <h2 className={s.screenTitle}>
                {lang === 'ru'
                  ? 'Легко и просто меняйте контент в редакторе'
                  : `Easily change content in the editor`}
              </h2>
              <p className={s.screenItem}>
                {lang === 'ru'
                  ? `Благодаря удобному интерфейсу, для редактирования контента
                  не потребуется много времени. Весь процесс займёт от 10 до 15
                  минут. При возникновении вопросов по оформлению и запуску сайта
                  можно обратиться за помощью к онлайн-помощнику, который сразу
                  даст ответ.`
                  : `Thanks to the user-friendly interface, it doesn't take much time to edit content. The entire process takes 10 to 15 minutes. If you have any questions about the design and launch of the site, you can ask for help from an online assistant who will give you an answer right away.`}
              </p>
            </div>
          </div>
          <div className={s.boxWebsitePromotion}>
            <div className={s.boxPromotion}>
              <h2 className={s.promotionTitle}>
                {lang === 'ru' ? 'Сайт готов к продвижению' : `The site is ready to promote`}
              </h2>
              <p className={s.promotionItem}>
                {lang === 'ru'
                  ? `Вам не потребуется обращаться за помощью к специалисту
                  по seo-продвижению. При работе с готовыми сайтами вы сможете
                  самостоятельно настроить seo-оптимизацию. Продуманные
                  seo-настройки помогут правильно выстроить продвижение.`
                  : `You will not need to seek help from a seo-promotion specialist. When working with ready-made sites, you can set up your own seo-optimization. Well thought-out seo-settings will help you to build the right promotion.`}
              </p>
            </div>
            <div className={s.boxImage}>
              <img className={s.logoCard} src={logoCard} alt="файл" />
              <img className={s.loupe} src={animationLoupe} alt="лупа" />
              <img className={s.file} src={logoFile} alt="файл" />
            </div>
          </div>
          <div className={s.boxAdopted}>
            <img className={s.imageModile} src={animationMobile} alt="Экран" />
            <div className={s.boxAdoptenScreenTitle}>
              <h2 className={s.adoptedTitle}>
                {lang === 'ru' ? 'Адаптивен для любых устройств' : `Adaptable for all devices`}
              </h2>
              <p className={s.adoptedItem}>
                {lang === 'ru'
                  ? `Многие люди пользуются мобильным интернетом и заходят на сайт
                  со смартфонов и планшетов. Если сайт не адаптирован,
                  то все тексты и изображения будут искажены при открытии сайта
                  на телефоне. Мы учли это при разработке готовых сайтов
                  и адаптировали сайты для всех гаджетов.`
                  : `Many people use the mobile Internet and access the site from smartphones and tablets. If the site is not adapted, then all the text and images will be distorted when you open the site on your phone. We have taken this into account in the development of ready-made sites and adapted the sites for all gadgets.`}
              </p>
            </div>
          </div>
          <div className={s.boxDomainHosting}>
            <div className={s.boxDomainHostTitle}>
              <h2 className={s.domainHostTitle}>
                {lang === 'ru' ? 'Домен и хостинг' : `Domain and hosting`}
              </h2>
              <p className={s.domainHostItem}>
                {lang === 'ru'
                  ? `Виртуальный хостинг поможет избежать трудностей с выводом сайта
                в интернет-пространство, а домен является адресом сайта.
                Выберите запоминающее название для своего бизнеса, чтобы клиенты
                смогли легко найти сайт среди конкурентов. Мы предлагаем
                подключить услугу хостинга и домена для надёжной и бесперебойной
                работы сайта.`
                  : `Virtual hosting can help you avoid the hassle of putting your site on the Internet, and the domain is the site's address. Choose a memorable name for your business so that customers can easily find the site among competitors. We offer to connect hosting and domain service for reliable and smooth operation of the site.`}
              </p>
            </div>
            <div className={s.boxAnimationSearch}>
              <img className={s.animationSearch} src={animationSearch} alt="Поиск" />
              <div className={s.robowebSiteBox}>
                <p className={s.roboweb}>https://roboweb.site</p>
              </div>
            </div>
          </div>
          <div className={s.boxTechnicalSupport}>
            <div className={s.boxImageRobot}>
              <img className={s.imageRobot} src={animationRobot} alt="Экран" />
              <img className={s.imageRobotEye} src={animationEye} alt="Экран" />
              <img className={s.imageRobotSmail} src={animationSmail} alt="Экран" />
            </div>
            <div className={s.boxTechnicalSupportTitle}>
              <h2 className={s.technicalSupportTitle}>
                {lang === 'ru' ? 'Техническая поддержка' : `Technical support`}
              </h2>
              <p className={s.technicalSupportItem}>
                {lang === 'ru'
                  ? `Веб-маркетплейс RoboWeb разработал специального
                Онлайн-помощника, который поможет с редактированием готового
                сайта и созданием уникального сайта для вашего бизнеса.`
                  : `RoboWeb web-marketplace has developed a special Online Assistant that will help with editing the ready-made website and creating a unique website for your business.`}
              </p>
            </div>
          </div>
          <img className={s.wave} src={logoWave} alt="Волна" />
        </div>
      </div>
    </section>
  );
}

export default Benefits;
