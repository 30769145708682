import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import s from './AdministrationHeader.module.scss';
import moderationIcon from '../../assets/img/moderationIcon.svg';
import notificationIcon from '../../assets/img/notificationIcon.svg';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import logoSvg from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';
import burherMenu from '../../assets/img/burgerMenu.svg';
import Button from '../ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../app/features/user/userSlice';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';
import axios from 'axios';
import { BASE_URL, config } from '../..';
import NotificationsBlock from '../NotificationsBlock/NotificationsBlock';
import { switchLanguage } from '../../app/features/user/languageSwitch';

function AdministrationHeader() {
  const [active, setActive] = useState(false);
  const [burgerActivePartner, setBurgerActivePartner] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);
  // const [analitic, setAnalitic] = useState(false);
  const [activeNatifications, setActiveNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notification, setNotification] = useState(null);

  const dispatch = useDispatch();
  const { setHeaderType } = useContext(HeaderTypeContext);
  const lang = useSelector((state) => state.lang.language);

  const navigate = useNavigate();

  const [count, setCount] = useState('');

  const getCountModer = async () => {
    let num = await axios.get(`${BASE_URL}/sa/template/moderation?templateManagmentStatus=SENT`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setCount(num.data.templateList.length);
  };

  async function getNotificationCount() {
    const res = await axios.get(`${BASE_URL}/sa/notification/count`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setNotificationCount(res.data);
  }

  async function getAdminNotification() {
    const res = await axios.get(`${BASE_URL}/sa/notification`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });

    setNotification(res.data);
  }

  async function deleteNotification(id) {
    await axios.delete(`${BASE_URL}/sa/notification/${id}`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    getAdminNotification();
    getNotificationCount();
  }
  console.log(notification, 'notificAtion');
  useEffect(() => {
    getCountModer();
    getNotificationCount();
    getAdminNotification();
  }, []);

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.id !== 'hello') {
        setActive(false);
      }
    });
  }, [active]);

  console.log(localStorage.getItem('role'))

  return (
    <div className={s.container}>
      <Link className={s.linkLogo} to="/">
        <img className={s.logoRoboweb} src={logoSvg} alt="roboweb" />
      </Link>
      <Link className={s.linkLogoMobile} to="/">
        <img className={s.logoMobile} src={logoMobile} alt="roboweb" />
      </Link>
      <ul className={s.navigateList}>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/templates">
            {lang === `ru` ? `Готовые сайты` : `Ready sites `}
          </Link>
        </li>
        {/* <li className={s.listItem}>
          <Link className={s.itemLink} to="/portfolio">
            {lang === `ru` ? `Портфолио` : `Portfolio`}
          </Link>
        </li> */}
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/price">
            {lang === `ru` ? `Стоимость` : `Price`}
          </Link>
        </li>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/about">
            {lang === `ru` ? `О нас` : `About us`}
          </Link>
        </li>
        <li className={s.listItem}>
          <Link className={s.itemLink} to="/partners">
            {lang === `ru` ? `Партнерам` : `Partners`}
          </Link>
        </li>
      </ul>
      {burgerActivePartner && (
        <ul className={s.navigateListMobile}>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profileAdmin">
              {lang === `ru` ? `Профиль` : `Profile`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profileAreaAdmin">
              {lang === `ru` ? `Администрация` : `Administration`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profileAdminModeration">
              {lang === `ru` ? `Заявки на модерацию` : `Request for moderation`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/profileAdminMySite">
              {lang === `ru` ? `Мои сайты` : `My sites`}
            </Link>
          </li>
          <li
            onClick={() => setBurgerActivePartner(!burgerActivePartner)}
            className={s.listItemMobile}
          >
            <Link className={s.itemLinkMobile} to="/ProfileAdminFinance">
              {lang === `ru` ? `Финансы` : `Finance`}
            </Link>
          </li>
          {/* <li className={`${s.list_item} ${s.launguageContainerMobile}`}>
            <div
              className={s.launguageBoxMobile}
              onClick={() => setActiveLunguage(!activeLunguage)}
            >
              <span className={s.launguageMobile}>{launguage}</span>
              <img
                className={s.arrowMenu}
                style={{ transform: activeLunguage ? 'rotate(180deg)' : 'rotate(0deg)' }}
                src={arrowMenu}
                alt="Arrow"
              />
            </div>
            <span
              className={s.swichLaunguageMobile}
              style={{
                transform: activeLunguage ? 'translate(0px, 35px)' : 'translate(0px, 0px)',
              }}
              onClick={launguageSwitcher}
            >
              {launguage === 'Ru' ? 'En' : 'Ru'}
            </span>
          </li> */}
          <Button
            onClick={() => {
              setHeaderType('');
              dispatch(logout());
              navigate('/');
            }}
            className={s.btnSignIn}
          >
            {lang === `ru` ? `Выйти` : `Exit`}
          </Button>
          <div className={s.switchBoxMobile}>
            <label className={s.switch}>
              <input
                type="checkbox"
                checked={lang === 'ru' ? '' : 'checked'}
                onClick={() =>
                  lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
                }
                onChange={(e) => {}}
              />
              <span className={`${s.slider} ${s.round}`}></span>
              <span className={s.ru}>RU</span>
              <span className={s.en}>EN</span>
            </label>
          </div>
        </ul>
      )}
      <div className={s.groupBox}>
        <div className={s.iconBox}>
          <Link to="/profileAdminModeration">
            <div className={s.moderationBox}>
              <img className={s.moderationIcon} src={moderationIcon} alt="Moderation" />
              <span className={s.moderationCount}>{count}</span>
            </div>
          </Link>
          <div className={s.notificationContain}>
            <div
              onClick={() => setActiveNotifications(!activeNatifications)}
              className={s.notificationBox}
            >
              <img className={s.notificationIcon} src={notificationIcon} alt="Notification" />
              {notificationCount.notificationCount && (
                <span className={s.notificationCount}>{notificationCount.notificationCount}</span>
              )}
            </div>
            {activeNatifications && (
              <NotificationsBlock
                deleteNotification={deleteNotification}
                notification={notification}
                setActiveNotifications={setActiveNotifications}
              />
            )}
          </div>
        </div>
        <div onClick={() => setActive(!active)} className={s.sortedBlock} id="hello">
          <span className={s.sortedMenu} id="hello">
            {lang === `ru` ? `Меню` : `Menu`}
          </span>
          <img
            style={{
              transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={s.arrowMenu}
            src={arrowMenu}
            alt="Arrow"
            id="hello"
          />
          {active && (
            <div className={s.dropDownBox}>
              <span onClick={() => setActive(!active)} className={s.profile}>
                <Link className={s.itemLinkMobile} to="/profileAdmin">
                  {lang === `ru` ? `Профиль` : `Profile`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.administration}>
                <Link className={s.itemLinkMobile} to="/profileAreaAdmin">
                  {lang === `ru` ? `Администрация` : `Administration`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.requestForModeration}>
                <Link className={s.itemLinkMobile} to="/profileAdminModeration">
                  {lang === `ru` ? `Заявки на модерацию` : `Request for moderation`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.mySite}>
                <Link className={s.itemLinkMobile} to="/profileAdminMySite">
                  {lang === `ru` ? `Мои сайты` : `My sites`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.finance}>
                <Link className={s.itemLinkMobile} to="/ProfileAdminFinance">
                  {lang === `ru` ? `Финансы` : `Finance`}
                </Link>
              </span>
              <span onClick={() => setActive(!active)} className={s.exit}>
                <Link
                  onClick={() => {
                    setHeaderType('');
                    dispatch(logout());
                  }}
                  className={s.itemLinkMobile}
                  to="/"
                >
                  {lang === `ru` ? `Выйти` : `Exit`}
                </Link>
              </span>
            </div>
          )}
        </div>
        <div className={s.switchBox}>
          <label className={s.switch}>
            <input
              type="checkbox"
              checked={lang === 'ru' ? '' : 'checked'}
              onClick={() =>
                lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
              }
              onChange={(e) => {}}
            />
            <span className={`${s.slider} ${s.round}`}></span>
            <span className={s.ru}>RU</span>
            <span className={s.en}>EN</span>
          </label>
        </div>
        <div onClick={() => setBurgerActivePartner(!burgerActivePartner)} className={s.burgerBox}>
          <img
            style={{
              transform: burgerActivePartner ? 'rotate(45deg)' : 'rotate(0deg)',
              transformOrigin: burgerActivePartner ? 'center' : '',
              marginBottom: burgerActivePartner ? '-8px' : '5px',
            }}
            className={s.burgerTop}
            src={burherMenu}
            alt="Burger menu"
          />
          <img
            style={{
              transform: burgerActivePartner ? 'rotate(-45deg)' : 'rotate(0deg)',
              transformOrigin: burgerActivePartner ? 'center' : '',
            }}
            className={s.burgerBottom}
            src={burherMenu}
            alt="Burger menu"
          />
        </div>
      </div>
    </div>
  );
}

export default AdministrationHeader;
