import React from 'react';
import loader from '../../assets/img/loading.svg';
import s from './Loader.module.scss';
import logo from '../../assets/img/logoMobile.svg';
import { CircularProgress } from '@mui/material';

function Loader(props) {
  const { helperText } = props;
  return (
    <>
    <style global jsx>
      {`
        body {
          overflow: hidden;
        }
      `}
    </style>
    <div className={s.container}>
      <div className={s.imgBox}>
        <img className={s.logo} src={logo} alt="" />
        <CircularProgress size="20vh" />
      </div>
      <h2 className={s.title}>{helperText}</h2>
    </div>
    </>
  );
}

export default Loader;
