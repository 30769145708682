import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../..';
import $api from '../../http';
import Button from '../ui/Button/Button';

import s from './AdministrationServiceCost.module.scss';

function AdministrationServiceCost() {
  const lang = useSelector((state) => state.lang.language);

  const [price, setPrice] = useState([]);

  const [service, setService] = useState([]);

  const [render, setRender] = useState(false);

  async function getPrice() {
    let newList = await axios.get(`${BASE_URL}/sa/template-type`, {
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    });
    let serviceTech = await axios.get(`${BASE_URL}/client/service`, {
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    });
    setService(serviceTech.data.serviceList);
    let priceList = [
      ...newList.data.templateTypeList,
      ...(serviceTech?.data?.serviceList || []),
    ];
    setPrice(priceList);
  }

  const changePrice = async () => {
    await $api.patch(`/sa/template-type`, { templateTypeList: price });
    setRender(!render);
  };

  const changePriceService = async () => {
    await $api.patch(`/sa/service`, { serviceList: [price[4]] });
    setRender(!render);
  };

  useEffect(() => {
    getPrice();
  }, [render]);

  const addNewValue = (id, e, val) => {
    price.map((item) => {
      if (item.id === id) {
        item[val] = e.target.value;
      }
    });
  };

  return (
    <div className={s.container}>
      <div className={s.serviceCostBlock}>
        <div className={s.priceBox}>
          <h3 className={s.mainTitle}>
            {lang === `ru` ? `Стоимость` : `Price`}
          </h3>

          {price?.map((pr) => (
            <div className={s.businessCardWebsiteBox} key={pr.id}>
              <span className={s.title}>
                {lang === `ru` ? pr.titleRu : pr.titleEn}
              </span>
              <input
                className={s.inputPrice}
                name={pr.titleEn}
                placeholder={pr.price}
                defaultValue={pr.price}
                type="number"
                onChange={(e) => addNewValue(pr.id, e, 'price')}
                value={pr.value}
              />
            </div>
          ))}
        </div>
        <div className={s.priceBox}></div>
        <div className={s.priceBox}></div>
        <div className={s.saleBox}>
          <h3 className={s.mainTitle}>{lang === `ru` ? `Скидки` : `Sale`}</h3>
          {price.map((pr) => (
            <div className={s.businessCardWebsiteBox} key={pr.id}>
              <span className={s.title}>
                {lang === `ru` ? pr.titleRu : pr.titleEn}
              </span>
              <input
                className={s.inputPrice}
                type="number"
                name={pr.id}
                placeholder={pr.sale}
                defaultValue={pr.sale}
                onChange={(e) => addNewValue(pr.id, e, 'sale')}
                // value={pr.sale}
              />
            </div>
          ))}
        </div>
        <div className={s.PriceDiscountBox}>
          <h3 className={s.mainTitle}>
            {lang === `ru`
              ? `Стоимость с учетом скидки`
              : `Price with discount`}
          </h3>
          {price.map((pr) => (
            <div className={s.businessCardWebsiteBox} key={pr.id}>
              <span className={s.title}>
                {lang === `ru` ? pr.titleRu : pr.titleEn}
              </span>
              <input
                className={s.inputPrice}
                placeholder={Math.ceil(pr.price * (1 - 0.01 * pr.sale))}
                type="text"
                // onChange={(e) => setPriceValue(e.target.value)}
                value={Math.ceil(pr.price * (1 - 0.01 * pr.sale))}
              />
            </div>
          ))}
        </div>
      </div>
      <Button
        className={s.btnSave}
        onClick={() => {
          changePrice();
          changePriceService();
        }}
      >
        {lang === `ru` ? `Сохранить` : `Save`}
      </Button>
    </div>
  );
}

export default AdministrationServiceCost;
