import React, { useState } from 'react';

import s from './FrequentlyRequestedInformation.module.scss';
import { ReactComponent as Loupe } from '../../assets/FullListTeamplates/loupe.svg';

// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// // import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import { styled } from '@mui/material/styles';

import iconBtn from '../../assets/FAQImg/iconBtn.svg';
import { useSelector } from 'react-redux';

function FrequentlyRequestedInformation() {
  // const [isActive, setIsActive] = useState(false);
  const [activeInfo, setIsActiveInfo] = useState(0);
  const [cardId, setCardId] = useState(0);

  const lang = useSelector((state) => state.lang.language);

  const accordionData = [
    {
      id: 1,
      title: 'Какая-то часто запрашиваемая инфо',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
          laborum cupiditate possimus labore, hic temporibus velit dicta earum
          suscipit commodi eum enim atque at? Et perspiciatis dolore iure
          voluptatem.`,
    },
    {
      id: 2,
      title: 'Какая-то часто запрашиваемая инфо',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
          reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
          quaerat iure quos dolorum accusantium ducimus in illum vero commodi
          pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
          quidem maiores doloremque est numquam praesentium eos voluptatem amet!
          Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 3,
      title: 'Какая-то часто запрашиваемая инфо',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
          quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
          dolor ut sequi minus iste? Quas?`,
    },
    {
      id: 4,
      title: 'Какая-то часто запрашиваемая инфо',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
        reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
        quaerat iure quos dolorum accusantium ducimus in illum vero commodi
        pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
        quidem maiores doloremque est numquam praesentium eos voluptatem amet!
        Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
  ];

  const nameBox = [
    { ru: 'Общая информация', en: 'General information', id: 0 },
    { ru: 'Оплата', en: 'Payment', id: 1 },
    { ru: 'Создание веб-сайта', en: 'Website creation', id: 2 },
  ];
  const changeCardId = (id) => {
    setCardId((prevId) => {
      if (prevId === id) {
        setCardId(null);
      } else {
        setCardId(id);
      }
    });
  };

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.inputBox}>
            <input
              className={s.inputMain}
              type="text"
              placeholder={
                lang === 'ru'
                  ? 'Введите ключевое слово, например, «хостинг»'
                  : 'Enter a keyword, for example "hosting"'
              }
            />
            <Loupe className={s.loupe} src={Loupe} alt="" />
          </div>
          <div className={s.mobileContainer}>
            <div className={s.mobileBox}>
              {nameBox.map((obj, i) => (
                <h2
                  className={s[activeInfo === obj.id ? 'active' : 'disabled']}
                  onClick={(e) => setIsActiveInfo(obj.id)}
                  key={obj.id}
                >
                  {lang === 'ru' ? obj.ru : obj.en}
                </h2>
              ))}
            </div>
          </div>
          <div className={s.frequentlyRequestedInformationBox}>
            {activeInfo === 0 && (
              <div className={s.generalInformationBox}>
                <h3 className={s.mainTitle}>
                  {lang === 'ru' ? 'Общая информация' : 'General information'}
                </h3>
                <div className={s.cardsBox}>
                  {accordionData.map((el) => (
                    <div className={s.cardBox} key={el.id}>
                      <div
                        onClick={() => changeCardId(el.id)}
                        className={s.titleBox}
                      >
                        <img
                          style={{
                            transform:
                              el.id === cardId
                                ? 'rotate(45deg)'
                                : 'rotate(0deg)',
                          }}
                          className={s.iconBtn}
                          src={iconBtn}
                          alt=""
                        />
                        <span className={s.title}>{el.title}</span>
                      </div>
                      <div className={s.aboutBox}>
                        {el.id === cardId && (
                          <span className={s.about}>{el.content}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeInfo === 1 && (
              <div className={s.paymentBox}>
                <h3 className={s.mainTitle}>
                  {lang === 'ru' ? 'Оплата' : 'Payment'}
                </h3>
                <div className={s.cardsBox}>
                  {accordionData.map((el) => (
                    <div className={s.cardBox} key={el.id}>
                      <div
                        onClick={() => changeCardId(el.id)}
                        className={s.titleBox}
                      >
                        <img
                          style={{
                            transform:
                              el.id === cardId
                                ? 'rotate(45deg)'
                                : 'rotate(0deg)',
                          }}
                          className={s.iconBtn}
                          src={iconBtn}
                          alt=""
                        />
                        <span className={s.title}>{el.title}</span>
                      </div>
                      <div className={s.aboutBox}>
                        {el.id === cardId && (
                          <span className={s.about}>{el.content}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeInfo === 2 && (
              <div className={s.createWebSiteBox}>
                <h3 className={s.mainTitle}>
                  {lang === 'ru' ? 'Создание веб-сайта' : 'Website creation'}
                </h3>
                <div className={s.cardsBox3}>
                  {accordionData.map((el) => (
                    <div className={s.cardBox3} key={el.id}>
                      <div
                        onClick={() => changeCardId(el.id)}
                        className={s.titleBox}
                      >
                        <img
                          style={{
                            transform:
                              el.id === cardId
                                ? 'rotate(45deg)'
                                : 'rotate(0deg)',
                          }}
                          className={s.iconBtn}
                          src={iconBtn}
                          alt=""
                        />
                        <span className={s.title}>{el.title}</span>
                      </div>
                      <div className={s.aboutBox}>
                        {el.id === cardId && (
                          <span className={s.about}>{el.content}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={s.frequentlyRequestedInformationBoxDesctop}>
            <div className={s.generalInformationBox}>
              <h3 className={s.mainTitle}>
                {lang === 'ru' ? 'Общая информация' : 'General information'}
              </h3>
              <div className={s.cardsBox}>
                {accordionData.map((el) => (
                  <div className={s.cardBox} key={el.id}>
                    <div
                      onClick={() => changeCardId(el.id)}
                      className={s.titleBox}
                    >
                      <img
                        style={{
                          transform:
                            el.id === cardId ? 'rotate(45deg)' : 'rotate(0deg)',
                        }}
                        className={s.iconBtn}
                        src={iconBtn}
                        alt=""
                      />
                      <span className={s.title}>{el.title}</span>
                    </div>
                    <div className={s.aboutBox}>
                      {el.id === cardId && (
                        <span className={s.about}>{el.content}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={s.paymentBox}>
              <h3 className={s.mainTitle}>
                {lang === 'ru' ? 'Оплата' : 'Payment'}
              </h3>
              <div className={s.cardsBox}>
                {accordionData.map((el) => (
                  <div className={s.cardBox} key={el.id}>
                    <div
                      onClick={() => changeCardId(el.id)}
                      className={s.titleBox}
                    >
                      <img
                        style={{
                          transform:
                            el.id === cardId ? 'rotate(45deg)' : 'rotate(0deg)',
                        }}
                        className={s.iconBtn}
                        src={iconBtn}
                        alt=""
                      />
                      <span className={s.title}>{el.title}</span>
                    </div>
                    <div className={s.aboutBox}>
                      {el.id === cardId && (
                        <span className={s.about}>{el.content}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={s.createWebSiteBox}>
              <h3 className={s.mainTitle}>
                {lang === 'ru' ? 'Создание веб-сайта' : 'Website creation'}
              </h3>
              <div className={s.cardsBox3}>
                {accordionData.map((el) => (
                  <div className={s.cardBox3} key={el.id}>
                    <div
                      onClick={() => changeCardId(el.id)}
                      className={s.titleBox}
                    >
                      <img
                        style={{
                          transform:
                            el.id === cardId ? 'rotate(45deg)' : 'rotate(0deg)',
                        }}
                        className={s.iconBtn}
                        src={iconBtn}
                        alt=""
                      />
                      <span className={s.title}>{el.title}</span>
                    </div>
                    <div className={s.aboutBox}>
                      {el.id === cardId && (
                        <span className={s.about}>{el.content}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FrequentlyRequestedInformation;
