import React, { useState } from 'react';

import Button from '../ui/Button/Button';
import walletIcon from '../../assets/OurPartnersReceive/walletIcon.svg';
import circleBlueRight from '../../assets/OurPartnersReceive/circleBlueRight.png';
import circleGreenRight from '../../assets/OurPartnersReceive/circleGreenRight.png';
import circleGreyRight from '../../assets/OurPartnersReceive/circleGreyRight.svg';
import circleGreenLeft from '../../assets/OurPartnersReceive/circleGreenLeft.png';
import circleBlueLeft from '../../assets/OurPartnersReceive/circleBlueLeft.png';

import s from './OurPartnersReceive.module.scss';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function OurPartnersReceive() {
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const navigate = useNavigate();

  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }
  const lang = useSelector((state) => state.lang.language);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.contentBox}>
            <div className={s.aboutBox}>
              <h2 className={s.mainTitle}>
                {lang === `ru` ? `Наши партнеры получают 25% ` : `Our partners receive 25% `}
                <br />
                {lang === `ru` ? `с каждой продажи!` : `from every sale!`}
              </h2>
              <p className={s.about}>
                {lang === `ru`
                  ? `С каждой продажи вашего готового шаблона сайта вы получаете 25%. Делайте крутые
                проекты и RoboWeb будет продавать их пользователям.`
                  : `From each sale of your finished website template, you get 25%. Do cool
                projects and RoboWeb will sell them to users.`}
              </p>
              <Button
                onClick={() =>
                  localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
                }
                className={s.btnPartners}
              >
                {lang === `ru` ? `Стать партнером` : `Become a partner`}
              </Button>
            </div>
            <img className={s.walletIcon} src={walletIcon} alt="Wallet" />
            <img className={s.circleBlueRight} src={circleBlueRight} alt="Circle blue" />
            <img className={s.circleGreenRight} src={circleGreenRight} alt="Circle Green" />
            <img className={s.circleGreyRight} src={circleGreyRight} alt="Circle grey" />
            <img className={s.circleGreenLeft} src={circleGreenLeft} alt="Circle Green" />
            <img className={s.circleBlueLeft} src={circleBlueLeft} alt="Circle Blue" />
          </div>
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default OurPartnersReceive;
