import React, {useEffect, useState} from "react"

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(()=> {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearInterval(handler)
        }
    }, [value, delay])

    return debouncedValue
}

export default useDebounce