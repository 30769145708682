import React, { useEffect, useState } from 'react';

import s from './PartnersUploadWorkBlock.module.scss';
// import CircularProgress from '@mui/material/CircularProgress';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import questionId from '../../assets/img/questionId.svg';
import uploadTemplate from '../../assets/img/upload.svg';
import uploadArchive from '../../assets/img/uploadArchive.svg';
import Button from '../ui/Button/Button';
import axios from 'axios';
import { BASE_URL } from '../..';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import osisstant from '../../assets/img/onlineOsisstant.svg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, height } from '@mui/system';
import Loader from '../Loader/Loader';

function PartnersUploadWorkBlock() {
  const [activeSiteName, setActiveSiteName] = useState(false);
  const [activeCategoryName, setActiveCategoryName] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);
  useEffect(() => {
    axios(`${BASE_URL}/client/category
    `).then((res) => setItems(res.data.categoryList));
  }, []);

  const options = [
    { ru: 'Сайт-визитка', id: '5e01febc6aaee931304e0bde' },
    { ru: 'Одностраничный сайт', id: '5db045ac3a86ec7504a2f9b4' },
    { ru: 'Корпоративный сайт', id: '5db045ac3a86ec7504a2f9b5' },
    { en: 'Promo site', id: '5e01febc6aaee931304e0bde' },
    { en: 'Single Page Site', id: '5db045ac3a86ec7504a2f9b4' },
    { en: 'Corporate website', id: '5db045ac3a86ec7504a2f9b5' },
  ];

  const [title, setTitle] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [desc, setDesc] = useState('');
  const [fullDesc, setFullDesc] = useState('');
  const [upload1, setUpload1] = useState('');
  const [upload2, setUpload2] = useState('');
  const [upload3, setUpload3] = useState('');

  const clearInput = () => {
    setTitle('');
    setCategoryId('');
    setTemplateType('');
    setDesc('');
    setFullDesc('');
    setUpload1('');
    setUpload2('');
    setUpload3('');
  };

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    maxWidth: '180px',
  };

  const notifyOk = () =>
    toast(
      <div>
        <span style={imgOssistant}>
          {lang === 'ru'
            ? 'Ваши данные успешно отправлены!'
            : 'Your data has been successfully sent!'}
        </span>
        <img width={300} src={osisstant} />
      </div>,
    );
  const notifyErr = () =>
    toast(
      <div>
        <span style={imgOssistant}>
          {lang === 'ru' ? 'Ошибка при отправке данных!' : 'Error sending data!'}
        </span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const [validate, setValidate] = useState({});

  let role = localStorage.getItem('role');

  const addTemplate = async (e) => {
    e.preventDefault();
    if (!upload1) {
      setValidate((prev) => ({
        ...prev,
        upload1: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    }
    if (!upload2) {
      setValidate((prev) => ({
        ...prev,
        upload2: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    }
    if (!upload3) {
      setValidate((prev) => ({
        ...prev,
        upload3: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    }
    if (!title) {
      setValidate((prev) => ({
        ...prev,
        title: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    } else if (title.length < 5) {
      setValidate((prev) => ({
        ...prev,
        title:
          lang === 'ru'
            ? 'Кол-во символов должно быть больше 5-ти'
            : 'The number of characters must be more than 5',
      }));
    }

    if (!desc) {
      setValidate((prev) => ({
        ...prev,
        desc: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    } else if (desc.length < 10) {
      setValidate((prev) => ({
        ...prev,
        desc:
          lang === 'ru'
            ? `Кол-во символов должно быть больше 10-ти`
            : 'The number of characters must be more than 10',
      }));
    }

    if (!fullDesc) {
      setValidate((prev) => ({
        ...prev,
        fullDesc: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    } else if (fullDesc.length < 30) {
      setValidate((prev) => ({
        ...prev,
        fullDesc:
          lang === 'ru'
            ? 'Кол-во символов должно быть больше 30-ти'
            : 'Number of characters must be greater than 30',
      }));
    }

    if (!templateType) {
      setValidate((prev) => ({
        ...prev,
        templateType: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    }
    if (!categoryId) {
      setValidate((prev) => ({
        ...prev,
        categoryId: lang === 'ru' ? 'Обязательное поле' : 'Required field',
      }));
    } else if (
      templateType &&
      categoryId &&
      desc.length > 10 &&
      fullDesc.length > 30 &&
      title.length > 5 &&
      upload1 &&
      upload2 &&
      upload3
    ) {
      // const formData = new FormData()
      // formData.append('image', upload1)
      setLoading(true);
      await axios
        .post(
          `${BASE_URL}/${role.toLowerCase() === 'partner' ? 'client' : 'sa'}/template`,
          {
            image: upload1,
            imageCatalog: upload2,
            template: upload3,
            title: title,
            categoryId: categoryId,
            templateTypeId: templateType,
            description: desc,
            descriptionFull: fullDesc,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-access-token': `${localStorage.getItem('token')}`,
            },
          },
        )
        .then((res) => {
          if (res.status) {
            setLoading(false);
            notifyOk();
            console.log('Succes' + templateType);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          notifyErr();
        });
    }
  };

  const changeInputValue = (e) => {
    if (validate[e.target.name] !== undefined) {
      setValidate((prev) => ({ ...prev, [e.target.name]: null }));
    }

    if (e.target.name === 'title') {
      setTitle(e.target.value);
    }

    if (e.target.name === 'categoryId') {
      // console.log(e.target.value.id);
      setCategoryId(e.target.value.id);
    }

    if (e.target.name === 'templateType') {
      setTemplateType(e.target.value.id);
    }

    if (e.target.name === 'desc') {
      setDesc(e.target.value);
    }

    if (e.target.name === 'fullDesc') {
      setFullDesc(e.target.value);
    }

    if (e.target.name === 'upload1') {
      setUpload1(e.target.value);
    }

    if (e.target.name === 'upload2') {
      setUpload2(e.target.value);
    }

    if (e.target.name === 'upload3') {
      setUpload3(e.target.value);
    }
  };

  console.log(upload3.size);

  const lang = useSelector((state) => state.lang.language);

  if (loading) {
    return <Loader helperText="Идет отправка данных..." />;
  } else {
    return (
      <form className={s.container} onSubmit={(event) => addTemplate(event)}>
        <div className={s.inputBox}>
          <label className={s.labelNameSite} htmlFor="">
            {lang === 'ru' ? `Название сайта` : 'Name of the site'}
          </label>

          <input
            className={s.nameSite}
            type="text"
            onChange={(e) => {
              changeInputValue({ target: { name: 'title' } });
              setTitle(e.target.value);
            }}
          />
          <div className={s.error}>
            {validate.title && <span className={s.errorVisible}>{validate.title}</span>}
          </div>

          <div
            onClick={() => setActiveCategoryName(!activeCategoryName)}
            className={s.selectCategotySiteBox}
          >
            <label className={s.titleSelectCategorySite}>
              {lang === 'ru'
                ? `Категория
                сайта`
                : 'Category site'}
            </label>
            <div className={s.selectCategorySite}>
              <span className={s.categoryTitle}>{selectedCategory}</span>
            </div>
            <img
              style={{
                transform: activeCategoryName ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              className={s.arrowMenu}
              src={arrowMenu}
              alt="Arrow menu"
            />
            {activeCategoryName && (
              <div className={s.contentBoxCategory}>
                {items?.map((el, i) => (
                  <span
                    key={el.id}
                    className={s.categoryTitle}
                    onClick={() => {
                      setSelectedCategory(
                        el.transcriptionRu !== 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                          ? el.transcriptionRu.slice(0, 1) +
                              el.transcriptionRu.slice(1).toLowerCase()
                          : el.transcriptionRu === 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                          ? el.transcriptionRu.slice(0, 2) +
                            el.transcriptionRu.slice(2).toLowerCase()
                          : el.transcriptionEn !== 'IT TECHNOLOGY' && lang === 'en'
                          ? el.transcriptionEn.slice(0, 1) +
                            el.transcriptionEn.slice(1).toLowerCase()
                          : el.transcriptionEn === 'IT TECHNOLOGY' && lang === 'en'
                          ? el.transcriptionEn.slice(0, 2) +
                            el.transcriptionEn.slice(2).toLowerCase()
                          : '',
                      );
                      setActiveCategoryName(!activeCategoryName);
                      setCategoryId((prev) => prev + el.id);
                      changeInputValue({
                        target: { name: 'categoryId', value: el },
                      });
                    }}
                  >
                    {el.transcriptionRu !== 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                      ? el.transcriptionRu.slice(0, 1) + el.transcriptionRu.slice(1).toLowerCase()
                      : ''}
                    {el.transcriptionRu === 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                      ? el.transcriptionRu.slice(0, 2) + el.transcriptionRu.slice(2).toLowerCase()
                      : ''}
                    {el.transcriptionEn !== 'IT TECHNOLOGY' && lang === 'en'
                      ? el.transcriptionEn.slice(0, 1) + el.transcriptionEn.slice(1).toLowerCase()
                      : ''}
                    {el.transcriptionEn === 'IT TECHNOLOGY' && lang === 'en'
                      ? el.transcriptionEn.slice(0, 2) + el.transcriptionEn.slice(2).toLowerCase()
                      : ''}
                  </span>
                ))}
              </div>
            )}
            <div className={s.error}>
              {validate.categoryId && <span className={s.errorVisible}>{validate.categoryId}</span>}
            </div>
          </div>

          {/* {items?.map((el) => (
          <>
            <div key={el._id} className={s.titleBox}>
              <img className={s.iconCategory} src={getNewIcon(el)} alt={el.title} />
              <span className={s.title}>{el.transcription.ru !== 'IT ТЕХНОЛОГИИ' ? el.transcription.ru.slice(0,1) + el.transcription.ru.slice(1).toLowerCase() : el.transcription.ru.slice(0,2) + el.transcription.ru.slice(2).toLowerCase()}</span>
            </div>
            <div className={s.borderLine}></div>
          </>
        ))} */}
          <div onClick={() => setActiveSiteName(!activeSiteName)} className={s.selectSiteViewBox}>
            <label className={s.titleSelectSiteView}>
              {lang === 'ru' ? `Вид сайта` : 'Site type'}
            </label>
            <div className={s.selectSiteView}>
              <span className={s.siteTitle}>{selected}</span>
            </div>
            <img
              style={{
                transform: activeSiteName ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              className={s.arrowMenu}
              src={arrowMenu}
              alt="Arrow menu"
            />
            {activeSiteName && (
              <div
                // style={{
                __//   transform: isActive ? 'rotateY(200px)' : 'rotate(0deg)',
                // }}
                className={s.contentBox}
              >
                {options.map((option, i) => (
                  <h3
                    key={i}
                    onClick={() => {
                      console.log(option);
                      setSelected(lang === 'ru' ? option.ru : option.en);
                      setTemplateType(option.id);
                      setActiveSiteName(false);
                      changeInputValue({
                        target: { name: 'templateType', value: option },
                      });
                    }}
                    className={s.title}
                  >
                    {lang === 'ru' ? option.ru : option.en}
                  </h3>
                ))}
              </div>
            )}
          </div>
          <div className={s.error}>
            {validate.templateType && (
              <span className={s.errorVisible}>{validate.templateType}</span>
            )}
          </div>

          <div className={s.shortDiscriptionBox}>
            <label className={s.labelShortDiscription} htmlFor="">
              {lang === 'ru'
                ? `Краткое
                описание`
                : 'Brief description'}
            </label>
            <img className={s.shortDiscriptionIcon} src={questionId} alt="" />
            <div className={s.questionShortDiscriptionBox}>
              <p className={s.title}>
                {lang === 'ru'
                  ? `Заголовок
                  — это название сайта в поисковой выдаче и на вкладке браузера.
                  Составить его нужно, используя до 3-х самых важных ключевых фраз
                  для Вашего сайта и название компании. Необходимо избежать
                  использования символов (= / + _ ) и точек, двоеточий,
                  восклицательных и вопросительных знаков. Например: «Интернет
                  магазин Варвара — купить косметику в Москве недорого».
                  Рекомендованная длина заголовка — до 60 символов.`
                  : `Header
                  - this is the name of the site in the search results and on the browser tab.
                  You need to compose it using up to 3 of the most important key phrases
                  for your website and company name. Must be avoided
                  using symbols (= / + _ ) and dots, colons,
                  exclamation and question marks. For example: "Internet
                  Varvara store - buy cosmetics in Moscow inexpensively.
                  The recommended title length is up to 60 characters.`}
              </p>
            </div>
          </div>

          <input
            className={s.shortDiscription}
            type="text"
            onChange={(e) => {
              changeInputValue({ target: { name: 'desc' } });
              setDesc(e.target.value);
            }}
          />
          <div className={s.error}>
            {validate.desc && <span className={s.errorVisible}>{validate.desc}</span>}
          </div>
          <div className={s.fullDiscriptionBox}>
            <label className={s.labelfullDiscription} htmlFor="">
              {lang === 'ru' ? `Полное описание` : 'Full description'}
            </label>
            <img className={s.fullDiscriptionIcon} src={questionId} alt="" />
            <div className={s.questionFullDiscriptionBox}>
              <p className={s.title}>
                {lang === 'ru'
                  ? `Описание
                  — это небольшой текст, который поисковая система показывает в
                  сниппете. Именно этот текст должен рассказывать содержания
                  страницы, ведь это поможет поисковику в ранжировании страницы в
                  поиске. Размер описания рекомендуем делать не более 160
                  символов, максимум — 200. Например: «Интернет магазин Варвара:
                  здесь можно купить косметику в Москве недорого с бесплатной
                  доставкой. Большой ассортимент! Премиум бренды оригинального
                  качества.»`
                  : `Description
                  is a small text that the search engine shows in
                  snippet. It is this text that should tell the content
                  pages, because this will help the search engine in ranking the page in
                  search. The size of the description is recommended to be no more than 160
                  characters, maximum - 200. For example: "Online store Varvara:
                  here you can buy cosmetics in Moscow inexpensively with free
                  delivery. A large assortment! Premium brands of original
                  quality."`}
              </p>
            </div>
          </div>
          <textarea
            className={s.fullDiscription}
            type="text"
            onChange={(e) => {
              changeInputValue({ target: { name: 'fullDesc' } });
              setFullDesc(e.target.value);
            }}
          />
          <div className={s.error}>
            {validate.fullDesc && <span className={s.errorVisible}>{validate.fullDesc}</span>}
          </div>
        </div>
        <div className={s.uploadCardsMainContain}>
          <div className={s.uploadCardsContain}>
            <div className={s.uploadCardTemlateBox}>
              <img className={s.uploadTemplate} src={uploadTemplate} alt="Upload icon" />
              <h3 className={s.titleUploadTemlate}>
                {lang === 'ru'
                  ? `Загрузить
                  превью шаблона`
                  : `Download
                  template preview`}
              </h3>
              <span className={s.size}>858х650</span>
              <Button className={s.btnUploadTemplate}>
                {lang === 'ru'
                  ? `Выбрать
                  файл`
                  : 'Choose file'}
                <input
                  className={s.inputFile}
                  type="file"
                  onChange={(e) => setUpload1(e.target.files[0])}
                  accept=".png, .jpg, .jpeg"
                />
                <LinearProgress
                  variant="determinate"
                  value={upload1?.size ? upload1?.size : '100'}
                />
              </Button>
            </div>
            <div className={s.uploadCardPictureBox}>
              <img className={s.uploadPicture} src={uploadTemplate} alt="Upload icon" />
              <h3 className={s.titlePicture}>
                {lang === 'ru'
                  ? `Загрузить
                  картинку каталога`
                  : 'Download catalog picture'}
              </h3>
              <span className={s.size}>220х350</span>
              <Button className={s.btnUploadPicture}>
                {lang === 'ru'
                  ? `Выбрать
                  файл`
                  : 'Choose file'}
                <input
                  className={s.inputFile}
                  type="file"
                  onChange={(e) => setUpload2(e.target.files[0])}
                  accept=".png, .jpg, .jpeg"
                />
                <LinearProgress
                  variant="determinate"
                  value={upload2?.size ? upload2?.size : '100'}
                />
              </Button>
            </div>
            <div className={s.uploadCardArchiveTemplateBox}>
              <img
                className={s.uploadArchive}
                src={uploadArchive}
                alt="Upload icon"
                accept=".png .jpg .jpeg"
              />
              <h3 className={s.titleArchive}>
                {lang === 'ru'
                  ? `Загрузить
                  превью шаблона`
                  : 'Download template preview'}
              </h3>
              <span className={s.sizeArchive}>
                {lang === 'ru'
                  ? `ZIP-архив
                  содержит папку “Site”, внутри которой шаблон.`
                  : 'ZIP archive contains the “Site” folder, inside which is the template.'}
              </span>

              {/* <label for="field__file-2"> */}
              <Button className={s.btnUploadArchive}>
                {lang === 'ru'
                  ? `Выбрать
                  файл`
                  : 'Choose file'}
                <input
                  className={s.inputFile}
                  type="file"
                  onChange={(e) => setUpload3(e.target.files[0])}
                  accept=".zip"
                />
                <LinearProgress
                  variant="determinate"
                  value={upload3?.size ? upload3?.size : '100'}
                />
              </Button>
              {/* </label> */}
            </div>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
          autoClose={6000}
          limit={1}
          // style={{ position: 'relative' }}
          position="bottom-left"
        />
        <Button
          className={s.btnSendForModeration}
          // onSubmit={(event) => addTemplate(event)}
        >
          <button className={s.btn}>
            {lang === 'ru'
              ? `Отправить на
              модерацию`
              : 'Send to moderation'}
          </button>
        </Button>
      </form>
    );
  }
}

export default PartnersUploadWorkBlock;
