import React, { useEffect } from 'react';

import s from './ViewTemplate.module.scss';
import { ReactComponent as ArrowLink } from '../../assets/img/arrowLink.svg';
import { ReactComponent as Like } from '../../assets/img/favoritesIcon.svg';
import arrowBackMobile from '../../assets/img/arrowBackMobile.svg';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { useState } from 'react';
import SimilarTemplates from '../SimilarTemplates/SimilarTemplates';
import SubmitYourApplicationTemplateBlock from '../SubmitYourApplicationTemplateBlock/SubmitYourApplicationTemplateBlock';
import Button from '../ui/Button/Button';
import CharacteristicsSiteBlock from '../CharacteristicsSiteBlock/CharacteristicsSiteBlock';
import SiteReviewsBlock from '../SiteReviewsBlock/SiteReviewsBlock';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { apiNewId } from '../../app/features/user/getNewId';
import $api from '../../http';

function ViewTemplate() {
  const lang = useSelector((state) => state.lang.language);
  const [getIsFavorite, setGetIsFavorite] = useState(false);

  const newIdState = useSelector((state) => state.newId.newId);
  const dispatch = useDispatch();

  const [status, setStatus] = useState('');

  useEffect(() => {
    const getStatus = async () => {
      let status = await $api.get(`/client/user/templates/${templateId}`);
      setStatus(status.data.template.status);
    };

    getStatus();
  }, []);
  // const createId = async (templateId) => {
  //   const responce = await dispatch(apiNewId(templateId));
  //   if (responce.payload && status.toLowerCase() !== 'dev') {
  //     navigate(`/templateUpdate/${responce.payload}`);
  //     console.log('dadadada');
  //   } else if (status.toLowerCase() === 'dev') {
  //     navigate(`/templateUpdate/${templateId}`);
  //     console.log('nononono');
  //   }
  // };

  const createId = async () => {
    if (status.toLowerCase() !== 'dev') {
      const responce = await dispatch(apiNewId(templateId));
      if (responce.payload) {
        navigate(`/templateUpdate/${responce.payload}`);
        console.log('dadadada');
      }
    }
    if (status.toLowerCase() === 'dev') {
      navigate(`/templateUpdate/${templateId}`);
      console.log('nononono');
    }
  };

  const [active, setActive] = useState(0);
  const [more, setMore] = useState('290px');
  const [activeMore, setActiveMore] = useState(true);
  const [activeLike, setActiveLike] = useState(false);

  const [template, setTemplate] = useState({});

  let { templateId } = useParams();

  const getTemplate = async (templateId) => {
    const response = await fetch(`${BASE_URL}/client/template/${templateId}`, {
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    });
    return await response.json();
  };

  const addFavorite = async (id) => {
    const response = await fetch(`${BASE_URL}/client/template/${id}/add-favorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token'),
      },
    });
    return await response.json();
  };

  const removeFavorite = async (id) => {
    const response = await fetch(`${BASE_URL}/client/template/${id}/remove-favorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token'),
      },
    });
    return await response.json();
  };

  const getFavorite = async (id) => {
    let checkFavorite = await $api.get(`/client/template/${id}/check-favorite`);
    console.log(checkFavorite.data.isFavorite);
    setGetIsFavorite(checkFavorite.data.isFavorite);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTemplate(templateId)
      .then((data) => {
        const key = Object.keys(data);
        setTemplate(data[key]);
      })
      .catch((err) => console.log(err));
  }, [templateId, active, getIsFavorite]);

  const linkNames = [
    { ru: 'Обзор', en: 'Review', id: 0 },
    { ru: 'Характеристики', en: 'Characteristics', id: 1 },
    { ru: 'Отзывы', en: 'Comments', id: 2 },
  ];

  useEffect(() => {
    setActiveLike(false);
    getFavorite(templateId);
    if (getIsFavorite) {
      setActiveLike(true);
    }
  },[getIsFavorite]);

  const navigate = useNavigate();

  return (
    <section>
      <div className={s.container}>
        <div className={s.viewTemplateBlock}>
          <div className={s.iconBackBox}>
            <Link className={s.linkTemplates} to="/templates">
              <img className={s.iconArrowBackMobile} src={arrowBackMobile} alt="Arrow Back" />
            </Link>
          </div>
          <ul className={s.linkList}>
            <Link className={s.linkMain} to="/">
              <li className={s.main}>{lang === `ru` ? `Главная` : `Home`}</li>
            </Link>
            <Link className={s.linkTemplates} to="/templates">
              <li className={s.template}>
                <ArrowLink className={s.arrowLink} alt="Arrow Link" />
                {lang === `ru` ? `Шаблоны` : `Templates`}
              </li>
            </Link>
            <li className={s.nameWebsiteTemplate}>
              <ArrowLink className={s.arrowLink} alt="" />
              {lang === `ru` ? `Шаблон сайта` : `Site template`} {template.title}
            </li>
          </ul>
          <h1 className={s.mainTilte}>
            {lang === `ru` ? `Шаблон сайта` : `Site template`} <br /> {template.title}
          </h1>
          <span className={s.aboutTitle}>
            {lang === `ru`
              ? `Сайт почти готов, необходимо только внести свои данные и контент`
              : `The site is almost ready, you only need to enter your data and content`}
          </span>
          <div className={s.buttonBox}>
            <div
              onClick={() => {
                if (activeLike) {
                  removeFavorite(templateId);
                  setGetIsFavorite(false);
                  setActiveLike(false);
                } else {
                  setActiveLike(true);
                  addFavorite(templateId);
                }
              }}
              className={s.favoriteBox}
            >
              <Like className={s[activeLike ? 'like' : 'unLike']} alt="Like" />
              <span className={s.favorite}>{lang === `ru` ? `В избранное` : `To favorites`}</span>
            </div>
            <Link className={s.robolookMobile} to={`/robolook/${templateId}`}>
              <span className={s.viewMobile}>{lang === `ru` ? `Посмотреть` : `Look`}</span>
            </Link>
            <ButtonTemplates
              onClick={() => {
                navigate(`/robolook/${templateId}`);
              }}
              className={s.view}
            >
              {lang === `ru` ? `Посмотреть` : `Look`}
            </ButtonTemplates>
          </div>
          <img className={s.siteImg} src={`${BASE_URL}/${template.img}`} alt="" />
          <Button
            // onClick={() => navigate(`/templateUpdate/${templateId}`)}

            onClick={() => {
              createId();
            }}
            className={s.btnUpdateTemplate}
          >
            {lang === `ru` ? `Редактировать` : `Edit`}
          </Button>
          <ul className={s.linkBlock}>
            {linkNames.map((obj) => (
              <li
                className={s[active === obj.id ? 'active' : 'disabled']}
                onClick={() => setActive(obj.id)}
                key={obj.id}
              >
                {lang === `ru` ? obj.ru : obj.en}
              </li>
            ))}
          </ul>
          {active === 0 && (
            <div className={s.aboutBox}>
              <p className={s.description}>{template.description}</p>
              <p className={s.descriptionFull}>{template.descriptionFull}</p>

              <div className={s.descriptionBox} style={{ height: more }}>
                <p className={s.descriptionFullMobile}>{template.descriptionFull}</p>
              </div>
              {activeMore && (
                <span
                  onClick={() => {
                    setMore('auto');
                    setActiveMore(!activeMore);
                  }}
                  className={s.moreBtn}
                >
                  {lang === `ru` ? `Подробнее` : `More`}
                </span>
              )}
            </div>
          )}
          {active === 1 && <CharacteristicsSiteBlock features={template.about} />}
          {active === 2 && <SiteReviewsBlock />}
          <SimilarTemplates />
        </div>
        <SubmitYourApplicationTemplateBlock />
      </div>
    </section>
  );
}

export default ViewTemplate;
