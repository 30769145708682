import React, { useEffect, useState } from 'react';

import s from './NotificationsBlock.module.scss';
import x from '../../assets/img/x.svg';
import { autoBatchEnhancer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';
import axios from 'axios';
import { BASE_URL } from '../..';
import dayjs from 'dayjs';

function NotificationsBlock({ setActiveNotifications, notification, deleteNotification }) {
  // const [isActive, setIsActive] = useState(false);
  // const [activeText, setActiveText] = useState(false);
  const [activeId, setActiveId] = useState(0);
  // console.log(activeId, '2222222222222');
  const lang = useSelector((state) => state.lang.language);
  // console.log(notification)

  
  return (
    <div className={ localStorage.getItem('role') === 'CLIENT' ? s.notificationsBlockUser : s.notificationsBlock}>
      <img
        onClick={() => setActiveNotifications((prev) => !prev)}
        className={s.x}
        src={x}
        alt="Exit"
      />
      <h3 className={s.mainTitle}>{lang === `ru` ? `Уведомления` : `Notifications`}</h3>
      <div className={s.container}>
        <div className={s.box}>
          <div className={s.massageBlock}>
            {notification.length ? (
              notification.map((el) => (
                <Ellipsis
                  deleteNotification={deleteNotification}
                  notification={notification}
                  el={el}
                  setActiveId={setActiveId}
                />
              ))
            ) : (
              <p className={s.notNotifications}>Нет уведомлений</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Ellipsis = ({ el, setActiveId, deleteNotification }) => {
  const [activeText, setActiveText] = useState(false);

  return (
    <div
      key={el.id}
      onClick={(e) => {
        // setIsActive(!isActive);
        setActiveId((prev) => (prev === el.id ? null : el.id));
      }}
      // style={{ height: el.id === activeId && el.about.length > 180 ? '100%' : '190px' }}
      className={s.massageBox}
    >
      <div className={s.textWrapper}>
        <img
          onClick={() => {
            // console.log(e.target);
            deleteNotification(el.id);
          }}
          className={s.massageClosed}
          src={x}
          alt="Closed"
        />
        <h3 className={s.title}>{el.message}</h3>
        <span className={s.data}>{dayjs(el.createdAt).format('DD/MM/YY в hh:mm')}</span>
        {!activeText ? (
          <LinesEllipsis
            text={el.review?.text ? el.review.text : el.template.descriptionFull}
            maxLine={3}
            onReflow={() => {}}
            basedOn="letters"
            ellipsis={
              <p align="right" className={s.openText} onClick={() => setActiveText(true)}>
                Открыть
              </p>
            }
            className={s.about}
          />
        ) : (
          <>
            <span className={s.about}>
              {el.review?.text ? el.review.text : el.template.descriptionFull}
            </span>
            <p align="right" className={s.closeText} onClick={() => setActiveText(false)}>
              Свернуть
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationsBlock;
