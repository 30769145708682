import React from 'react';
import Container from '../ui/Container/Container';
import s from './CompareYourSelf.module.scss';

import webStudio from '../../assets/compareYourSelf/webStudio.svg';
import freelancer from '../../assets/compareYourSelf/freelancer.svg';
import constructorSite from '../../assets/compareYourSelf/constructorSite.svg';
import robot from '../../assets/compareYourSelf/robot.svg';
import { useSelector } from 'react-redux';

function CompareYourSelf() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <Container>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `Сравните сами` : `Compare for yourself`}
          </h2>
          <div className={s.compareYourSelfBlock}>
            <div className={s.compareYourSelfBox}>
              {/* <div className={s.imageBox}> */}
              <img className={s.iconTitle} src={webStudio} alt="web-studio" />
              {/* </div> */}
              <div className={s.cardBox}>
                <div className={s.aboutBox}>
                  <h3 className={s.titleCard}>
                    {lang === 'ru' ? `Веб-студия` : `Web Studio`}
                  </h3>
                  <p className={s.aboutTitle1}>
                    {lang === 'ru'
                      ? `Стоимость работы от `
                      : `The cost of work from `}
                    &shy; 50000 ₽
                    {lang === 'ru'
                      ? `, что сильно ударит
                    по кошельку`
                      : `, which will hit the wallet hard`}
                  </p>
                  <div className={s.borderLine}></div>
                  <span className={s.time}>
                    {lang === 'ru'
                      ? `Сроки — от 3 месяцев`
                      : `Time frame: from 3 months`}
                  </span>
                  <div className={s.borderLine}></div>
                  <p className={s.timeAbout}>
                    {lang === 'ru'
                      ? `По окончанию разработки необходимо искать клиентов`
                      : `You need to look for clients when the development is finished`}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.compareYourSelfBox}>
              {/* <div className={s.imageBox}> */}
              <img className={s.iconTitle} src={freelancer} alt="freelancer" />
              {/* </div> */}
              <div className={s.cardBox}>
                <div className={s.aboutBox}>
                  <h3 className={s.titleCard}>
                    {lang === 'ru' ? `Фрилансер` : `Freelancer`}
                  </h3>
                  <p className={s.aboutTitle}>
                    {lang === 'ru'
                      ? `Специалист сделал работу некачественно`
                      : `The specialist did not do the work well`}
                  </p>
                  <div className={s.borderLine}></div>
                  <span className={s.time}>
                    {lang === 'ru'
                      ? `Сроки — были нарушены`
                      : `The deadline was violated`}
                  </span>
                  <div className={s.borderLine}></div>
                  <p className={s.timeAbout1}>
                    {lang === 'ru'
                      ? `Отсутствие технической поддержки специалистов`
                      : `Lack of technical support specialists`}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.compareYourSelfBox}>
              {/* <div className={s.imageBox}> */}
              <img
                className={s.iconTitle}
                src={constructorSite}
                alt="constructor-site"
              />
              {/* </div> */}
              <div className={s.cardBox}>
                <div className={s.aboutBox}>
                  <h3 className={s.titleCard}>
                    {lang === 'ru' ? `Конструктов сайтов` : `Site builder`}
                  </h3>
                  <p className={s.aboutTitle1}>
                    {lang === 'ru'
                      ? `Без опыта не получается создать красивый и работающий сайт`
                      : `Without experience you can not create a beautiful and working website`}
                  </p>
                  <div className={s.borderLine}></div>
                  <span className={s.time}>
                    {lang === 'ru'
                      ? ` Сроки — от 1 месяца`
                      : `Timing - from 1 month`}
                  </span>
                  <div className={s.borderLine}></div>
                  <p className={s.timeAbout}>
                    {lang === 'ru'
                      ? `На изучение функций конструктора потребуется много времени`
                      : `Learning the functions of the designer will take a lot of time`}
                  </p>
                </div>
              </div>
            </div>
            <div className={s.compareYourSelfBox}>
              {/* <div className={s.imageBox}> */}
              <img className={s.iconTitle} src={robot} alt="turnkey solution" />
              {/* </div> */}
              <div className={s.cardBox}>
                <div className={s.aboutBox}>
                  <h3 className={s.titleCard}>
                    {lang === 'ru' ? `Готовое решение` : `Ready-made solution`}
                  </h3>
                  <p className={s.aboutTitle1}>
                    {lang === 'ru'
                      ? `Качественный сайт за небольшие деньги и время`
                      : `A quality site for a little money and time`}
                  </p>
                  <div className={s.borderLine}></div>
                  <span className={s.time}>
                    {lang === 'ru'
                      ? `Сроки — от 15 минут`
                      : `Time frame - from 15 minutes`}
                  </span>
                  <div className={s.borderLine}></div>
                  <p className={s.timeAbout1}>
                    {lang === 'ru'
                      ? `Не нужно разбираться с настройками и поддержкой сайта`
                      : `No need to deal with the settings and support of the site`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default CompareYourSelf;
