import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    auth: !!localStorage.getItem("token")
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state) => {
            state.auth = true
        },
        logout: (state) => {
            localStorage.clear()
            state.auth = false
        }
    },
    extraReducers: () => {}
})

export const {login, logout} = userSlice.actions

export default userSlice.reducer