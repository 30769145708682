// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateSkeleton_cardBox__wWKGQ {\n  gap: 30px;\n}\n@media (max-width: 1283px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    max-width: 260px;\n  }\n}\n@media (max-width: 1116px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    max-width: 240px;\n  }\n}\n@media (max-width: 820px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    width: 210px;\n  }\n}\n@media (max-width: 720px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    width: 190px;\n  }\n}\n@media (max-width: 640px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    width: 165px;\n  }\n}\n@media (max-width: 350px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    width: 140px;\n  }\n}\n@media (max-width: 300px) {\n  .TemplateSkeleton_cardBox__wWKGQ {\n    width: 120px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/TemplateSkeleton/TemplateSkeleton.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AAGE;EALF;IAMI,gBAAA;EAAF;AACF;AACE;EARF;IASI,gBAAA;EAEF;AACF;AADE;EAXF;IAYI,YAAA;EAIF;AACF;AAHE;EAdF;IAeI,YAAA;EAMF;AACF;AALE;EAjBF;IAkBI,YAAA;EAQF;AACF;AAPE;EApBF;IAqBI,YAAA;EAUF;AACF;AATE;EAvBF;IAwBI,YAAA;EAYF;AACF","sourcesContent":[".cardBox {\n  gap: 30px;\n  @media (max-width: 1350px) {\n    // max-width: 260px;\n  }\n  @media (max-width: 1283px) {\n    max-width: 260px;\n  }\n  @media (max-width: 1116px) {\n    max-width: 240px;\n  }\n  @media (max-width: 820px) {\n    width: 210px;\n  }\n  @media (max-width: 720px) {\n    width: 190px;\n  }\n  @media (max-width: 640px) {\n    width: 165px;\n  }\n  @media (max-width: 350px) {\n    width: 140px;\n  }\n  @media (max-width: 300px) {\n    width: 120px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBox": "TemplateSkeleton_cardBox__wWKGQ"
};
export default ___CSS_LOADER_EXPORT___;
