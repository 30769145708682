/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useRef, useState } from 'react';

import s from './TemplateUpdate.module.scss';
import editImage from '../../assets/TemplateUpdate/editImage.svg';
import download from '../../assets/TemplateUpdate/download.svg';
import { ReactComponent as BackIcon } from '../../assets/TemplateUpdate/backIcon.svg';
import { ReactComponent as CancelIcon } from '../../assets/TemplateUpdate/cancelIcon.svg';
import { ReactComponent as NextIcon } from '../../assets/TemplateUpdate/nextIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/TemplateUpdate/settingsIcon.svg';
import { ReactComponent as AssistantIcon } from '../../assets/TemplateUpdate/assistantIcon.svg';
import { ReactComponent as SaveIcon } from '../../assets/TemplateUpdate/saveIcon.svg';
import { ReactComponent as ReviewIcon } from '../../assets/TemplateUpdate/reviewIcon.svg';
import { ReactComponent as OtherIcon } from '../../assets/TemplateUpdate/otherIcon.svg';

import Button from '../ui/Button/Button';
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderContext } from '../../utils/HeaderContext';
import { useDispatch, useSelector } from 'react-redux';

import PopApTariffPayment from '../PopApTariffPayment/PopApTariffPayment';

import $api from '../../http';
import {
  historyForward,
  historyPop,
  historyPush,
  historyPushImg,
  histoyPopImg,
} from '../../app/features/editTemplate';
import { ChangeTextAction } from './ChangeTextAction';
import { createBtn } from './changeImage';
import { createDiv } from './changeText/changeText.js';
import ChangeMeta from './ChangeMeta/ChangeMeta';

import {
  editDescription,
  editKeywords,
  editTitle,
} from '../../app/features/editMeta';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { changeSocialBlock } from './changeSocial/changeSocial';

function TemplateUpdate() {
  const [visiblityPayment, setVisiblityPayment] = useState(false);
  const [visiblity, setVisiblity] = useState(false);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }
  function openModal() {
    if (localStorage.token) {
      setVisiblityPayment(!visiblityPayment);
    } else {
      setVisiblity(!visiblity);
      disabledScroll();
    }
  }

  function closeModal(e) {
    setVisiblityPayment(e);
    setVisiblity(e);
  }

  const { setIsHeader } = useContext(HeaderContext);
  const { templateId } = useParams();
  const [activeOther, setActiveOther] = useState(false);

  const lang = useSelector((state) => state.lang.language);

  const dispatch = useDispatch();

  const history = useSelector((state) => state);

  let navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [modal, setModal] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setIsHeader(false);
    const getStatus = async () => {
      let status = await $api.get(`/client/user/templates/${templateId}`);
      setStatus(status.data.template.status);
    };

    getStatus();

    return () => {
      setIsHeader(true);
    };
  }, []);

  //ALL FOR EDIT TEMPLATE
  let current = null;
  let prev = null;

  const createInput = (res) => {
    const input = document.createElement('input');
    res.appendChild(input);
    input.setAttribute('class', 'inputEdit');
    input.value = current.textContent;
    input.style.cssText = `
    position: absolute;
    z-index: 10;
    top: 0;
    padding-bottom: 10px;
    `;
    return input;
  };

  const [iframeMeta, setIframeMeta] = useState('');

  const title = useSelector((state) => state.title.title);

  const disabledRef = useRef(false);
  // const [saveMeta, setSaveMeta] = useState(false);
  // const handleTitle = () => {
  //   setSaveMeta(!saveMeta);
  //   console.log(saveMeta);
  // };
  const [switche, setSwitch] = useState(true);

  function openSignIn() {
    setSwitch(!switche);
  }

  useEffect(() => {
    let iframe = document.getElementById('iframe');
    iframe.addEventListener('load', () => {
      iframe.contentDocument.body.getElementsByTagName(
        'header'
      )[0].style.marginTop = `50px`;

      //Добавление link Для cropper

      let link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute(
        'integrity',
        'sha512-+VDbDxc9zesADd49pfvz7CgsOl2xREI/7gnzcdyA9XjuTxLXrdpuz21VVIqc5HPfZji2CypSbxx1lgD7BgBK5g=='
      );
      link.setAttribute(
        'href',
        'https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.css'
      );
      link.setAttribute('crossorigin', 'anonymous');
      link.setAttribute('referrerpolicy', 'no-referrer');
      iframe.contentDocument.head.appendChild(link);

      //Добавление script для cropper

      let script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.js'
      );
      script.setAttribute(
        'integrity',
        'sha512-ooSWpxJsiXe6t4+PPjCgYmVfr1NS5QXJACcR/FPpsdm6kqG1FmQ2SVyg2RXeVuCRBLr0lWHnWJP6Zs1Efvxzww=='
      );
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('referrerpolicy', 'no-referrer');

      iframe.contentDocument.body.appendChild(script);

      setIframeMeta(iframe.contentDocument);
      dispatch(editTitle(iframe.contentDocument.title));
      dispatch(
        editDescription(
          iframe.contentDocument.getElementsByTagName('meta')[0].content
        )
      );
      dispatch(
        editKeywords(
          iframe.contentDocument.getElementsByTagName('meta')[1].content
        )
      );
      let arrContent = iframe.contentDocument.querySelectorAll(
        '[data-type="string"]'
      );
      for (let i = 0; i < arrContent.length; i++) {
        arrContent[i].setAttribute('contenteditable', true);
      }
      const listener = (e) => {
        if (disabledRef.current) return;
        // console.log(e.target);
        const currentHovered = iframe.contentDocument.querySelector(
          '[data-editable-container="true"]:hover'
        );

        const prevHovered = iframe.contentDocument.querySelector(
          '[data-hovered="true"]'
        );

        //edit title

        if (prevHovered && prevHovered !== currentHovered) {
          prevHovered.dataset.hovered = null;
          prevHovered.style.outline = 'none';
          // saveTemplate();

          const {
            fontSize,
            color,
            fontFamily,
            fontWeight,
            textDecoration,
            fontStyle,
            textAlign,
          } = window.getComputedStyle(prevHovered.childNodes[1]);

          prev = prevHovered.childNodes[1];
          // CHECK MATCH

          if (
            prevHovered.childNodes[1].getAttribute('data-type') === 'string'
          ) {
            dispatch(
              historyPush({
                el: new ChangeTextAction(
                  prev,
                  prev.textContent,
                  current.textContent,
                  [
                    fontFamily,
                    fontSize,
                    // color,
                    fontStyle,
                    // textDecoration,
                    fontWeight,
                    textAlign,
                  ]
                ),
                cb: (state, action) => {
                  console.log(state);
                  // if (
                  //   action.payload.el.currentText.toLowerCase() ===
                  //   state.history[
                  //     state.history.length - 1
                  //   ]?.currentText?.toLowerCase()
                  // ) {
                  //   console.log('dont push wat');
                  //   console.log(
                  //     'action',
                  //     action.payload.el.currentText.toLowerCase()
                  //   );
                  //   console.log(
                  //     'history',
                  //     state.history[
                  //       state.history.length - 1
                  //     ]?.currentText?.toLowerCase()
                  //   );
                  //   state.history.pop();
                  //   state.cursor--;
                  // }
                },
                focus: false,
              })
            );
          }

          if (
            prevHovered.childNodes[1].getAttribute('data-type') === 'link' &&
            !prevHovered.childNodes[1].hasAttribute('data-soc')
          ) {
            prevHovered.childNodes[1].textContent =
              prevHovered.querySelector('.inputEdit')?.value;
            dispatch(
              historyPush({
                el: new ChangeTextAction(
                  prev,
                  prevHovered.querySelector('.inputEdit')?.value,
                  prevHovered.querySelector('.inputEdit')?.value,
                  [
                    fontFamily,
                    fontSize,
                    // color,
                    fontStyle,
                    // textDecoration,
                    fontWeight,
                    textAlign,
                  ]
                ),
                // focus: true,
                cb: (state, action) => {
                  console.log(state);
                },
              })
            );
          }

          prevHovered.querySelector('.textEdit')?.remove();
          prevHovered.querySelector('.inputEdit')?.remove();
          prevHovered.querySelector('.btnImage')?.remove();

          // if (prev?.getAttribute('data-type') === 'img') {
          // }
          prev = null;
        }

        if (currentHovered && currentHovered?.dataset.hovered !== 'true') {
          const {
            fontSize,
            lineHeight,
            color,
            fontFamily,
            textShadow,
            textTransform,
            fontWeight,
            textDecoration,
            fontStyle,
            textAlign,
            marginTop,
          } = window.getComputedStyle(currentHovered.childNodes[1]);
          current = currentHovered.childNodes[1];

          currentHovered.dataset.hovered = 'true';
          currentHovered.style.outline = '1px solid #666';

          if (
            // current.getAttribute('data-type') === 'link' ||
            current.getAttribute('data-type') === 'string'
          ) {
            currentHovered.appendChild(createDiv(currentHovered.childNodes[1]));
            console.log(currentHovered, history);

            dispatch(
              historyPush({
                el: new ChangeTextAction(
                  current,
                  current.textContent,
                  current.textContent,
                  [
                    fontFamily,
                    fontSize,
                    // color,
                    fontStyle,
                    // textDecoration,
                    fontWeight,
                    textAlign,
                  ]
                ),
                cb: (state, action) => {
                  console.log(state);
                  if (
                    action.payload.el.currentText.toLowerCase() ===
                    state.history[
                      state.history.length - 1
                    ]?.currentText?.toLowerCase()
                  ) {
                    console.log('dont push text');
                    state.history.pop();
                    state.cursor--;
                  } else if (
                    JSON.stringify(action.payload.el.prevStyle) ===
                    JSON.stringify(
                      state.history[state.history.length - 1]?.prevStyle
                    )
                  ) {
                    console.log('dont push style');
                    state.history.pop();
                    state.cursor--;
                  }
                },
                focus: true,
              })
            );
          }

          if (
            current.getAttribute('data-type') === 'link' &&
            !current.hasAttribute('data-soc')
          ) {
            currentHovered.appendChild(createDiv(currentHovered.childNodes[1]));
            createInput(currentHovered);
            dispatch(
              historyPush({
                el: new ChangeTextAction(
                  current,
                  currentHovered.querySelector('.inputEdit')?.value,
                  currentHovered.querySelector('.inputEdit')?.value,
                  [fontFamily, fontSize, fontStyle, fontWeight, textAlign]
                ),
                focus: true,
                cb: (state, action) => {
                  console.log(state);
                },
              })
            );
          }

          if (
            current.getAttribute('data-type') === 'link' &&
            current.hasAttribute('data-soc')
          ) {
            currentHovered.appendChild(
              changeSocialBlock(
                currentHovered.childNodes[1],
                iframe.contentDocument,
                disabledRef
              )
            );
            // createInput(currentHovered);
            //   dispatch(
            //     historyPush({
            //       el: new ChangeTextAction(
            //         current,
            //         currentHovered.querySelector('.inputEdit')?.value,
            //         currentHovered.querySelector('.inputEdit')?.value,
            //         [
            //           fontFamily,
            //           fontSize,
            //           fontStyle,
            //           fontWeight,
            //           textAlign,
            //         ]
            //       ),
            //       focus: true,
            //       cb: (state, action) => {
            //         console.log(state);
            //       },
            //     })
            //   );
          }
          if (current.getAttribute('data-type') === 'img') {
            createBtn(
              iframe.contentDocument.body,
              currentHovered,
              templateId,
              lang,
              editImage,
              download,
              disabledRef,
              // listener,
              dispatch,
              historyPushImg,
              currentHovered.childNodes[1].height,
              histoyPopImg
            );
          }
        }
      };

      iframe.contentDocument.addEventListener('mousemove', listener);
      iframe.contentDocument.addEventListener('mouseout', listener);

      return () =>
        iframe.contentDocument.removeEventListener('mousemove', listener);
    });
  }, [templateId, title, history, disabledRef]);

  useEffect(() => {
    const undo = document.querySelector('#undo');
    const redo = document.querySelector('#redo');

    undo.addEventListener('click', () => {
      dispatch(historyPop());
    });

    redo.addEventListener('click', () => {
      dispatch(historyForward());
    });
  }, []);

  const saveTemplate = async () => {
    let iframe = document.getElementById('iframe');
    try {
      await $api.patch(`/client/edit/template/${templateId}`, {
        source: iframe.contentWindow.document.children[0].innerHTML,
        path: iframe.contentWindow.document.location.pathname.split('/').pop(),
      });
      console.log('save');
    } catch (err) {
      console.log(err);
    }
  };

  const [metaVisible, setMetaVisible] = useState(false);

  const handleMetaVisible = () => {
    setMetaVisible(!metaVisible);
  };

  return (
    <section>
      {modal}
      <div className={s.container}>
        <div className={s.box1}>
          <div className={s.backBox} onClick={() => navigate(-1)}>
            <BackIcon className={s.arrowBack} src={BackIcon} alt="" />
            {/* <span className={s.back}>{lang === `ru` ? `Назад` : `Back`}</span> */}
          </div>
          <div className={s.arrowBox}>
            <CancelIcon
              className={s.cancelIcon}
              src={CancelIcon}
              alt="Cancel"
              id="undo"
            />
            <NextIcon
              className={s.nextIcon}
              src={NextIcon}
              alt="Next"
              id="redo"
            />
          </div>
          <SettingsIcon
            className={s.settingsIcon}
            src={SettingsIcon}
            alt="Settings"
            onClick={() => {
              handleMetaVisible();
              disabledRef.current = true;
            }}
          />
          <AssistantIcon
            className={s.assistantIcon}
            src={AssistantIcon}
            // onClick={setEdit}
            alt="Assistant"
          />
        </div>
        <div
          onClick={() => setActiveOther(!activeOther)}
          className={s.otherBox}
        >
          <OtherIcon className={s.otherIcon} src={OtherIcon} alt="" />
          {activeOther && (
            <div className={s.dropDownBox}>
              <span className={s.look}>
                {lang === `ru` ? `Посмотреть` : `Look`}
              </span>
              <span className={s.cancel}>
                {lang === `ru` ? `Отменить` : `Cancel`}
              </span>
              <span className={s.repeat}>
                {lang === `ru` ? `Повторить` : `Repeat`}
              </span>
              <span className={s.save}>
                {lang === `ru` ? `Сохранить` : `Save`}
              </span>
              <span className={s.settings}>
                {lang === `ru` ? `Настройки` : `Option`}
              </span>
            </div>
          )}
        </div>
        <div className={s.box2}>
          <SaveIcon
            className={s.saveIcon}
            src={SaveIcon}
            alt="Save"
            onClick={() => saveTemplate()}
          />
          <ReviewIcon
            className={s.reviewIcon}
            src={ReviewIcon}
            alt="ReviewIcon"
            onClick={async () => {
              await saveTemplate();
              navigate(`/robolook/${templateId}`);
            }}
          />
          {status.toLowerCase() === 'payed' ? (
            ''
          ) : (
            <Button
              className={s.btnPublish}
              onClick={() => {
                saveTemplate();
                openModal();
              }}
            >
              {lang === `ru` ? `Опубликовать` : `Publish`}
            </Button>
          )}
          <span className={s.launguage}>RU</span>
        </div>
      </div>
      <div style={{ display: 'flex', height: '91vh' }}>
        <iframe
          id="iframe"
          className={s.iframe}
          src={`../../templates/${templateId}/index.html`}
          style={{
            width: '100%',
            height: '100%',
            frameborder: '0',
            flexGrow: '1',
          }}
        ></iframe>
      </div>
      <PopApTariffPayment
        show={visiblityPayment}
        closeModal={closeModal}
        // openModal={openModal}
      />
      <ChangeMeta
        iframe={iframeMeta}
        metaVisible={metaVisible}
        setMetaVisible={setMetaVisible}
        disabledRef={disabledRef}
        // handleTitle
      />
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default TemplateUpdate;
