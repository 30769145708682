import React from 'react';

import s from './AboutMain.module.scss';

import logoGroup from '../../assets/iconAboutMain/comp.svg';
import logoIllustration from '../../assets/iconAboutMain/ilustration.svg';
import logoRobot from '../../assets/iconAboutMain/robot.svg';
import logoRobotHand from '../../assets/iconAboutMain/robotHand.svg';
import logoKeyboard from '../../assets/iconAboutMain/keyboard.svg';
import logoKeyboardHand from '../../assets/iconAboutMain/keyboardHand.svg';
import logoRocket from '../../assets/iconAboutMain/rocket.svg';
import logoSeo from '../../assets/iconAboutMain/seo.svg';
import logoSeoLoupe from '../../assets/iconAboutMain/loupe.svg';
import logoScreen from '../../assets/iconAboutMain/screen.svg';
import logoHand from '../../assets/iconAboutMain/hand.svg';
import logoPuzzleLeftTop from '../../assets/iconAboutMain/puzzleLeftTop.svg';
import logoPuzzleRightTop from '../../assets/iconAboutMain/puzzleRightTop.svg';
import logoPuzzleLeftBottom from '../../assets/iconAboutMain/puzzleLeftBottom.svg';
import logoPuzzleRightBottom from '../../assets/iconAboutMain/puzzleRightBottom.svg';
import logoFlame from '../../assets/iconAboutMain/flame.svg';
import logoFlameSmall from '../../assets/iconAboutMain/flameSmall.svg';
import { useSelector } from 'react-redux';

const item = [
  {
    id: 1,
    img: logoGroup,
    imgGroup: logoScreen,
    className: s.imgGroup,
    title: 'Шаблоны сайтов',
    item: 'Если вам нужен конкурентоспособный сайт для развития своего бизнеса, но вы не желаете тратить много времени на разработку сайта, то веб-маркетплейс RoboWeb придёт вам на помощь. Каждый клиент может выбрать из множества вариантов готовых сайтов и отредактировать их под себя.',
    titleEn: 'Website templates',
    itemEn: `If you need a competitive website to develop your business, but you don't want to spend a lot of time developing a website, then RoboWeb web-marketplace comes to your aid. Each customer can choose from a variety of ready-made websites and edit them to suit their needs.`,
  },
  {
    id: 2,
    img: logoIllustration,
    imgGroup: logoHand,
    className: s.imgHand,
    title: 'Без профессионалов',
    item: 'При разработке сайта участвует сразу несколько специалистов: дизайнер, программист, маркетолог, копирайтер. В создании готовых сайтов участвовала целая команда профессионалов, которая создала разные варианты под каждый бизнес.',
    titleEn: 'Without professionals',
    itemEn: `When developing the site, several professionals are involved at once: a designer, a programmer, a marketer and a copywriter. A whole team of professionals participated in the creation of the ready-made sites, which created different options for each business.`,
  },
  {
    id: 3,
    imgLeftTop: logoPuzzleLeftTop,
    imgRightTop: logoPuzzleRightTop,
    imgLeftBottom: logoPuzzleLeftBottom,
    imgRightBottom: logoPuzzleRightBottom,
    title: 'Готовый контент',
    item: 'Наполнение сайта нередко вызывает множество затруднений. Что написать и какие изображения поставить, чтобы клиентам нравился сайт? Сомневаетесь? Тогда мы вам поможем! Команда профессионалов подготовила готовый контент, который можно отредактировать под свой сайт.',
    titleEn: 'Ready-made content',
    itemEn: `Filling a website often causes a lot of difficulties. What to write and what images to put to make customers like the site? Doubting? Then we can help you! A team of professionals has prepared a ready-made content, which can be edited to fit your site.`,
  },
  {
    id: 4,
    logoKeyboard,
    logoKeyboardHand,
    title: 'Интуитивный интерфейс',
    item: 'Переживаете, что не сможете самостоятельно разобраться с функционалом сайта? Тогда веб-маркетплейс вам поможет! Интерфейс RoboWeb продуман до мелочей. Вам будет максимально комфортно редактировать готовые сайты и работать в дальнейшем.',
    titleEn: 'Intuitive interface',
    itemEn:
      'Are you worried that you can not handle the functionality of the site by yourself? Then web-marketplace will help you! RoboWeb has an intuitive interface. You will have maximum comfort to edit ready sites and work in the future.',
  },
  {
    id: 5,
    logoRocket,
    logoFlame,
    logoFlameSmall,
    title: 'Скорость',
    item: 'Как и все наши клиенты, мы ценим время и качество. Именно поэтому мы уже сделали всё, чтобы вы могли оформить собственный сайт всего за 15 минут! Ни один фрилансер, веб-мастер и конструктор сайтов не сделает вам качественный сайт за это время.',
    titleEn: 'Speed',
    itemEn:
      'Like all our clients, we appreciate time and quality. That is why we have already done everything so that you can design your own website in just 15 minutes! Not a single freelancer, webmaster, or website builder will make you a quality website in that time.',
  },
  {
    id: 6,
    img: logoSeo,
    imgGroupe: logoSeoLoupe,
    className: s.seo,
    classNameGroupe: s.seoLoupe,
    classBoximg: s.boxSeo,
    title: 'SEO оптимизация',
    item: 'Каждому бизнесу нужны новые клиенты. И мы знаем, что без грамотного продвижения сайта здесь не обойтись. SEO оптимизация позволит сайту выйти на первые страницы в поисковых запросах. При работе с готовыми сайтами предусмотрены все необходимые SEO настройки.',
    titleEn: 'SEO optimization',
    itemEn: `Every business needs new customers. And we know that without a competent promotion of the site can't do without it. SEO optimization will allow the site to reach the first pages in search queries. When working with ready-made sites all the necessary SEO settings are provided.`,
  },
  {
    id: 7,
    img: logoRobot,
    imgGroupe: logoRobotHand,
    className: s.robot,
    classNameGroupe: s.robotHand,
    classBoximg: s.boxHand,
    title: 'Личный онлайн помошник',
    item: 'Мы ценим наших клиентов и хотим помочь вам создать качественный сайт. При возникновении вопросов вы всегда сможете обратиться за квалифицированной помощью к онлайн-помощнику, который быстро ответит на все интересующие вас вопросы.',
    titleEn: 'Personal online assistant',
    itemEn:
      'We appreciate our customers and want to help you create a high-quality website. If you have any questions, you can always ask for qualified help from our online assistant, who will quickly answer all your questions.',
  },
];

function AboutMain() {
  const lang = useSelector((state) => state.lang.language);
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru'
              ? 'Всё готово и придумано за Вас!'
              : `Everything is ready and designed for You!`}
          </h2>
          <ul className={s.list}>
            {item.slice(0, 2).map((el) => (
              <li key={el.id} className={s.itemBox}>
                <div className={s.imageBox}>
                  <img className={s.icon} src={el.img} alt="Icon" />
                  <img className={el.className} src={el.imgGroup} alt="Group" />
                </div>
                <h3 className={s.title}>
                  {lang === 'ru' ? el.title : el.titleEn}
                </h3>
                <p className={s.item}>{lang === 'ru' ? el.item : el.itemEn}</p>
              </li>
            ))}
          </ul>
          <ul className={s.list1}>
            {item.slice(2, 5).map((el) => (
              <li key={el.id} className={s.itemBox1}>
                <div className={s.imageBox1}>
                  {!!el?.imgLeftTop && (
                    <img
                      className={s.imgLeftTop}
                      src={el?.imgLeftTop}
                      alt="icon"
                    />
                  )}
                  {!!el?.imgRightTop && (
                    <img
                      className={s.imgRightTop}
                      src={el?.imgRightTop}
                      alt="icon"
                    />
                  )}
                  {!!el?.imgLeftBottom && (
                    <img
                      className={s.imgLeftBottom}
                      src={el?.imgLeftBottom}
                      alt="icon"
                    />
                  )}
                  {!!el?.imgRightBottom && (
                    <img
                      className={s.imgRightBottom}
                      src={el?.imgRightBottom}
                      alt="icon"
                    />
                  )}

                  {!!el?.logoKeyboard && (
                    <img
                      className={s.keyboard}
                      src={el?.logoKeyboard}
                      alt="icon"
                    />
                  )}
                  {!!el?.logoKeyboardHand && (
                    <img
                      className={s.keyboardHand}
                      src={el?.logoKeyboardHand}
                      alt="icon"
                    />
                  )}
                  <div className={s.rocketBox}>
                    {!!el?.logoRocket && (
                      <img
                        className={s.rocket}
                        src={el?.logoRocket}
                        alt="icon"
                      />
                    )}
                    {!!el?.logoFlameSmall && (
                      <img
                        className={s.flameSmall}
                        src={el?.logoFlameSmall}
                        alt="icon"
                      />
                    )}
                    {!!el?.logoFlame && (
                      <img className={s.flame} src={el?.logoFlame} alt="icon" />
                    )}
                  </div>
                </div>
                <h3 className={s.title}>
                  {lang === 'ru' ? el.title : el.titleEn}
                </h3>
                <p className={s.item}>{lang === 'ru' ? el.item : el.itemEn}</p>
              </li>
            ))}
          </ul>
          <ul className={s.list2}>
            {item.slice(5).map((el) => (
              <li key={el.id} className={s.itemBox2}>
                <div className={s.imageBox2}>
                  <img className={el.className} src={el.img} alt="icon" />
                  {/* <div className={el.classBoximg}> */}
                  <img
                    className={el.classNameGroupe}
                    src={el.imgGroupe}
                    alt="icon"
                  />
                  {/* </div> */}
                </div>
                <h3 className={s.title}>
                  {lang === 'ru' ? el.title : el.titleEn}
                </h3>
                <p className={s.item}>{lang === 'ru' ? el.item : el.itemEn}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AboutMain;
