import React, { useEffect, useState } from 'react';

import s from './ProfilePartners.module.scss';
// import avatar from '../../assets/topTenPartnersIcon/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';

import PartnersPersonalDataBlock from '../PartnersPersonalDataBlock/PartnersPersonalDataBlock';
import PasswordChangeBlock from '../PasswordChangeBlock/PasswordChangeBlock';
import PartnersTheDocumentsBlock from '../PartnersTheDocumentsBlock/PartnersTheDocumentsBlock';
import { useSelector } from 'react-redux';
import $api from '../../http';
import WithdrawMoney from '../WithdrawMoney/WithdrawMoney';

import axios from 'axios';
import { BASE_URL } from '../..';

export default function ProfilePartners() {
  const [active, setActive] = useState(0);
  const [avatar, setAvatar] = useState('');
  const [userInfo, setUserInfo] = useState({});


  const lang = useSelector((state) => state.lang.language);

  // const [selected, setSelected] = useState('Сайт-визитка');
  const linkNames = [
    { ru: 'Личные данные', en: 'Personal data', id: 0 },
    { ru: 'Смена пароля', en: 'Password change', id: 1 },
    { ru: 'Операции', en: 'Operations', id: 2 },
    { ru: 'Выписка', en: 'Extract', id: 3 },
    { ru: 'Документы', en: 'The documents', id: 4 },
  ];

  const [infoPartner, setInfoPartner] = useState('');
  const [visibleButton, setVisibleButton] = useState(false);
  const config = {
    headers: { 'x-access-token': `${localStorage.getItem('token')}` },
  };

  const getMyInfo = async () => {
    let info = await $api(`/client/user/me`);
    setInfoPartner(info.data.user);
  };

  useEffect(() => {
    getMyInfo();
  }, [userInfo]);

  //ИЗМЕНЕНИЕ АВАТАРА

  const OnSumbit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    formData.append('files', avatar);
    console.log(formData);
    axios
      .patch(`${BASE_URL}/client/user/avatar`, formData, config)
      .then((res) => {
        console.log(`Success` + res);
        getMyInfo();
        setVisibleButton(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>{lang === 'ru' ? `Профиль` : `Profile`}</h2>
          <div className={s.profilePartnersBox}>
            <div className={s.infoBox}>
              <form onSubmit={(event) => OnSumbit(event)}>
                <div className={s.personal_image}>
                  <label className={s.label}>
                    <input
                      type="file"
                      // value={avatar}
                      onChange={(e) => setAvatar(e.target.files[0])}
                      onClick={() => setVisibleButton(true)}
                      accept=".png, .jpg, .jpeg"
                    />
                    <figure className={s.personal_figure}>
                      <img
                        src={
                          infoPartner['avatar']
                            ? `${BASE_URL}${infoPartner['avatar']}`
                            : avatarDefault
                        }
                        className={s.personal_avatar}
                        alt="avatar"
                      />
                      <figcaption className={s.personal_figcaption}>
                        <img
                          src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                          alt="avatar"
                        />
                      </figcaption>
                    </figure>
                  </label>
                </div>
                {visibleButton && avatar ? (
                  <button className={s.btnImageSave}>
                    {lang === 'ru' ? `Обновить` : `Refresh`}
                  </button>
                ) : (
                  ''
                )}
              </form>
              {/* <img className={s.profileImage} src={avatar} alt="Avatar" /> */}
              <div className={s.aboutBox}>
                <h3 className={s.partnersName}>{`${infoPartner.name} ${infoPartner.surname || ''}`} </h3>
                <p className={s.partnersJobTitle}>{lang === 'ru' ? 'Партнер' : 'Partner'}</p>
              </div>
            </div>
            <div className={s.userBalanceBox}>
              <h3 className={s.titleBalance}>
                {lang === 'ru' ? `Ваш баланс:` : `Your balance:`}
                <span className={s.userBalance}>
                  {new Intl.NumberFormat('ru-RU').format(infoPartner.balance)} ₽
                </span>
              </h3>
            </div>
            <div className={s.linkAboutContainer}>
              <ul className={s.linkAboutBox}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>

            {active === 0 && (
              <PartnersPersonalDataBlock infoPartner={infoPartner} avatar={avatar} setUserInfo={setUserInfo}/>
            )}
            {active === 1 && <PasswordChangeBlock />}
            {active === 2 && <WithdrawMoney />}
            {active === 4 && <PartnersTheDocumentsBlock />}
          </div>
        </div>
      </div>
    </section>
  );
}
