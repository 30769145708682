import React from 'react';
import Typed from 'typed.js';
import { useEffect, useRef } from 'react';

import s from './TechnicalSupportHeading.module.scss';

import circleBlue from '../../assets/TechnicalSupportHeading/circleBlue.png';
import circleGreen from '../../assets/TechnicalSupportHeading/circleGreen.png';
import gearBlue from '../../assets/TechnicalSupportHeading/gearBlue.svg';
import gearGreenLeft from '../../assets/TechnicalSupportHeading/gearGreenLeft.svg';
import gearGreenRight from '../../assets/TechnicalSupportHeading/gearGreenRight.svg';
import union from '../../assets/TechnicalSupportHeading/union.svg';
import robot from '../../assets/TechnicalSupportHeading/robot.svg';
import hand from '../../assets/TechnicalSupportHeading/hand.svg';
import eye from '../../assets/TechnicalSupportHeading/eye.svg';
import neiroRight from '../../assets/TechnicalSupportHeading/neiroRight.svg';
import neiroMiddleRight from '../../assets/TechnicalSupportHeading/neiroMiddleRight.svg';
import neiroLeft from '../../assets/TechnicalSupportHeading/neiroLeft.svg';
import neiroMiddleLeft from '../../assets/TechnicalSupportHeading/neiroMiddleLeft.svg';
import logoWave from '../../assets/img/wave.svg';
import { useSelector } from 'react-redux';

function TechnicalSupportHeading() {
  const el = useRef(null);

  const lang = useSelector((state) => state.lang.language);

  useEffect(() => {
    window.scrollTo(0, 0);
    const typed = new Typed(el.current, {
      strings: [
        lang === 'ru'
          ? 'Я всегда здесь, чтобы помочь вам'
          : `I'm always here to help you`,
      ],
      startDelay: 200,
      typeSpeed: 10,
      showCursor: false,
    });
  });
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.technicalSupportBox}>
            <div className={s.aboutBox}>
              <h2 className={s.title} ref={el}></h2>
              <img className={s.union} src={union} alt="" />
            </div>
            <h2 className={s.titleMobile}>
              {lang === 'ru'
                ? 'Я всегда здесь, чтобы помочь вам'
                : `I'm always here to help you`}
            </h2>
            <div className={s.imageBox}>
              <img className={s.robot} src={robot} alt="" />
              <img className={s.hand} src={hand} alt="" />
              <img className={s.eye} src={eye} alt="" />
            </div>
          </div>
          <img className={s.neiroRight} src={neiroRight} alt="Neiro" />
          <img
            className={s.neiroMiddleRight}
            src={neiroMiddleRight}
            alt="Neiro"
          />
          <img
            className={s.neiroMiddleLeft}
            src={neiroMiddleLeft}
            alt="Neiro"
          />
          <img className={s.neiroLeft} src={neiroLeft} alt="Neiro" />
          <img className={s.circleBlue} src={circleBlue} alt="Circle blue" />
          <img className={s.circleGreen} src={circleGreen} alt="Circle green" />
          <img
            className={s.gearGreenRight}
            src={gearGreenRight}
            alt="Gear green"
          />
          <img className={s.gearBlue} src={gearBlue} alt="Gear blue" />
          <img
            className={s.gearGreenLeft}
            src={gearGreenLeft}
            alt="Gear green"
          />
          <img className={s.logoWave} src={logoWave} alt="Волна" />
        </div>
      </div>
    </section>
  );
}

export default TechnicalSupportHeading;
