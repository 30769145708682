export const mediaBreakpoints = {
    xxxs: 350,
    xxs: 450,
    xs: 600,
    sm: 768,
    md: 979,
    lg: 1025,
    xl: 1200,
    xxl: 1300,
    xxxl: 1455,
  };