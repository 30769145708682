import React from 'react';
import AboutMain from '../../components/AboutMain/AboutMain';
import AdditionalOffers from '../../components/AdditionalOffers/AdditionalOffers';
import Benefits from '../../components/Benefits/Benefits';
import CompareYourSelf from '../../components/CompareYourSelf/CompareYourSelf';
import DigitBlock from '../../components/DigitBlock/DigitBlock';
import FAQ from '../../components/FAQ/FAQ';
import Heading from '../../components/Heading/Heading';
import HowToStartBlockMain from '../../components/HowToStartBlockMain/HowToStartBlockMain';
import PortfolioBlock from '../../components/PortfolioBlock/PortfolioBlock';
import PriceBlock from '../../components/PriceBlock/PriceBlock';
import ReviewsBlockMain from '../../components/ReviewsBlockMain/ReviewsBlockMain';
import TemplatesPurpose from '../../components/TemplatesPurpose/TemplatesPurpose';
import UseForFree from '../../components/UseForFree/UseForFree';

export default function Main() {
  return (
    <>
      <Heading />
      <AboutMain />
      <Benefits />
      <DigitBlock />
      <TemplatesPurpose />
      <PriceBlock />
      <PortfolioBlock />
      <ReviewsBlockMain />
      <CompareYourSelf />
      <HowToStartBlockMain />
      <AdditionalOffers />
      <FAQ />
      <UseForFree />
    </>
  );
}
