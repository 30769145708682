import axios from 'axios';
import $api from '../../http';
import { ChangeImgAction } from './ChangeImgAction';
import { cropperEdit } from './CropperEditor';
import close from '../../assets/TemplateUpdate/closeImg.svg';

let arrImage = [];

let arrUnsplashImage = [];

let page = 1;

const getImage = async (templateId) => {
  let imageList = await $api.get(
    `/client/edit/images?templateId=${templateId}`
  );
  imageList.data.imageList.map((item) => {
    arrImage.push(item);
  });
};

const getUnsplashImage = async (query, num) => {
  let imageList = await axios.get(
    `https://api.unsplash.com/search/collections?client_id=lwQAsZjOvPkGepXZtjseqeyHT9XzXaR_C6AsPLhiBv4&query=car&page=${num}&per_parge=10`
  );
  imageList.data.results.map((img) =>
    arrUnsplashImage.push(img.cover_photo.urls.regular)
  );
};

getUnsplashImage('', page);

const nextImage = async () => {
  page = page + 1;
  getUnsplashImage('', page);
};

const divImage = async (
  res,
  templateId,
  current,
  lang,
  disabledRef,
  // listener,
  dispatch,
  historyPush,
  heightImg,
  histoyPopImg
) => {
  const div = document.createElement('div');
  div.setAttribute('data-editable-container', 'true');
  // div.setAttribute('data-hovered', 'true');
  res.appendChild(div);

  const divImg = document.createElement('div');
  const divBtnClose = document.createElement('div');
  const btnClose = document.createElement('img');

  divBtnClose.style.cssText = `
width: 100%;
text-align: end;
`;

  btnClose.src = close;

  btnClose.style.cssText = `
    width: 24px;
    height: 24px;
    margin: 22px;
    cursor: pointer;
`;

  divBtnClose.appendChild(btnClose);
  div.appendChild(divImg);
  div.insertBefore(divBtnClose, divImg);
  btnClose.onclick = function () {
    // dispatch(histoyPopImg());
    div.remove();
    disabledRef.current = false;
  };
  div.setAttribute('class', 'editImage');
  if (window.innerWidth > 1000) {
    div.style.cssText = `
    height: 80%;
    width: 80%;
    position: fixed;
    top: 7%;
    left: 10%;
    background-color: white;
    z-index: 11;
    overflow-y: auto;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    `;
    divImg.style.cssText = `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    padding: 10px;
    gap: 10px;
    `;
  }
  if (window.innerWidth < 1000) {
    div.style.cssText = `
    width: 75%;
    height: 75%;
    position: fixed;
    top: 15%;
    left: 15%;
    background-color: white;
    z-index: 11;
    overflow-y: auto;
    border-radius: 15px;

    `;
    divImg.style.cssText = `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 10px;
    gap: 10px;
    `;
  }

  const btn = document.createElement('button');
  div.appendChild(btn);
  btn.style.cssText = `
  background: linear-gradient(178.7deg, #00C6FB 2.64%, #159AE6 36.84%, #005BEA 90.16%);
  width: 100px;
  height: 50px;
  padding: 5px;
  outline: none;
  font-family: 'Circe';
  font-weight: 700;
  font-size: 20px;
  line-height: 14px;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin: 10px auto;
  `;
  btn.textContent = lang === 'ru' ? 'Еще' : 'More';

  // await getImage(templateId);

  btn.onclick = function () {
    nextImage();
    createImg(
      div,
      current,
      disabledRef,
      // listener,
      dispatch,
      historyPush,
      heightImg,
      divImg
    );
  };

  //Проход и добавление картинок внутри шаблона + сюда добавляются загруженные картинки с устройства

  arrImage.map((img) => {
    const image = document.createElement('img');
    image.setAttribute('src', `/templates/${templateId}/images/${img}`);
    image.style.cssText = `
        width: 100%;
        height: 190px;
        object-fit: cover;
        margin: 5px;
        border: 1px solid black;
        border-radius: 30px;
        `;
    image.addEventListener('click', () => {
      current.src = `images/${img}`;
      console.log(current);
      dispatch(
        historyPush({
          el: new ChangeImgAction(current.src, current),
          cb: (state, action) => {},
        })
      );
      disabledRef.current = false;

      current.style.aspectRatio = `${current.width} / ${heightImg}`;
      arrImage = [];
      div.remove();
      console.log('arrimg', arrImage);
      console.log('unsp', arrUnsplashImage);
    });
    divImg.appendChild(image);

    return image;
  });

  return div;
};

const createImg = (
  div,
  current,
  disabledRef,
  // listener,
  dispatch,
  historyPush,
  heightImg,
  divImg
) => {
  let count = arrUnsplashImage.length;
  arrUnsplashImage.slice(count - 10, count).map((img) => {
    const image = document.createElement('img');
    image.setAttribute('src', img);
    image.style.cssText = `
    width: 100%;
    height: 190px;
    object-fit: cover;
    margin: 5px;
    border: 1px solid black;
        `;
    image.addEventListener('click', () => {
      dispatch(
        historyPush({
          el: new ChangeImgAction(img, current),
          cb: (state, action) => {},
        })
      );
      current.style.aspectRatio = `${current.width} / ${heightImg}`;
      disabledRef.current = false;
      current.src = img;
      arrUnsplashImage = [];
      arrImage = [];
      div.remove();
      console.log('arrimg', arrImage);
      console.log('unsp', arrUnsplashImage);
    });
    divImg.appendChild(image);

    return image;
  });
};

export const createBtn = (
  res,
  current,
  templateId,
  lang,
  editImage,
  download,
  disabledRef,
  // listener,
  dispatch,
  historyPush,
  heightImg,
  histoyPopImg
) => {
  //Создание Div для кнопок взаимодействия с картинкой
  const div = document.createElement('div');
  current.appendChild(div);

  div.setAttribute('class', 'btnImage');
  div.style.cssText = `
  position: absolute;
  z-index: 10;
  top: -50px;
  left: 0;
  width: 160px;
  height: 50px;
  padding: 0;
  padding-bottom: 10px;
  background-size: 70%;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  `;

  //Создание кнопки для изменения изображения
  const btn = document.createElement('img');
  div.appendChild(btn);
  // current.appendChild(btn);
  btn.setAttribute('class', 'btnImage');
  btn.onclick = async function () {
    await getImage(templateId);
    disabledRef.current = true;
    divImage(
      res,
      templateId,
      current.childNodes[1],
      lang,
      disabledRef,
      // listener,
      dispatch,
      historyPush,
      heightImg,
      histoyPopImg
    );
    dispatch(
      historyPush({
        el: new ChangeImgAction(
          current.childNodes[1].src,
          current.childNodes[1]
        ),
        focus: true,
        cb: (state, action) => {},
      })
    );
    current.querySelector('.btnImage')?.remove();
  };
  btn.src = editImage;
  btn.style.cssText = `
  background-color: white;
  width: 50px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid grey;
  `;

  //Все для скачивания картинки

  const label = document.createElement('label');
  div.appendChild(label);
  label.setAttribute('class', 'btnImage');

  const btnDownload = document.createElement('img');
  const inputFile = document.createElement('input');
  inputFile.type = 'file';
  inputFile.setAttribute('id', 'inputFile');
  inputFile.style.cssText = `
  position: absolute;
  right: 0%;
  background-color: white;
  width: 50px;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  z-index: -1;
  `;

  const addImage = async (e) => {
    let newImg = e.target.files[0];
    const formData = new FormData();
    formData.append('files', newImg);

    try {
      await axios.patch(
        `https://back.roboweb.site/client/edit/images?templateId=${templateId}`,
        formData,
        {
          headers: { 'x-access-token': localStorage.getItem('token') },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  inputFile.setAttribute('class', 'btnImage');
  inputFile.onchange = function (e) {
    addImage(e);
  };
  label.appendChild(btnDownload);
  label.appendChild(inputFile);
  // current.appendChild(btnDownload);
  btnDownload.setAttribute('class', 'btnImage');
  btnDownload.onclick = function () {
    current.querySelector('.btnImage')?.remove();
  };
  btnDownload.src = download;
  btnDownload.style.cssText = `
  background-color: white;
  width: 50px;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid grey;
  z-index: 3;
  `;

  //Кнопка для cropper
  const btnCrop = document.createElement('img');
  btnCrop.src = editImage;
  btnCrop.style.cssText = `
  background-color: white;
  width: 50px;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid grey;
  z-index: 3;
  `;
  div.appendChild(btnCrop);
  btnCrop.onclick = function () {
    cropperEdit(
      res,
      current.childNodes[1],
      current,
      disabledRef,
      dispatch,
      historyPush
    );
    dispatch(
      historyPush({
        el: new ChangeImgAction(
          current.childNodes[1].src,
          current.childNodes[1]
        ),
        focus: true,
        cb: (state, action) => {},
      })
    );
    disabledRef.current = true;
  };
};
