import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import $api from '../../http';
// import { REACT_APP_CP_PUBLIC_ID } from '../../utils/constants';
import Button from '../ui/Button/Button';
import s from './WithdrawMoney.module.scss';
function WithdrawMoney() {
  const lang = useSelector((state) => state.lang.language);
  //   const [amount, setAmount] = useState('');

  //   //CloudPayments

  //   const pay = (id) => {
  //     let payments = new window.cp.Widget({
  //       language: 'ru-RU',
  //       email: '',
  //       applePaySupport: false,
  //       googlePaySupport: false,
  //       yandexPaySupport: true,
  //       tinkoffInstallmentSupport: false,
  //     });
  //     payments
  //       .pay('charge', {
  //         publicId: REACT_APP_CP_PUBLIC_ID,
  //         description: 'Тестовая оплата',
  //         amount: +amount,
  //         currency: 'RUB',
  //         invoiceId: id,
  //         // accountId: '123',
  //         // email: '',
  //         skin: 'classic',
  //         requireEmail: false,
  //       })
  //       .then(function (widgetResult) {
  //         console.log('result', widgetResult);
  //       })
  //       .catch(function (error) {
  //         console.log('error', error);
  //       });
  //   };

  //Получение скрипта для CloudPayments
  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
  //     document.head.appendChild(script);
  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }, []);

  //   //Пополнение баланса
  //   const sendAmount = async (e) => {
  //     try {
  //       const res = await $api.post(`/client/bill`, {
  //         amount: +amount,
  //         type: 'REPLENISHMENT',
  //       });
  //       pay(res.data.id);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      <form>
        <label className={s.inputLabel} htmlFor="">
          {lang === 'ru' ? 'Снять баланс' : 'Withdraw money'}
        </label>
        <div className={s.inputBox}>
          <span className={s.about}>{lang === 'ru' ? 'Введите сумму' : 'Enter amount'}</span>
          <input
            className={s.inputMany}
            // value={amount}
            // onChange={(e) => setAmount(e.target.value)}
            type="number"
          />
        </div>
        <Button className={s.btnSubmitMany}>{lang === 'ru' ? 'Снять' : 'Take off'}</Button>
      </form>
    </>
  );
}

export default WithdrawMoney;
