import React, { useEffect, useState } from 'react';

import s from './ExtractBlock.module.scss';
import dayjs from 'dayjs';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';
import infoExtract from '../../assets/img/infoExtract.svg';
import iconBlocked from '../../assets/img/iconBlocked.svg';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

const headerTable = [
  {
    ru: '№',
    en: '№',
  },
  {
    ru: 'Дата',
    en: 'Date',
  },
  {
    ru: 'Товар',
    en: 'Product',
  },
  {
    ru: 'Тип',
    en: 'Type',
  },
  {
    ru: 'Цена',
    en: 'Price',
  },
];

function ExtractBlock() {
  const LIMIT = 2;
  const lang = useSelector((state) => state.lang.language);

  // const [data, setData] = useState({});
  const [current, setCurrent] = useState(1);
  // const [value, setValue] = useState(1);
  const [pages, setPages] = useState(1);
  const [extract, setExtract] = useState([]);
  // const [offset, setOffset] = useState(0);
  const [render, setRender] = useState(false);
  const [templateTypeId, setTemplateTypeId] = useState('');

  //   useEffect(() => {
  //     getPartners(current);
  //   }, [current]);

  //   function adminTableFormat(data) {
  //     return data.map((el) => {
  //       return {
  //         id: el.id,
  //         isBlock: el.isBlock,
  //         name: el.name,
  //         lastName: el.surname,
  //         telephone: el.tel,
  //         email: el.email,
  //         registration: el.createdAt,
  //         purchases: el.orders?.length ? el.orders?.length + 1 : 0,
  //         favorites: el.templatesFavorites?.length ? el.templatesFavorites?.length + 1 : 0,
  //         onSupport: el.supportTemplates?.length ? el.supportTemplates?.length + 1 : 0,
  //       };
  //     });
  //   }

  async function getExtract() {
    fetch(`${BASE_URL}/client/bill/?orderSortType=DESC`, {
      method: 'GET',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    })
      .then((result) => result.json())
      .then((data) => {
        setExtract(data.billList);
        // if (!data.userCount) return;
        // setPages(Math.ceil(data.userCount / LIMIT));
        // setPartners(adminTableFormat(data.userList));
      })
      .catch((err) => console.log(err));
  }
  console.log(extract, 'extract');
  useEffect(() => {
    getExtract();
  }, []);

  function getTemplateTypeId(id) {
    switch (id) {
      case '5db045ac3a86ec7504a2f9b5':
        return lang === 'ru' ? 'Корпоративный сайт' : 'Corporate website';
      case '5db045ac3a86ec7504a2f9b4':
        return lang === 'ru' ? 'Одностраничный' : 'Single Page';
      case '5e01febc6aaee931304e0bde':
        return lang === 'ru' ? 'Сайт визитка' : 'Website business card';
      default:
        break;
    }
    // if (id === "5dcd4676c78d2379f37d9392") {
    //   return lang === 'ru' ? 'Корпоративный сайт' : 'Corporate website';
    // }
    //  if (id === '"5db045ac3a86ec7504a2f9b4"') {
    //   return lang === 'ru' ? 'Одностраничный' : 'Single Page';
    // } else  {
    //   return lang === 'ru' ? 'Сайт визитка' : 'Website business card';
    // }
  }

  // console.log(LIMIT * current === 1 ? 0 : current * LIMIT - 2, '2222222');
  // console.log(current, 'curent');
  // console.log(offset, 'ofset');
  return (
    <div className={s.mainContainer}>
      <div className={s.container}>
        <div className={s.tableBlock}>
          <div className={s.tableHeader}>
            {headerTable.map((obj) => (
              <div className={s.tableHeader_item}>
                <span className={s.title}>{lang === `ru` ? obj.ru : obj.en}</span>
              </div>
            ))}
          </div>
          <div className={s.tableTitle}>
            {extract?.map((obj, i) => {
              console.log(obj);
              return (
                <div className={s.tableTitle_item}>
                  <span className={s.title}>{i + 1}</span>
                  <span className={s.title}>{dayjs(obj.createdAt).format('DD.MM.YY')}</span>
                  <Tooltip title={obj.order?.template.title} placement="left">
                    <span className={s.title}>{obj.order?.template.title}</span>
                  </Tooltip>
                  <Tooltip
                    title={getTemplateTypeId(obj.order?.template.templateTypeId)}
                    placement="left"
                  >
                    <span className={s.title}>
                      {getTemplateTypeId(obj.order?.template.templateTypeId)}
                    </span>
                  </Tooltip>
                  <span className={s.title}>{obj.amount}</span>
                  <span className={s.title}>
                    <img
                      className={s.iconBlocked}
                      src={infoExtract}
                      // onClick={() => blockUser(obj.id)}
                      alt="Ban"
                    />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.paginationBox}>
          <input
            value={current || ''}
            onChange={(e) => {
              setCurrent(+e.target.value <= pages ? +e.target.value : current);
            }}
            onBlur={(e) => setCurrent(+e.target.value)}
            className={s.inputPagination}
            type="number"
          />
          <span className={s.current}>из {pages}</span>
          <div className={s.btnBox}>
            <button
              className={s.btnPrev}
              onClick={() => {
                if (current <= pages && current !== 1) {
                  setCurrent((prev) => prev - 1);
                }

                window.scrollTo(0, 370);
              }}
            >
              <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
            </button>
            <button
              className={s.btnNext}
              onClick={() => {
                if (current < pages) {
                  setCurrent((prev) => prev + 1);
                }
                window.scrollTo(0, 370);
              }}
            >
              <Arrow className={s.arrow} src={Arrow} alt="Like" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtractBlock;
