import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  description: '',
  keywords: '',
};

const editMeta = createSlice({
  name: 'title',
  initialState,
  reducers: {
    editTitle(state, action) {
      state.title = action.payload;
    },
    editKeywords(state, action) {
      state.keywords = action.payload;
    },
    editDescription(state, action) {
      state.description = action.payload;
    },
  },
});

export const { editTitle, editKeywords, editDescription } = editMeta.actions;
export default editMeta.reducer;
