import { useLocation, Navigate, Outlet } from 'react-router-dom';

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();

  //   return auth?.role?.find((role) => allowedRoles?.includes(role)) ? (
  return localStorage.getItem('role')?.toLowerCase() === allowedRoles ? (
    <Outlet />
  ) : localStorage.getItem('token') ? (
    // Для 404
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
