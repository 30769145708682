import React, { useEffect, useState } from 'react';

import s from './PopApTechnicalSupport.module.scss';
import exit from '../../assets/img/exit.svg';
import Button from '../ui/Button/Button';
import PhoneInput from 'react-phone-input-2';

function PopApTechnicalSupport(props) {
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
    props.closeModal(false);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  function enableScroll() {
    document.body.classList.remove('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div
          style={{
            visibility: show ? 'visible' : 'hidden',
            opacity: show ? '1' : '0',
          }}
          className={s.wrapper}
        >
          <div className={s.popApBox}>
            <img
              onClick={() => [handleClose(), enableScroll()]}
              className={s.exit}
              src={exit}
              alt=""
            />
            <h2 className={s.mainTitle}>Добавить новое обращение</h2>
            <p className={s.about}>Оставьте свои контакты и мы обязательно с вами свяжемся</p>
            <div className={s.inputBox}>
              <input placeholder="Имя" className={s.inputName} type="text" />
              <input placeholder="E-mail" className={s.inputEmail} type="email" />
              <PhoneInput
                country={'ru'}
                placeholder="Телефон"
                className={s.inputTelephone}
                type="number"
                specialLabel={''}
              />
              <textarea
                className={s.question}
                placeholder="Задайте вопрос"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className={s.confidentiallyBox}>
              <div className={s.linkConfidentiallyBox1}>
                <input className={s.checkBoxe} type="checkbox" id="checke" value="letter" />
                <label className={s.inputLabele} for="check">
                  Принимаю условия
                </label>
                <span className={s.linkConfidentially}>Политики конфиденциальности</span>
              </div>
              <div className={s.linkConfidentiallyBox2}>
                <div className={s.i}>и</div>
                <span className={s.linkConfidentially2} href="">
                  Пользовательского соглашения
                </span>
              </div>
            </div>
            <Button onClick={() => enableScroll()} className={s.btnSend}>
              Отправить
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopApTechnicalSupport;
