import React from 'react'
import ContactSupport from '../../components/ContactSupport/ContactSupport'
// import FAQ from '../../components/FAQ/FAQ'
import FaqMobile from '../../components/FaqMobile/FaqMobile'
import FrequentlyRequestedInformation from '../../components/FrequentlyRequestedInformation/FrequentlyRequestedInformation'
import TechnicalSupportHeading from '../../components/TechnicalSupportHeading/TechnicalSupportHeading'

function TechnicalSupport() {
  return (
    <>
    <TechnicalSupportHeading/>
    <FrequentlyRequestedInformation/>
    <FaqMobile/>
    <ContactSupport/>
    </>
  )
}

export default TechnicalSupport