/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Button from '../ui/Button/Button';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import viewPassword from '../../assets/img/viewPassword.svg';
import hiddenPassword from '../../assets/img/hiddenPassword.svg';

import s from './PasswordChangeBlock.module.scss';
import { BASE_URL } from '../..';
import osisstant from '../../assets/img/onlineOsisstant.svg';
import { useSelector } from 'react-redux';

function PasswordChangeBlock() {
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const lang = useSelector((state) => state.lang.language);

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  };

  const notifyOk = () =>
    toast(
      <div>
        <span style={imgOssistant}>
          {lang === 'ru'
            ? 'Ваши данные успешно сохранены!'
            : 'Your data has been successfully saved!'}
        </span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const notifyErr = () =>
    toast(
      <div>
        <span style={imgOssistant}>
          {lang === 'ru' ? 'Неверный текущий пароль!' : 'Wrong current password!'}
        </span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    validate: (values) => {
      const errors = {};

      if (values.newPassword !== values.repeatNewPassword) {
        errors.repeatNewPassword = lang === 'ru' ? 'Пароли не совпадают' : 'Password mismatch';
      }

      if (!values.oldPassword) {
        errors.oldPassword = lang === 'ru' ? 'Введите старый пароль' : 'Enter old password';
      }

      if (!values.newPassword) {
        errors.newPassword = lang === 'ru' ? 'Введите новый пароль' : 'Enter a new password';
      }

      if (!values.repeatNewPassword) {
        errors.repeatNewPassword = lang === 'ru' ? 'Повторите новый пароль' : 'Repeat new password';
      }

      return errors;
    },
    onSubmit: (values) => {
      setErrorMessage('');
      fetch(`${BASE_URL}/client/user/change-password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        }),
      })
        .then((result) => {
          console.log(result);

          result.json();
          if (result.status === 400) {
            setErrorMessage(lang === 'ru' ? 'Неверный текущий пароль' : 'Wrong current password');
            notifyErr();
          } else {
            formik.resetForm();
            notifyOk();
            // setIsLoading(false);
          }
        })
        // .then((data) => {
        //   console.log(data);
        // })
        .catch((err) => console.log(err));
    },
  });

  //Отображение пароля
  const [type, setType] = useState('password');
  const [typeNew, setTypeNew] = useState('password');
  const [typeRepeat, setTypeRepeat] = useState('password');
  const typeOldPassword = (e) => {
    e.preventDefault();
    setType(type === 'password' ? 'text' : 'password');
  };
  const typeNewPassword = (e) => {
    e.preventDefault();
    setTypeNew(typeNew === 'password' ? 'text' : 'password');
  };
  const typePasswordRepeat = (e) => {
    e.preventDefault();
    setTypeRepeat(typeRepeat === 'password' ? 'text' : 'password');
  };
  console.log(errorMessage);
  return (
    <form onSubmit={formik.handleSubmit} className={s.passwordChangeBlock}>
      <div className={s.currentPasswordBox}>
        <span className={s.currentPassword}>
          {lang === 'ru' ? 'Текущий пароль' : 'Current Password'}{' '}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <div className={s.inputOldPasswordBox}>
        <input
          className={s.inputCurrentPassword}
          type={type}
          id={'oldPassword'}
          name={'oldPassword'}
          onChange={(e) => {
            formik.handleChange(e);
            setErrorMessage('');
          }}
          value={formik.values.oldPassword}
        />
        <img
          src={type === 'password' ? hiddenPassword : viewPassword}
          className={s.passwordVisible}
          onClick={typeOldPassword}
        />
      </div>
      {errorMessage && (
        <div className={s.error}>
          <span className={s.errorVisible}>{errorMessage}</span>
        </div>
      )}
      {formik.errors.oldPassword ? (
        <div className={s.error}>
          <span className={s.errorVisible}>{formik.errors.oldPassword}</span>
        </div>
      ) : (
        <div className={s.error}></div>
      )}
      <div className={s.newPasswordBox}>
        <span className={s.newPassword}>
          {lang === 'ru' ? 'Новый пароль' : 'New Password'}{' '}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <div className={s.inputNewPasswordBox}>
        <input
          className={s.inputNewPassword}
          type={typeNew}
          id={'newPassword'}
          name={'newPassword'}
          onChange={formik.handleChange}
          value={formik.values.newPassword}
        />
        <img
          src={typeNew === 'password' ? hiddenPassword : viewPassword}
          className={s.passwordVisible}
          onClick={typeNewPassword}
        />
      </div>
      {formik.errors.newPassword ? (
        <div className={s.error}>
          <span className={s.errorVisible}>{formik.errors.newPassword}</span>
        </div>
      ) : (
        <div className={s.error}></div>
      )}
      <div className={s.repeatPasswordBox}>
        <span className={s.repeatPassword}>
          {lang === 'ru' ? 'Повторите пароль' : 'Repeat password'}{' '}
          <span className={s.requiredField}>*</span>
        </span>
      </div>
      <div className={s.inputRepeatPasswordBox}>
        <input
          className={s.inputRepeatPassword}
          type={typeRepeat}
          id={'repeatNewPassword'}
          name={'repeatNewPassword'}
          onChange={formik.handleChange}
          value={formik.values.repeatNewPassword}
        />
        <img
          src={typeRepeat === 'password' ? hiddenPassword : viewPassword}
          className={s.passwordVisible}
          onClick={typePasswordRepeat}
        />
      </div>
      {formik.errors.repeatNewPassword ? (
        <div className={s.error}>
          <span className={s.errorVisible}>{formik.errors.repeatNewPassword}</span>
        </div>
      ) : (
        <div className={s.error}></div>
      )}
      <Button
        type={'submit'}
        className={s.btnPasswordSave}
        onClick={() => {
          // toastValidate();
          // formik.values.newPassword = '';
          // formik.values.repeatNewPassword = '';
          // formik.values.oldPassword = '';
        }}
      >
        Сохранить
      </Button>
      <ToastContainer
        hideProgressBar={true}
        autoClose={6000}
        limit={1}
        // style={{ position: 'relative' }}
        position="bottom-left"
      />
    </form>
  );
}

export default PasswordChangeBlock;
