import React, { useEffect, useState } from 'react';
import s from './HostingBlock.module.scss';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import Button from '../ui/Button/Button';
import threeDots from '../../assets/img/threeDots.svg';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

function HostingBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(
    lang === 'ru' ? `По названию` : 'By name'
  );
  const [activeId, setActiveId] = useState(0);
  const [activeOtherId, setActiveOtherId] = useState(0);
  const [activeTreeDots, setActiveTreeDots] = useState(null);

  const [sortType, setSortType] = useState('NAME');
  const [hostingList, setHostingList] = useState([]);

  const getHostingSites = async (sortType) => {
    const response = await fetch(
      `${BASE_URL}/client/user/templates/hosting?templateSortType=${sortType}&orderSortType=DESC`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
      }
    );
    return await response.json();
  };

  useEffect(() => {
    getHostingSites(sortType).then((data) => {
      const key = Object.keys(data)[0];
      setHostingList(data[key]);
    });
  }, [sortType]);

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  const otherArr = [
    { ru: 'Скачать сайт', en: 'Download site', id: 0 },
    { ru: 'Посмотреть', en: 'Look', id: 1 },
    { ru: 'Заявки', en: 'Applications', id: 2 },
    { ru: 'Переименовать', en: 'Rename', id: 3 },
    { ru: 'Остановить', en: 'Stop', id: 4 },
    { ru: 'Удалить', en: 'Delete', id: 5 },
  ];

  return (
    <div className={s.mainContainer}>
      <div onClick={() => setActive(!active)} className={s.sortedBlock}>
        <span className={s.sortedMenu}>{sortedName}</span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  setSortType(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        <div className={s.mySiteBlock}>
          {hostingList.length
            ? hostingList.map((site) => (
                <div key={site.id} className={s.cardBox}>
                  <img
                    className={s.siteImage}
                    src={`${BASE_URL}/${site.img}`}
                    alt="Site"
                  />
                  <div className={s.aboutSite}>
                    <span className={s.siteName}>{site.title}</span>
                    <span className={s.date}>
                      {site.hostingPaymentList[0].expire}
                    </span>
                  </div>
                  <div className={s.buttonBox}>
                    <Button className={s.btnUpdate}>
                      {lang === 'ru' ? `Редактировать` : `Edit`}
                    </Button>
                    <img
                      onClick={() =>
                        setActiveTreeDots((prev) =>
                          prev === site.id ? null : site.id
                        )
                      }
                      className={s.threeDots}
                      src={threeDots}
                      alt="Three dots"
                    />
                    {activeTreeDots === site.id && (
                      <div className={s.dropDownBoxOther}>
                        <div className={s.boxFirst}>
                          {otherArr.slice(0, 3).map((obj) => (
                            <span
                              key={obj.id}
                              onClick={() => {
                                setActiveTreeDots(null);
                                setActiveOtherId(obj.id);
                              }}
                              className={s.otherFirst}
                            >
                              {lang === `ru` ? obj.ru : obj.en}
                            </span>
                          ))}
                        </div>
                        {otherArr.slice(3).map((obj) => (
                          <span
                            key={obj.id}
                            onClick={() => {
                              setActiveTreeDots(null);
                              setActiveOtherId(obj.id);
                            }}
                            className={s.otherlast}
                          >
                            {lang === 'ru' ? obj.ru : obj.en}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default HostingBlock;
