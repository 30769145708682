import React, { useEffect, useState } from 'react';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import s from './InDevelopingBlock.module.scss';
import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Like } from '../../assets/FullListTeamplates/like.svg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton';

function InDevelopingBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(
    lang === 'ru' ? `По дате создания` : `By creation date`,
  );
  const [activeId, setActiveId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const skeletons = [...new Array(4)].map((_, i) => <TemplateSkeleton key={i} />);

  //sorting
  const [sortType, setSortType] = useState('CREATION_DATE');

  const sortedArr = [
    { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
    { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
    {
      ru: 'По дате создания',
      en: 'By creation date',
      id: 2,
      sortType: 'CREATION_DATE',
    },
    {
      ru: ' По дате изменения',
      en: 'By date modified',
      id: 3,
      sortType: 'UPDATE_DATE',
    },
  ];

  const getSitesInDeveloping = async (templateSortType) => {
    const response = await fetch(
      `${BASE_URL}/client/user/templates/dev?templateSortType=${templateSortType}&orderSortType=DESC`,
      {
        method: 'GET',
        headers: {
          'x-access-token': localStorage.getItem('token'),
        },
      },
    );
    setIsLoading(false);
    return await response.json();
  };

  const navigate = useNavigate();

  const [inDeveloping, setInDeveloping] = useState([]);
  // console.log(inDeveloping)
  useEffect(() => {
    getSitesInDeveloping(sortType)
      .then((data) => {
        const key = Object.keys(data)[0];
        setInDeveloping(data[key]);
      })
      .catch((err) => console.log(err));
  }, [sortType]);

  return (
    <div className={s.mainContainer}>
      <div onClick={() => setActive(!active)} className={s.sortedBlock}>
        <span className={s.sortedMenu}>{sortedName}</span>
        <img
          style={{
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {active && (
          <div className={s.dropDownBox}>
            {sortedArr.map((obj) => (
              <span
                onClick={() => {
                  setActive(!active);
                  setSortedName(lang === 'ru' ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  setSortType(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
                key={obj.id}
              >
                {lang === 'ru' ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.container}>
        {/* <div className={s.inDevelopingBlock}>
          {inDeveloping.length
            ? inDeveloping.map((site) => (
              <div
              key={site.id}
              className={s.cardBoxMain}
          >
                <div key={site.id} className={s.cardBox}>
                  <div className={s.imgBox}>
                    <img
                      className={s.cardImage}
                      src={`roboweb.site/${site.img}`}
                      alt="Site"
                    />
                    <Button className={s.btnUpdate}>Редактировать</Button>
                    <ButtonTemplates
                      onClick={() => {
                        navigate(`/viewTemplate/${site.id}`);
                      }}
                      className={s.btnLook}
                    >
                      Посмотреть
                    </ButtonTemplates>
                    <Like className={s.like} src={Like} alt="Like" />
                  </div>
                  <span className={s.siteName}>Аренда автомобилей</span>
                  <Button className={s.btnUpdateMobile}>Редактировать</Button>
                  <Button
                    onClick={() => {
                      navigate(`/viewTemplate/${site.id}`);
                    }}
                    className={s.btnViewingMobile}
                  >
                    Посмотреть
                  </Button>
                </div>
                </div>
              ))
            : null}
        </div> */}
        <div className={s.cardBlock}>
          {inDeveloping.length ? (
            inDeveloping.map((item) => {
              // console.log(item);
              return (
                <div key={item.id} className={s.cardBoxMain}>
                  <div className={s.cardBox}>
                    <div className={s.imgBox}>
                      <img
                        className={s.imgCard}
                        src={`${BASE_URL}${item.img}`}
                        alt="Шаблон сайта"
                      />
                    </div>
                    <Button
                      className={s.btnUpdate}
                      onClick={() => {
                        navigate(`/templateUpdate/${item.id}`);
                      }}
                    >
                      {lang === 'ru' ? `Редактировать` : `Edit`}
                    </Button>
                    <ButtonTemplates
                      onClick={() => {
                        navigate(`/viewTemplate/${item.id}`);
                      }}
                      className={s[lang === 'ru' ? 'btnViewing' : 'btnViewingEn']}
                    >
                      {' '}
                      {lang === 'ru' ? `Посмотреть` : `Look`}
                    </ButtonTemplates>
                    {/* <Like className={s.like} src={Like} alt="Like" /> */}
                  </div>
                  <h3 className={s.siteName}>{item.title}</h3>
                  <Button
                    onClick={() => {
                      navigate(`/templateUpdate/${item.id}`);
                    }}
                    className={s.btnUpdateMobile}
                  >
                    {lang === 'ru' ? `Редактировать` : `Edit`}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(`/viewTemplate/${item.id}`);
                    }}
                    className={s.btnViewingMobile}
                  >
                    {lang === 'ru' ? `Посмотреть` : `Look`}
                  </Button>
                </div>
              );
            })
          ) : isLoading ? (
            skeletons
          ) : (
            <div className={s.cardBoxoMain}>
              <p className={s.cardBoxo}>Список пуст</p>
            </div>
          )}
        </div>
        <ButtonTemplates className={s.btnCreateSite} onClick={() => navigate('/templates')}>
          {lang === 'ru' ? `Создать сайт` : `Create a website`}
        </ButtonTemplates>
      </div>
    </div>
  );
}

export default InDevelopingBlock;
