import React from 'react';
import s from './AdditionalOffers.module.scss';
import topWave from '../../assets/img/wave_top.svg';
import wave from '../../assets/img/wave.svg';
import sale from '../../assets/additionalOffers/sale.svg';
import wallet from '../../assets/additionalOffers/wallet.svg';
import books from '../../assets/additionalOffers/books.svg';
import percent from '../../assets/additionalOffers/percent.svg';
import { useSelector } from 'react-redux';

function AdditionalOffers() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <img className={s.topWave} src={topWave} alt="Wave" />
          <div className={s.additonalOffersBlock}>
            <h2 className={s.mainTitle}>
              {lang === 'ru'
                ? `Дополнительные предложения`
                : `Additional offers`}
            </h2>
            <div className={s.additonalOffersBlockCards}>
              <div className={s.additionalOffersBox1}>
                <div className={s.cardBox1}>
                  <img className={s.saleImg} src={sale} alt="Sale" />
                  <h3 className={s.cardTitle}>
                    {lang === 'ru'
                      ? `Персональные скидки`
                      : `Personal discounts`}
                  </h3>
                  <p className={s.aboutTitle}>
                    {lang === 'ru'
                      ? `Специальные промокоды, которые предлагают выгодные условия для создания сайта`
                      : `Special promo codes that offer great deals on website development`}
                  </p>
                </div>
                <div className={s.cardBox2}>
                  <img className={s.booksImg} src={books} alt="Books" />
                  <h3 className={s.cardTitle}>
                    {lang === 'ru'
                      ? `Полезная информация`
                      : `Useful information`}
                  </h3>
                  <p className={s.aboutTitle}>
                    {lang === 'ru'
                      ? `RoboWeb расскажет обо всех нюансах создания сайта
                      и его дальнейшего продвижения`
                      : `RoboWeb will tell you about all the nuances of creating a website and its further promotion`}
                  </p>
                </div>
              </div>
              <div className={s.additionalOffersBox2}>
                <div className={s.cardBox3}>
                  <img className={s.walletImg} src={wallet} alt="Wallet" />
                  <h3 className={s.cardTitle}>
                    {lang === 'ru'
                      ? `Сайт в рассрочку`
                      : `Website in installments`}
                  </h3>
                  <p className={s.aboutTitle}>
                    {lang === 'ru'
                      ? `RoboWeb даёт уникальный шанс создать инструмент для развития
                      бизнеса сейчас, а платить за него потом`
                      : `RoboWeb gives you a unique chance to create a business development tool now and pay for it later.`}
                  </p>
                </div>
                <div className={s.cardBox4}>
                  <img className={s.percentImg} src={percent} alt="Percent" />
                  <h3 className={s.cardTitle}>
                    {lang === 'ru' ? `Закрытые распродажи` : `Closed sales`}
                  </h3>
                  <p className={s.aboutTitle}>
                    {lang === 'ru'
                      ? `Доступные только подписчикам сообщества RoboWeb акции
                      и промо`
                      : `Promotions and promos available only to subscribers of RoboWeb community`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className={s.waveBottom} src={wave} alt="Wave" />
        </div>
      </div>
    </section>
  );
}

export default AdditionalOffers;
