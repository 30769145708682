import React, { useEffect, useState } from 'react';

import s from './AdministrationUserSiteBlock.module.scss';

import Button from '../ui/Button/Button';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import arrowMenu from '../../assets/img/arrowMenu.svg';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatar from '../../assets/img/site.svg';
import iconLike from '../../assets/img/iconLike.svg';
import iconBasket from '../../assets/img/basket.svg';
import threeDots from '../../assets/img/threeDots.svg';
import axios from 'axios';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
import $api from '../../http';
import ForgotPasswordPopAp from '../ForgotPasswordPopAp/ForgotPasswordPopAp';
import { useNavigate } from 'react-router-dom';
import SkeletonsMySiteBlock from '../SkeletonsMySiteBlock/SkeletonsMySiteBlock';

//Получение токена
const config = {
  headers: {
    'x-access-token': localStorage.getItem('token'),
  },
};

//Проброс в вверх страницы
const setUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

function AdministrationUserSitesBlock({ items }) {
  const lang = useSelector((state) => state.lang.language);

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [value, setValue] = useState(1);

  const [activeCategoryName, setActiveCategoryName] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    lang === `ru` ? 'Все категории' : `All categories`,
  );

  const [activeSortedNameSite, setActiveSortedNameSite] = useState(false);
  const [sortedName, setSortedName] = useState(lang === `ru` ? 'Все сайты' : `All sites`);
  const [activeId, setActiveId] = useState(0);
  const [activeOtherId, setActiveOtherId] = useState(0);
  const [activeTreeDots, setActiveTreeDots] = useState(null);
  const [activeCategoryId, setActiveCategoryId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  //sorting
  const [sortType, setSortType] = useState(0);
  // const sortedArr = [
  //   { ru: 'По названию', en: 'By name', id: 0, sortType: 'NAME' },
  //   { ru: 'По стоимости', en: 'By cost', id: 1, sortType: 'COST' },
  //   { ru: 'По дате создания', en: 'By creation date', id: 2, sortType: 'CREATION_DATE' },
  //   { ru: 'По дате изменения', en: 'By date modifiedg', id: 3, sortType: 'UPDATE_DATE' },
  // ];
  const skeletons = [...new Array(6)].map((_, i) => <SkeletonsMySiteBlock key={i} />);

  const otherArr = [
    { ru: 'Скачать сайт', en: 'Download site', id: 0 },
    { ru: 'Посмотреть', en: 'Look', id: 1 },
    { ru: 'Заявки', en: 'Applications', id: 2 },
    { ru: 'Переименовать', en: 'Rename', id: 3 },
    { ru: 'Остановить', en: 'Stop', id: 4 },
    { ru: 'Удалить', en: 'Delete', id: 5 },
  ];
  const sortedArrNameSite = [
    { ru: 'Все сайты', en: 'All sites', id: 0, sortType: 'NAME' },
    {
      ru: 'Сайт-визитка',
      en: 'Business card site',
      id: '5e01febc6aaee931304e0bde',
      sortType: 'COST',
    },
    {
      ru: 'Одностраничный сайт',
      en: 'Landing page',
      id: '5db045ac3a86ec7504a2f9b4',
      sortType: 'CREATION_DATE',
    },
    {
      ru: 'Корпоративный сайт',
      en: 'Corporate website',
      id: '5db045ac3a86ec7504a2f9b5',
      sortType: 'UPDATE_DATE',
    },
    {
      ru: 'Индивидуальная разработка',
      en: 'Individual development',
      id: '5f8801bff2a48205849f909c',
      sortType: 'UPDATE_DATE',
    },
  ];

  const [selectCategoryId, setSelectCategoryId] = useState(false);
  const [totalCount, setTotalCount] = useState(1);

  const [popup, setPopup] = useState(false);

  const navigate = useNavigate();

  //Получение всех шаблонов
  const getTemplateUser = async () => {
    let total = current === 1 ? 0 : current * 15 - 15;
    let templateList = await axios.get(
      `${BASE_URL}/sa/template?isUserTemplate=true&limit=${15}&offset=${total}${
        sortType === 0 ? '' : `&templateTypeId=${sortType}`
      }${selectCategoryId ? `&categoryId=${selectCategoryId}` : ''}`,
      config,
    );
    setData(templateList.data.templateList);
    setTotalCount(templateList.data.templateTotalCount);
    setUp();
    setIsLoading(false);
    // console.log('totalCount', totalCount);
    // console.log('total', total);
    // console.log('current', current);
  };

  // console.log(current);

  useEffect(() => {
    getTemplateUser();
  }, [current, sortType, selectCategoryId, popup]);
console.log(isLoading, 'userSiteBlockkkkkk')
  function changeCurrentPage(n) {
    if (n > data.count / 15) {
      // console.log(n);
      setCurrent(Math.ceil(data?.count / 15));
      setValue(Math.ceil(data?.count / 15));
      return;
    }
    if (n < 1) {
      setCurrent(1);
      setValue(1);
      return;
    }

    setCurrent(n);
    setValue(n);
  }

  const deleteTemplate = async (id) => {
    try {
      await $api.delete(`/sa/template/${id}`);
      getTemplateUser();
      console.log('template delete');
    } catch (err) {
      console.log(err.message);
    }
  };

  const close = (e) => {
    e.stopPropagation();
    console.log(e.target);
    if (e.target.id !== 'treeDots' && e.target.id !== 'title') {
      setActiveTreeDots(null);
      document.removeEventListener('click', close);
    }
  };

  return (
    <div className={s.mainContainer}>
      <div className={s.menuBlock}>
        <div
          onClick={() => {
            setActiveCategoryName(false);

            setActiveSortedNameSite(!activeSortedNameSite);
          }}
          className={s.sortedBlock}
        >
          <span className={s.sortedMenu}>{sortedName}</span>
          <img
            style={{
              transform: activeSortedNameSite ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={s.arrowMenu}
            src={arrowMenu}
            alt="Arrow"
          />
          {activeSortedNameSite && (
            <div className={s.dropDownBox}>
              {sortedArrNameSite.map((obj) => (
                <span
                  key={obj.id}
                  onClick={() => {
                    setActiveSortedNameSite(!activeSortedNameSite);

                    setSortedName(lang === `ru` ? obj.ru : obj.en);
                    setActiveId(obj.id);
                    setSortType(obj.id);
                  }}
                  className={s[activeId === obj.id ? 'active' : 'disabled']}
                >
                  {lang === `ru` ? obj.ru : obj.en}
                </span>
              ))}
            </div>
          )}
        </div>
        <div
          onClick={() => {
            setActiveCategoryName(!activeCategoryName);
            setActiveSortedNameSite(false);
          }}
          className={s.categoryMenuBlock}
        >
          <span className={s.categotyMenu}>{selectedCategory}</span>
          <img
            className={s.arrowMenu}
            style={{
              transform: activeCategoryName ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            src={arrowMenu}
            alt="Arrow"
          />
          {activeCategoryName && (
            <div className={s.contentBoxCategory}>
              {items?.map((el) => (
                <span
                  key={el.id}
                  className={s[activeCategoryId === el.id ? 'active' : 'disabled']}
                  onClick={() => {
                    setActiveCategoryId(el.id);
                    setSelectedCategory(
                      // activeCategoryId === el.id
                      //   ? 'Все категории'
                      //   : el.transcriptionRu !== 'IT ТЕХНОЛОГИИ'
                      //   ? el.transcriptionRu.slice(0, 1) +
                      //     el.transcriptionRu.slice(1).toLowerCase()
                      //   : el.transcriptionRu.slice(0, 2) +
                      //     el.transcriptionRu.slice(2).toLowerCase()
                      el.transcriptionRu !== 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                        ? el.transcriptionRu.slice(0, 1) + el.transcriptionRu.slice(1).toLowerCase()
                        : el.transcriptionRu === 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                        ? el.transcriptionRu.slice(0, 2) + el.transcriptionRu.slice(2).toLowerCase()
                        : el.transcriptionEn !== 'IT TECHNOLOGY' && lang === 'en'
                        ? el.transcriptionEn.slice(0, 1) + el.transcriptionEn.slice(1).toLowerCase()
                        : el.transcriptionEn === 'IT TECHNOLOGY' && lang === 'en'
                        ? el.transcriptionEn.slice(0, 2) + el.transcriptionEn.slice(2).toLowerCase()
                        : '',
                    );
                    setActiveCategoryName(!activeCategoryName);
                    setSelectCategoryId(el.id);
                  }}
                >
                  {/* {el.transcriptionRu !== 'IT ТЕХНОЛОГИИ'
                    ? el.transcriptionRu.slice(0, 1) +
                      el.transcriptionRu.slice(1).toLowerCase()
                    : el.transcriptionRu.slice(0, 2) +
                      el.transcriptionRu.slice(2).toLowerCase()} */}
                  {el.transcriptionRu !== 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                    ? el.transcriptionRu.slice(0, 1) + el.transcriptionRu.slice(1).toLowerCase()
                    : ''}
                  {el.transcriptionRu === 'IT ТЕХНОЛОГИИ' && lang === 'ru'
                    ? el.transcriptionRu.slice(0, 2) + el.transcriptionRu.slice(2).toLowerCase()
                    : ''}
                  {el.transcriptionEn !== 'IT TECHNOLOGY' && lang === 'en'
                    ? el.transcriptionEn.slice(0, 1) + el.transcriptionEn.slice(1).toLowerCase()
                    : ''}
                  {el.transcriptionEn === 'IT TECHNOLOGY' && lang === 'en'
                    ? el.transcriptionEn.slice(0, 2) + el.transcriptionEn.slice(2).toLowerCase()
                    : ''}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={s.container}>
        <div className={s.mySiteBlock}>
          {data.length ? (
            data.map((temp) => (
              <div className={s.cardBox} key={temp.id}>
                <img className={s.siteImage} src={`${BASE_URL}/${temp.img}`} alt="Site" />
                <div className={s.aboutSite}>
                  <span className={s.siteName}>{temp.title}</span>
                  <span className={s.categorySite}>{temp.templateType.titleRu}</span>
                  <span className={s.id}>ID {temp.id}</span>
                  <span className={s.sitePrice}>1 015 ₽</span>
                </div>
                <div className={s.buttonBox}>
                  <Button className={s.btnWatch} onClick={() => navigate(`/robolook/${temp.id}`)}>
                    {lang === `ru` ? `Смотреть` : `Look`}
                  </Button>
                  <img
                    id="treeDots"
                    onClick={() => {
                      setActiveTreeDots((prev) => (prev === temp.id ? null : temp.id));
                      document.addEventListener('click', close);
                    }}
                    className={s.threeDots}
                    src={threeDots}
                    alt="Three dots"
                  />
                  {activeTreeDots === temp.id && (
                    <div className={s.dropDownBoxOther} id="treeDots">
                      <div className={s.boxFirst} id="treeDots">
                        {otherArr.slice(0, 3).map((obj) => (
                          <span
                            id="treeDots"
                            onClick={() => {
                              setActiveTreeDots(null);
                              setActiveOtherId(obj.id);
                              obj.ru.toLowerCase() === ('посмотреть' || 'look') &&
                                navigate(`/robolook/${temp.id}`);
                            }}
                            className={s.otherFirst}
                          >
                            {lang === `ru` ? obj.ru : obj.en}
                          </span>
                        ))}
                      </div>
                      {otherArr.slice(3).map((obj) => (
                        <span
                          id="treeDots"
                          onClick={() => {
                            // setActiveTreeDots(null);
                            setActiveOtherId(obj.id);
                            obj.ru.toLowerCase() === ('переименовать' || 'rename') &&
                              setPopup(true);
                            obj.ru.toLowerCase() === ('удалить' || 'delete') &&
                              deleteTemplate(temp.id);
                          }}
                          className={s.otherlast}
                        >
                          {lang === `ru` ? obj.ru : obj.en}
                        </span>
                      ))}
                      <ForgotPasswordPopAp
                        showForgot={popup}
                        setShowForgot={setPopup}
                        component="rename"
                        templateId={temp.id}
                        setActiveTreeDots={setActiveTreeDots}
                        oldTitle={temp.title}
                        role="admin"
                        description={temp.description}
                        descriptionFull={temp.descriptionFull}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : isLoading ? (
            skeletons
          ) : (
            <div className={s.cardBoxoMain}>
              <p className={s.cardBoxo}>{lang === 'ru' ? `Список пуст` : `The list is empty`}</p>
            </div>
          )}

          {/* <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnWatch}>Смотреть</Button>
              <img className={s.threeDots} src={threeDots} alt="Three dots" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIRwwwwwwwwwww</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnWatch}>Смотреть</Button>
              <img className={s.threeDots} src={threeDots} alt="Three dots" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnWatch}>Смотреть</Button>
              <img className={s.threeDots} src={threeDots} alt="" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnWatch}>Смотреть</Button>
              <img className={s.threeDots} src={threeDots} alt="" />
            </div>
          </div>
          <div className={s.cardBox}>
            <img className={s.siteImage} src={avatar} alt="Site" />
            <div className={s.aboutSite}>
              <span className={s.siteName}>Автосервис CAR REPAIR</span>
              <span className={s.categorySite}>Авто и мото</span>
              <span className={s.id}>ID 141622Z3Q</span>
              <span className={s.sitePrice}>1 015 ₽</span>
            </div>
            <div className={s.buttonBox}>
              <Button className={s.btnWatch}>Смотреть</Button>
              <img className={s.threeDots} src={threeDots} alt="" />
            </div>
          </div> */}
        </div>
      </div>
      <div className={s.paginationBox}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => changeCurrentPage(value)}
          className={s.inputPagination}
          type="number"
          min="1"
          max={totalCount / 15}
        />
        <span className={s.current}>
          {lang === `ru` ? `из` : `of`}{' '}
          {Math.ceil(totalCount / 15) === 0 ? '1' : Math.ceil(totalCount / 15)}
        </span>
        <div className={s.btnBox}>
          <button
            className={s.btnPrev}
            onClick={() =>
              value > 1 ? changeCurrentPage(+current - 1) : changeCurrentPage(+current)
            }
          >
            <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
          </button>
          <button
            className={s.btnNext}
            onClick={() =>
              Math.ceil(totalCount / 15) <= value
                ? changeCurrentPage(+current)
                : changeCurrentPage(+current + 1)
            }
          >
            <Arrow className={s.arrow} src={Arrow} alt="Like" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdministrationUserSitesBlock;
