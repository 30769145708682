import React from 'react';
import Button from '../ui/Button/Button';

import s from './SendingForModeration.module.scss';
import x from '../../assets/img/x.svg';

function SendingForModeration() {
  return (
    <div className={s.container}>
      <img className={s.exitIcon} src={x} alt="Exit" />
      <div className={s.sendingForModerationBox}>
        <h3 className={s.mainTitle}>Ваша работа отправлена на модерацию!</h3>
        <p className={s.about}>
          Для отслеживания статуса перейдите в раздел «Мои работы». Проверка обычно занимает не
          более 48 часов.
        </p>
        <Button className={s.btnLinkMyWorks}>Перейти в мои работы</Button>
      </div>
    </div>
  );
}

export default SendingForModeration;
