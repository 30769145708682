import React, {useState, createContext} from "react"

export const HeaderContext = createContext(null)

export function HeaderContextWrapper({children}) {
    const [isHeader, setIsHeader] = useState(true)

    return (
        <HeaderContext.Provider value={{isHeader, setIsHeader}}>
            {children}
        </HeaderContext.Provider>
    )
}