import { configureStore } from '@reduxjs/toolkit';
import switchLanguageReducer from './features/user/languageSwitch';
import userReducer from './features/user/userSlice';
import getNewIdReducer from './features/user/getNewId';
import { historyReducer } from './features/editTemplate';
import editTitleReducer from './features/editMeta';

const reducer = {
  user: userReducer,
  lang: switchLanguageReducer,
  newId: getNewIdReducer,
  history: historyReducer,
  title: editTitleReducer,
};

const store = configureStore({
  reducer,
});
window.store = store;

export { store };
