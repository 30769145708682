export class ChangeTextAction {
  constructor(el, prevText, currentText, prevStyle, currentStyle, src) {
    this.el = el;
    this.prevText = prevText;
    this.currentText = currentText;
    this.prevStyle = prevStyle;
    this.currentStyle = currentStyle;
    this.src = src;
  }

  undo() {
    this.el.textContent = this.prevText;
    this.el.style.fontFamily = this.prevStyle[0];
    this.el.style.fontSize = this.prevStyle[1];
    this.el.style.fontStyle = this.prevStyle[2];
    this.el.style.fontWeight = this.prevStyle[3];
    this.el.style.textAlign = this.prevStyle[4];
  }

  redo() {
    this.el.textContent = this.currentText;
    this.el.style.fontFamily = this.prevStyle[0];
    this.el.style.fontSize = this.prevStyle[1];
    this.el.style.fontStyle = this.prevStyle[2];
    this.el.style.fontWeight = this.prevStyle[3];
    this.el.style.textAlign = this.prevStyle[4];
  }
}
