import React, { useRef, useState, useEffect } from 'react';

import logoCircle1 from '../../assets/DigitalImage/background.svg';
import logoRing from '../../assets/DigitalImage/ring.svg';
import logoRingLast from '../../assets/DigitalImage/ringLast.svg';
import logoSmallBallBlue from '../../assets/DigitalImage/smallBlueBall.svg';
import logoSmallBallLast from '../../assets/DigitalImage/smallBall.svg';
import logoBlueBall from '../../assets/DigitalImage/blueBall.png';
import logoGreenBall from '../../assets/DigitalImage/ballGreen.svg';
import logoGreyBall from '../../assets/DigitalImage/ballGrey.svg';
import logoRobot from '../../assets/DigitalImage/robot.svg';
import logoBrush from '../../assets/DigitalImage/brush.svg';
import logoEye from '../../assets/DigitalImage/eye.svg';
import logoFingers from '../../assets/DigitalImage/fingers.svg';
import logoHand from '../../assets/DigitalImage/hand.svg';
import logoSmale from '../../assets/DigitalImage/smale.svg';
import logoTablet from '../../assets/DigitalImage/tablet.svg';
import s from './DigitalBlock.module.scss';
import Counter from '../Counter/Counter';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';

function DigitBlock() {
  const ref = useRef();

  const lang = useSelector((state) => state.lang.language);

  useEffect(() => {
    let blueBall = document.querySelector(`.${s.blueBall}`);
    let greyBall = document.querySelector(`.${s.greyBall}`);
    let greenBall = document.getElementsByClassName(`${s.greenBall}`)[0];
    // let greenBall = document.querySelector(`.${s.blueBall}`);
    let boxRobot = document.getElementById(`boxRobot`);
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      blueBall.style.transform = 'translate(-' + x * 150 + 'px, -' + y * 110 + 'px)';
      greyBall.style.transform = 'translate(-' + x * 98 + 'px, -' + y * 85 + 'px)';
      greenBall.style.transform = 'translate(-' + x * 138 + 'px, -' + y * 125 + 'px)';
      boxRobot.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 56 + 'px)';
    });
  }, []);

  const [info, setInfo] = useState([]);

  const getCurrent = async () => {
    let getInfo = await axios.get(`${BASE_URL}/client/info`);
    setInfo(getInfo.data);
  };

  useEffect(() => {
    getCurrent();
  }, []);

  console.log(info, 'infoinfo');

  return (
    <section>
      <div className={s.container}>
        <div ref={ref} className={s.wrapper}>
          <div className={s.boxDigitalBlock}>
            <div className={s.boxIllustration}>
              <div className={s.boxRobot} id="boxRobot">
                <div className={s.boxHand}>
                  <img className={s.hand} src={logoHand} alt="Рука" />
                </div>
                <div className={s.boxTablet}>
                  <img className={s.tablet} src={logoTablet} alt="Экран" />
                  {info.totalTemplates >= 0 ? (
                    <Counter value={info.totalTemplates} className={s.tabletCount}></Counter>
                  ) : null}
                  <h3 className={s.tabletTitle}>
                    {lang === 'ru' ? 'Готовых сайтов' : `Ready templates`}
                  </h3>
                </div>
                <div className={s.boxSmale}>
                  <img className={s.smale} src={logoSmale} alt="Улыбка" />
                </div>
                <div className={s.boxEye}>
                  <img className={s.eye} src={logoEye} alt="Глаз" />
                </div>
                <div className={s.boxBrush}>
                  <img className={s.brush} src={logoBrush} alt="Кисть" />
                </div>
                <div className={s.boxFingers}>
                  <img className={s.fingers} src={logoFingers} alt="Пальцы" />
                </div>
                <img className={s.robot} src={logoRobot} alt="Робот" />
              </div>
              <img className={s.blueBall} src={logoBlueBall} alt="Шар синий" />
              <img className={s.greyBall} src={logoGreyBall} alt="Серый синий" />
              <img className={s.greenBall} src={logoGreenBall} alt="Зеленый синий" />
              <img className={s.logoCircle1} src={logoCircle1} alt="Круг" />
              {/* <div className={s.backGround}>
              </div> */}
            </div>
            <div className={s.boxData}>
              <div className={s.data1}>
                <div className={s.boxCircle}>
                  <img className={s.smallBall1} src={logoSmallBallBlue} alt="Маленький шар" />
                  <img className={s.ring} src={logoRing} alt="Кольцо" />
                  {info.inWorkTemplates >= 0 ? (
                    <Counter value={info.inWorkTemplates} className={s.count3}></Counter>
                  ) : null}
                </div>
                <h3 className={s.siteWork}>
                  {lang === 'ru' ? 'Сайтов в работе' : `Sites in operation`}
                </h3>
              </div>
              <div className={s.data2}>
                <div className={s.boxCircle}>
                  <img className={s.smallBall2} src={logoSmallBallLast} alt="Маленький шар" />
                  <img className={s.ring} src={logoRingLast} alt="Кольцо" />
                  {info.userCount >= 0 ? (
                    <Counter value={info.userCount} className={s.count2}></Counter>
                  ) : null}
                </div>
                <h3 className={s.users}>{lang === 'ru' ? 'Пользователей' : `Users`}</h3>
              </div>

              <div className={s.data3}>
                <div className={s.boxCircle}>
                  <img className={s.smallBall3} src={logoSmallBallBlue} alt="Маленький шар" />
                  <img className={s.ring} src={logoRing} alt="Кольцо" />
                  {info.hostingCount >= 0 ? (
                    <Counter value={info.hostingCount} className={s.count3}></Counter>
                  ) : null}
                </div>

                <h3 className={s.technicalSupport}>
                  {lang === 'ru' ? 'На техподдержке' : `At tech supports`}
                </h3>
              </div>

              <div className={s.data4}>
                <div className={s.boxCircle}>
                  <img className={s.smallBall4} src={logoSmallBallLast} alt="Маленький шар" />
                  <img className={s.ring} src={logoRingLast} alt="Кольцо" />
                  {info.editedTemplates >= 0 ? (
                    <Counter value={info.editedTemplates} className={s.count4}></Counter>
                  ) : null}
                </div>
                <h3 className={s.updated}>{lang === 'ru' ? 'Отредактировано' : `Edited by`}</h3>
              </div>

              <div className={s.data5}>
                <div className={s.boxCircle}>
                  <img className={s.smallBall5} src={logoSmallBallBlue} alt="Маленький шар" />
                  <img className={s.ring} src={logoRing} alt="Кольцо" />
                  {info.favoriteTemplates >= 0 ? (
                    <Counter value={info.favoriteTemplates} className={s.count5}></Counter>
                  ) : null}
                </div>

                <h3 className={s.favorites}>{lang === 'ru' ? 'В избранном' : `Favorites`}</h3>
              </div>
            </div>
          </div>
          <div
            // direction="left"
            // scrollamount="30"
            className={s.mobileConatainer}
          >
            <ul className={s.countBlock}>
              <li className={s.mobCount}>
                {info ? info.totalTemplates : null}
                <li className={s.mobName}>
                  {lang === 'ru' ? 'Готовых сайтов' : `Ready templates`}
                </li>
              </li>
              <li className={s.mobCount}>
                {info ? info.inWorkTemplates : null}
                <li className={s.mobName}>
                  {lang === 'ru' ? 'Сайтов в работе' : `Sites in operation`}
                </li>
              </li>
              <li className={s.mobCount}>
                {info ? info.userCount : null}
                <li className={s.mobName}>{lang === 'ru' ? 'Пользователей' : `Users`}</li>
              </li>
              <li className={s.mobCount}>
                {info ? info.hostingCount : null}
                <li className={s.mobName}>
                  {lang === 'ru' ? 'На техподдержке' : `At tech supports`}
                </li>
              </li>
              <li className={s.mobCount}>
                {info ? info.editedTemplates : null}
                <li className={s.mobName}>{lang === 'ru' ? 'Отредактировано' : `Edited by`}</li>
              </li>
              <li className={s.mobCount}>
                {info ? info.favoriteTemplates?.length : null}
                <li className={s.mobName}>{lang === 'ru' ? 'В избранном' : `Favorites`}</li>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitBlock;
