// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 1320px) {\n  .SkeletonsMySiteBlock_cardBox__c0CrP {\n    width: 362px;\n  }\n}\n@media (max-width: 1220px) {\n  .SkeletonsMySiteBlock_cardBox__c0CrP {\n    width: 300px;\n  }\n}\n@media (max-width: 1016px) {\n  .SkeletonsMySiteBlock_cardBox__c0CrP {\n    width: 265px;\n  }\n}\n@media (max-width: 900px) {\n  .SkeletonsMySiteBlock_cardBox__c0CrP {\n    width: 240px;\n  }\n}\n@media (max-width: 820px) {\n  .SkeletonsMySiteBlock_cardBox__c0CrP {\n    margin-bottom: 5px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/SkeletonsMySiteBlock/SkeletonsMySiteBlock.module.scss"],"names":[],"mappings":"AACE;EADF;IAEI,YAAA;EACF;AACF;AACE;EALF;IAMI,YAAA;EAEF;AACF;AAAE;EATF;IAUI,YAAA;EAGF;AACF;AADE;EAbF;IAcI,YAAA;EAIF;AACF;AAFE;EAjBF;IAkBI,kBAAA;EAKF;AACF","sourcesContent":[".cardBox {\n  @media (max-width: 1320px) {\n    width: 362px;\n    // height: 608px;\n  }\n  @media (max-width: 1220px) {\n    width: 300px;\n    // height: 603px;\n  }\n  @media (max-width: 1016px) {\n    width: 265px;\n    // height: 487px;\n  }\n  @media (max-width: 900px) {\n    width: 240px;\n    // height: 468px;\n  }\n  @media (max-width: 820px) {\n    margin-bottom: 5px;\n    // height: 433px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardBox": "SkeletonsMySiteBlock_cardBox__c0CrP"
};
export default ___CSS_LOADER_EXPORT___;
