import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import $api from '../../../http';

const initialState = {
  newId: '',
};

export const apiNewId = createAsyncThunk(
  `newId/getNewId`,
  async function (templateId) {
    const newId = await $api.post(`/client/template/${templateId}/edit`);

    return newId.data.templateId;
  }
);

const getNewIdSlice = createSlice({
  name: 'newId',
  initialState,
  reducers: {
    getNewId(state, action) {
      state.newId = action.payload;
    },
  },
  extraReducers: {
    [apiNewId.pending]: (state, action) => {
      state.status = 'loading';
      state.error = null;
    },
    [apiNewId.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.newId = action.payload;
    },
    [apiNewId.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { getNewId } = getNewIdSlice.actions;
export default getNewIdSlice.reducer;
