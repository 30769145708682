import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './TopTenPartnersBlock.module.scss';
import gearBlue from '../../assets/topTenPartnersIcon/gearBlue.svg';
import gearGreenSmall from '../../assets/topTenPartnersIcon/gearGreenSmall.svg';
import gearGreenBig from '../../assets/topTenPartnersIcon/gearGreenBig.svg';
// import av from '../../assets/topTenPartnersIcon/av.jpg';
// import avatar from '../../assets/topTenPartnersIcon/avatar.jpeg';
import star from '../../assets/topTenPartnersIcon/star.svg';
import avatarDefault from '../../assets/img/ava.jpeg';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';

function TopTenPartnersBlock() {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  // const partners = [
  //   {
  //     id: 1,
  //     img: av,
  //     name: 'Анна петрова',
  //     job: 'UX/UI дизайнер',
  //     sales: '1 300',
  //     inFavorites: '3 111',
  //     templates: 30,
  //     earnedFrom: 100,
  //   },
  //   {
  //     id: 2,
  //     img: avatar,
  //     name: 'Аль пачино',
  //     job: 'Веб-дизанейр',
  //     sales: '1 400',
  //     inFavorites: '3 111',
  //     templates: 30,
  //     earnedFrom: 100,
  //   },
  //   {
  //     id: 3,
  //     img: av,
  //     name: 'Дмитрий иванов',
  //     job: 'UX/UI дизайнер',
  //     sales: '1 500',
  //     inFavorites: '3 111',
  //     templates: 30,
  //     earnedFrom: 100,
  //   },
  //   {
  //     id: 4,
  //     img: avatar,
  //     name: 'Анна Сидорова',
  //     job: 'Веб-дизанейр',
  //     sales: '1 600',
  //     inFavorites: '3 111',
  //     templates: 30,
  //     earnedFrom: 100,
  //   },
  //   {
  //     id: 5,
  //     img: av,
  //     name: 'Анна Гальцова',
  //     job: 'Веб-дизанейр',
  //     sales: '1 700',
  //     inFavorites: '3 111',
  //     templates: 30,
  //     earnedFrom: 100,
  //   },
  // ];

  const [statistics, setStatistics] = useState({});

  const getStatistics = async () => {
    const response = await fetch(`${BASE_URL}/client/info/statistics-partners`);
    return await response.json();
  };

  console.log(statistics);

  //Получение топа партнеров

  const [topPartners, setTopPartners] = useState([]);

  const getTopPartners = async () => {
    let topPartner = await axios.get(`${BASE_URL}/client/info/top-partners?type=SALES`);
    setTopPartners(topPartner.data.partnerInfoList);
  };

  useEffect(() => {
    getStatistics()
      .then((data) => setStatistics(data))
      .catch((err) => console.log(err));
    getTopPartners();
  }, []);

  const lang = useSelector((state) => state.lang.language);
  console.log(topPartners, 'uuuuuuu');
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.aboutBlockMain}>
            <img className={s.gearBlue} src={gearBlue} alt="Gear" />
            <img className={s.gearGreenSmall} src={gearGreenSmall} alt="Gear" />
            <img className={s.gearGreenBig} src={gearGreenBig} alt="Gear" />
            <div className={s.partnersCountBox}>
              <h1 className={s.partnersCount}>{statistics ? statistics.partnerCount : null}</h1>
              <span className={s.partners}>{lang === 'ru' ? `Парнеров` : `Partners`}</span>
            </div>
            <div className={s.templatesCountBox}>
              <h1 className={s.templatesCount}>{statistics ? statistics.countTemplates : null}</h1>
              <span className={s.createdTemplates}>
                {lang === 'ru' ? `Создано шаблонов` : `Create templates`}
              </span>
            </div>
            <div className={s.earnedByPartnersBox}>
              <h1 className={s.earnedByPartnersCount}>
                {statistics ? statistics.totalEarning : null}
              </h1>
              <span className={s.earnedByPartners}>
                {lang === 'ru'
                  ? `Заработано партнерами`
                  : `Earned by partners
`}
              </span>
            </div>
            <div className={s.revbewsBox}>
              <h1 className={s.reviewsCount}>{statistics ? statistics.countReview : null}</h1>
              <span className={s.reviews}>{lang === 'ru' ? `Отзывов` : `Reviews`}</span>
            </div>
          </div>
          <h2 className={s.topTenPartners}>
            {lang === 'ru' ? `Топ-10 партнеров` : `Top-10 partners`}
          </h2>
          <div className={s.partnersCardsBlock}>
            <Swiper
              ref={sliderRef}
              slidesPerView={4}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={false}
              spaceBetween={30}
              breakpoints={{
                0: {
                  // width: 930,
                  slidesPerView: 1.4,
                  spaceBetween: 15,
                },
                280: {
                  // width: 930,
                  slidesPerView: 1.4,
                  spaceBetween: 15,
                },
                380: {
                  // width: 930,
                  slidesPerView: 1.6,
                  spaceBetween: 15,
                },
                480: {
                  // width: 930,
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                756: {
                  // width: 930,
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1060: {
                  // width: 930,
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1360: {
                  // width: 930,
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                2920: {
                  // width: 930,
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Navigation]}
              // style={{ width: '1309px' }}
              className={s.mySwiper4}
            >
              {topPartners?.map((obj) => (
                <SwiperSlide
                  key={obj.id}
                  className={s.cardsBox}
                  // style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {/* <div className={s.aboutPartnersBox}>
                    <img className={s.star} src={star} alt="star" />
                    <img className={s.avatar} src={obj.img} alt="Avatar" />
                    <h3 className={s.partnersName}>{obj.name}</h3>
                    <span className={s.jobTitle}>{obj.job}</span>
                  </div>
                  <div className={s.infoBox}>
                    <span className={s.sales}>Продаж: {obj.sales}</span>
                    <span className={s.inFavorites}>В избранном: {obj.inFavorites}</span>
                    <span className={s.templates}>Шаблонов: {obj.templates}</span>
                    <span className={s.earnedFrom}>Заработано от {obj.earnedFrom} тыс</span>
                  </div> */}
                  <div className={s.aboutPartnersBox}>
                    <img className={s.star} src={star} alt="star" />
                    <img
                      className={s.avatar}
                      src={obj.user.avatar ? `${BASE_URL}${obj.user.avatar}` : avatarDefault}
                      alt="Avatar"
                    />
                    <h3 className={s.partnersName}>{obj.user.name}</h3>
                    <span className={s.jobTitle}>
                      {lang === 'ru' ? `Веб-разработчик` : `web developer`}
                    </span>
                  </div>
                  <div className={s.infoBox}>
                    <span className={s.sales}>
                      {lang === 'ru' ? `Продаж: ` : `Sales: `}
                      {obj.countSales}
                    </span>
                    <span className={s.inFavorites}>
                      {lang === 'ru' ? `В избранном: ` : `In favorite: `}
                      {obj.countFavorite}
                    </span>
                    <span className={s.templates}>
                      {lang === 'ru' ? `Шаблонов: ` : `Templates: `}
                      {obj.countTemplates}
                    </span>
                    <span className={s.earnedFrom}>
                      {lang === 'ru' ? `Заработано от ` : `Earned from `}
                      {obj.earning} {lang === 'ru' ? `тыс ` : `th`}
                    </span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={s.buttonBox}>
              <button className={s.arrowBtnPrev} onClick={handlePrev}>
                <ArrowLeft className={s.arrowPrev} src={Arrow} alt="Arrow" />
              </button>
              <button onClick={handleNext} className={s.arrowBtnNext}>
                <Arrow className={s.arrowNext} src={Arrow} alt="Arrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopTenPartnersBlock;
