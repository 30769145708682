/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import s from './SignIn.module.scss';
import logo from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';

import exit from '../../assets/img/exit.svg';
import vaewPassword from '../../assets/img/viewPassword.svg';
import hiddenPassword from '../../assets/img/hiddenPassword.svg';
import Button from '../ui/Button/Button';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';

import { BASE_URL } from '../..';
import ForgotPasswordPopAp from '../ForgotPasswordPopAp/ForgotPasswordPopAp';

import $api from '../../http';

import { useSelector } from 'react-redux';

function SignIn(props) {
  const lang = useSelector((state) => state.lang.language);

  const [toggle, setToggle] = useState('Пользователь');
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [block, setBlock] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  const { setHeaderType } = useContext(HeaderTypeContext);

  const navigate = useNavigate();

  function handleClose() {
    setShow(false);
    setError('');
    props.closeModal(false);
  }

  function handleSignUp() {
    props.openSignIn(false);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = lang === 'ru' ? 'Введите Email' : `Enter Email`;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = lang === 'ru' ? `Неверный формат Email` : `Invalid email format`;
      }

      if (!values.password) {
        errors.password = lang === 'ru' ? `Введите пароль` : `Enter password`;
      }

      return errors;
    },
    onSubmit: ({ email, password }) => {
      fetch(`${BASE_URL}/auth/sign-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
        .then((result) => {
          console.log(result);
          return result.json();
        })
        .then((data) => {
          if (data.token) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('role', data.role);
            setHeaderType(data.role);
            setShow(false);
            if (data.role === 'ADMIN') {
              return navigate('/profileAreaAdmin');
            } else if (data.role === 'PARTNER' && toggle.toLowerCase() === 'партнер') {
              return navigate('/profilePartnerMyWork');
            } else if (data.role === 'CLIENT' && toggle.toLowerCase() === 'партнер') {
              localStorage.removeItem('token');
              localStorage.removeItem('role');
              setHeaderType('');
              setShow(true);
              setError(lang === 'ru' ? `Неверный логин или пароль` : `Wrong login or password`);
            } else if (data.role === 'PARTNER' && toggle.toLowerCase() === 'пользователь') {
              localStorage.removeItem('token');
              localStorage.removeItem('role');
              setHeaderType('');
              setShow(true);
              setError(lang === 'ru' ? `Неверный логин или пароль` : `Wrong login or password`);
            }
            return navigate('/personalArea');
          }
          if (data.message) {
            // setError(data.message);
            setError(lang === 'ru' ? `Неверный логин или пароль` : `Wrong login or password`);
          }
        })
        .catch((err) => {
          console.log(err.status);
        });
    },
  });

  //Отображение пароля
  const [type, setType] = useState('password');
  const onClick = (e) => {
    e.preventDefault();
    setType(type === 'password' ? 'text' : 'password');
  };

  useEffect(() => {
    setError('');
  }, [formik.values.email, formik.values.password]);

  function enableScroll() {
    document.body.classList.remove('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div
          style={{
            visibility: show ? 'visible' : 'hidden',
            opacity: show ? '1' : '0',
          }}
          className={s.wrapper}
        >
          <div className={s.signInBox}>
            <img
              onClick={() => {
                handleClose();
                enableScroll();
              }}
              className={s.exit}
              src={exit}
              alt="Exit"
            />
            <img className={s.logo} src={logo} alt="Logo" />
            <img className={s.logoMobile} src={logoMobile} alt="Logo" />
            <h2 className={s.mainTitle}>{lang === 'ru' ? `Войти в систему` : `Sign in`}</h2>
            <div className={s.toggleContainer}>
              <div className={s.toggleBox}>
                <div
                  onClick={() => {
                    setError('');
                    setToggle('Пользователь');
                  }}
                  className={s[toggle === 'Пользователь' ? 'toggleACtive' : 'toggleItem']}
                >
                  {lang === 'ru' ? `Пользователь` : `User`}
                </div>
                <div
                  onClick={() => {
                    setError('');
                    setToggle('Партнер');
                  }}
                  className={s[toggle === 'Партнер' ? 'toggleACtive' : 'toggleItem']}
                >
                  {lang === 'ru' ? `Партнер` : `Partner`}
                </div>
              </div>
            </div>
            <form className={s.form} onSubmit={formik.handleSubmit}>
              <div className={s.inputBox}>
                <input
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="E-mail"
                  type={'email'}
                  id={'email'}
                  className={s.inputName}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className={s.error}>
                    <span className={s.errorVisible}>{formik.errors.email}</span>
                  </p>
                ) : // <p className={s.error} style={{ height: 16 }}></p>
                null}
                <div className={s.passwordVisibleBlock}>
                  <input
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder={lang === 'ru' ? `Пароль` : `Password`}
                    id={'password'}
                    className={s.inputName}
                    type={type}
                    onBlur={formik.handleBlur}
                  />
                  <img
                    src={type === 'password' ? hiddenPassword : vaewPassword}
                    className={s.passwordVisible}
                    onClick={onClick}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <p className={s.error}>
                    <span className={s.errorVisible}>{formik.errors.password}</span>
                  </p>
                ) : // <p className={s.error} style={{ height: 16 }}></p>
                null}
              </div>
              <p className={s.error} style={{ textAlign: 'center', height: 16 }}>
                {error}
              </p>
              <span
                onClick={() => {
                  handleClose();
                  setShowForgot(true);
                  enableScroll();
                }}
                className={lang === 'ru' ? s.forgotPassword : s.forgotPasswordEn}
              >
                {lang === 'ru' ? `Забыли пароль` : `Forgot your password`}
              </span>
              <Button
                onClick={() => {
                  enableScroll();
                }}
                onSubmit={() => {
                  enableScroll();
                }}
                type={'submit'}
                className={s.btnSignIn}
              >
                {lang === 'ru' ? `Войти` : `Sign in`}
              </Button>
              <span onClick={handleSignUp} className={s.linkSignUp}>
                {lang === 'ru' ? `Зарегистрироваться` : `Register`}
              </span>
            </form>
          </div>
        </div>
      </div>
      <ForgotPasswordPopAp
        showForgot={showForgot}
        setShowForgot={setShowForgot}
        buttonName="Отправить"
      />
    </section>
  );
}

export default SignIn;
