import React, { useCallback, useEffect, useRef } from 'react';
import s from './FullListTemplates.module.scss';
import { ReactComponent as Loupe } from '../../assets/FullListTeamplates/loupe.svg';
import { useState } from 'react';
import CategorySite from '../CategorySite/CategorySite';
import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Like } from '../../assets/FullListTeamplates/like.svg';
import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';
import Screen from '../Screen/Screen';
import CategorySiteMobile from '../CategorySiteMobile/CategorySiteMobile';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../../utils/useDebounce';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton';
import { BASE_URL } from '../..';
import $api from '../../http';
import { useDispatch, useSelector } from 'react-redux';
import { apiNewId } from '../../app/features/user/getNewId';
import qs from 'qs';
import { Tooltip } from '@mui/material';

function FullListTemplates() {
  const lang = useSelector((state) => state.lang.language);

  // const [page, setPage] = useState(1);
  const [templateList, setTemplateList] = useState([]);
  const [templatePagination, setTemplatePagination] = useState(1);
  //const [offset, setOffset] = useState(0);
  const [activeLike, setActiveLike] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  const [viewList, setViewList] = useState([]);
  const [activeListItem, setActiveListItem] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search.slice(1));
  const page = queryParams.page ? parseInt(queryParams.page, 10) : 1;
  const locationRef = useRef(location);
  locationRef.current = location;

  const offset = (page - 1) * 15;

  const inputRef = useRef();

  const setPage = useCallback(
    (value) => {
      const location = locationRef.current;
      navigate(`${location.pathname}?${qs.stringify({ ...queryParams, page: value })}`);
    },
    [navigate, locationRef],
  );

  useEffect(() => {
    if (inputRef.current) {
      console.log('input', inputRef.current);
      inputRef.current.value = page;
    }
  }, [page, inputRef]);

  const getTemplates = async (limit, offset, categoryId, templateTypeId, searchValue) => {
    const response = await fetch(
      `${BASE_URL}/client/template?limit=${limit}&offset=${offset}&categoryId=${categoryId}&templateTypeId=${templateTypeId}&searchValue=${searchValue}`,
    );
    return await response.json();
  };

  const preparePagination = (count, perPage) => {
    return count / perPage <= 1 ? 1 : Math.floor(count / perPage);
  };

  // Categories
  const [category, setCategory] = useState([]);
  const [activeItem, setActiveItem] = useState('');
  const [templateId, setTemplateId] = useState(null);

  const getCategories = async () => {
    const response = await fetch(`${BASE_URL}/client/category`);
    return await response.json();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // if (loading.current === true) {
    getCategories()
      .then((data) => {
        const key = Object.keys(data)[0];
        setCategory((prevState) => [...prevState, ...data[key]]);
      })
      .catch((err) => console.log(err));
    // }
    return () => {
      setCategory([]);
      // loading.current = true;
    };
  }, [page]);
  console.log(page, 'page');
  console.log(templatePagination);
  // view sites
  const getViews = async () => {
    const response = await fetch(`${BASE_URL}/client/template-type`);
    return await response.json();
  };

  useEffect(() => {
    console.log('category', activeItem);
    setPage(1);
  }, [setPage, activeItem]);

  useEffect(() => {
    getViews()
      .then((data) => {
        const key = Object.keys(data)[0];
        setViewList((prevState) => [...prevState, ...data[key]]);
      })
      .catch((err) => console.log(err));
    return () => {
      setViewList([]);
    };
  }, []);
  const addFavorite = async (id) => {
    const response = await fetch(`${BASE_URL}/client/template/${id}/add-favorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token'),
      },
    });
    return await response.json();
  };

  const removeFavorite = async (id) => {
    const response = await fetch(`${BASE_URL}/client/template/${id}/remove-favorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token'),
      },
    });
    return await response.json();
  };

  const [templateName, setTemplateName] = useState('');

  const debouncedTemplateName = useDebounce(templateName, 1000);

  const skeletons = [...new Array(30)].map((_, i) => <TemplateSkeleton key={i} />);

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    // setIsLoading(true);
    // if (loading.current === true) {
    getTemplates(15, offset, activeItem, activeListItem, debouncedTemplateName)
      .then((data) => {
        console.log(data);
        const key = Object.keys(data)[0];
        if (!Array.isArray(data[key])) return;
        setTemplateList((prevState) => [...prevState, ...data[key]]);
        setTemplatePagination(preparePagination(data.templateTotalCount, 15));
      })
      .catch((err) => console.log(err));
    // }
    return () => {
      setTemplateList([]);
      setTemplatePagination(1);
      // loading.current = true;
    };
  }, [activeItem, activeListItem, offset, debouncedTemplateName, page]);

  const getFavorite = async (id) => {
    let checkFavorite = await $api.get(`/client/template/${id}/check-favorite`);
    console.log(checkFavorite.data.isFavorite);
    setIsFavorite(checkFavorite.data.isFavorite);
  };

  const dispatch = useDispatch();

  const createId = async (templateId) => {
    const responce = await dispatch(apiNewId(templateId));
    if (responce.payload) {
      navigate(`/templateUpdate/${responce.payload}`);
      console.log('work');
    } else {
      console.log('nononono');
    }
  };
  useEffect(() => {
    setActiveLike(false);
    getFavorite(templateId);
    if (isFavorite) {
      setActiveLike(true);
    }
    console.log(templateId);
  }, [isFavorite]);
  console.log(activeLike, 'activeLike');
  console.log(activeLike, 'activeLike');
  return (
    <section>
      <div className={s.fullListTemlatesBlock}>
        <Screen size="lg">
          <CategorySite
            category={category}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            viewList={viewList.filter((el) => el.id !== '5f8801bff2a48205849f909c')}
            activeListItem={activeListItem}
            setActiveListItem={setActiveListItem}
            setPage={setPage}
          />
        </Screen>
        <div className={s.templatesBox}>
          <div className={s.inputBox}>
            <input
              value={templateName}
              onChange={(event) => {
                // setPage(1);
                setTemplateName(event.target.value);
              }}
              className={s.inputMain}
              type="text"
              placeholder={lang === 'ru' ? `Введите наименование сайта` : `Enter site name`}
            />
            <Loupe className={s.loupe} src={Loupe} alt="" />
          </div>
          <Screen size="lg" down>
            <CategorySiteMobile
              category={category}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              viewList={viewList.filter((el) => el.id !== '5f8801bff2a48205849f909c')}
              activeListItem={activeListItem}
              setActiveListItem={setActiveListItem}
            />
          </Screen>
          <div className={s.box}>
            <div className={s.cardBlock}>
              {templateList.length === 0 && templateName.length === 0 ? (
                skeletons
              ) : templateList.length === 0 && templateName.length > 0 ? (
                <>
                  <div className={s.c}></div>
                  <div className={s.no}>
                    {lang === 'ru'
                      ? `По вашему запросу ничего не найдено`
                      : `Nothing found for your request`}
                  </div>
                  <div></div>
                </>
              ) : (
                templateList?.map((item) => (
                  <div
                    onMouseOver={() => {
                      getFavorite(item.id);
                      setTemplateId(item.id);
                    }}
                    key={item.id}
                    className={s.cardBoxMain}
                    // onMouseEnter={() => {
                    //   getFavorite(item.id);
                    // }}
                    // onMouseLeave={() => {
                    //   getIsFavorite(false);
                    // }}
                  >
                    <div className={s.cardBox}>
                      <div className={s.imgBox}>
                        <img
                          className={s.imgCard}
                          src={`${BASE_URL}/${item.img}`}
                          alt="Шаблон сайта"
                        />
                      </div>
                      <Button
                        onClick={() => {
                          if (localStorage.getItem('token')) {
                            createId(item.id);
                          } else {
                            navigate(`/templateUpdate/${item.id}`);
                          }
                        }}
                        className={s.btnUpdate}
                      >
                        {lang === 'ru' ? `Редактировать` : `Edit`}
                      </Button>
                      <ButtonTemplates
                        onClick={() => {
                          navigate(`/viewTemplate/${item.id}`);
                        }}
                        className={s[lang === 'ru' ? 'btnViewing' : 'btnViewingEn']}
                      >
                        {lang === 'ru' ? `Посмотреть` : `Look`}
                      </ButtonTemplates>
                      {localStorage.token && (
                        <Like
                          onClick={() => {
                            setTemplateId(item.id);
                            // setActiveLike(!activeLike);
                            // eslint-disable-next-line no-lone-blocks
                            if (activeLike) {
                              removeFavorite(item.id);
                              setIsFavorite(false);
                              setActiveLike(false);
                              getFavorite(item.id);
                              console.log(item.id, 'itemId');
                            } else {
                              setActiveLike(true);
                              addFavorite(item.id);
                              getFavorite(item.id);
                              console.log(item.id, 'itemId');
                            }
                          }}
                          className={s[activeLike ? 'like' : 'unLike']}
                          src={Like}
                          alt="Like"
                        />
                      )}
                    </div>
                    <Tooltip title={item.title} placement="top">
                      <h3 className={s.siteName}>
                        {item.title.length > 26 ? item.title.slice(0, 26) + '...' : item.title}
                      </h3>
                    </Tooltip>
                    <Button
                      onClick={() => {
                        if (localStorage.getItem('token')) {
                          createId(item.id);
                        } else {
                          navigate(`/templateUpdate/${item.id}`);
                        }
                      }}
                      className={s.btnUpdateMobile}
                    >
                      {lang === 'ru' ? `Редактировать` : `Edit`}
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/viewTemplate/${item.id}`);
                      }}
                      className={s.btnViewingMobile}
                    >
                      {lang === 'ru' ? `Посмотреть` : `Look`}
                    </Button>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className={s.paginationBox}>
            <input
              ref={inputRef}
              defaultValue={page}
              onBlur={(event) => {
                const { value } = event.target;
                if (value < 1) {
                  setPage(1);
                } else if (value <= templatePagination) {
                  console.log('pathname', location.pathname, qs.stringify({ page: value }));
                  setPage(value);
                  // setPage(value < 1 ? '' : value);
                  //setOffset(() => (value <= 1 ? 0 : Number(value) * 15));
                  // setTimeout(() => {
                  window.scrollTo(0, 570);
                  // setTemplateName('');
                  // }, 2000);
                }
              }}
              className={s.inputPagination}
              type="number"
            />
            <span className={s.current}>из {templatePagination}</span>
            <div className={s.btnBox}>
              <button
                className={s.btnPrev}
                onClick={() => {
                  setPage(page <= 1 ? page : page - 1);
                  window.scrollTo(0, 570);
                }}
              >
                <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
              </button>
              <button
                className={s.btnNext}
                onClick={() => {
                  setPage(page >= templatePagination ? page : page + 1);

                  // setPage(
                  //   (prevState) =>
                  //     prevState >= templatePagination ? Number(prevState) : Number(prevState) + 1,
                  //   setTimeout(() => {
                  window.scrollTo(0, 570);
                  //     // setTemplateName('');
                  //   }, 2000),
                  // );
                  //page + 1 <= templatePagination && setOffset((prevState) => prevState + 15);
                  // setTemplateName('');
                }}
              >
                <Arrow className={s.arrow} src={Arrow} alt="Like" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FullListTemplates;
