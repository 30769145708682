// import React, { useEffect } from 'react';

import gearGreenMiddle from '../../assets/PortfolioHeading/gearGreenMiddle.svg';
import circleArrow from '../../assets/TemplatesHeadingIcon/circleArrow.svg';
import neirosetLeft from '../../assets/TemplatesHeadingIcon/neirosetLeft.svg';
import neirosetRight from '../../assets/TemplatesHeadingIcon/neirosetRight.svg';
import neirosetMiddle from '../../assets/TemplatesHeadingIcon/neirosetMiddle.svg';
import circleBlue from '../../assets/TemplatesHeadingIcon/circleBlue.png';
import circleGreenRight from '../../assets/TemplatesHeadingIcon/circleGreenRight.svg';
import circleGreenBig from '../../assets/PortfolioHeading/circleGreenLeft.svg';
import gearBlueRight from '../../assets/PortfolioHeading/gearBlueRight.svg';
import gearBackgroundBlueLeft from '../../assets/TemplatesHeadingIcon/gearBackgroundBlueLeft.svg';
import s from './AboutUsHeading.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function AboutUsHeading() {
  const lang = useSelector((state) => state.lang.language);

  useEffect(() => {
    window.scrollTo(0, 0);
    //   let aboutCircleGreenBig = document.querySelector(`.${s.aboutCircleGreenBig}`)
    //   let aboutCircleBlue = document.querySelector(`.${s.aboutCircleBlue}`);
    //   let aboutCircleGreenRight = document.querySelector(`.${s.aboutCircleGreenRight}`)
    //  return window.addEventListener('mousemove', function(e) {
    //       let x = e.clientX / window.innerWidth;
    //       let y = e.clientY / window.innerHeight;
    //       aboutCircleBlue.style.transform = 'translate(-' + x * 180 + 'px, -' + y * 50 + 'px)';
    //       aboutCircleGreenBig.style.transform = 'translate(-' + x * 220 + 'px, -' + y * 20 + 'px)';
    //       aboutCircleGreenRight.style.transform = 'translate(-' + x * 138 + 'px, -' + y * 25 + 'px)';
    //   });
  }, []);
  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.aboutUsHeadingBlock}>
            <div className={s.aboutUsHeadingBox}>
              <div className={s.titleBox}>
                <h1 className={s.mainTitle}>
                  {lang === 'ru' ? `О нас` : `About us`}
                </h1>
                <h3 className={s.about}>
                  {lang === 'ru'
                    ? `Веб-маркетплейс RoboWeb поможет вам в создании
                  привлекательного и многофункционального сайта для любых целей.
                  У нас вы можете выбрать готовый сайт и отредактировать
                  его под свои цели. Продвинутые инструменты для маркетинга
                  и продвижения сайта позволят вам самостоятельно участвовать
                  в разработке сайта, контролируя каждый этап работы.`
                    : `The RoboWeb web marketplace will help you create
                  an attractive and multifunctional website for any purpose.
                  Here you can choose a ready site and edit it
                  it for your purposes. Advanced Marketing Tools
                  and website promotion will allow you to participate independently
                  in website development, controlling every stage of work.`}
                </h3>
              </div>
              <div className={s.gearBox}>
                <img
                  className={s.gearGreenMiddle}
                  src={gearGreenMiddle}
                  alt="Gear"
                />
                <img className={s.arrowCircle} src={circleArrow} alt="Arrow" />
              </div>
              <img className={s.neirosetLeft} src={neirosetLeft} alt="" />
              <img className={s.neirosetMiddle} src={neirosetMiddle} alt="" />
              <img className={s.neirosetRight} src={neirosetRight} alt="" />
              <img className={s.circleBlue} src={circleBlue} alt="" />
              <img
                className={s.circleGreenRight}
                src={circleGreenRight}
                alt=""
              />
              <img className={s.circleGreenBig} src={circleGreenBig} alt="" />
              <img className={s.gearBlueRight} src={gearBlueRight} alt="" />
              <img
                className={s.gearBackgroundBlueLeft}
                src={gearBackgroundBlueLeft}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUsHeading;
