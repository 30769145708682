import React, { useState, useContext, useEffect } from 'react';

import logoSvg from '../../assets/img/Logo.svg';
import arrowMenu from '../../assets/img/arrowMenu.svg';
import iconPersonArea from '../../assets/img/icon_person_area.svg';
import burherMenu from '../../assets/img/burgerMenu.svg';
import './Header.scss';
import { Link } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import AdministrationHeader from '../AdministrationHeader/AdministrationHeader';
import PartnersHeader from '../PartnersHeader/PartnersHeader';
import UserHeader from '../UserHeader/UserHeader';
import Button from '../ui/Button/Button';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';
import Heading from '../Heading/Heading';
import UseForFree from '../UseForFree/UseForFree';
import { useDispatch, useSelector } from 'react-redux';
import { switchLanguage } from '../../app/features/user/languageSwitch';

function Header() {
  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);
  // const [activeLunguage, setActiveLunguage] = useState(false);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.language);

  const { headerType } = useContext(HeaderTypeContext);

  // useEffect(() => {}, [lang]);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }
  function enableScroll() {
    document.body.classList.remove('disabled-scroll');
  }

  if (headerType === 'CLIENT') {
    return (
      <header className={'headerMain'}>
        <div className="container">
          <UserHeader />
        </div>
      </header>
    );
  }

  if (headerType === 'ADMIN') {
    return (
      <header className={'headerMain'}>
        <div className="container">
          <AdministrationHeader />
        </div>
      </header>
    );
  }

  if (headerType === 'PARTNER') {
    return (
      <header className={'headerMain'}>
        <div className="container">
          <PartnersHeader />
        </div>
      </header>
    );
  }
  function get() {}

  return (
    <header className="headerMain">
      <div className="container">
        {/* <PartnersHeader/> */}
        <div className="wrapper">
          <Link className="item-link" to="/">
            <img className="logo-roboweb" src={logoSvg} alt="roboweb" />
          </Link>
          <ul className="navigate_list">
            <li className="list_item">
              <Link className="item-link" to="/templates">
                {lang === 'ru' ? 'Готовые сайты ' : 'Ready sites'}
              </Link>
            </li>
            {/* <li className="list_item">
              <Link className="item-link" to="/portfolio">
                {lang === 'ru' ? 'Портфолио ' : 'Portfolio'}
              </Link>
            </li> */}
            <li className="list_item">
              <Link className="item-link" to="/price">
                {lang === 'ru' ? 'Стоимость ' : 'Price'}
              </Link>
            </li>
            <li className="list_item">
              <Link className="item-link" to="/about">
                {lang === 'ru' ? 'О нас ' : 'About us'}
              </Link>
            </li>
            <li className="list_item">
              <Link className="item-link" to="/partners">
                {lang === 'ru' ? 'Партнерам ' : 'Partners'}
              </Link>
            </li>
          </ul>
          {burgerActive && (
            <ul className="navigate_list mobile">
              {/* <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/">
                  {lang === `ru` ? `Главная` : `Home`}
                </Link>
              </li> */}
              <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/templates">
                  {lang === `ru` ? `Готовые сайты` : `Ready sites`}
                </Link>
              </li>
              {/* <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/portfolio">
                  {lang === `ru` ? `Портфолио` : `Portfolio`}
                </Link>
              </li> */}
              <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/price">
                  {lang === `ru` ? `Стоимость` : `Price`}
                </Link>
              </li>
              <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/about">
                  {lang === `ru` ? `О нас` : `About us`}
                </Link>
              </li>
              <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile"
              >
                <Link className="item-link_mobile" to="/partners">
                  {lang === `ru` ? `Партнерам` : `Portfolio`}
                </Link>
              </li>
              <li
                onClick={() => {
                  setBurgerActive(!burgerActive);
                  enableScroll();
                }}
                className="list_item_mobile technical_support_mobile"
              >
                <Link className="item-link_mobile" to="/technicalSupport">
                  {lang === `ru` ? `Тех.поддержка` : `Support`}
                </Link>
              </li>
              <Button
                onClick={() => {
                  openModal();
                  // disabledScroll();
                }}
                className="btn_SignIn"
              >
                {lang === `ru` ? `Войти` : `Sign in`}
              </Button>
              <div className="switch-box-mobile">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={lang === 'ru' ? '' : 'checked'}
                    onClick={() =>
                      lang === 'ru'
                        ? dispatch(switchLanguage('en'))
                        : dispatch(switchLanguage('ru'))
                    }
                    onChange={(e) => {}}
                  />
                  <span className="slider round"></span>
                  <span className="ru">RU</span>
                  <span className="en">EN</span>
                </label>
              </div>
            </ul>
          )}
          <img
            onClick={() => {
              openModal();
              disabledScroll();
            }}
            className="icon_person_area"
            src={iconPersonArea}
            alt="личный кабинет"
          />
          {/* </Link> */}
          <div className="switch-box">
            <label className="switch">
              <input
                type="checkbox"
                checked={lang === 'ru' ? '' : 'checked'}
                onClick={() =>
                  lang === 'ru' ? dispatch(switchLanguage('en')) : dispatch(switchLanguage('ru'))
                }
                onChange={(e) => {}}
              />
              <span className="slider round"></span>
              <span className="ru">RU</span>
              <span className="en">EN</span>
            </label>
          </div>
          {switche ? (
            <SignIn
              show={visiblity}
              closeModal={closeModal}
              openModal={openModal}
              openSignIn={openSignIn}
            />
          ) : (
            <SignUp
              show={visiblity}
              closeModal={closeModal}
              openModal={openModal}
              openSignIn={openSignIn}
            />
          )}
          <div
            onClick={() => {
              setBurgerActive(!burgerActive);

              !burgerActive ? disabledScroll() : enableScroll();
            }}
            className="burgerBox"
          >
            <img
              style={{
                transform: burgerActive ? 'rotate(45deg)' : 'rotate(0deg)',
                transformOrigin: burgerActive ? 'center' : '',
                marginBottom: burgerActive ? '-8px' : '5px',
              }}
              className="burger_top"
              src={burherMenu}
              alt="Burger menu"
            />
            <img
              style={{
                transform: burgerActive ? 'rotate(-45deg)' : 'rotate(0deg)',
                transformOrigin: burgerActive ? 'center' : '',
              }}
              className="burger_Bottom"
              src={burherMenu}
              alt="Burger menu"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
