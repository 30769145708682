import React, { useEffect, useState } from 'react';

import s from './AdministrationTableUsers.module.scss';
import dayjs from 'dayjs';
import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';
import unbanIcon from '../../assets/img/unban.svg';
import iconBlocked from '../../assets/img/iconBlocked.svg';
import { CollectionsOutlined } from '@mui/icons-material';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

const headerTable = [
  {
    ru: '№',
    en: '№',
  },
  {
    ru: 'Имя',
    en: 'Name',
  },
  {
    ru: 'Фамилия',
    en: 'Surname',
  },
  {
    ru: 'Телефон',
    en: 'Telephone',
  },
  {
    ru: 'Почта',
    en: 'Mail',
  },
  {
    ru: 'Регистрация',
    en: 'Registration',
  },
  {
    ru: 'Покупки',
    en: 'Purchases',
  },
  {
    ru: 'Избранное',
    en: 'Favorites',
  },
  {
    ru: 'На поддержке',
    en: 'On support',
  },
];

function AdministrationTableUsers() {
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [render, setRender] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pagesNextDisabled, setPagesNextDisabled] = useState(false);
  const [pagesPrevDisabled, setPagesPrevDisabled] = useState(false);

  const [ban, setBan] = useState(false);
  const lang = useSelector((state) => state.lang.language);


  useEffect(() => {
    getUsers(current);
  }, [current, offset]);

  // useEffect(() => {

  //   getUsers(current)

  // }, [render])

  function adminTableFormat(data) {
    return data.map((el) => {
      return {
        id: el.id,
        isBlock: el.isBlock,
        name: el.name,
        lastName: el.surname,
        telephone: el.tel,
        email: el.email,
        registration: el.createdAt,
        purchases: el.orders?.length ? el.orders?.length + 1 : 0,
        favorites: el.templatesFavorites?.length ? el.templatesFavorites?.length + 1 : 0,
        onSupport: el.supportTemplates?.length ? el.supportTemplates?.length + 1 : 0,
      };
    });
  }
  async function getUsers(pageNum = 1, limit = 6) {
    if (offset >= 0) {
      fetch(`${BASE_URL}/sa/user?role=CLIENT&limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
          'x-access-token': `${localStorage.getItem('token')}`,
        },
      })
        .then((result) => result.json())
        .then((data) => {
          setPages(Math.ceil(data.userCount / limit));
          setUsers(adminTableFormat(data.userList));
          // console.log('limittt', limit);
          // console.log('countus', data.userCount);
        })
        .catch((err) => console.log(err));
    }
  }

  // console.log(users);

  // function deleteUser(id) {
  //   setUsers((prevState) => prevState.filter((item) => item.id !== id));
  //   fetch('http://localhost:5001/user/deleteUser', {
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ id }),
  //   })
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((err) => console.log(err));
  // }

  async function blockUser(id) {
    fetch(`${BASE_URL}/sa/user/${id}/ban`, {
      method: 'POST',
      headers: {
        // "Authorization": `Bearer ${localStorage.getItem('token')}`,
        'x-access-token': `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((result) => {
        // console.log('banned');
        getUsers(current);
        return result;
      })
      .catch((err) => console.log(err));
    setRender(!render);
  }

  async function unlockUser(id) {
    fetch(`${BASE_URL}/sa/user/${id}/unban`, {
      method: 'POST',
      headers: {
        // "Authorization": `Bearer ${localStorage.getItem('token')}`,
        'x-access-token': `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((result) => {
        // console.log('unbanned');
        getUsers(current);
        return result;
      })
      .catch((err) => console.log(err));
    setRender(!render);
  }

  function handleChangePage(e) {
    const { value } = e.target;

    if (value < 0) {
      setCurrent(1);
      setOffset(0);
    } else if (value > pages) {
      setCurrent(current);
      setOffset(() => current * 6 - current);
    } else {
      setCurrent(value);
      setOffset(() => value * 6 - 6);
    }
  }

  return (
    <div className={s.mainContainer}>
      <div className={s.container}>
        <div className={s.tableBlock}>
          <div className={s.tableHeader}>
            {headerTable.map((obj, index) => (
              <div key={index} className={s.tableHeader_item}>
                <span className={s.title}>{lang === 'ru' ? obj.ru : obj.en}</span>
              </div>
            ))}
          </div>
          <div className={s.tableTitle}>
            {users.map((obj, i) => {
              return (
                <div key={obj.id} className={s.tableTitle_item}>
                  <span className={s.title}>{i + 1}</span>
                  <span className={s.title}>{obj.name}</span>
                  <span className={s.title}>{obj.lastName}</span>
                  <span className={s.title}>{obj.telephone}</span>
                  <span className={s.title}>{obj.email}</span>
                  <span className={s.title}>{dayjs(obj.registration).format('DD.MM.YY')}</span>
                  <span className={s.title}>{obj.purchases}</span>
                  <span className={s.title}>{obj.favorites}</span>
                  <span className={s.title}>{obj.onSupport}</span>
                  <span className={s.title}>
                    {!obj.isBlock ? (
                      <img
                        onClick={() => blockUser(obj.id)}
                        className={s.iconBlocked}
                        src={iconBlocked}
                        alt="Ban"
                      />
                    ) : (
                      <img
                        onClick={() => unlockUser(obj.id)}
                        className={s.iconUnban}
                        src={unbanIcon}
                        alt="Unban"
                      />
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.paginationBox}>
          <input
            value={current}
            // onBlur={() => setCurrent(current < 1 ? 1 : current)}
            onChange={handleChangePage}
            className={s.inputPagination}
            type="number"
            min="1"
          />
          <span className={s.current}>из {pages}</span>
          <div className={s.btnBox}>
            <button
              className={s.btnPrev}
              onClick={() => {
                setPagesNextDisabled(false);

                if (current < 2) {
                  setPagesPrevDisabled(true);
                } else {
                  setCurrent((prevState) => prevState - 1);
                  setOffset((prevState) => (prevState === 0 ? 0 : prevState - 6));

                  window.scrollTo(0, 300);

                  setPagesPrevDisabled(false);
                }
              }}
              disable={pagesPrevDisabled}
            >
              <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
            </button>
            <button
              className={s.btnNext}
              onClick={() => {
                setPagesPrevDisabled(false);
                if (current === pages) {
                  setPagesNextDisabled(true);
                } else {
                  setCurrent((prevState) => (prevState >= pages ? +prevState : +prevState + 1));
                  // setOffset((prev) => prev + 4);
                  window.scrollTo(0, 300);
                  setOffset((prevState) => prevState + 6);
                  setPagesNextDisabled(false);
                }
              }}
              disabled={pagesNextDisabled}
            >
              <Arrow className={s.arrow} src={Arrow} alt="Like" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationTableUsers;
