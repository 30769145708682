import { useState } from 'react';
import osisstant from '../../assets/img/onlineOsisstant.svg';

import s from './MyWorksLeaveFeedback.module.scss';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
// import arrowMenu from '../../assets/img/arrowMenu.svg';
import x from '../../assets/img/x.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../ui/Button/Button';
import { BASE_URL } from '../../http';
import { useSelector } from 'react-redux';

function MyWorksLeaveFeedback() {
  const [validate, setValidate] = useState({});

  const lang = useSelector((state) => state.lang.language);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [rating, setRating] = useState(0);

  const imgOssistant = {
    position: 'absolute',
    left: '132px',
    top: '29px',
    fontFamily: 'Circe',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  };

  const notifyOk = () =>
    toast(
      <div>
        <span style={imgOssistant}>Ваш отзыв был отправлен на модерацию!</span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const notifyErr = () =>
    toast(
      <div>
        <span style={imgOssistant}>Вы уже оставляли отзыв!</span>
        <img width={300} src={osisstant} />
      </div>,
    );

  const zeroingValues = () => {
    setTitle('');
    setText('');
    setRating(0);
  };

  //Валидация

  const sendFeedback = async () => {
    if (!title) {
      setValidate((prev) => ({
        ...prev,
        title: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (title.length < 5) {
      setValidate((prev) => ({
        ...prev,
        title:
          lang === 'ru'
            ? `Кол-во символов должно быть больше 5-ти`
            : `
    The number of characters must be more than 5`,
      }));
    }

    if (!text) {
      setValidate((prev) => ({
        ...prev,
        text: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (text.length < 10) {
      setValidate((prev) => ({
        ...prev,
        text:
          lang === 'ru'
            ? `Кол-во символов должно быть больше 10-ти`
            : `
    The number of characters must be more than 10`,
      }));
    }

    if (!rating) {
      setValidate((prev) => ({
        ...prev,
        rating: lang === 'ru' ? `Обязательное поле` : `Required field`,
      }));
    } else if (rating && text.length > 10 && title.length > 5) {
      await fetch(`${BASE_URL}/client/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({ title, text, rating: +rating }),
      }).then((res) => {
        if (res.ok) {
          console.log('use');
          zeroingValues();
          notifyOk();
        } else {
          notifyErr();
        }
      });
    }

    // return await response.json();
  };

  const changeInputValue = (e) => {
    if (validate[e.target.name] !== undefined) {
      setValidate((prev) => ({ ...prev, [e.target.name]: null }));
    }

    if (e.target.name === 'title') {
      setTitle(e.target.value);
    }

    if (e.target.name === 'text') {
      setText(e.target.value);
    }

    if (e.target.name === 'rating') {
      setRating(e.target.value);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.leaveFeedbackBlock}>
        <div className={s.ratingBox}>
          <div className={s.error}>
            <h3 className={s.titleRating}>{lang === 'ru' ? `Оценка:` : `Grade:`}</h3>
            {validate.rating && <span className={s.errorVisible}>{validate.rating}</span>}
          </div>
          <Rating
            name="size-large"
            defaultValue={0}
            size="large"
            value={rating}
            onChange={(e) => {
              changeInputValue({ target: { name: 'rating' } });
              setRating(e.target.value);
            }}
            emptyIcon={<StarIcon style={{ opacity: 0.55, color: '#9EC3FF' }} fontSize="inherit" />}
          />
        </div>
        <div className={s.headerBox}>
          <h3 className={s.titleHeader}>{lang === 'ru' ? `Заголовок:` : `Title:`}</h3>
          {validate.title && <span className={s.errorVisible}>{validate.title}</span>}
          <input
            className={s.header}
            value={title}
            onChange={(e) => {
              changeInputValue({ target: { name: 'title' } });
              setTitle(e.target.value);
            }}
          />

          <img onClick={() => setTitle('')} className={s.x} src={x} alt="Arrow menu" />
        </div>
        <div className={s.commentBox}>
          <h3 className={s.titleComment}>{lang === 'ru' ? `Комментарий:` : `Comment:`}</h3>
          {validate.text && <span className={s.errorVisible}>{validate.text}</span>}
          <textarea
            className={s.comment}
            value={text}
            onChange={(e) => {
              changeInputValue({ target: { name: 'text' } });
              setText(e.target.value);
            }}
          />
          <img onClick={() => setText('')} className={s.x} src={x} alt="Arrow menu" />
        </div>
        <p className={s.agreement}>
          {lang === 'ru'
            ? `Оставляя
          отзыв, вы соглашаетесь с`
            : `Leaving
          review, you agree to`}{' '}
          <span className={s.terms}>
            {lang === 'ru'
              ? `условиями
            использования `
              : `conditions
              use `}
          </span>
          {lang === 'ru' ? `сайта` : `site`}
        </p>
        <Button
          onClick={() => {
            sendFeedback();
          }}
          className={s.btnReview}
        >
          {lang === 'ru'
            ? `Отправить
          отзыв`
            : `Send review`}
        </Button>
        <ToastContainer
          hideProgressBar={true}
          autoClose={6000}
          limit={1}
          // style={{ position: 'relative' }}
          position="bottom-left"
        />
      </div>
    </div>
  );
}

export default MyWorksLeaveFeedback;
