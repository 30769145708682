/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';

import s from './ForgotPasswordPopAp.module.scss';
import logo from '../../assets/img/Logo.svg';
import logoMobile from '../../assets/img/logoMobile.svg';

import exit from '../../assets/img/exit.svg';
import Button from '../ui/Button/Button';
import { HeaderTypeContext } from '../../utils/HeaderTypeContext';

import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';
// import SignIn from '../SignIn/SignIn';

function ForgotPasswordPopAp({
  showForgot,
  setShowForgot,
  component,
  templateId,
  oldTitle,
  setActiveTreeDots,
  role,
  description,
  descriptionFull,
}) {
  //   const [toggle, setToggle] = useState('Пользователь');
  //   const [show, setShow] = useState(true);
  //   const [error, setError] = useState('');
  //   const [block, setBlock] = useState(false);
  const [resOk, setResOk] = useState(false);

  const formik = useFormik(
    component === 'rename'
      ? {
          initialValues: {
            title: oldTitle,
            description,
            descriptionFull,
          },
          validate: (values) => {
            const errors = {};
            if (values.title.trim().length === 0) {
              errors.title = 'Введите наименование';
            } else if (values.title.length < 5) {
              errors.title = 'Наименование должно быть больше 5ти символов';
            } else if (values.title.length > 25) {
              errors.title = 'Наименование должно быть меньше 25ти символов';
            }
            return errors;
          },
          onSubmit: (title, description, descriptionFull) => {
            fetch(
              role === 'admin'
                ? `${BASE_URL}/sa/template/${templateId}`
                : `${BASE_URL}/client/user/templates/${templateId}`,
              {
                method: 'PATCH',
                headers: {
                  'x-access-token': localStorage.getItem('token'),
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(title, description, descriptionFull),
              }
            )
              .then((result) => {
                setResOk(result.ok);
                setShowForgot(false);
                setActiveTreeDots(null);
                return result.json();
              })
              .catch((err) => {
                console.log(err);
              });
          },
        }
      : {
          initialValues: {
            email: '',
          },
          validate: (values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Введите Email';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = 'Неверный формат Email';
            }
            return errors;
          },
          onSubmit: (email) => {
            fetch(`${BASE_URL}/auth/reset-password`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(email),
            })
              .then((result) => {
                setResOk(result.ok);
                return result.json();
              })
              .catch((err) => {
                console.log(err.status);
              });
          },
        }
  );

  const lang = useSelector((state) => state.lang.language);

  const { setHeaderType } = useContext(HeaderTypeContext);

  return (
    <section id="treeDots">
      <div className={s.container} id="treeDots">
        <div
          style={{
            visibility: showForgot ? 'visible' : 'hidden',
            opacity: showForgot ? '1' : '0',
          }}
          className={s.wrapper}
        >
          <div className={s.forgotBox} id="treeDots">
            <img
              onClick={() => {
                setShowForgot(false);
                setResOk(false);
              }}
              className={s.exit}
              src={exit}
              alt="Exit"
            />
            <img className={s.logo} src={logo} alt="Logo" id="treeDots" />
            <img
              className={s.logoMobile}
              src={logoMobile}
              alt="Logo"
              id="treeDots"
            />
            <h2 className={s.mainTitle} id="treeDots">
              {component === 'rename' && lang === 'ru'
                ? 'Переименовать шаблон'
                : component !== 'rename' && lang === 'ru'
                ? 'Восcтановление пароля'
                : component === 'rename' && lang === 'en'
                ? 'Rename template'
                : 'Password recovery'}
            </h2>
            <form
              className={s.form}
              onSubmit={formik.handleSubmit}
              id="treeDots"
            >
              <div className={s.inputBox} id="treeDots">
                {!resOk ? (
                  <input
                    value={
                      component === 'rename'
                        ? formik.values.title
                        : formik.values.email
                    }
                    onChange={formik.handleChange}
                    placeholder={
                      component === 'rename' && lang === 'ru'
                        ? 'Введите новое наименование'
                        : component !== 'rename' && lang === 'ru'
                        ? 'E-mail'
                        : component === 'rename' && lang === 'en'
                        ? 'Enter a new name'
                        : 'E-mail'
                    }
                    type={component === 'rename' ? 'title' : 'email'}
                    id={component === 'rename' ? 'title' : 'email'}
                    className={s.inputName}
                    onBlur={formik.handleBlur}
                  />
                ) : component === 'rename' ? (
                  setShowForgot(false)
                ) : (
                  <span className={s.passwordMessage} id="treeDots">
                    {lang === 'ru'
                      ? 'Пароль отправлен Вам на почту!'
                      : 'Password sent to your email!'}
                  </span>
                )}
                {component === 'rename' ? (
                  formik.touched.title && formik.errors.title ? (
                    <p className={s.error} id="treeDots">
                      <span className={s.errorVisible} id="treeDots">
                        {formik.errors.title}
                      </span>
                    </p>
                  ) : // <p className={s.error} style={{ height: 16 }}></p>
                  null
                ) : formik.touched.email && formik.errors.email ? (
                  <p className={s.error} id="treeDots">
                    <span className={s.errorVisible}>
                      {formik.errors.email}
                    </span>
                  </p>
                ) : // <p className={s.error} style={{ height: 16 }}></p>
                null}
              </div>
              {!resOk && (
                <Button
                  type={'submit'}
                  className={s.btnSignIn}
                  onClick={formik.handleSubmit}
                >
                  {component === 'rename' && lang === 'ru'
                    ? 'Переименовать'
                    : component !== 'rename' && lang === 'ru'
                    ? 'Отправить'
                    : component === 'rename' && lang === 'en'
                    ? 'Rename'
                    : 'Send'}
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPasswordPopAp;
