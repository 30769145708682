// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_button__sBx4u {\n  font-family: \"Circe\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 35px;\n  text-align: center;\n  background: linear-gradient(178.7deg, #00C6FB 2.64%, #159AE6 36.84%, #005BEA 90.16%);\n  border-radius: 600px;\n  color: #FFFFFF;\n  padding: 23px 37px;\n  border: none;\n  cursor: pointer;\n}\n.Button_button__sBx4u:hover {\n  background: linear-gradient(178.7deg, #3FA6F1 2.64%, #2F8CE2 39.73%, #194AC8 90.16%);\n}\n.Button_button__sBx4u:active {\n  background: linear-gradient(178.7deg, #4DABEF 2.64%, #0C74D5 39.73%, #0B3AB5 90.16%);\n}\n.Button_button__sBx4u:disabled {\n  background: linear-gradient(180deg, #BCD6FF 0%, #9EC3FF 54.69%, #76AAFF 100%);\n  opacity: 0.9;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,oFAAA;EACA,oBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AACJ;AAAI;EACI,oFAAA;AAER;AAAI;EACI,oFAAA;AAER;AAAI;EACI,6EAAA;EACA,YAAA;AAER","sourcesContent":[".button {\n    font-family: 'Circe';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 24px;\n    line-height: 35px;\n    text-align: center;\n    background: linear-gradient(178.7deg, #00C6FB 2.64%, #159AE6 36.84%, #005BEA 90.16%);\n    border-radius: 600px;\n    color: #FFFFFF;\n    padding: 23px 37px;\n    border: none;\n    cursor: pointer;\n    &:hover {\n        background: linear-gradient(178.7deg, #3FA6F1 2.64%, #2F8CE2 39.73%, #194AC8 90.16%);\n    }\n    &:active {\n        background: linear-gradient(178.7deg, #4DABEF 2.64%, #0C74D5 39.73%, #0B3AB5 90.16%);\n    }\n    &:disabled {\n        background: linear-gradient(180deg, #BCD6FF 0%, #9EC3FF 54.69%, #76AAFF 100%);\n        opacity: 0.9;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button_button__sBx4u"
};
export default ___CSS_LOADER_EXPORT___;
