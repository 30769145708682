import React, { useRef, useState } from 'react';

import Button from '../ui/Button/Button';
import styles from './Heading.module.scss';
import logoScreen from '../../assets/img/screen.svg';
import logoIllustrationRobot from '../../assets/img/robot.svg';
import logoNeuralNetwork from '../../assets/img/neural_network.svg';
import logoNeuralNetworkRight from '../../assets/img/neural_network_right.svg';
import logoWave from '../../assets/img/wave.svg';
import iconGearGreen from '../../assets/img/gear_green.svg';
import iconGearBlue from '../../assets/img/gear_blue.svg';
import iconSemiCircle from '../../assets/img/semiCircle.svg';
import iconSmallGearGreen from '../../assets/img/smallGearGreen.svg';
import iconGearBlueBig from '../../assets/img/gearBlueBig.svg';
import iconCircleBigBlue from '../../assets/img/circleBigBlue.svg';
import iconBallBackground from '../../assets/img/ballBackground.png';
import iconBallGreenLeftBackground from '../../assets/img/ballGreenLeftBackground.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import UseForFree from '../UseForFree/UseForFree';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { useSelector } from 'react-redux';

function Heading({ closeModal, openModal, openSignIn }) {
  const lang = useSelector((state) => state.lang.language);

  const navigate = useNavigate();
  const ref = useRef();

  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    let robot = document.querySelector(`.${styles.illustrationRobot}`);
    let circleBigBlue = document.querySelector(`.${styles.circleBigBlue}`);
    let ballBackground = document.querySelector(`.${styles.ballBackground}`);
    let ballGreenLeftBackground = document.querySelector(`.${styles.ballGreenLeftBackground}`);
    let screen = document.querySelector(`.${styles.logoScreen}`);
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      robot.style.transform = 'translate(-' + x * 10 + 'px, -' + y * 50 + 'px)';
      circleBigBlue.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 10 + 'px)';
      ballBackground.style.transform = 'translate(-' + x * 38 + 'px, -' + y * 25 + 'px)';
      ballGreenLeftBackground.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 26 + 'px)';
      screen.style.transform = 'translate(-' + x * 25 + 'px, -' + y * 60 + 'px)';
    });
  }, []);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }
  // function enableScroll() {
  //   document.body.classList.remove('disabled-scroll');
  // }

  return (
    <section className={styles.heading}>
      <div className={styles.container}>
        <div ref={ref} className={styles.wrapper}>
          <img className={styles.circleBigBlue} src={iconCircleBigBlue} alt="Синий шар" />
          <img className={styles.ballBackground} src={iconBallBackground} alt="Синий шар" />
          <img
            className={styles.ballGreenLeftBackground}
            src={iconBallGreenLeftBackground}
            alt="Шар на заднем фоне"
          />

          <img className={styles.gearGreenBlueBig} src={iconGearBlueBig} alt="Шестеренка" />
          <img className={styles.smallGearGreen} src={iconSmallGearGreen} alt="Шестеренка" />
          <img className={styles.semiCircle} src={iconSemiCircle} alt="Полукруг" />
          <img className={styles.gearGreen} src={iconGearGreen} alt="Шестеренка" />
          <img className={styles.gearBlue} src={iconGearBlue} alt="Шестеренка" />
          <img className={styles.neuralNetwork} src={logoNeuralNetwork} alt="Нейросеть" />
          <img className={styles.neuralNetworkRight} src={logoNeuralNetworkRight} alt="Нейросеть" />
          <div className={styles.freeInfoBox}>
            <h1 className={styles.freeInfo}>
              {lang === 'ru'
                ? 'Получите свой сайт онлайн за считанные минуты'
                : 'Get your website online within minutes'}
            </h1>
            <p className={styles.about}>
              {lang === 'ru'
                ? 'Первый в России веб-маркетплейс RoboWeb — '
                : `Russia's first web-marketplace RoboWeb`}
              <br />
              {lang === 'ru'
                ? 'это возможность получить свой сайт быстро, просто '
                : `is an opportunity to get a website quickly, easily`}
              <br className={styles.br} />
              {lang === 'ru' ? 'и с минимальным бюджетом' : `and with minimal budget`}
            </p>
            <Button
              onClick={() =>
                localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
              }
              className={styles.buttonHeading}
            >
              {lang === 'ru' ? 'Попробовать бесплатно' : `Try For Free`}
            </Button>
          </div>
          <img className={styles.logoScreen} src={logoScreen} alt="Экран" />
          <img className={styles.illustrationRobot} src={logoIllustrationRobot} alt="robot" />
          <img className={styles.wave} src={logoWave} alt="Волна" />
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default Heading;
