import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import $api from '../../http';
import { REACT_APP_CP_PUBLIC_ID } from '../../utils/constants';
import Button from '../ui/Button/Button';
import s from './OperationCardForm.module.scss';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/lib/styles.scss';
import { useSelector } from 'react-redux';

function OperationCardForm() {
  const [amount, setAmount] = useState('');
  const lang = useSelector((state) => state.lang.language);

  const test = useRef();

  const testCheck = useRef();

  const [crypto, setCrypto] = useState('');

  const [card, setCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  //CloudPayments
  console.log(card);
  const checkout = () => {
    const form = document.createElement('form');
    form.innerHTML = `
    <input value="${card.number}" type="text" data-cp="cardNumber">
    <input value="${card.expiry.slice(0, 2)}" type="text" data-cp="expDateMonth">
    <input value="${card.expiry.slice(2)}" type="text" data-cp="expDateYear">
    <input value="${card.cvc}" type="text" data-cp="cvv">
    <input value="${card.name}" type="text" data-cp="name">
    `;

    let checkout = new window.cp.Checkout({
      publicId: REACT_APP_CP_PUBLIC_ID,
      container: form,
    });

    return checkout.createPaymentCryptogram();
  };

  const pay = (id) => {
    let payments = new window.cp.Widget({
      language: 'ru-RU',
      email: '',
      applePaySupport: false,
      googlePaySupport: false,
      yandexPaySupport: true,
      tinkoffInstallmentSupport: false,
    });
    payments
      .pay('charge', {
        publicId: REACT_APP_CP_PUBLIC_ID,
        description: 'Тестовая оплата',
        amount: +amount,
        currency: 'RUB',
        invoiceId: id,
        // accountId: '123',
        // email: '',
        skin: 'classic',
        requireEmail: false,
      })
      .then(function (widgetResult) {
        console.log('result', widgetResult);
      })
      .catch(function (error) {
        console.log('error', error);
      });
  };

  //Получение скрипта для CloudPayments
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const scriptCheckout = document.createElement('script');
    scriptCheckout.src = 'https://checkout.cloudpayments.ru/checkout.js';
    document.head.appendChild(scriptCheckout);
    return () => {
      document.head.removeChild(scriptCheckout);
    };
  }, []);

  //Пополнение баланса
  const sendAmount = async () => {
    try {
      const res = await $api.post(`/client/bill/widget`, {
        amount: +amount,
        type: 'REPLENISHMENT',
      });
      pay(res.data.id);
      // checkout();
    } catch (error) {
      console.log(error);
    }
  };

  const [cardList, setCardList] = useState([]);
  const [secure, setSecure] = useState({});

  const getCard = async () => {
    const cards = await $api.get('/client/card');
    console.log(cards);
    setCardList(cards.data);
  };

  useEffect(() => {
    getCard();
  }, []);

  const addCard = async (crypto) => {
    const cardInfo = await $api.post('/client/card', {
      cardCryptogram: crypto,
    });
    if (cardInfo.status === 201) {
      setSecure(cardInfo.data);
      // setTimeout(() => {
      //   testCheck.current.submit();
      // }, 1000);
    }
    console.log(cardInfo);
  };
  useEffect(() => {
    if (Object.keys(secure).length) {
      testCheck.current.submit();
    }
  }, [secure]);

  console.log(secure);

  const [acs, setAcs] = useState({});

  const paymentAmount = async () => {
    try {
      const res = await $api.post(`/client/bill/card`, {
        cardCryptogram: crypto,
        saveCard: true,
      });
      console.log(res);
      setAcs(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputFocus = (evt) => {
    setCard((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setCard((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div className={s.wrapper}>
      <form className={s.FillTheBalanceForm}>
        <label className={s.inputLabel} htmlFor="">
          {lang === 'ru' ? 'Пополнить баланс' : 'Top up balance'}
        </label>
        <div className={s.inputBox}>
          <span className={s.about}>{lang === 'ru' ? 'Введите сумму' : 'Enter amount'}</span>
          <input
            className={s.inputMany}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
          />
        </div>
        <Button
          className={s.btnSubmitMany}
          onClick={async (e) => {
            await sendAmount(e);
          }}
        >
          {lang === 'ru' ? 'Пополнить' : 'Top up'}
        </Button>
      </form>
      {/* <div className={s.cardBox} id="PaymentForm">
        <Cards
          className={s.rccs}
          number={card.number}
          expiry={card.expiry}
          cvc={card.cvc}
          name={card.name}
          focused={card.focus}
        />
        <form className={s.cardForm} id="paymentFormSample" autocomplete="off">
          <label className={s.cardNumber} htmlFor="">
            {lang === 'ru' ? 'Номер карты ' : 'Card number '}
            <span className={s.requiredField}>*</span>
          </label>
          <input
            className={s.inputCardNumber}
            type="number"
            name="number"
            value={card.number}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            data-cp="cardNumber"
          />
          <label className={s.labelName} htmlFor="">
            {lang === 'ru' ? 'Имя Фамилия' : 'First Name Last Name '}
            <span className={s.requiredField}>*</span>
          </label>
          <input
            className={s.inputName}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={card.name}
            type="text"
            data-cp="name"
            name="name"
          />
          <div className={s.dataAndCvcConrainer}>
            <div className={s.dataBox}>
              <label className={s.labelDate} htmlFor="">
                {lang === 'ru' ? 'Дата' : 'Date'}
                <span className={s.requiredField}>*</span>
              </label>
              <input
                className={s.inputDate}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={card.expiry}
                type="text"
                data-cp="expiry"
                name="expiry"
              />
            </div>
            <div className={s.cvcBox}>
              <label className={s.labelCvc} htmlFor="">
                {lang === 'ru' ? 'CVC' : 'CVC'}

                <span className={s.requiredField}>*</span>
              </label>
              <input
                className={s.inputCvc}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={card.cvc}
                type="text"
                data-cp="cvc"
                name="cvc"
              />
            </div>
          </div>

          <button
            className={s.btnCard}
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              const crypto = await checkout();
              // console.log(crypto);
              await addCard(crypto);
            }}
          >
            Сохранить
          </button>
        </form>
      </div> */}
      <form id="paymentFormSample" autocomplete="off">
        {/* <input
          type="text"
          name="number"
          value={card.number}
          onChange={(e) => changeIV(e)}
          data-cp="cardNumber"
        />
        <input
          type="text"
          name="dateM"
          value={card.dateM}
          onChange={(e) => changeIV(e)}
          data-cp="expDateMonth"
        />
        <input
          type="text"
          name="dateY"
          value={card.dateY}
          onChange={(e) => changeIV(e)}
          data-cp="expDateYear"
        />
        <input
          type="text"
          name="cvv"
          value={card.cvv}
          onChange={(e) => changeIV(e)}
          data-cp="cvv"
        />
        <input
          type="text"
          name="name"
          value={card.name}
          onChange={(e) => changeIV(e)}
          data-cp="name"
        /> */}

        {/* <input type="text" value="4242 4242 4242 4242" data-cp="cardNumber" />
        <input type="text" value="04" data-cp="expDateMonth" />
        <input type="text" value="44" data-cp="expDateYear" />
        <input type="text" value="123" data-cp="cvv" />
        <input type="text" value="TIM" data-cp="name" /> */}
      </form>

      {/* пока что закоментить */}
      {/* <button onClick={paymentAmount}>Проверка</button> */}
      <form
        ref={test}
        name="downloadForm"
        // action="https://acs.cloudpayments.ru/test"
        action={acs.AcsUrl}
        method="POST"
      >
        <input
          type="hidden"
          name="PaReq"
          value={acs.PaReq}
          // value="+/eyJNZXJjaGFudE5hbWUiOm51bGwsIkZpcnN0U2l4IjoiNDI0MjQyIiwiTGFzdEZvdXIiOiI0MjQyIiwiQW1vdW50IjoxNS4wLCJDdXJyZW5jeUNvZGUiOiJSVUIiLCJEYXRlIjoiMjAyMy0wMi0wM1QwMDowMDowMCswMDowMCIsIkN1c3RvbWVyTmFtZSI6bnVsbCwiQ3VsdHVyZU5hbWUiOiJydS1SVSIsIkRlc2NyaXB0aW9uIjpudWxsLCJNZXJjaGFudExvZ29VcmwiOm51bGx9"
        />
        <input
          type="hidden"
          name="MD"
          // value="504"
          value={acs.MD}
        />
        <input
          type="hidden"
          name="TermUrl"
          // value="https://example.com/post3ds?order=1234567"
          value={acs.TermUrl}
        />

        {/* пока что закоментить */}
        {/* <button onClick={() => test.current.submit()}>Test</button> */}
      </form>
      <form
        ref={testCheck}
        name="downloadForm"
        // action="https://acs.cloudpayments.ru/test"
        action={secure.AcsUrl}
        method="POST"
      >
        <input
          type="hidden"
          name="PaReq"
          value={secure.PaReq}
          // value="+/eyJNZXJjaGFudE5hbWUiOm51bGwsIkZpcnN0U2l4IjoiNDI0MjQyIiwiTGFzdEZvdXIiOiI0MjQyIiwiQW1vdW50IjoxNS4wLCJDdXJyZW5jeUNvZGUiOiJSVUIiLCJEYXRlIjoiMjAyMy0wMi0wM1QwMDowMDowMCswMDowMCIsIkN1c3RvbWVyTmFtZSI6bnVsbCwiQ3VsdHVyZU5hbWUiOiJydS1SVSIsIkRlc2NyaXB0aW9uIjpudWxsLCJNZXJjaGFudExvZ29VcmwiOm51bGx9"
        />
        <input
          type="hidden"
          name="MD"
          // value="504"
          value={secure.MD}
        />
        <input
          type="hidden"
          name="TermUrl"
          // value="https://example.com/post3ds?order=1234567"
          value={secure.TermUrl}
        />

        {/* пока что закоментить */}
        {/* <button onClick={() => testCheck.current.submit()}>Test 09/02</button> */}
      </form>
    </div>
  );
}

export default OperationCardForm;
