import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import s from './SimilarTemplates.module.scss';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

// import {Pagination} from 'swiper'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import axios from 'axios';
import Button from '../ui/Button/Button';
import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import { ReactComponent as Like } from '../../assets/FullListTeamplates/like.svg';

// import avatar from '../../assets/reviewsImg/avatar.jpeg';
// import av from '../../assets/topTenPartnersIcon/av.jpg';
// import star from '../../assets/topTenPartnersIcon/star.svg';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function SimilarTemplates() {
  const [data, setData] = useState({});

  const lang = useSelector((state) => state.lang.language);
  //   let swiper;
  //   useEffect(() => {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       wiper = document.querySelector('.mySwiper2').swiper;

  //ОШИБКА 2/15
  //   }, []);
  // console.log(swiper);
  // useEffect(() => {
  //   axios(`https://back.roboweb.site/templates/get/2/15`).then((res) =>
  //     setData(res.data)
  //   );
  //   // window.scrollTo({top:0})
  //   // window.scrollTo({
  //   //   top: 0,
  //   //   behavior: 'instant',
  //   // });
  // }, []);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className={s.semilarTemplatescontainer}>
      <div className={s.partnersCardsBlock}>
        <h2 className={s.mainTitle}>
          {lang === `ru` ? `Похожие шаблоны` : `Similar Templates`}
        </h2>
        <div className={s.cardBlock}>
          <Swiper
            ref={sliderRef}
            slidesPerView={1}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            spaceBetween={15}
            // pagination={true}
            pagination={{
              // clickable: true,
              dynamicBullets: true,
            }}
            // navigation={true}

            // breakpoints={{
            //   830: {
            //    width: 640,
            //     slidesPerView: 1,
            //     // spaceBetween: 10
            //   },
            // }}

            // Responsive breakpoints
            breakpoints={{
              // when window width is >= 320px
              280: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              720: {
                slidesPerView: 2,
                spaceBetween: 100,
              },
              // when window width is >= 480px
              820: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              1160: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Pagination, Navigation]}
            style={{
              paddingBottom: '15px',
              paddingTop: '15px',
              paddingLeft: '8px',
            }}
            className={s.mySwiper2}
          >
            {data?.templates?.map((obj) => (
              <SwiperSlide key={obj.id} className={s.cardsBox}>
                <div className={s.cardBoxMain} key={obj.id}>
                  <div className={s.cardBox}>
                    <div className={s.imgBox}>
                      <img
                        className={s.imgCard}
                        src={`https://back.roboweb.site/${obj.variants[0].imageCatalog}`}
                        alt="Шаблон сайта"
                      />
                    </div>
                    <Button className={s.btnUpdate}>
                      {lang === `ru` ? `Редактировать` : `Edit`}
                    </Button>

                    <ButtonTemplates className={s.btnViewing}>
                      {lang === `ru` ? `Посмотреть` : `Look`}
                    </ButtonTemplates>
                    <Like className={s.like} src={Like} alt="Like" />
                  </div>
                  <h3 className={s.siteName}>{obj.title}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className={s.buttonBox}>
          <button className={s.arrowBtnPrev} onClick={handlePrev}>
            <ArrowLeft className={s.arrowPrev} src={Arrow} alt="Arrow" />
          </button>
          <button className={s.arrowBtnNext} onClick={handleNext}>
            <Arrow className={s.arrowNext} src={Arrow} alt="Arrow" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SimilarTemplates;
