import React, { useState } from 'react';
import Button from '../ui/Button/Button';

import s from './SubmitYourApplicationTemplateBlock.module.scss';
import notebookImg from '../../assets/img/notebook.svg';
import ballBackground from '../../assets/img/ballBackground.png';
import ballBlue from '../../assets/img/ballBlue.png';
import bigGreenBall from '../../assets/img/bigGreenBall.svg';
import PopApWebsiteDevelopmentRequest from '../PopApWebsiteDevelopmentRequest/PopApWebsiteDevelopmentRequest';
import { useSelector } from 'react-redux';

function SubmitYourApplicationTemplateBlock() {
  const [visiblityRequest, setVisiblityRequest] = useState(false);

  const lang = useSelector((state) => state.lang.language);

  function openModalRequest() {
    setVisiblityRequest(!visiblityRequest);
  }

  function closeModalRequest(e) {
    setVisiblityRequest(e);
  }

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }
  return (
    <section>
      <div className={s.container}>
        <div className={s.submitYourApplicationBlock}>
          <div className={s.titleBox}>
            <h2 className={s.mainTitle}>
              {lang === `ru`
                ? `Нужна доработка сайта или профессиональная разработка сайта?`
                : `
Do you need website redesign or professional website development?`}
            </h2>
            <p className={s.about}>
              {lang === `ru`
                ? `Вам срочно требуется создать новый сайт для вашей компании?
              RoboWeb поможет вам! Мы создадим новый проект, полностью
              отвечающий вашим критериям. Также, вы можете выбрать готовый
              шаблон сайта и самостоятельно его отредактировать. С нами вы легко
              и быстро получите качественный сайт.`
                : `Do you urgently need to create a new website for your company?
              RoboWeb will help you! We will create a new project, completely
              matching your criteria. Also, you can choose
              site template and edit it yourself. With us you are easy
              and quickly get a quality site.`}
            </p>
            <Button
              onClick={() => [openModalRequest(), disabledScroll()]}
              className={s.btnSubmitApplication}
            >
              {lang === `ru`
                ? `Оставить заявку`
                : `
            Submit your application`}
            </Button>
            <PopApWebsiteDevelopmentRequest
              show={visiblityRequest}
              closeModal={closeModalRequest}
            />
          </div>
          <img className={s.notebookImg} src={notebookImg} alt="Notebook" />
          <img className={s.ballBackground} src={ballBackground} alt="Ball background" />
          <img className={s.ballBlue} src={ballBlue} alt="Ball blue" />
          <img className={s.bigGreenBall} src={bigGreenBall} alt="Green ball" />
        </div>
      </div>
    </section>
  );
}

export default SubmitYourApplicationTemplateBlock;
