import React, { useEffect, useState } from 'react';

import s from './AdministrationTablePartners.module.scss';
import dayjs from 'dayjs';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';
import iconUnban from '../../assets/img/unban.svg';
import iconBlocked from '../../assets/img/iconBlocked.svg';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

const headerTable = [
  {
    ru: '№',
    en: '№',
  },
  {
    ru: 'Имя',
    en: 'Name',
  },
  {
    ru: 'Фамилия',
    en: 'Surname',
  },
  {
    ru: 'Телефон',
    en: 'Telephone',
  },
  {
    ru: 'Почта',
    en: 'Mail',
  },
  {
    ru: 'Регистрация',
    en: 'Registration',
  },
  {
    ru: 'Шаблоны',
    en: 'Templates',
  },
  {
    ru: 'Избранное',
    en: 'Favorites',
  },
  {
    ru: 'Просмотры',
    en: 'Views',
  },
];


//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@exampleq.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
//   {
//     name: 'Алина',
//     lastName: 'Иванцова',
//     telephone: '+7 999 756 5323',
//     email: 'mail@example.ru',
//     registration: '01.10.21',
//     purchases: 15,
//     favorites: 87,
//     onSupport: 2,
//     iconUnban,
//     iconBlocked,
//   },
// ];

function AdministrationTablePartners() {
  const LIMIT = 2;
  const lang = useSelector((state) => state.lang.language);

  // const [data, setData] = useState({});
  const [current, setCurrent] = useState(1);
  // const [value, setValue] = useState(1);
  const [pages, setPages] = useState(1);
  const [partners, setPartners] = useState([]);
  // const [offset, setOffset] = useState(0);
  const [render, setRender] = useState(false);

  useEffect(() => {
    getPartners(current);
  }, [current]);

  function adminTableFormat(data) {
    return data.map((el) => {
      return {
        id: el.id,
        isBlock: el.isBlock,
        name: el.name,
        lastName: el.surname,
        telephone: el.tel,
        email: el.email,
        registration: el.createdAt,
        purchases: el.orders?.length ? el.orders?.length + 1 : 0,
        favorites: el.templatesFavorites?.length ? el.templatesFavorites?.length + 1 : 0,
        onSupport: el.supportTemplates?.length ? el.supportTemplates?.length + 1 : 0,
      };
    });
  }

  async function getPartners() {
    fetch(
      `${BASE_URL}/sa/user?role=PARTNER&limit=${LIMIT}&offset=${
        LIMIT * current === 1 ? 0 : current * LIMIT - 2
      }`,
      {
        method: 'GET',
        headers: {
          'x-access-token': `${localStorage.getItem('token')}`,
        },
      },
    )
      .then((result) => result.json())
      .then((data) => {
        if (!data.userCount) return;
        setPages(Math.ceil(data.userCount / LIMIT));
        setPartners(adminTableFormat(data.userList));
      })
      .catch((err) => console.log(err));
  }

  async function blockUser(id) {
    fetch(`${BASE_URL}/sa/user/${id}/ban`, {
      method: 'POST',
      headers: {
        // "Authorization": `Bearer ${localStorage.getItem('token')}`,
        'x-access-token': `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((result) => {
        // console.log('banned');
        getPartners(current);
        return result;
      })
      .catch((err) => console.log(err));
    setRender(!render);
  }

  async function unlockUser(id) {
    fetch(`${BASE_URL}/sa/user/${id}/unban`, {
      method: 'POST',
      headers: {
        // "Authorization": `Bearer ${localStorage.getItem('token')}`,
        'x-access-token': `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((result) => {
        // console.log('unbanned');
        getPartners(current);
        return result;
      })
      .catch((err) => console.log(err));
    setRender(!render);
  }

  // console.log(LIMIT * current === 1 ? 0 : current * LIMIT - 2, '2222222');
  // console.log(current, 'curent');
  // console.log(offset, 'ofset');
  return (
    <div className={s.mainContainer}>
      <div className={s.container}>
        <div className={s.tableBlock}>
          <div className={s.tableHeader}>
            {headerTable.map((obj) => (
              <div className={s.tableHeader_item}>
                <span className={s.title}>{lang === `ru` ? obj.ru : obj.en}</span>
              </div>
            ))}
          </div>
          <div className={s.tableTitle}>
            {partners.map((obj, i) => {
              return (
                <div className={s.tableTitle_item}>
                  <span className={s.title}>{i + 1}</span>
                  <span className={s.title}>{obj.name}</span>
                  <span className={s.title}>{obj.lastName}</span>
                  <span className={s.title}>{obj.telephone}</span>
                  <span className={s.title}>{obj.email}</span>
                  <span className={s.title}> {dayjs(obj.registration).format('DD.MM.YY')}</span>
                  <span className={s.title}>{obj.purchases}</span>
                  <span className={s.title}>{obj.favorites}</span>
                  <span className={s.title}>{obj.onSupport}</span>
                  <span className={s.title}>
                    {!obj.isBlock ? (
                      <img
                        className={s.iconBlocked}
                        src={iconBlocked}
                        onClick={() => blockUser(obj.id)}
                        alt="Ban"
                      />
                    ) : (
                      <img
                        className={s.iconUnban}
                        src={iconUnban}
                        onClick={() => unlockUser(obj.id)}
                        alt="Unban"
                      />
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.paginationBox}>
          <input
            value={current || ''}
            onChange={(e) => {
              setCurrent(+e.target.value <= pages ? +e.target.value : current);
            }}
            onBlur={(e) => setCurrent(+e.target.value)}
            className={s.inputPagination}
            type="number"
          />
          <span className={s.current}>из {pages}</span>
          <div className={s.btnBox}>
            <button
              className={s.btnPrev}
              onClick={() => {
                if (current <= pages && current !== 1) {
                  setCurrent((prev) => prev - 1);
                }

                window.scrollTo(0, 370);
              }}
            >
              <ArrowLeft className={s.arrowLeft} src={ArrowLeft} alt="Like" />
            </button>
            <button
              className={s.btnNext}
              onClick={() => {
                if (current < pages) {
                  setCurrent((prev) => prev + 1);
                }
                window.scrollTo(0, 370);
              }}
            >
              <Arrow className={s.arrow} src={Arrow} alt="Like" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationTablePartners;
