import React from 'react';
import ContentLoader from 'react-content-loader';
import s from './TemplateSkeleton.module.scss'
const TemplateSkeleton = () => (
  <ContentLoader
    speed={2}
    width={300}
    // height={614}
    viewBox="0 0 300 614"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className={s.cardBox}
  >
    <rect className={s.body} x="0" y="535" rx="16" ry="16" width="299" height="75" />
    <rect className={s.title} x="0" y="8" rx="31" ry="31" width="301" height="500" />
  </ContentLoader>
);

export default TemplateSkeleton;
