import React, {useState, createContext} from "react";

export const HeaderTypeContext = createContext(null)

export function HeaderTypeWrapper({children}) {
    const [headerType, setHeaderType] = useState(localStorage.getItem('role') ?? '')

    return (
        <HeaderTypeContext.Provider value={{headerType, setHeaderType}}>
            {children}
        </HeaderTypeContext.Provider>
    )
}