import React, { useEffect, useState } from 'react';

import s from './PersonalAreaLinkUserBlock.module.scss';
import MySiteBlock from '../MySiteBlock/MySiteBlock';
import InDevelopingBlock from '../InDevelopingBlock/InDevelopingBlock';
import FavoritesBlock from '../FavoritesBlock/FavoritesBlock';
import HostingBlock from '../HostingBlock/HostingBlock';
import LeaveFeedbackBlock from '../LeaveFeedbackBlock/LeaveFeedbackBlock';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const linkNames = [
  { ru: 'Мои сайты', en: 'My sites', id: 0 },
  { ru: 'В разработке', en: 'In developing', id: 1 },
  { ru: 'Избранное', en: 'Favorites', id: 2 },
  { ru: 'Хостинг', en: 'Hosting', id: 3 },
  { ru: 'Оставить отзыв', en: 'Leave feedback', id: 4 },
];

function PersonalAreaLinkUserBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(0);
  const { state } = useLocation();

  useEffect(() => {
    state?.active === 2 ? setActive(state.active) : setActive(0);
  }, [state]);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.personalAreaLinkUserBlock}>
            <h2 className={s.mainTitle}>
              {lang === 'ru' ? linkNames[active].ru : linkNames[active].en}
            </h2>
            <div className={s.linkContainer}>
              <ul className={s.linkBlock}>
                {linkNames.map((obj) => (
                  <li
                    className={s[active === obj.id ? 'active' : 'disabled']}
                    onClick={(e) => setActive(obj.id)}
                    key={obj.id}
                  >
                    {lang === 'ru' ? obj.ru : obj.en}
                  </li>
                ))}
              </ul>
            </div>
            {active === 3 && (
              <p className={s.aboutHosting}>
                {lang === 'ru'
                  ? `При выборе данной услуги вам будет предоставлен домен вида
                “yoursite.robohost.site” и хостинг сроком на 1 месяц. Вы сможете
                выбрать наиболее подходящий вариант доменного имени для вашего
                сайта из предоставленных, после чего сайт будет опубликован в
                течение 24 часов. Также эта услуга RoboWeb позволит Вам в
                течение месяца вносить неограниченное количество изменений на
                сайт.`
                  : `When choosing this service, you will be provided with a domain of the form
                “yoursite.robohost.site” and hosting for 1 month. You can
                choose the most suitable domain name option for your
                site from those provided, after which the site will be published in
                within 24 hours. Also, this RoboWeb service will allow you to
                within a month to make an unlimited number of changes to
                site.`}
              </p>
            )}
            {active === 0 && <MySiteBlock />}
            {active === 1 && <InDevelopingBlock />}
            {active === 2 && <FavoritesBlock />}
            {active === 3 && <HostingBlock />}
            {active === 4 && <LeaveFeedbackBlock />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalAreaLinkUserBlock;
