import { createReducer, createAction } from '@reduxjs/toolkit';

export const historyPop = createAction('history/pop');
export const historyForward = createAction('history/forward');
export const historyPush = createAction('history/push');
export const historyPushImg = createAction('history/pushImg');
export const histoyPopImg = createAction('history/popImg');

export const historyReducer = createReducer(
  {
    history: [],
    cursor: 0,
    focus: false,
  },
  (builder) =>
    builder
      .addCase(historyPop, (state) => {
        if (state.cursor === 0) return;
        if (state.cursor === state.history.length) {
          state.history[state.cursor - 2].undo();
          state.cursor = state.cursor - 2;
        } else {
          state.history[state.cursor - 1].undo();
          state.cursor--;
        }
      })
      .addCase(historyForward, (state, action) => {
        if (state.cursor === state.history.length) return;
        if (state.cursor === 0) {
          state.history[state.cursor + 1].redo();
          state.cursor = state.cursor + 2;
        } else {
          state.history[state.cursor].redo();
          state.cursor++;
        }
      })
      .addCase(historyPush, (state, action) => {
        state.focus = action.payload.focus;
        action.payload.cb(state, action);
        if (
          action.payload.el.currentText.toLowerCase() !==
            state.history[
              state.history.length - 1
            ]?.currentText?.toLowerCase() ||
          state.focus
        ) {
          state.history.push(action.payload.el);
          state.cursor++;
          state.history.splice(
            state.cursor,
            state.history.length - state.cursor
          );
        } else if (
          JSON.stringify(action.payload.el.prevStyle) !==
          JSON.stringify(state.history[state.history.length - 1]?.prevStyle)
        ) {
          state.history.push(action.payload.el);
          state.cursor++;
          state.history.splice(
            state.cursor,
            state.history.length - state.cursor
          );
        } else {
          console.log('уже было');
          state.history.pop();
          state.cursor--;
          console.log(state.focus);
        }
        // state.focus = action.payload.focus;
        // if (
        //   action.payload.el.currentText.toLowerCase() ===
        //     state.history[
        //       state.history.length - 1
        //     ]?.currentText?.toLowerCase() &&
        //   state.focus &&
        //   JSON.stringify(action.payload.el.prevStyle) ===
        //     JSON.stringify(state.history[state.history.length - 1]?.prevStyle)
        // ) {
        //   console.log('dont push wat');
        //   console.log(action.payload.el);
        //   console.log(
        //     JSON.stringify(action.payload.el.prevStyle) !==
        //       JSON.stringify(state.history[state.history.length - 1]?.prevStyle)
        //   );
        //   state.history.pop();
        //   state.cursor--;
        // } else {
        //   console.log(
        //     'test',
        //     action.payload.el.currentText.toLowerCase() ===
        //       state.history[
        //         state.history.length - 1
        //       ]?.currentText?.toLowerCase()
        //   );
        //   console.log('focus', state.focus);
        //   console.log(
        //     'style',
        //     JSON.stringify(action.payload.el.prevStyle) ===
        //       JSON.stringify(state.history[state.history.length - 1]?.prevStyle)
        //   );
        //   console.log('push');
        //   state.history.push(action.payload.el);
        //   state.cursor++;
        //   state.history.splice(
        //     state.cursor,
        //     state.history.length - state.cursor
        //   );
        // }

        console.log('state', [...state.history]);
      })
      .addCase(historyPushImg, (state, action) => {
        if (
          !state.history[state.history.length - 1]?.src ||
          state.history[state.history.length - 1].src !== action.payload.el.src
        ) {
          console.log('worked');
          state.history.push(action.payload.el);
          state.cursor++;
          state.history.splice(
            state.cursor,
            state.history.length - state.cursor
          );
          state.focus = action.payload.focus;
          console.log('state', [...state.history]);
        } else {
          console.log('too many');
        }
      })
      .addCase(histoyPopImg, (state, action) => {
        state.history.pop();
        console.log([...state.history]);
      })
);
