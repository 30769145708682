import React from 'react';
import { useState } from 'react';

import s from './CategorySiteMobile.module.scss';

import itIcon from '../../assets/categorySiteIcon/it.svg';
import animalsIcon from '../../assets/categorySiteIcon/animals.svg';
import architectureIcon from '../../assets/categorySiteIcon/architecture.svg';
import artPhotoIcon from '../../assets/categorySiteIcon/artPhoto.svg';
import autoMotoIcon from '../../assets/categorySiteIcon/autoMoto.svg';
import businesIcon from '../../assets/categorySiteIcon/busines.svg';
import clubsIcon from '../../assets/categorySiteIcon/clubs.svg';
import computerIcon from '../../assets/categorySiteIcon/computer.svg';
import consultingIcon from '../../assets/categorySiteIcon/consulting.svg';
import cultureIcon from '../../assets/categorySiteIcon/culture.svg';
import designIcon from '../../assets/categorySiteIcon/design.svg';
import educationIcon from '../../assets/categorySiteIcon/education.svg';
import electronicsIcon from '../../assets/categorySiteIcon/electronics.svg';
import endustryIcon from '../../assets/categorySiteIcon/endustry.svg';
import entertainmentIcon from '../../assets/categorySiteIcon/entertainment.svg';
import fashionIcon from '../../assets/categorySiteIcon/fashion.svg';
import foodIcon from '../../assets/categorySiteIcon/food.svg';
import galleryIcon from '../../assets/categorySiteIcon/gallery.svg';
import houmFamilyIcon from '../../assets/categorySiteIcon/houmFamily.svg';
import interiorIcon from '../../assets/categorySiteIcon/interior.svg';
import internetIcon from '../../assets/categorySiteIcon/internet.svg';
import landscapeIcon from '../../assets/categorySiteIcon/landscape.svg';
import lawIcon from '../../assets/categorySiteIcon/law.svg';
import medicineIcon from '../../assets/categorySiteIcon/medicine.svg';
import musicIcon from '../../assets/categorySiteIcon/music.svg';
import portfolioIcon from '../../assets/categorySiteIcon/portfolio.svg';
import realEstateIcon from '../../assets/categorySiteIcon/realEstate.svg';
import restaurantsIcon from '../../assets/categorySiteIcon/restaurants.svg';
import safetyIcon from '../../assets/categorySiteIcon/safety.svg';
import scienceIcon from '../../assets/categorySiteIcon/science.svg';
import servicesIcon from '../../assets/categorySiteIcon/services.svg';
import sportIcon from '../../assets/categorySiteIcon/sport.svg';
import styleIcon from '../../assets/categorySiteIcon/style.svg';
import travelIcon from '../../assets/categorySiteIcon/travel.svg';
import webDesignIcon from '../../assets/categorySiteIcon/webDesign.svg';
import hotelIcon from '../../assets/categorySiteIcon/hotelIcon.svg';
import arrowIcon from '../../assets/categorySiteIcon/arrow.svg';
import { useSelector } from 'react-redux';

function CategorySiteMobile({
  category,
  activeItem,
  setActiveItem,
  viewList,
  activeListItem,
  setActiveListItem,
}) {
  const [activeCategory, setActiveCategory] = useState(false);
  const [activeSiteView, setActiveSiteView] = useState(false);

  function getNewIcon(data) {
    switch (data.title) {
      case 'animals':
        return animalsIcon;
      case 'auto':
        return autoMotoIcon;
      case 'business':
        return businesIcon;
      case 'electronics':
        return electronicsIcon;
      case 'design':
        return designIcon;
      case 'culture':
        return cultureIcon;
      case 'fashion':
        return fashionIcon;
      case 'entertainment':
        return entertainmentIcon;
      case 'food':
        return foodIcon;
      case 'household':
        return houmFamilyIcon;
      case 'medicine':
        return medicineIcon;
      case 'realestate':
        return realEstateIcon;
      case 'sport':
        return sportIcon;
      case 'learn':
        return educationIcon;
      case 'it':
        return itIcon;
      case 'consulting':
        return consultingIcon;
      case 'architecture':
        return architectureIcon;
      case 'cafe':
        return restaurantsIcon;
      case 'portfolio':
        return portfolioIcon;
      case 'webdesign':
        return webDesignIcon;
      case 'computers':
        return computerIcon;
      case 'science':
        return scienceIcon;
      case 'interior':
        return interiorIcon;
      case 'internet':
        return internetIcon;
      case 'gallery':
        return galleryIcon;
      case 'landscape':
        return landscapeIcon;
      case 'art':
        return artPhotoIcon;
      case 'security':
        return safetyIcon;
      case 'night':
        return clubsIcon;
      case 'law':
        return lawIcon;
      case 'music':
        return musicIcon;
      case 'service':
        return servicesIcon;
      case 'travel':
        return travelIcon;
      case 'hotels':
        return hotelIcon;
      case 'industry':
        return endustryIcon;
      case 'style':
        return styleIcon;
      default:
        return data.imageUrl;
    }
  }
  const lang = useSelector((state) => state.lang.language);

  return (
    <aside>
      <div className={s.mainTitleBox}>
        <div className={s.siteViewContainer}>
          <div
            onClick={() => {
              setActiveSiteView(!activeSiteView);
              setActiveCategory(false);
            }}
            className={s.siteViewBox}
          >
            <span className={s.mainTitle}>
              {lang === `ru`
                ? `Вид сайта`
                : `Site view
`}
            </span>
            <img
              className={s.siteIcon}
              src={arrowIcon}
              style={{
                transform: activeSiteView ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              alt="Arrow"
            />
          </div>
          {activeSiteView && (
            <div className={s.positionSiteBox}>
              {viewList.length
                ? viewList.map((el) => (
                    <div
                      key={el.id}
                      className={`${s.titleContainer} ${
                        activeListItem === el.id ? s.activeView : null
                      }`}
                      onClick={() => {
                        el.id === activeListItem
                          ? setActiveListItem('')
                          : setActiveListItem(el.id);
                        setActiveSiteView(false);
                      }}
                    >
                      <div className={s.titleBox}>
                        <span className={s.title}>
                          {lang === `ru` ? el.titleRu : el.titleEn}
                        </span>
                      </div>
                      <div className={s.borderLine}></div>
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
        <div className={s.categoryContainer}>
          <div
            onClick={() => {
              setActiveCategory(!activeCategory);
              setActiveSiteView(false);
            }}
            className={s.categoryBox}
          >
            <span className={s.mainTitle}>
              {lang === `ru` ? `Категории сайтов` : `Site categories`}
            </span>
            <img
              className={s.iconCategory}
              src={arrowIcon}
              style={{
                transform: activeCategory ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              alt="Arrow"
            />
          </div>
          {activeCategory && (
            <div className={s.positionCategoryBox}>
              {category.length
                ? category.map((item) => (
                    <div key={item.id} className={s.titleContainer}>
                      <div
                        className={`${s.titleBox} ${
                          activeItem === item.id ? s.active : null
                        }`}
                        onClick={() => {
                          item.id === activeItem
                            ? setActiveItem('')
                            : setActiveItem(item.id);
                          setActiveCategory(false);
                        }}
                      >
                        <img
                          className={s.iconCategory}
                          src={getNewIcon(item)}
                          alt={item.title}
                        />
                        <span className={s.title}>
                          {/* {item.transcriptionRu !== 'IT ТЕХНОЛОГИИ'
                            ? item.transcriptionRu.slice(0, 1) +
                              item.transcriptionRu.slice(1).toLowerCase()
                            : item.transcriptionRu.slice(0, 2) +
                              item.transcriptionRu.slice(2).toLowerCase()} */}
                          {item.transcriptionRu !== 'IT ТЕХНОЛОГИИ' &&
                          lang === 'ru'
                            ? item.transcriptionRu.slice(0, 1) +
                              item.transcriptionRu.slice(1).toLowerCase()
                            : ''}
                          {item.transcriptionRu === 'IT ТЕХНОЛОГИИ' &&
                          lang === 'ru'
                            ? item.transcriptionRu.slice(0, 2) +
                              item.transcriptionRu.slice(2).toLowerCase()
                            : ''}
                          {item.transcriptionEn !== 'IT TECHNOLOGY' &&
                          lang === 'en'
                            ? item.transcriptionEn.slice(0, 1) +
                              item.transcriptionEn.slice(1).toLowerCase()
                            : ''}
                          {item.transcriptionEn === 'IT TECHNOLOGY' &&
                          lang === 'en'
                            ? item.transcriptionEn.slice(0, 2) +
                              item.transcriptionEn.slice(2).toLowerCase()
                            : ''}
                        </span>
                      </div>
                      <div className={s.borderLine}></div>
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}

export default CategorySiteMobile;
