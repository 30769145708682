import React, { useEffect, useState } from 'react';

import s from './AdministrationReviewsBlock.module.scss';

import arrowMenu from '../../assets/img/arrowMenu.svg';
import iconTemplates from '../../assets/img/iconTemplates.svg';

import star from '../../assets/reviewsImg/star.svg';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';
import LinkTemplates from '../ui/LinkTemplates/LinkTemplates';
import { BASE_URL } from '../..';
import { useSelector } from 'react-redux';

function AdministrationReviewsBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [activeSortedNameSite, setActiveSortedNameSite] = useState(false);
  const [sortedName, setSortedName] = useState(lang === `ru` ? 'Все' : `All`);
  const [activeId, setActiveId] = useState(0);

  //sorting
  const [sortType, setSortType] = useState('');
  const [reviews, getReviews] = useState([]);
  const [render, setRender] = useState(false);
  const [dateFilter, setDateFilter] = useState('');

  async function setReviews(type) {
    // fetch(`http://localhost:5001/user/getAll/${pageNum}/${limit}`, {
    fetch(
      `${BASE_URL}/sa/review?${sortType ? `&filter=${sortType}` : ''}${
        dateFilter ? '&reviewSortType=DATE' : ''
      }`,
      {
        method: 'GET',
        headers: {
          // "Authorization": `Bearer ${localStorage.getItem('token')}`,
          'x-access-token': `${localStorage.getItem('token')}`,
        },
      },
    )
      .then((result) => result.json())
      .then((data) => {
        getReviews(data.reviewList);
      })
      .catch((err) => console.log(err));
  }

  async function moderReviews(id, res) {
    fetch(`${BASE_URL}/sa/review/${id}/${res}`, {
      method: 'POST',
      headers: {
        'x-access-token': `${localStorage.getItem('token')}`,
      },
    })
      .then((result) => result.json())
      .then((data) => {
        getReviews(reviews);
        setRender(!render);
        console.log('approve');
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setReviews(sortType);
  }, [render, sortType]);

  const sortedArrNameSite = [
    { ru: 'Все', en: 'All', id: 0, sortType: 'ALL' },
    { ru: 'Новые', en: 'New', id: 1, sortType: 'NEW' },
    { ru: 'Опубликованные', en: 'Published', id: 2, sortType: 'APPROVED' },
    { ru: 'Отклоненные', en: 'Rejected', id: 3, sortType: 'UNAPPROVED' },
    { ru: 'По дате', en: 'By date', id: 4, sortType: 'DATE' },
  ];
  console.log(reviews);
  return (
    <div className={s.container}>
      <div
        onClick={() => {
          setActiveSortedNameSite(!activeSortedNameSite);
        }}
        className={s.sortedBlock}
      >
        <span className={s.sortedMenu}>{sortedName}</span>
        <img
          style={{
            transform: activeSortedNameSite ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          className={s.arrowMenu}
          src={arrowMenu}
          alt="Arrow"
        />
        {activeSortedNameSite && (
          <div className={s.dropDownBox}>
            {sortedArrNameSite.map((obj) => (
              <span
                key={obj.id}
                onClick={() => {
                  setActiveSortedNameSite(!activeSortedNameSite);
                  setSortedName(lang === `ru` ? obj.ru : obj.en);
                  setActiveId(obj.id);
                  {
                    obj.sortType.toLowerCase() === 'date'
                      ? setDateFilter('DATA')
                      : setSortType(obj.sortType);
                  }
                  // setSortType(
                  //   obj.sortType.toLowerCase() === 'date'
                  //     ? (obj.sortType = 'ALL' && setDateFilter('DATA'))
                  //     : obj.sortType
                  // );
                  setReviews(obj.sortType);
                }}
                className={s[activeId === obj.id ? 'active' : 'disabled']}
              >
                {lang === `ru` ? obj.ru : obj.en}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={s.reviewsBlock}>
        {reviews.map((rev) => {
          return (
            <div className={s.reviewsCard}>
              <div className={s.userBox}>
                <img
                  className={s.avatar}
                  src={rev.user.avatar ?  `${BASE_URL}${rev.user.avatar}` : avatarDefault}
                  alt="Аватар"
                />
                <div className={s.infoUser}>
                  <h3 className={s.userName}>{`${rev.user.name} ${rev.user.surname || ''}`}</h3>
                  <span className={s.rating}>{rev.rating}</span>
                  <img src={star} alt="Star" />
                  <span className={s.date}>
                    {new Date(rev.updatedAt).toLocaleDateString() +
                      (lang === `ru` ? ' в ' : ` at `) +
                      new Date(rev.updatedAt).toLocaleTimeString().slice(0, -3)}
                  </span>
                </div>
              </div>
              <div className={s.aboutBox}>
                <p className={s.about}>{rev.text}</p>
                <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
                <div className={s.temlatesBox}>
                  <img className={s.iconTemplates} src={iconTemplates} alt="" />
                  <a href={`http://localhost:3000/viewTemplate/${rev.templateId}`}>
                    <span className={s.templates}>{lang === `ru` ? `Шаблон` : `Templates`}</span>
                  </a>
                </div>
              </div>
              <div className={s.buttonBox}>
                {/* <button className={s.publish}>{rev.isApproved?"Опубликовано":"Опубликовать"}</button>
            <button className={s.reject}>{rev.isApproved?"Отклонить":"Отклонено"}</button> */}
                <button className={s.publish} onClick={() => moderReviews(rev.id, 'approve')}>
                  {/* {rev.isApproved && lang === `ru`
                    ? 'Опубликовано'
                    : 'Опубликовать'} */}
                  {rev.isApproved && lang === `ru`
                    ? 'Опубликовано'
                    : !rev.isApproved && lang === `ru`
                    ? `Опубликовать`
                    : rev.isApproved && lang === `en`
                    ? `Published`
                    : `Publish`}
                </button>
                <button className={s.reject} onClick={() => moderReviews(rev.id, 'unapprove')}>
                  {/* {rev.isApproved === false || null ? 'Отклонено' : 'Отклонить'} */}
                  {(rev.isApproved === false || rev.isApproved === null) && lang === `ru`
                    ? 'Отклонено'
                    : (!rev.isApproved === false || !rev.isApproved === null) && lang === `ru`
                    ? 'Отклонить'
                    : (rev.isApproved === false || null) && lang === `en`
                    ? `Rejected`
                    : `Reject`}
                </button>
              </div>
            </div>
          );
        })}
        {/* <div className={s.reviewsCard}>
          <div className={s.userBox}>
            <img className={s.avatar} src={avatar} alt="Аватар" />
            <div className={s.infoUser}>
              <h3 className={s.userName}>Анна Утюшева</h3>
              <span className={s.rating}>5.0</span>
              <img src={star} alt="Star" />
              <span className={s.date}>11.02.2022 в 12:43</span>
            </div>
          </div>
          <div className={s.aboutBox}>
            <p className={s.about}>
              Каждый 
            </p>
            <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
            <div className={s.temlatesBox}>
              <img className={s.iconTemplates} src={iconTemplates} alt="" />
              <span className={s.templates}>Шаблон</span>
            </div>
          </div>
          <div className={s.buttonBox}>
            <button className={s.publish}>Опубликовать</button>
            <button className={s.reject}>Отклонить</button>
          </div>
        </div>
        <div className={s.reviewsCard}>
          <div className={s.userBox}>
            <img className={s.avatar} src={avatar} alt="Аватар" />
            <div className={s.infoUser}>
              <h3 className={s.userName}>Анна Утюшева</h3>
              <span className={s.rating}>5.0</span>
              <img src={star} alt="Star" />
              <span className={s.date}>11.02.2022 в 12:43</span>
            </div>
          </div>
          <div className={s.aboutBox}>
            <p className={s.about}>
           e
            </p>
            <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
            <div className={s.temlatesBox}>
              <img className={s.iconTemplates} src={iconTemplates} alt="" />
              <span className={s.templates}>Шаблон</span>
            </div>
          </div>
          <div className={s.buttonBox}>
            <button className={s.publish}>Опубликовать</button>
            <button className={s.reject}>Отклонить</button>
          </div>
        </div>
        <div className={s.reviewsCard}>
          <div className={s.userBox}>
            <img className={s.avatar} src={avatar} alt="Аватар" />
            <div className={s.infoUser}>
              <h3 className={s.userName}>Анна Утюшева</h3>
              <span className={s.rating}>5.0</span>
              <img src={star} alt="Star" />
              <span className={s.date}>11.02.2022 в 12:43</span>
            </div>
          </div>
          <div className={s.aboutBox}>
            <p className={s.about}>
              Каждый из нас понимает очевидную вещь: сложившаяся структура организации
              в значительной степени обусловливает важность экспериментов, поражающих своей
              масштабности и грандиозности. Реплицированные с зарубежных источников, современные
              исследования неоднозначны и будут заблокированы в рамках своих собственных
              рациональных ограничений.
            </p>
            <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
            <div className={s.temlatesBox}>
              <img className={s.iconTemplates} src={iconTemplates} alt="" />
              <span className={s.templates}>Шаблон</span>
            </div>
          </div>
          <div className={s.buttonBox}>
            <button className={s.publish}>Опубликовать</button>
            <button className={s.reject}>Отклонить</button>
          </div>
        </div>
        <div className={s.reviewsCard}>
          <div className={s.userBox}>
            <img className={s.avatar} src={avatar} alt="Аватар" />
            <div className={s.infoUser}>
              <h3 className={s.userName}>Анна Утюшева</h3>
              <span className={s.rating}>5.0</span>
              <img src={star} alt="Star" />
              <span className={s.date}>11.02.2022 в 12:43</span>
            </div>
          </div>
          <div className={s.aboutBox}>
            <p className={s.about}>
              Каждый из нас понимает очевидную вещь: сложившаяся структура организации
              в значительной степени обусловливает важность экспериментов, поражающих своей
              масштабности и грандиозности. Реплицированные с зарубежных источников, современные
              исследования неоднозначны и будут заблокированы в рамках своих собственных
              рациональных ограничений.
            </p>
            <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
            <div className={s.temlatesBox}>
              <img className={s.iconTemplates} src={iconTemplates} alt="" />
              <span className={s.templates}>Шаблон</span>
            </div>
          </div>
          <div className={s.buttonBox}>
            <button className={s.publish}>Опубликовать</button>
            <button className={s.reject}>Отклонить</button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AdministrationReviewsBlock;
