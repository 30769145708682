import React from 'react';

import s from './PartnersBenefitsBlock.module.scss';
import highPercent from '../../assets/PartnersBenefitsIcon/highPercent.svg';
import passiveIncome from '../../assets/PartnersBenefitsIcon/passiveIncome.svg';
import noLimits from '../../assets/PartnersBenefitsIcon/noLimits.svg';
import { useSelector } from 'react-redux';

const item = [
  {
    id: 1,
    img: highPercent,
    title: 'Высокий процент',
    titleEn: 'High percent',
    item: 'Мы предлагаем 25% с каждой продажи',
    itemEn: 'We offer 25% off every sale',
  },
  {
    id: 2,
    img: passiveIncome,
    title: 'Пассивный доход',
    titleEn: 'Passive income',
    item: 'Создавайте готовые шаблоны сайтов и получайте доход от покупок пользователей ежемесячно',
    itemEn:
      'Create ready-made website templates and earn monthly income from user purchases',
  },
  {
    id: 3,
    img: noLimits,
    title: 'Отсутствие лимитов',
    titleEn: 'No limits',
    item: 'Вывод денег производится в любое время суток при любой сумме на вашем балансе',
    itemEn: `Withdrawal of money is made at any time of the day with any amount on your balance`,
  },
];

function PartnersBenefitsBlock() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <ul className={s.list}>
            {item.map((el) => (
              <li key={el.id} className={s.itemBox}>
                <div className={s.imageBox}>
                  <img className={s.icon} src={el.img} alt="Icon" />
                  {/* <img className={el.className} src={el.imgGroup} alt="Group" /> */}
                </div>
                <h3 className={s.title}>
                  {lang === 'ru' ? el.title : el.titleEn}
                </h3>
                <p className={s.item}>{lang === 'ru' ? el.item : el.itemEn}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default PartnersBenefitsBlock;
