import './App.css';
import Footer from './components/Footer/Footer';
// import PortfolioBlock from './components/PortfolioBlock/PortfolioBlock';
// import Heading from './components/Heading/Heading';
import Header from './components/Header/Header';
// import AboutMain from './components/AboutMain/AboutMain';
// import Benefits from './components/Benefits/Benefits';
// import DigitBlock from './components/DigitBlock/DigitBlock';
// import TemplatesPurpose from './components/TemplatesPurpose/TemplatesPurpose';
// import PriceBlock from './components/PriceBlock/PriceBlock';
// import ReviewsBlockMain from './components/ReviewsBlockMain/ReviewsBlockMain';
// import CompareYourSelf from './components/CompareYourSelf/CompareYourSelf';
// import HowToStartBlockMain from './components/HowToStartBlockMain/HowToStartBlockMain';
// import AdditionalOffers from './components/AdditionalOffers/AdditionalOffers';
// import FAQ from './components/FAQ/FAQ';
// import UseForFree from './components/UseForFree/UseForFree';
// import TemplatesHeading from './components/TemplatesHeading/TemplatesHeading';
// import FullListTemplates from './components/FullListTemplates/FullListTemplates';
// import PriceHeading from './components/PriceHeading/PriceHeading';
import Portfolio from './pages/Portfolio/Portfolio';
import { Routes, Route } from 'react-router-dom';
import Price from './pages/Price/Price';
import Main from './pages/Main/Main';
import Templates from './pages/Templates/Templates';
import AboutUs from './pages/AboutUs/AboutUs';
import Partners from './pages/Partners/Partners';
import TechnicalSupport from './pages/TechnicalSupport/TechnicalSupport';
// import PopApWebsiteDevelopmentRequest from './components/PopApWebsiteDevelopmentRequest/PopApWebsiteDevelopmentRequest';
import ProfileUser from './components/ProfileUser/ProfileUser';
import PersonalAreaLinkUserBlock from './components/PersonalAreaLinkUserBlock/PersonalAreaLinkUserBlock';
import ProfilePartners from './components/ProfilePartners/ProfilePartners';
import PersonalAccountPartnerMyWork from './components/PersonalAccountPartnerMyWork/PersonalAccountPartnerMyWork';
import SendingForModeration from './components/SendingForModeration/SendingForModeration';
import ProfileAdministrator from './components/ProfileAdministrator/ProfileAdministrator';
import PersonalAccountAdministrator from './components/PersonalAccountAdministrator/PersonalAccountAdministrator';
import AdministrationRequestsForModeration from './components/AdministrationRequestsForModeration/AdministrationRequestsForModeration';
import AdministrationMySiteBlock from './components/AdministrationMySiteBlock/AdministrationMySiteBlock';
import ViewTemplate from './components/ViewTemplate/ViewTemplate';
import TemplatePreviewBlock from './components/TemplatePreviewBlock/TemplatePreviewBlock';
import AdministrationFinanceBlock from './components/AdministrationFinanceBlock/AdministrationFinanceBlock';
import { useContext, useEffect, useState } from 'react';
import { HeaderContext } from './utils/HeaderContext';
import TemplateUpdate from './components/TemplateUpdate/TemplateUpdate';
import RequireAuth from './components/SignIn/RequireAuth';
import { LanguageSwitch } from './utils/LanguageSwitch';
import Loader from './components/Loader/Loader';



// import ProfileAdministrator from './components/ProfileAdministrator/ProfileAdministrator';

const ROLES = {
  User: 'client',
  Partner: 'partner',
  Admin: 'admin',
};

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const { isHeader } = useContext(HeaderContext);

  const language = useContext(LanguageSwitch);

  useEffect(() => {
        setTimeout(() =>{
          setIsLoading(false);
        },4000)
      if(window.location.pathname.slice(0,10) === '/templates') {
        setIsLoading(false);
      }
    }, []);
    
// console.log('llllloooooooudddeerrr', isLoading)
// console.log('lllll', window.location.pathname.slice(0,10))
return (
<> 
   {isLoading && <Loader />}
    <LanguageSwitch.Provider value={language}>
      <div style={{opacity: isLoading ? 0 : 1}} className="app">
        {isHeader ? <Header /> : null}
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/price" element={<Price />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/technicalSupport" element={<TechnicalSupport />} />
          <Route path="/viewTemplate/:templateId" element={<ViewTemplate />} />
          <Route
            path="/robolook/:templateId"
            element={<TemplatePreviewBlock />}
          />
          <Route
            path="/templateUpdate/:templateId"
            ПОМЕНЯЛ
            // path="/templates/:templateId"
            element={<TemplateUpdate />}
          />
          {/* <Route path="/pop" element={<PopApWebsiteDevelopmentRequest />} /> */}
          <Route element={<RequireAuth allowedRoles={ROLES.User} />}>
            <Route path="/profileUser" element={<ProfileUser />} />
            <Route
              path="/personalArea"
              element={<PersonalAreaLinkUserBlock />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={ROLES.Partner} />}>
            <Route path="/profilePartners" element={<ProfilePartners />} />
            <Route
              path="/profilePartnerMyWork"
              element={<PersonalAccountPartnerMyWork />}
            />
            <Route path="/send" element={<SendingForModeration />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
            <Route path="/profileAdmin" element={<ProfileAdministrator />} />
            <Route
              path="/profileAreaAdmin"
              element={<PersonalAccountAdministrator />}
            />
            <Route
              path="/profileAdminModeration"
              element={<AdministrationRequestsForModeration />}
            />
            <Route
              path="/profileAdminMySite"
              element={<AdministrationMySiteBlock />}
            />
            <Route
              path="/ProfileAdminFinance"
              element={<AdministrationFinanceBlock />}
            />

          </Route>
        </Routes>
        {isHeader ? <Footer /> : null}
      </div>
    </LanguageSwitch.Provider>
    </>
  );
}

export default App;
