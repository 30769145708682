import React, { useEffect, useRef } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';

import Container from '../ui/Container/Container';
import s from './FaqMobile.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useMediaQuery } from '@mui/material';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';

import iconBtn from '../../assets/FAQImg/iconBtn.svg';
import iconGearGreen from '../../assets/FAQImg/gearGreen.svg';
import iconCircleBlueLeft from '../../assets/FAQImg/circleBlueLeft.png';
import iconCircleBlueRight from '../../assets/FAQImg/circleBlueRight.png';
import iconCircleGreen from '../../assets/FAQImg/circleGreen.png';
import iconGearBlue from '../../assets/FAQImg/gearBlue.png';
import { useSelector } from 'react-redux';

function FaqMobile() {
  const lang = useSelector((state) => state.lang.language);

  const ref = useRef();
  useEffect(() => {
    let circleBlueLeft = document.querySelector(`.${s.iconCircleBlueLeft}`);
    let circleGreen = document.querySelector(`.${s.iconCircleGreen}`);
    let circleBlueRight = document.querySelector(`.${s.iconCircleBlueRight}`);
    return ref.current.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      circleBlueLeft.style.transform =
        'translate(-' + x * 220 + 'px, -' + y * 150 + 'px)';
      circleGreen.style.transform =
        'translate(-' + x * 150 + 'px, -' + y * 170 + 'px)';
      circleBlueRight.style.transform =
        'translate(-' + x * 130 + 'px, -' + y * 225 + 'px)';
    });
  }, []);
  const accordionData = [
    {
      id: 1,
      title: 'Section 1',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`,
    },
    {
      id: 2,
      title: 'Section 2',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 3,
      title: 'Section 3',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
    },
    {
      id: 4,
      title: 'Section 4',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 5,
      title: 'Section 5',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 6,
      title: 'Section 6',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 7,
      title: 'Section 7',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 8,
      title: 'Section 8',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 9,
      title: 'Section 9',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      id: 10,
      title: 'Section 10',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
    reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
    quaerat iure quos dolorum accusantium ducimus in illum vero commodi
    pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
    quidem maiores doloremque est numquam praesentium eos voluptatem amet!
    Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
  ];

  const md = useMediaQuery('(max-width:820px');
  const sm = useMediaQuery('(max-width: 500px)');
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    '& .css-15v22id-MuiAccordionDetails-root': {
      padding: '0',
    },
    '& .css-yw020d-MuiAccordionSummary-expandIconWrapper img': {
      width: sm ? '30px' : 'inherit',
    },
    '& .Mui-expanded img': {
      width: sm ? '30px' : 'inherit',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(45deg)',
    },
    '& .css-1xoziyy-MuiPaper-root-MuiAccordion-root:before': {
      opacity: 0,
    },
    '& .css-orijyx-MuiTypography-root': {},
    // '& .css-1xoziyy-MuiPaper-root-MuiAccordion-root:before': {
    //   opacity: 0

    // }
  }));

  return (
    <section>
      <Container>
        <div ref={ref} className={s.wrapper}>
          <div className={s.illustrationBoxLeft}>
            <img
              className={s.iconGearGreen}
              src={iconGearGreen}
              alt="Gear green"
            />
            <img
              className={s.iconCircleBlueLeft}
              src={iconCircleBlueLeft}
              alt="Circle blue left"
            />
          </div>
          <div className={s.illstrationBoxRight}>
            <img
              className={s.iconCircleBlueRight}
              src={iconCircleBlueRight}
              alt="Circle blue right"
            />
            <img
              className={s.iconCircleGreen}
              src={iconCircleGreen}
              alt="Circle green"
            />
            <img
              className={s.iconGearBlue}
              src={iconGearBlue}
              alt="Gear blue"
            />
          </div>
          <div className={s.faqBox}>
            <h2 className={s.mainTitle}>
              {lang === 'ru'
                ? 'Часто задаваемые вопросы'
                : `Frequently asked questions`}
            </h2>
            <div className={s.faqCard}>
              {accordionData.map((obj) => (
                <Accordion
                  key={obj.id}
                  sx={{
                    boxShadow: 'none',
                  }}
                  className={s.accordion}
                >
                  <AccordionSummary
                    expandIcon={<img src={iconBtn} alt="" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      flexDirection: 'row-reverse',
                      gap: md ? (sm ? '10px' : '15px') : '24px',
                      width: '100%',
                      //   padding: '15px 0px 15px 0px',
                      paddingTop: md ? (sm ? '0px' : '10px') : '15px',
                      paddingBottom: md ? (sm ? '0px' : '10px') : '15px',
                      paddingLeft: md ? (sm ? '0px' : '0px') : '15px',
                    }}
                    className={s.accordionSummary}
                  >
                    <Typography
                      className={s.Title}
                      sx={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: md ? (sm ? 16 : 18) : 22,

                        lineHeight: '23px',

                        color: '#000000',
                      }}
                    >
                      {obj.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingLeft: 0,
                      paddingTop: md ? (sm ? '0px' : '0px') : '15px',
                      paddingBottom: md ? (sm ? '0px' : '0px') : '15px',
                    }}
                  >
                    <Typography
                      className={s.about}
                      sx={{
                        fontFamily: 'Circe',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        // fontSize: '22px',
                        fontSize: md ? (sm ? 16 : 18) : 22,

                        lineHeight: md ? (sm ? '24px' : '28px') : '32px',
                        // border: '1px solid red',
                        color: '#000000',
                      }}
                    >
                      {obj.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default FaqMobile;
