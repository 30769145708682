import React, { useState } from 'react';

import s from './UseForFree.module.scss';
import card from '../../assets/useForFreeImg/card.svg';
import Button from '../../components/ui/Button/Button';
import wave from '../../assets/img/wave_top.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import { useSelector } from 'react-redux';

function UseForFree({ openModal }) {
  const lang = useSelector((state) => state.lang.language);

  const navigate = useNavigate();

  const [switche, setSwitch] = useState(true);
  const [visiblity, setVisiblity] = useState(false);
  // const [burgerActive, setBurgerActive] = useState(false);
  // const [launguage, setLaunguage] = useState('Ru');
  // const [activeLunguage, setActiveLunguage] = useState(false);

  // const { headerType } = useContext(HeaderTypeContext);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function openSignIn() {
    setSwitch(!switche);
  }
  function closeModal(e) {
    setVisiblity(e);
    setSwitch(true);
  }

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <img className={s.waveTop} src={wave} alt="Wave" />
          <div className={s.useForFreeBox}>
            <div className={s.aboutBox}>
              <h2 className={s.title}>
                {lang === 'ru'
                  ? `Пользуйтесь бесплатно до запуска сайта`
                  : `Use for free before you launch your site`}
              </h2>
              <p className={s.content}>
                {lang === 'ru'
                  ? `Попробуйте бесплатно создать свой сайт, выбрав наиболее подходящий вариант
                из готовых сайтов для бизнеса. Оплата потребуется только после редактирования,
                перед скачиванием сайта.`
                  : `Try to create your own site for free by selecting the most suitable option from ready-made templates. Payment will be required only after editing, before downloading the site.`}
              </p>
              <Button
                onClick={() =>
                  localStorage.token ? navigate('/templates') : [openModal(), disabledScroll()]
                }
                className={s.btnFree}
              >
                {lang === 'ru' ? `Попробовать бесплатно` : `Try for free`}
              </Button>
            </div>
            <img className={s.cardImg} src={card} alt="" />
          </div>
        </div>
      </div>
      {switche ? (
        <SignIn
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      ) : (
        <SignUp
          show={visiblity}
          closeModal={closeModal}
          openModal={openModal}
          openSignIn={openSignIn}
        />
      )}
    </section>
  );
}

export default UseForFree;
