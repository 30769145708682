import React from 'react';
import s from './WhoSuits.module.scss';

import ButtonTemplates from '../ui/ButtonTemplates/ButtonTemplates';
import background from '../../assets/whoSuitsIcon/background.svg';
import iconGear from '../../assets/whoSuitsIcon/iconGear.svg';
import iconPuzzle from '../../assets/whoSuitsIcon/iconPuzzle.svg';
import cPlus from '../../assets/whoSuitsIcon/cPlus.svg';
import gear from '../../assets/whoSuitsIcon/gear.svg';
import htmlIcon from '../../assets/whoSuitsIcon/htmlIcon.svg';
import ui from '../../assets/whoSuitsIcon/ui.svg';
import ux from '../../assets/whoSuitsIcon/ux.svg';
import { useSelector } from 'react-redux';

function WhoSuits() {
  const lang = useSelector((state) => state.lang.language);

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <h2 className={s.mainTitle}>
            {lang === 'ru' ? `Кому подойдёт?` : `Who will suit?`}
          </h2>
          <div className={s.whoSuitsBlock}>
            <div className={s.aboutBox}>
              <div className={s.card1}>
                <div className={s.titleBox}>
                  <img className={s.iconPuzzle} src={iconPuzzle} alt="Img" />
                  <h3 className={s.title}>
                    {lang === 'ru'
                      ? `Веб-дизайнеры, UX/UI`
                      : `Web designers, UX/UI`}
                  </h3>
                </div>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `На биржах фриланса специалисты имеют нестабильный доход. Если вы классный
                  веб-дизайнер, готовый создавать яркие нестандартные дизайны для сайтов —
                  приглашаем вас к сотрудничеству!`
                    : `On freelance exchanges, specialists have an unstable income. If you are cool
                  a web designer who is ready to create bright custom designs for websites —
                  we invite you to cooperation!`}
                </p>
                <ButtonTemplates className={s.btnStartEarning}>
                  {lang === 'ru' ? `Начать зарабатывать` : `Start earning`}
                </ButtonTemplates>
              </div>
              <div className={s.card2}>
                <div className={s.titleBox}>
                  <img className={s.iconGear} src={iconGear} alt="Img" />
                  <h3 className={s.title}>
                    {lang === 'ru' ? `Веб-разработчики` : `Web developers`}
                  </h3>
                </div>
                <p className={s.about}>
                  {lang === 'ru'
                    ? `Веб-разработчики всегда пользуются спросом среди фрилансеров. Если вы хотите
                  получать пассивный доход и не зависеть от биржи фриланса, то RoboWeb станет
                  отличным началом вашего пути.`
                    : `Web developers are always in demand among freelancers. If you want to
                  receive passive income and not depend on the freelance exchange, then RoboWeb will become
                  a great start to your journey.`}
                </p>
                <ButtonTemplates className={s.btnStartEarning}>
                  {lang === 'ru' ? `Начать зарабатывать` : `Start earning`}
                </ButtonTemplates>
              </div>
            </div>
            <div className={s.imgBox}>
              <img className={s.background} src={background} alt="Img" />
              <img className={s.cPlus} src={cPlus} alt="Img" />
              <img className={s.gear} src={gear} alt="Img" />
              <img className={s.htmlIcon} src={htmlIcon} alt="Img" />
              <img className={s.ui} src={ui} alt="Img" />
              <img className={s.ux} src={ux} alt="Img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoSuits;
