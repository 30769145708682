import React from 'react'
import FullListTemplates from '../../components/FullListTemplates/FullListTemplates'
import TemplatesHeading from '../../components/TemplatesHeading/TemplatesHeading'

function Templates() {
  return (
    <>
    <TemplatesHeading/>
    <FullListTemplates/>
    </>
  )
}



export default Templates