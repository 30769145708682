import React from 'react';
import ContentLoader from 'react-content-loader';
import s from './SkeletonsMySiteBlock.module.scss';

const SkeletonsMySiteBlock = () => (
  <ContentLoader
    speed={2}
    width={390}
    // height={567}
    viewBox="0 0 410 567"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className={s.cardBox}

    // {...props}
  >
    <rect x="0" y="0" rx="31" ry="31" width="410" height="567" />
  </ContentLoader>
);

export default SkeletonsMySiteBlock;
