/* eslint-disable jsx-a11y/iframe-has-title */

import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import s from './TemplatePreviewBlock.module.scss';
import { ReactComponent as DesctopIcon } from '../../assets/img/desctopIcon.svg';
import { ReactComponent as TabletIcon } from '../../assets/img/tabletIcon.svg';
import { ReactComponent as MobileIcon } from '../../assets/img/mobileIcon.svg';
import { ReactComponent as LikeIcon } from '../../assets/img/likeIcon.svg';
import { ReactComponent as ArrowBack } from '../../assets/img/ArrowBack.svg';

import Button from '../ui/Button/Button';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { HeaderContext } from '../../utils/HeaderContext';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from 'swiper';
import { useSelector } from 'react-redux';

function TemplatePreviewBlock() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(0);
  const [data, setData] = useState({});
  const [activeLike, setActiveLike] = useState(false);
  const { setIsHeader } = useContext(HeaderContext);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1025 && window.innerWidth > 576) {
      setActive(1);
    } else if (window.innerWidth < 576) {
      setActive(2);
    } else if (window.innerWidth > 1024) {
      setActive(0);
    }
  }, []);

  useEffect(() => {
    setIsHeader(false);

    return () => {
      setIsHeader(true);
    };
  }, []);
  console.log(active);
  useEffect(() => {
    let frame = document.querySelector('#frame');
    const arr = [...frame.querySelectorAll('p')];
    arr.forEach((el) => {
      el.addEventListener('click', (E) => {
        console.log(E);
      });
    });
  }, []);

  const { templateId } = useParams();
  const navigate = useNavigate();

  if (data === undefined) return <h1>Loading...</h1>;

  // console.log(data.templates[0].variants[0]._id);

  function adaptation() {
    if (active === 0) {
      return { width: '100%', height: '1000px' };
    } else if (active === 1) {
      return { width: '820px', height: '1000px' };
    } else {
      return { width: '390px', height: '1000px' };
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title

    <div className={s.container}>
      <header>
        <div className={s.backBox} onClick={() => navigate(-1)}>
          <ArrowBack className={s.arrowBack} src={ArrowBack} alt="" />
          <span className={s.back}>Назад</span>
        </div>
        <div className={s.adaptatiomIconBox}>
          <DesctopIcon
            onClick={() => setActive(0)}
            className={s[active === 0 ? 'active' : 'disabled']}
            src={DesctopIcon}
            alt="Desctop icon"
          />
          <TabletIcon
            onClick={() => setActive(1)}
            className={s[active === 1 ? 'active' : 'disabled']}
            src={TabletIcon}
            alt="Tablet icon"
          />
          <MobileIcon
            onClick={() => setActive(2)}
            className={s[active === 2 ? 'active' : 'disabled']}
            src={MobileIcon}
            alt="Mobile icon"
          />
        </div>
        <div className={s.adoptedIconContainer}>
          <button
            onClick={() => {
              handlePrev();
              setActive((prev) => (prev > 0 ? prev - 1 : 0));
            }}
            className={s.btnupdatedIconPrev}
          ></button>
          <div className={s.adaptatiomIconBoxMobile}>
            <TabletIcon
              onClick={() => setActive(1)}
              className={s[active === 1 ? 'activeTablet' : 'disabledTablet']}
              src={TabletIcon}
              alt="Tablet icon"
            />
            <MobileIcon
              onClick={() => setActive(2)}
              className={s[active === 2 ? 'activeTablet' : 'disabledTablet']}
              src={MobileIcon}
              alt="Mobile icon"
            />
          </div>
          <button
            onClick={() => {
              handleNext();
              setActive((prev) => (prev < 2 ? prev + 1 : 2));
            }}
            className={s.btnupdatedIconNext}
            // disabled={active < 0 ? true : true}
          ></button>
        </div>
        <div className={s.updatedAndFavoriteBox}>
          <LikeIcon
            onClick={() => setActiveLike(!activeLike)}
            className={s[activeLike ? 'like' : 'unLike']}
            src={LikeIcon}
            alt="Like icon"
          />
          <Button onClick={() => navigate(`/templateUpdate/${templateId}`)} className={s.btnUpdate}>
            {lang === `ru` ? `Редактировать` : `Edit`}
          </Button>
        </div>
      </header>
      <iframe
        id="frame"
        className={s.iframe}
        // src={`https://back.roboweb.site/templates/${templateId}`}
        src={`../../templates/${templateId}/index.html`}
        style={adaptation()}
      />
    </div>
  );
}

export default TemplatePreviewBlock;
