import React, { useState } from 'react';

import s from './ContactSupport.module.scss';
import Button from '../ui/Button/Button';
import contactImage from '../../assets/contactSupport/contactImg.svg';
import PopApTechnicalSupport from '../PopApTechnicalSupport/PopApTechnicalSupport';
import { useSelector } from 'react-redux';

function ContactSupport() {
  const [visiblity, setVisiblity] = useState(false);

  function openModal() {
    setVisiblity(!visiblity);
  }

  function closeModal(e) {
    setVisiblity(e);
  }

  const lang = useSelector((state) => state.lang.language);

  function disabledScroll() {
    document.body.classList.add('disabled-scroll');
  }

  return (
    <section>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.contactSupportBox}>
            <div className={s.aboutBox}>
              <h2 className={s.mainTitle}>
                {lang === 'ru' ? 'Хотите связаться с поддержкой?' : `Want to contact support?`}
              </h2>
              <span className={s.about}>
                {lang === 'ru'
                  ? 'Для консультаций используйте форму обратной связи'
                  : `For consultations, use the feedback form`}
              </span>
              <Button onClick={() => [openModal(), disabledScroll()]} className={s.contactBtn}>
                {lang === 'ru' ? 'Cвязаться' : `Contact`}
              </Button>
            </div>
            <div className={s.imgBox}>
              <img className={s.contactImage} src={contactImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <PopApTechnicalSupport show={visiblity} closeModal={closeModal} openModal={openModal} />
    </section>
  );
}

export default ContactSupport;
