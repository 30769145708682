import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';

import s from './SiteReviewsBlock.module.scss';
import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import star from '../../assets/reviewsImg/star.svg';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';

import LinkTemplates from '../ui/LinkTemplates/LinkTemplates';

import { useParams } from 'react-router-dom';
import { BASE_URL } from '../..';

function SiteReviewsBlock() {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const [reviewList, setReviewList] = useState([]);

  const { templateId } = useParams();

  const getReviewList = async (templateId) => {
    const response = await fetch(`${BASE_URL}/client/template/${templateId}`);
    return await response.json();
  };

  useEffect(() => {
    getReviewList(templateId)
      .then((data) => {
        const key = Object.keys(data)[0];
        setReviewList(data[key].reviewList);
      })
      .catch((err) => console.log(err));
  }, [templateId]);

  console.log(reviewList, 'reviewwwww');

  return (
    <div className={s.container}>
      {reviewList.length ? (
        <div className={s.reviewsBox}>
          <Swiper
            ref={sliderRef}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            breakpoints={{
              830: {
                width: 640,
                slidesPerView: 1,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Navigation]}
            className={s.mySwiper4}
          >
            {reviewList.map((review) => (
              <SwiperSlide key={review.id} className={s.swiperSlide}>
                <div className={s.reviewsCard}>
                  <div className={s.userBox}>
                    <img
                      className={s.avatar}
                      src={review.user.avatar ? `${BASE_URL}${review.user.avatar}` : avatarDefault}
                      alt="Аватар"
                    />
                    <div className={s.infoUser}>
                      <h3 className={s.userName}>{review.user.name}</h3>
                      <span className={s.rating}>{review.rating}</span>
                      <img src={star} alt="Star" />
                    </div>
                  </div>
                  <div className={s.aboutBox}>
                    <p className={s.about}>{review.text}</p>
                    <LinkTemplates className={s.linkTemplates}>www.salondeli.ru</LinkTemplates>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <section className={s.buttonBox}>
            <button className={s.arrowBtnPrev} onClick={handlePrev}>
              <ArrowLeft className={s.arrowPrev} src={Arrow} alt="Arrow" />
            </button>
            <button onClick={handleNext} className={s.arrowBtnNext}>
              <Arrow className={s.arrowNext} src={Arrow} alt="Arrow" />
            </button>
          </section>
        </div>
      ) : (
        <h2>Нет отзывов</h2>
      )}
    </div>
  );
}

export default SiteReviewsBlock;
