import React, { useCallback, useEffect, useRef, useState } from 'react';

import s from './PartnerMyWorksRating.module.scss';
// import avatar from '../../assets/reviewsImg/avatar.jpeg';
import avatarDefault from '../../assets/img/ava.jpeg';

import arrowMenu from '../../assets/img/arrowMenu.svg';
// import av from '../../assets/topTenPartnersIcon/av.jpg';
import star from '../../assets/topTenPartnersIcon/star.svg';

import { ReactComponent as Arrow } from '../../assets/reviewsImg/Arrow.svg';
import { ReactComponent as ArrowLeft } from '../../assets/reviewsImg/arrowLeft.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { BASE_URL } from '../..';
import axios from 'axios';
import { useSelector } from 'react-redux';

function PartnerMyWorksRating() {
  const lang = useSelector((state) => state.lang.language);

  const [active, setActive] = useState(false);
  const [sortedName, setSortedName] = useState(
    lang === 'ru' ? `По количеству продаж` : `By number of sales`,
  );
  const [activeId, setActiveId] = useState(0);
  const [activeOtherId, setActiveOtherId] = useState(0);
  const [activeTreeDots, setActiveTreeDots] = useState(false);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  //sorting
  const [sortType, setSortType] = useState('SALES');

  const sortedArr = [
    {
      ru: 'По количеству продаж',
      en: 'By number of sales',
      id: 0,
      sortType: 'SALES',
    },
    {
      ru: 'По количеству шаблонов',
      en: 'By number of templates',
      id: 1,
      sortType: 'TEMPLATES',
    },
    {
      ru: 'По добавленным в избранное',
      en: 'By added to favorites',
      id: 2,
      sortType: 'FAVORITES',
    },
    {
      ru: 'По дате изменения',
      en: 'By date modifiedg',
      id: 3,
      sortType: 'RATING ',
    },
  ];
  // let swiper;

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   swiper = document.querySelector('.mySwiper1').swiper;
  // }, []);

  //Получение данных партнера

  const [myInfo, setMyInfo] = useState([]);

  const getMyInfo = async () => {
    let InfoList = await axios.get(`${BASE_URL}/client/partner/rating`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
    });
    setMyInfo(InfoList.data);
  };

  useEffect(() => {
    getMyInfo();
  }, []);

  //Получение топа партнеров

  const [topPartners, setTopPartners] = useState([]);

  const getTopPartners = async () => {
    let topPartner = await axios.get(`${BASE_URL}/client/info/top-partners?type=${sortType}`);
    setTopPartners(topPartner.data.partnerInfoList);
  };

  useEffect(() => {
    getTopPartners();
  }, [sortType]);

  console.log(myInfo);

  return (
    <div className={s.container}>
      <div className={s.partnerMyWorksRatingBlock}>
        <div className={s.partnerCardRatingBox}>
          <div className={s.patnerAbout}>
            <img className={s.avatar} src={avatarDefault} alt="Avatar" />
            <h3
              className={s.partnerName}
            >{`${myInfo.partnerInfo?.user?.name || ''} ${myInfo.partnerInfo?.user?.surname || ''}`}</h3>
            <span className={s.jobTitle}>Веб-дизайнер ТУТ НАДО МЕНЯТЬ</span>
          </div>
          <div className={s.partnerRatingBox}>
            <div className={s.ratingBox}>
              <h3 className={s.partenrRating}>{lang === 'ru' ? `Ваш рейтинг:` : `Your rating:`}</h3>
              <p className={s.numberOfSales}>
                {lang === 'ru' ? `По количеству продаж:` : `By number of sales:`}
                <span className={s.countSales}>
                  {myInfo.rating?.TopSales} {lang === 'ru' ? `место` : `place`}
                </span>
              </p>
              <p className={s.ByAddedToFavorites}>
                {lang === 'ru' ? `По добавленному в избранное:` : `By added to favorites:`}
                <span className={s.countFavorite}>
                  {myInfo.rating?.TopFavorite} {lang === 'ru' ? `место` : `place`}
                </span>
              </p>
              <p className={s.numberOfTemplates}>
                {lang === 'ru' ? `По количеству шаблонов:` : `Number of templates:`}
                <span className={s.countTemplates}>
                  {myInfo.rating?.TopTemplate}
                  {lang === 'ru' ? `место` : `place`}
                </span>
              </p>
            </div>
            <div className={s.countTemplateBox}>
              <p className={s.sales}>
                {lang === 'ru' ? `Продаж:` : `Sales`}

                <span className={s.countSales}>{myInfo.partnerInfo?.countSales}</span>
              </p>
              <p className={s.favorite}>
                {lang === 'ru' ? `В избранном:` : `Favorite:`}

                <span className={s.countFavorite}>{myInfo.partnerInfo?.countFavorite}</span>
              </p>
              <p className={s.template}>
                {lang === 'ru' ? ` Шаблонов:` : `Templates`}

                <span className={s.temlateCount}>{myInfo.partnerInfo?.countTemplates}</span>
              </p>
            </div>
          </div>
        </div>

        <div className={s.sortedBlock}>
          <span onClick={() => setActive(!active)} className={s.sortedMenu}>
            {sortedName}
          </span>
          <img
            style={{
              transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={s.arrowMenu}
            src={arrowMenu}
            alt="Arrow"
          />
          {active && (
            <div className={s.dropDownBox}>
              {sortedArr.map((obj) => (
                <span
                  key={obj.id}
                  onClick={() => {
                    setActive(!active);
                    setSortedName(lang === `ru` ? obj.ru : obj.en);
                    setActiveId(obj.id);
                    setSortType(obj.sortType);
                  }}
                  className={s[activeId === obj.id ? 'active' : 'disabled']}
                >
                  {lang === `ru` ? obj.ru : obj.en}
                </span>
              ))}
            </div>
          )}
        </div>
        <h3 className={s.ratingNumber}>{lang === 'ru' ? `Топ-10` : `Top-10`}</h3>
        <div className={s.partnersCardsBlock}>
          <Swiper
            ref={sliderRef}
            slidesPerView={4}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            spaceBetween={15}
            breakpoints={{
              0: {
                // width: 930,
                slidesPerView: 1.4,
                spaceBetween: 15,
              },
              280: {
                // width: 930,
                slidesPerView: 1.4,
                spaceBetween: 15,
              },
              380: {
                // width: 930,
                slidesPerView: 1.6,
                spaceBetween: 15,
              },
              480: {
                // width: 930,
                slidesPerView: 2,
                spaceBetween: 15,
              },
              756: {
                // width: 930,
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1060: {
                // width: 930,
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1360: {
                // width: 930,
                slidesPerView: 4,
                spaceBetween: 30,
              },
              2920: {
                // width: 930,
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            pagination={{
              dynamicBullets: true,
            }}
            // style={{ paddingBottom: '15px', paddingTop: '15px', width: '1309px' }}
            modules={[Pagination, Navigation]}
            className={s.mySwiper5}
          >
            {topPartners?.map((obj) => (
              <SwiperSlide key={obj.id} className={s.cardsBox}>
                <div className={s.aboutPartnersBox}>
                  <img className={s.star} src={star} alt="star" />
                  <img className={s.avatar} src={obj.user.avatar || avatarDefault} alt="Avatar" />
                  <h3 className={s.partnersName}>{obj.user.name}</h3>
                  <span className={s.jobTitle}>Веб-разработчик МЕН</span>
                </div>
                <div className={s.infoBox}>
                  <span className={s.sales}>
                    {lang === 'ru' ? ` Продаж:` : `Sales:`}
                    {obj.countSales}
                  </span>
                  <span className={s.inFavorites}>
                    {lang === 'ru' ? `В избранном:` : `Favorite:`}
                    {obj.countFavorite}
                  </span>
                  <span className={s.templates}>
                    {lang === 'ru' ? ` Шаблонов:` : `Templates:`}
                    {obj.countTemplates}
                  </span>
                  <span className={s.earnedFrom}>
                    {lang === 'ru' ? `Заработано от ` : `Earned from `}
                    {obj.earning} {lang === 'ru' ? ` тыс:` : `thousand`}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={s.buttonBox}>
            <button className={s.arrowBtnPrev} onClick={handlePrev}>
              <ArrowLeft className={s.arrowPrev} src={Arrow} alt="Arrow" />
            </button>
            <button onClick={handleNext} className={s.arrowBtnNext}>
              <Arrow className={s.arrowNext} src={Arrow} alt="Arrow" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerMyWorksRating;
