import React, { useEffect } from 'react';

import s from './ButtonTemplates.module.scss';
import { ReactComponent as Arrow } from '../../../assets/iconUi/arrow.svg';
import { ReactComponent as Circle } from '../../../assets/iconUi/circle.svg';

function ButtonTemplates({ children, className, onClick = () => {} }) {
  useEffect(() => {

    
  }, [className]);
  return (
    <button onClick={onClick} className={`${s.buttonTemplates} + ' ' + ${className}`}>
      {children}
      <div className={s.buttonGroup}>
        <Arrow className={s.arrow} src={Arrow} alt="Стрелка" />
        <Circle className={s.circle} src={Circle} alt="Круг" />
      </div>
    </button>
  );
}

export default ButtonTemplates;
